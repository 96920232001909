import React from 'react';
import reactStringReplace from 'react-string-replace';

const Location = (props) => {
  const {
    searchText,
    searchable_id,
    searchable_type,
    name,
    description,
    changeLocation,
  } = props;

  const handleClick = () => {
    changeLocation(name, searchable_id);
  };

  const renderName = () => {
    const regex = new RegExp(`(${searchText})`, 'i');
    return reactStringReplace(name, regex, match => <strong>{match}</strong>);
  };

  return (
    <div className="autocomplete-suggestion" onClick={handleClick}>
      <div className="cont">
        <div className="text">
          <div className="headline">
            {renderName()}
          </div>
          <div className="description">
            {searchable_type === 'Location' && (
              description.substring(description.indexOf(',') + 2, description.length)
            )}
          </div>
        </div>
        <div className="badges">
          <div className="type">
            {searchable_type === 'Location' ? (
              'Area'
            ) : (
              searchable_type
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;

import React, {Component} from 'react';
import Dropzone from 'react-dropzone';

// import {saveMarker, markerKindOptions, markerIsCommercial, addMarker, removeMarker} from '../util';

class CragEditPopup extends Component {

  constructor(props) {
    super(props);
    const {map} = props;
    this.state = {
      inputs: {
        name: '',
        lat: map.getCenter().lat.toFixed(4),
        lng: map.getCenter().lng.toFixed(4),
      }
    };
  }

  handleInputChange = (input, e) => {
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        [input]: e.target.value,
      },
    });
  }

  handleOutsideClick = e => {
    if (e.target.className === 'map-popup-container') {
      this.cancel();
    }
  }

  cancel = e => {
    const {marker} = this.props;
    this.props.hide();
  }

  save = e => {
    const {marker, map} = this.props;
    // saveMarker(
    //   marker,
    //   {
    //     ...marker.properties.marker,
    //     ...this.state.inputs,
    //   },
    //   map,
    //   'other-markers',
    // );
    this.props.hide();
  }

  render() {
    const {inputs} = this.state;

    return (
      <div className="map-popup-container" onClick={this.handleOutsideClick}>
        <div className="mapbox" style={{maxHeight: 630, width: 400, overflow: 'auto'}}>
          <form action={`/crags`} method={`post`}>
            <h4>{'Add new crag'}</h4>
            <div className="form-group">
              <label htmlFor="map-marker-name">Name</label>
              <input type="text" name="crag[name]" id="map-marker-name" value={inputs.name}
                     onChange={this.handleInputChange.bind(this, 'name')}/>
            </div>
            <div className="form-group">
              <label htmlFor="map-marker-lat">Latitude</label>
              <input type="text" name="crag[latitude]" id="map-marker-lat" value={inputs.lat}
                     onChange={this.handleInputChange.bind(this, 'lat')}/>
            </div>
            <div className="form-group">
              <label htmlFor="map-marker-lng">Longitude</label>
              <input type="text" name="crag[longitude]" id="map-marker-lng" value={inputs.lng}
                     onChange={this.handleInputChange.bind(this, 'lng')}/>
            </div>
            <div className="marker-info-footer">
              <button type={`submit`} className="btn btn-primary">Save</button>
              {' '}
              <button className="btn btn-default" onClick={this.cancel}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CragEditPopup;

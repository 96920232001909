import React, { Component } from "react";
import DropDownFilter from '../../common/components/DropDownFilter';
import StarRatingSelectField from "./StarRatingSelectField";

class RatingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredRating: this.props.rating
    };
    this.updateHoveredRating = this.updateHoveredRating.bind(this);
    this.updateRating = this.updateRating.bind(this);
  }

  updateHoveredRating(rating) {
    this.setState({
      hoveredRating: rating
    });
  }

  updateRating(rating) {
    this.setState({ hoveredRating: rating });
    this.props.changeRating(rating);
  }

  render() {
    const { rating } = this.props;
    const btnClass =
      rating[0] == 0 && rating[1] == 3 ? "btn btn-default" : "btn btn-primary";
    return (
      <DropDownFilter
        titleButton={"Rating"}
        titleHeader={"Rating"}
        titleClose={"Apply"}
        showDropdown={this.props.showDropdown}
        hideDropdown={this.props.hideDropdown}
        btnClass={btnClass}
      >
        <ul className="premium-search-filter__checkbox-container">
          <div className="rating-star-container">
            <StarRatingSelectField
              isLower={true}
              rating={rating}
              hoveredRating={this.state.hoveredRating}
              updateHoveredRating={this.updateHoveredRating}
              updateRating={this.updateRating}
            />
            -{" "}
            <StarRatingSelectField
              isLower={false}
              rating={rating}
              hoveredRating={this.state.hoveredRating}
              updateHoveredRating={this.updateHoveredRating}
              updateRating={this.updateRating}
            />
          </div>
        </ul>
      </DropDownFilter>
    );
  }
}

export default RatingButton;

import * as types from '../common/actionTypes'

const reducer = (_climber, following, apiUrl, grades, gradingSystems) => {
  const initialState = {
    apiUrl,
    following,
    climber: {
      ..._climber,
      boulders: _climber.boulders === 'true',
      ice: _climber.ice === 'true',
      mountains: _climber.mountains === 'true',
      sport: _climber.sport === 'true',
      trad: _climber.trad === 'true',
      location_id: _climber.location_id ? _climber.location_id : 0,
      follow_enabled: true,
      grading_system_sport: _climber.grading_system_sport ? _climber.grading_system_sport : 'French',
      grading_system_boulder: _climber.grading_system_boulder ? _climber.grading_system_boulder : 'Font',
      grading_system_trad: _climber.grading_system_trad ? _climber.grading_system_trad : 'French',
      grade_int: _climber.grade_int ? _climber.grade_int : 100,
    },
    grades,
    gradingSystems: JSON.parse(gradingSystems),
    searchedClimbers: [],
    searchedLocations: [],
    showLocationResults: true,
    searchDone: false,
    onboardingProgressVisible: true,
  }

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `${types.CONTINUE_ONBOARDING}_PENDING`:
        return {
          ...state,
          climber: {
            ...state.climber,
            onboarding_state: payload,
          },
        }
      case `${types.CONTINUE_ONBOARDING}_FULFILLED`:
        return {
          ...state,
          climber: {
            ...state.climber,
            onboarding_state: state.climber.onboarding_state === '4' ? '5' : state.climber.onboarding_state,
          },
        }
      case types.CHANGE_GENRE_SELECTED:
        return {
          ...state,
          climber: {
            ...state.climber,
            boulders: payload === 'boulders' ? !state.climber.boulders : state.climber.boulders,
            trad: payload === 'trad' ? !state.climber.trad : state.climber.trad,
            sport: payload === 'sport' ? !state.climber.sport : state.climber.sport,
            mountains: payload === 'mountains' ? !state.climber.mountains : state.climber.mountains,
            ice: payload === 'ice' ? !state.climber.ice : state.climber.ice,
          },
        }
      case types.CHANGE_GRADE:
        return {
          ...state,
          climber: {
            ...state.climber,
            grade_int: payload,
          },
        }
      case types.CHANGE_GRADING_SYSTEM_SPORT:
        return {
          ...state,
          climber: {
            ...state.climber,
            grading_system_sport: payload,
          },
        }
      case types.CHANGE_GRADING_SYSTEM_BOULDER:
        return {
          ...state,
          climber: {
            ...state.climber,
            grading_system_boulder: payload,
          },
        }
      case types.CHANGE_GRADING_SYSTEM_TRAD:
        return {
          ...state,
          climber: {
            ...state.climber,
            grading_system_trad: payload,
          },
        }
      case `${types.SEARCH_LOCATIONS}_FULFILLED`:
        return {
          ...state,
          searchedLocations: payload.data.search_keys,
        }
      case types.CHANGE_LOCATION:
        return {
          ...state,
          climber: {
            ...state.climber,
            home_town: payload.location,
            location_id: payload.locationId,
          },
          showLocationResults: !payload.locationId,
        }
      case types.CHANGE_SEX:
        return {
          ...state,
          climber: {
            ...state.climber,
            sex: payload,
          },
        }
      case types.TOGGLE_FOLLOW_ENABLED:
        return {
          ...state,
          climber: {
            ...state.climber,
            follow_enabled: !state.climber.follow_enabled,
          },
        }
      case `${types.FETCH_CLIMBERS_BY_LOCATION}_FULFILLED`:
        return {
          ...state,
          searchedClimbers: payload.data.climbers,
        }
      case `${types.SEARCH_CLIMBERS}_FULFILLED`:
        return {
          ...state,
          searchedClimbers: payload.data.climbers,
          searchDone: true,
        }
      case `${types.TOGGLE_FOLLOW}_FULFILLED`:
        for (let i = 0; i < state.following.length; i += 1) {
          if (state.following[i].followee_id === payload.data.followee_id) {
            return {
              ...state,
              following: state.following.filter((follow) => follow.followee_id !== payload.data.followee_id),
            }
          }
        }
        return {
          ...state,
          following: state.following.concat(payload.data),
        }
      default:
        return state
    }
  }
}

export default reducer

import React, {Component} from 'react'
import {debounce} from 'throttle-debounce'
import Map from './map/Container'
import List from './List'
import Filters from './Filters'
import Crag from './Crag'
import MarkerEditPopup from './MarkerEditPopup'
import CragEditPopup from './CragEditPopup'
import CommercialMarkerPopup from './CommercialMarkerPopup'
import ResizeAware from 'react-resize-aware'

class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.filterMarkers = debounce(800, this.filterMarkers)
    this.sortCrags = debounce(800, this.sortCrags)
    this.state = {
      infoWindow: null,
      cragWindowVisible: false,
      cragLocationSelectVisible: false,
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const premium = urlParams.get('premium')
    if (premium === 'true' && !this.props.filters.freeCrags) {
      this.props.toggleFilter('freeCrags')
      this.filterMarkers()
    }
  }

  toggleViewClick = e => {
    this.props.toggleView()
  }

  toggleFiltersVisibilityClick = e => {
    this.props.toggleFiltersVisibility()
  }

  showAddNewCrag = () => {
    this.setState({cragWindowVisible: true, cragLocationSelectVisible: false});
  }

  hideAddNewCrag = () => {
    this.setState({cragWindowVisible: false, cragLocationSelectVisible: false});
  }

  addCragClick = e => {
    this.setState({cragLocationSelectVisible: true});
  }

  cancelAddCrag = e => {
    this.setState({cragLocationSelectVisible: false, cragWindowVisible: false});
  }

  filterMarkers = () => {
    const markerClickListener = cragId => {
      this.props.highlightCrag(cragId)
    }
    this.props.filterMarkers(markerClickListener)
  }

  filterAdditionalMarkers = () => {
    this.props.filterAdditionalMarkers()
  }

  sortCrags = () => {
    if (this.props.listCrags.length > 0) {
      this.props.sortCrags()
    }
  }

  resizeMapbox = ({width, height}) => {
    const {map} = this.props.mapbox
    if (height < window.innerHeight * 0.8) {
      map.scrollZoom.disable()
      map.boxZoom.enable()
    } else {
      map.boxZoom.disable()
      map.scrollZoom.enable()
    }
    map.resize()
  }

  openCommercialMarkerPopup = marker => {
    this.props.highlightCrag(null)
    this.props.setCommercialMarkerPopup(true, marker)
  }

  highlightCrag = cragId => {
    const marker = this.props.mapbox.cragMarkers.features[cragId]
    const {selectedCrag, mapbox, cragPopupVisible} = this.props
    if (marker) {
      marker.properties.icon = marker.properties.iconHighlight
      if (cragPopupVisible) {
        const crag = mapbox.cragMarkers.features[selectedCrag.id]
        mapbox.map.getSource('highlighted').setData({type: 'FeatureCollection', features: [marker, crag]})
      } else {
        mapbox.map.getSource('highlighted').setData({type: 'FeatureCollection', features: [marker]})
      }
    }
  }

  unHighlightCrag = cragId => {
    const selectedCragId = this.props.selectedCrag ? this.props.selectedCrag.id : null
    if (this.props.cragPopupVisible) {
      const marker = this.props.mapbox.cragMarkers.features[selectedCragId]
      if (marker) {
        this.props.mapbox.map.getSource('highlighted').setData({type: 'FeatureCollection', features: [marker]})
      }
    } else {
      this.props.mapbox.map.getSource('highlighted').setData({type: 'FeatureCollection', features: []})
    }
  }

  render() {
    const {
      mapbox,
      showList,
      showFilters,
      listCrags,
      listCommercialMarkers,
      selectedCrag,
      cragPopupVisible,
      sort,
      sortGrades,
      view,
      filters,
      icons,
      markerEditPopup,
      cragEditPopup,
      commercialMarkerPopup,
      toggleFilter,
      setSort,
      setMarkerEditPopup,
      setCommercialMarkerPopup,
      newTab,
    } = this.props

    const getFiltersIcon = () => {
      if (
        filters.freeCrags &&
        ((filters.sport && filters.trad && filters.boulder && filters.dws) ||
          (!filters.sport && !filters.trad && !filters.boulder && !filters.dws))
      ) {
        return <i className="glyphicon glyphicon-filter"/>
      }
      let filterCount = 0
      ;[filters.freeCrags, filters.boulder, filters.sport, filters.trad, filters.dws].forEach(enabled => {
        if (!enabled) {
          filterCount++
        }
      })
      return <span className="filter-count">{filterCount}</span>
    }

    let mapControlsContainerClass = 'full-height'
    if (listCrags.length > 0 || listCommercialMarkers.length > 0) {
      mapControlsContainerClass += ' map-controls-visible'
    } else {
      mapControlsContainerClass += ' map-controls-small'
    }
    if (view === 'list') {
      mapControlsContainerClass += ' map-controls-mobile-visible'
    }

    return (
      <ResizeAware className="map-container" onResize={this.resizeMapbox}>
        {markerEditPopup.visible && (
          <MarkerEditPopup
            marker={markerEditPopup.mapboxMarker}
            icons={icons}
            map={mapbox.map}
            hide={() => setMarkerEditPopup(false, null)}
          />
        )}
        {this.state.cragWindowVisible && (
          <CragEditPopup
            map={mapbox.map}
            hide={() => this.hideAddNewCrag()}
          />
        )}
        {commercialMarkerPopup.visible && (
          <CommercialMarkerPopup
            marker={commercialMarkerPopup.mapboxMarker}
            icons={icons}
            hide={() => setCommercialMarkerPopup(false, null)}
          />
        )}
        {showList && !this.state.cragLocationSelectVisible && !this.state.cragWindowVisible && (
          <div id="mapControlsContainer" className={mapControlsContainerClass}>
            <div className="controls-container">
              <List
                mapbox={mapbox}
                crags={listCrags}
                selectedCrag={selectedCrag}
                icons={icons}
                commercialMarkers={listCommercialMarkers}
                visible={view === 'list'}
                toggleFiltersVisibility={this.toggleFiltersVisibilityClick}
                onCragMouseEnter={this.highlightCrag}
                onCragMouseLeave={this.unHighlightCrag}
                commercialMarkerClick={this.openCommercialMarkerPopup}
                cragPopupVisible={cragPopupVisible}
                newTab={newTab}
              />
            </div>
          </div>
        )}
        <Map infoWindow={this.state.infoWindow} setInfoWindow={infoWindow => this.setState({infoWindow})}/>
        {
          <Filters
            visible={filters.visible}
            filters={filters}
            icons={icons}
            sort={sort}
            sortGrades={sortGrades}
            toggleFilter={filter => {
              toggleFilter(filter)
              this.filterMarkers()
            }}
            toggleCommercialFilter={filter => {
              toggleFilter(filter)
              this.filterAdditionalMarkers()
            }}
            setSort={(sort, isGradeSort) => {
              setSort(sort, isGradeSort)
              this.sortCrags()
            }}
            toggleFiltersVisibility={this.toggleFiltersVisibilityClick}
          />
        }
        {(showList || showFilters) && (
          <div
            className={`map-buttons-container ${showList && !showFilters && 'map-buttons-container-hidden-desktop'}`}
          >
            <div className="map-buttons">
              {showList && view === 'map' && (
                <div className="button hidden-sm hidden-md hidden-lg" onClick={this.toggleViewClick}>
                  List <i className="glyphicon glyphicon-list"/>
                </div>
              )}
              {showList && view === 'list' && (
                <div className="button hidden-sm hidden-md hidden-lg" onClick={this.toggleViewClick}>
                  Map <i className="glyphicon glyphicon-map"/>
                </div>
              )}
              {showList && showFilters && <div className="divider hidden-sm hidden-md hidden-lg"/>}
              {showFilters && (
                <div className="button" onClick={this.toggleFiltersVisibilityClick} style={{paddingBottom: '9px'}}>
                  Filters {getFiltersIcon()}
                </div>
              )}
            </div>
          </div>
        )}
        <div className={`map-add-crag-button-container`}>
          <button className="btn btn-default" onClick={this.addCragClick}>Add new crag</button>
        </div>
        {this.state.cragLocationSelectVisible && (
          <div className={`map-add-crag-container`}>
            <div className={`header-container`}>
              <div className={`action-left`}>
                <button className={`btn btn-default`} onClick={this.cancelAddCrag}>Cancel</button>
              </div>
              <div className={`title`}>Move the map to select a location for the new crag</div>
              <div className={`action-right`}>
                <button className={`btn btn-default`} onClick={this.showAddNewCrag}>Ok</button>
              </div>
            </div>
            <div className={`pointer-container`}>
              <i className="glyphicon glyphicon-screenshot pointer"></i>
            </div>
          </div>
        )}
        <div className={`crag-popup ${cragPopupVisible && 'crag-popup-visible'}`}>
          {selectedCrag && <Crag {...selectedCrag} newTab={newTab}/>}
        </div>
      </ResizeAware>
    )
  }
}

export default MapContainer

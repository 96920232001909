import React from 'react';

const FeedEmbed = ({ thumbnailUrl, url, providerName, description, isLandscape }) => {
  if (thumbnailUrl) {
    return (
      <a className="feed-embed" href={url} target="_blank">
        <div className={isLandscape ? 'embed-landscape' : 'embed-portrait'}>
          <div className="embed-photo" style={{ backgroundImage: `url(${thumbnailUrl})` }}>
            <img src={thumbnailUrl} alt="embed_photo" style={{ display: 'block', maxWidth: '100%', visibility: 'hidden' }} />
          </div>
          <div className="content">
            <div className="title">{providerName}</div>
            <div className="text">{description}</div>
            <div className="url">{url}</div>
          </div>
        </div>
      </a>
    );
  }
  return (
    <a href={url}>{title}</a>
  );
};

export default FeedEmbed;

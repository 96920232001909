import * as types from '../../common/actionTypes';
import moment from "moment";

const visitsReducer = (crags, paramId) => {
  const initialState = {
    crags: crags,
    paramId: paramId,
    visits: [],
    loading: true,
    startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `${types.GET_VISITS_DATA}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${types.GET_VISITS_DATA}_ERROR`:
        return {
          ...state,
          loading: false,
        };
      case `${types.GET_VISITS_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
          visits: payload.data.visits,
        };

      default:
        return {
          ...state,
          loading: false
        };
    }
  };
};
export default visitsReducer;

import React, { Component } from "react";

class StarSpan extends Component {
  render() {
    let rating_left = this.props.rating;
    let parent = [];
    for (let i = 0; i < 3; i++) {
      if (rating_left > 0.75) {
        parent.push(
          <div key={i} className="star full glyphicon glyphicon-star" />
        );
        rating_left -= 1;
      } else if (rating_left > 0.25) {
        parent.push(
          <div key={i} className="star half glyphicon glyphicon-star-empty">
            <div className="star full glyphicon glyphicon-star" />
          </div>
        );
        rating_left -= 0.5;
      } else if (this.props.includeEmptyStars) {
        parent.push(
          <div key={i} className="star empty glyphicon glyphicon-star-empty" />
        );
      }
    }
    return <span className={this.props.spanClass}>{parent}</span>;
  }
}

export default StarSpan;

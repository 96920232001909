import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';
import Container from './Container';

const OnboardingApp = () => {
  const store = ReactOnRails.getStore('sharedStore');

  return (
    <Provider store={store}>
      <Container />
    </Provider>
  );
};

export default OnboardingApp;

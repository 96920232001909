//-------------------------------------------------
//		Quick Pager jquery plugin
//		Created by dan and emanuel @geckonm.com
//		www.geckonewmedia.com
//
//
//		18/09/09 * bug fix by John V - http://blog.geekyjohn.com/
//		1.2 - allows reloading of pager with new items
//-------------------------------------------------

(function($) {

  $.fn.quickPager = function(options) {

    var defaults = {
      pageSize: 10,
      currentPage: 1,
      holder: null,
      pagerLocation: "after",
      onPageChange: null
    };

    var options = $.extend(defaults, options);



    return this.each(function() {

      var selector = $(this);
      var pageCounter = 1;


      var onPageActive = function(items){
        if(typeof options.onPageChange === 'function') {
          options.onPageChange(items);
        }
      };


      selector.wrap("<div class='simplePagerContainer'></div>");

      selector.parents(".simplePagerContainer").find("ul.pagination").remove();

      selector.children().each(function(i){

        if(i < pageCounter*options.pageSize && i >= (pageCounter-1)*options.pageSize) {
          $(this).addClass("simplePagerPage"+pageCounter);
        }
        else {
          $(this).addClass("simplePagerPage"+(pageCounter+1));
          pageCounter ++;
        }

      });

      // show/hide the appropriate regions
      selector.children().hide();
      selector.children(".simplePagerPage"+options.currentPage).show();

      if(pageCounter <= 1) {
        return;
      }

      //Build pager navigation
      var pageNav = "<ul class='pagination'>";
      for (i=1;i<=pageCounter;i++){
        if (i==options.currentPage) {
          pageNav += "<li class='active simplePageNav"+i+"'><a rel='"+i+"' href='#'>"+i+"</a></li>";
        }
        else {
          pageNav += "<li class='simplePageNav"+i+"'><a rel='"+i+"' href='#'>"+i+"</a></li>";
        }
      }
      pageNav += "</ul>";


      if(!options.holder) {
        switch(options.pagerLocation)
        {
          case "before":
            selector.before(pageNav);
            break;
          case "both":
            selector.before(pageNav);
            selector.after(pageNav);
            break;
          default:
            selector.after(pageNav);
        }
      }
      else {
        $(options.holder).append(pageNav);
      }

      onPageActive(selector.find(".simplePagerPage1"));

      //pager navigation behaviour
      selector.parent().find(".pagination a").click(function() {

        //grab the REL attribute
        var clickedLink = $(this).attr("rel");
        options.currentPage = clickedLink;

        if(options.holder) {
          $(this).parent("li").parent("ul").parent(options.holder).find("li.active").removeClass("active");
          $(this).parent("li").parent("ul").parent(options.holder).find("a[rel='"+clickedLink+"']").parent("li").addClass("active");
        }
        else {
          //remove current current (!) page
          $(this).parent("li").parent("ul").parent(".simplePagerContainer").find("li.active").removeClass("active");
          //Add current page highlighting
          $(this).parent("li").parent("ul").parent(".simplePagerContainer").find("a[rel='"+clickedLink+"']").parent("li").addClass("active");
        }


        //hide and show relevant links
        selector.children().hide();
        selector.find(".simplePagerPage"+clickedLink).show();

        onPageActive(selector.find(".simplePagerPage"+clickedLink));

        return false;
      });
    });
  }


})(jQuery);
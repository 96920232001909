
window.EditableCragMap = function() {
  
  var views = CragMapViews();
  var json = arguments[0] || {};
  var map = CragMap(json);
  var g_map = map.g_map;
  var info_window;

  function open_info_window(content, latlng_or_marker, close_callback, keep_unsaved_symbol, ready_callback) {
    close_info_window(keep_unsaved_symbol);
    info_window = new google.maps.InfoWindow({content: content});
    if ( ready_callback ) {
      var ready_event = google.maps.event.addListener(info_window, 'domready', function() {
        google.maps.event.removeListener(ready_event);
        ready_callback();
      });
    }
    // border-radius hack
    google.maps.event.addListener(info_window, 'domready', function(){
      $('div.mapbox').parent().parent().prev().css('border-radius', '3px');
      $('table.mapbox').parent().parent().parent().prev().css('border-radius', '3px');
    });
    // end of border-radius hack
    if ( latlng_or_marker.get ) {
      info_window.open(g_map, latlng_or_marker);
    } else {
      info_window.setPosition(latlng_or_marker);
      info_window.open(g_map);
    }
    if ( close_callback ) {
      google.maps.event.addListener(info_window, 'closeclick', close_callback);
    }
  }

  function close_info_window(keep_unsaved_symbol) {
    if ( info_window ) {
      info_window.close();
      if ( !keep_unsaved_symbol ) google.maps.event.trigger(info_window, 'closeclick');
      info_window = null;
    }
  }

  function resize_info_window() {
    if ( info_window ) info_window.setContent(info_window.getContent());
  }

  var helper_overlay = new google.maps.OverlayView();
  helper_overlay.draw = function(){};
  helper_overlay.setMap(g_map);

  function zoom_by(delta) {
    var new_zoom = g_map.getZoom() + delta;
    if ( new_zoom < 0 ) return;
    var projection = helper_overlay.getProjection();
    if ( projection ) { // projection may be undefined (if the overlay is not ready I guess)
      var center = projection.fromLatLngToContainerPixel(g_map.getCenter());
      var focus  = projection.fromLatLngToContainerPixel(info_window.getPosition());
      for (var i = Math.abs(delta); i > 0; i--)
        center = (delta > 0) ?
          new google.maps.Point((center.x + focus.x) / 2, (center.y + focus.y) / 2) :
          new google.maps.Point(center.x * 2 - focus.x, center.y * 2 - focus.y);
      g_map.setCenter(projection.fromContainerPixelToLatLng(center));
    }
    g_map.setZoom(new_zoom);
  }

  function activate_zoom_controls() {
    $('.zoomIn2' ).click(function(){ zoom_by(2);  });
    $('.zoomIn1' ).click(function(){ zoom_by(1);  });
    $('.zoomOut1').click(function(){ zoom_by(-1); });
    $('.zoomOut2').click(function(){ zoom_by(-2); });
  }
  
  function new_attr_hash(kind, latlng, end_latlng) {
    var controller = kind + 's';
    if ( kind == 'parking_space' ) controller = 'map_markers';
    var url = json.crag.url + '/' + controller + '/new';
    if ( kind == 'sector' && !end_latlng ) end_latlng = latlng;
    return {url: url, kind: kind, latlng: latlng, end_latlng: end_latlng};
  }
  
  // Show the view to create a new marker
  function new_marker(kind, latlng) {
    close_info_window();
    var new_marker = map.add_marker(new_attr_hash(kind, latlng));
    new_marker.to_dom();
    if ( kind != 'sector' ) activate(new_marker);
    return new_marker;
  }
  
  function activate(marker) {
    marker.enable_dragging();
    marker.reveal();
  }
  
  function new_sector(latlng) {
    var sector = new_marker('sector', latlng);
    MapTooltip.off();
    var move_event = google.maps.event.addListener(g_map, 'mousemove', function(event) {
      sector.markers.last().set_latlng(event.latLng);
      sector.overlay().redraw();
    });
    var click_event = google.maps.event.addListener(g_map, 'click', function(event) {
      if ( event.latLng ) {
        google.maps.event.removeListener(move_event);
        google.maps.event.removeListener(click_event);
        activate(sector);
        MapTooltip.on();
      }
    });
  }
  
  function activate_marker_buttons(latlng) {
    $('.addStartingPoint').click(function(e){ e.stopPropagation(); new_sector(latlng); });
    $('.addBoulder').click(      function(e){ e.stopPropagation(); new_marker('boulder', latlng); });
    $('.addParkingSpace').click( function(e){ e.stopPropagation(); new_marker('parking_space', latlng); });
  }
  
  function make_editable() {
    map.set_edit_mode(true);
    map.show_details();
    google.maps.event.addListener(g_map, 'click', function(event) {
      if ( event.latLng ) { // clicks close to the info window trigger a second event without latlng [IN MAPS V2]
        open_info_window(views.marker_buttons(), event.latLng, null, null, function() {
          activate_zoom_controls();
          activate_marker_buttons(event.latLng);
        });
      }
    });
    // make markers draggable
    map.each_marker('enable_dragging');
    // TODO: display instructions
  }
  
  function save_general_marker(latlng) {
    app.wait();
    close_info_window();
    $('#engage').hide();
    map.add_general_marker({url: json.crag.url, latlng: latlng});
    var handle = google.maps.event.addListener(g_map, 'moveend', function() {
      google.maps.event.removeListener(handle);
      setTimeout(function() {
        if      ( g_map.getZoom() <=  6 ) g_map.setZoom(10);
        else if ( g_map.getZoom() <= 10 ) g_map.setZoom(12);
        else if ( g_map.getZoom() <= 14 ) g_map.setZoom(15);
      }, 300);
    });
    g_map.panTo(latlng);
    rails_helpers.update(json.crag.url, {crag: {latitude: latlng.lat(), longitude: latlng.lng()}}, function(response) {
      var success_message = '<h3>Thanks for your contribution!</h3><p>The map marker was saved. Drag and drop to move the marker, or ' +
      'click the map to zoom and add details such as crag sectors, boulders or parking spaces.</p>';
      if ( map.markers[json.crag.url].update_attributes(response, success_message) ) {
        make_editable();

        // todo: remove this ugly thing
        if($('#todo_set_crag_location')[0]) {
          $('#todo_set_crag_location').css('text-decoration', 'line-through');
        }
        app.close_dialog();
      }
    });
  }

  console.log('CLICK');
  // The constructor
  if ( json.crag ) {
    if ( !map.has_location() ) {
      // we don't have any location information => add event listener to ask for the general marker
      google.maps.event.addListener(g_map, 'click', function(event) {
        if ( event.latLng ) { // clicks close to the info window trigger a second event without latlng [IN MAPS V2]
          open_info_window(views.area_location(json.crag.name), event.latLng, null, null, function() {
            activate_zoom_controls();
            $('.saveAreaLocation').click(function(e){ e.stopPropagation(); save_general_marker(event.latLng); });
          });
        }
      });
    } else { // we have at least a general marker => make_editable
      make_editable();
    }
  }
  map.open_info_window = open_info_window;
  map.close_info_window = close_info_window;
  map.resize_info_window = resize_info_window;
  return map;
}

import ReactOnRails from 'react-on-rails'
import configureStore from '../bundles/React/common/store';
import Mapbox from '../bundles/React/mapbox/App';
import Feed from '../bundles/React/feed/App';
import RouteComments from '../bundles/React/routeComments/App';
import FeedItem from '../bundles/React/feedItem/App';
import GenericFeed from '../bundles/React/genericFeed/App';
import CragFeed from '../bundles/React/cragFeed/App';
import Onboarding from '../bundles/React/onboarding/App';
import onboardingReducer from '../bundles/React/onboarding/reducer';
import RouteList from '../bundles/React/routeList/App';
import PremiumSearch from '../bundles/React/premiumSearch/App';
import Visits from '../bundles/React/cragAnalytics/visits/App';
import TopoValue from '../bundles/React/cragAnalytics/topoValue/App';
import Nav from '../bundles/React/nav/App';
import AnnualSummary from '../bundles/React/annualSummary/App';
import GradeOpinions from '../bundles/React/gradeOpinions/App';

const store = (railsProps) => {
    if (railsProps.climber) {
        return configureStore(
            onboardingReducer(
                JSON.parse(railsProps.climber),
                railsProps.following,
                railsProps.apiUrl,
                JSON.parse(railsProps.grades),
                railsProps.gradingSystems,
            ),
        );
    }
    return {};
};

ReactOnRails.registerStore({
    sharedStore: store,
});

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    Mapbox,
    Feed,
    RouteComments,
    FeedItem,
    GenericFeed,
    CragFeed,
    Onboarding,
    RouteList,
    Nav,
    PremiumSearch,
    Visits,
    TopoValue,
    AnnualSummary,
    GradeOpinions
})

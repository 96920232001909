import axios from 'axios'
import * as types from '../common/actionTypes'
import { holdHash, steepnessHash, styleHash, filterHash, typesArr } from '../common/util.js'
import * as apiEndpoints from '../common/apiEndpoints'

const findRoutes = (filters, rating, gradeMin, gradeMax, routes) => {
  const filtered = []
  for (let i = 0; i < routes.length; i++) {
    var include = true
    const holds = filters.filter((f) => Object.keys(holdHash).includes(f))
    const steepness = filters.filter((f) => Object.keys(steepnessHash).includes(f))
    const styles = filters.filter((f) => Object.keys(styleHash).includes(f))
    const types = filters.filter((f) => typesArr.includes(f))
    if (holds.length > 0 && !holds.some((hold) => routes[i][hold])) include = false
    if (steepness.length > 0 && !steepness.some((steep) => routes[i][steep])) include = false
    if (styles.length > 0 && !styles.some((style) => routes[i][style])) include = false
    if (types.length > 0 && !types.some((type) => routes[i].genre == type)) include = false
    if (include) {
      const grades = Object.keys(GRADES_HASH)
      if (rating.length == 2 && grades.includes(gradeMin.toString()) && grades.includes(gradeMax.toString())) {
        if (
          parseFloat(routes[i].rating) >= parseFloat(rating[0]) &&
          parseFloat(routes[i].rating) <= parseFloat(rating[1])
        ) {
          if (
            parseInt(routes[i].grade_int) <= parseInt(gradeMax) &&
            parseInt(routes[i].grade_int) >= parseInt(gradeMin)
          ) {
            filtered.push(routes[i])
          }
        }
      } else if (grades.includes(gradeMin.toString()) && grades.includes(gradeMax.toString())) {
        if (
          parseInt(routes[i].grade_int) <= parseInt(gradeMax) &&
          parseInt(routes[i].grade_int) >= parseInt(gradeMin)
        ) {
          filtered.push(routes[i])
        }
      } else filtered.push(routes[i])
    }
  }
  return filtered
}

export const filterRoutes = (filters, rating, gradeMin, gradeMax, orderer = 'name', order_dir = 'asc') => async (
  dispatch,
  getState,
) => {
  const ordered = await orderRoutes(orderer, order_dir, getState().routeList.routes)
  const filtered = await findRoutes(filters, rating, gradeMin, gradeMax, ordered)
  dispatch({
    type: types.FILTER_ROUTES,
    payload: filtered,
  })
}

export const orderBy = (orderer, order_dir) => (dispatch, getState) => {
  const routes = getState().routeList.filteredRoutes
  let payload = orderRoutes(orderer, order_dir, routes)
  dispatch({
    type: types.ORDER_BY,
    payload: payload,
  })
}

const orderRoutes = (orderer, order_dir, routes) => {
  let ordered
  if (orderer == 'property') {
    ordered =
      order_dir == 'asc'
        ? routes.sort((a, b) =>
            Object.keys(filterHash).filter((key) => a[key]).length >
            Object.keys(filterHash).filter((key) => b[key]).length
              ? 1
              : -1,
          )
        : routes.sort((a, b) =>
            Object.keys(filterHash).filter((key) => a[key]).length <
            Object.keys(filterHash).filter((key) => b[key]).length
              ? 1
              : -1,
          )
  } else {
    ordered =
      order_dir == 'asc'
        ? routes.sort((a, b) => (a[orderer] > b[orderer] ? 1 : -1))
        : routes.sort((a, b) => (a[orderer] < b[orderer] ? 1 : -1))
  }
  return ordered
}

export const searchPhotos = (query) => (dispatch, getState) => {
  const searchedPhotos = getState().routeList.searchedPhotos
  var photoIds = []
  for (let i = 0; i < searchedPhotos.length; i++) {
    photoIds.push(searchedPhotos[i].map((photo) => photo.id))
  }
  const filteredQuery = query.filter((id) => !photoIds.flat().includes(id))
  if (filteredQuery.length > 0) {
    var ids = filteredQuery[0]
    for (let i = 1; i < filteredQuery.length; i++) {
      ids += ',' + filteredQuery[i]
    }
    dispatch({
      type: types.SEARCH_PHOTOS,
      payload: axios.get(apiEndpoints.photo_search_path(ids)),
    })
  }
}

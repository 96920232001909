
window.GoogleMap = function(json) {

  json.map = json.map || {};
  var $map = $('#map');
  
  // adjust map size and possibly zoom to the browser window
  if ( window.location.pathname.match('.embeddable') ) {
    $map.height($(window).height());
  } else if ( $map.hasClass('world') || $map.hasClass('area')) {
    $map.css({
      height: ($(window).height() - $('header').outerHeight() - $('footer').outerHeight()) + 'px'
    });

  } else if ( $map.hasClass('crag') ) {
    var space = $(window).height() - $('body').height();
    if ( space > 10 ) $map.height($map.height() + space - 10);
  }

  if ( json.map.editable ) {
    app.map = EditableCragMap(json);
    app.map_markers = new WorldMapMarkers(app.map);
    app.map_markers.hideMarker(json.crag.id);
  }
  else if ( $map.hasClass('crag')  ) app.map = CragMap(json, $map);
  else if ($map.hasClass('area') && !json.crags) {
    app.map = AreaMap(json);
    app.map_markers = new WorldMapMarkers(app.map);
  }
  else {
    app.map = WorldMap(json);
    app.map_markers = new WorldMapMarkers(app.map);
    app.map_results = new WorldMapCragList('#mapSearchResult');
    app.map_filters = new WorldMapFilters(app.map_markers, app.map_results);
  }

  if($map.hasClass('no-controls')) {
    app.map.g_map.streetViewControl = false;
    app.map.g_map.zoomControl = false;
    app.map.g_map.mapTypeControl = false;
  }
  
  app.initialize_map_events($map, app.map, app.map.g_map);
}

import React, { Component } from 'react';

class CommercialMarkerPopup extends Component {

  handleOutsideClick = e => {
    if (e.target.className === 'map-popup-container') {
      this.props.hide();
    }
  }

  renderBookingButton = () => {
    const { url_bookingcom, url_airbnb, url_otherbooking, tracking_url } = this.props.marker.properties.marker;
    if (url_bookingcom || url_airbnb || url_otherbooking) {
      return (
        <div>
          <a target="_blank" href={tracking_url || url_bookingcom || url_airbnb || url_otherbooking}>
            <button className="btn btn-primary btn-block btn-rounded">
              {['Booking.com', 'Airbnb', 'Book now'][[!!url_bookingcom, !!url_airbnb, !!url_otherbooking].indexOf(true)]}
            </button>
          </a>
        </div>
      )
    }
    return null;
  }

  render() {
    var properties = null;
    try {
      properties = JSON.parse(this.props.marker.properties.marker);
    } catch(e) {
      properties = this.props.marker.properties.marker;
    }
    const {
      name,
      info,
      kind,
      distance_to_crag,
      link,
      email,
      address,
      gym_opening_hours,
      website_tracking_url,
      photos
    } = properties;
    return (
      <div className="map-popup-container" onClick={this.handleOutsideClick}>
        <div className="mapbox commercial-marker-popup">
          {photos && photos.length > 0 && (
            <div className="photo" style={{ background: `url(${photos[0].styles.size_m.url}) center / cover` }} />
          )}
          <div className="commercial-marker-popup-header"><img src={this.props.icons[kind].normal} />{name}</div>
          {info && <div className="popup-row popup-row__info">{info}</div>}
          {distance_to_crag && (
            <div className="popup-row">
              <i className="glyphicon glyphicon-person-walking" />
              <span>{distance_to_crag}</span>
            </div>
          )}
          {link && (
            <div className="popup-row text-ellipsis">
              <i className="glyphicon glyphicon-globe" />
              <span><a href={website_tracking_url || link} target="_blank">{link.replace(/http:\/\/|https:\/\//, '')}</a></span>
            </div>
          )}
          {email && (
            <div className="popup-row">
              <i className="glyphicon glyphicon-envelope" />
              <span><a href={`mailto:${email}`}>{email}</a></span>
            </div>
          )}
          {address && (
            <div className="popup-row">
              <i className="glyphicon glyphicon-map-marker" />
              <span><a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}>{address}</a></span>
            </div>
          )}
          {gym_opening_hours && (
            <div className="popup-row">
              <i className="glyphicon glyphicon-calendar" />
              <span style={{ whiteSpace: 'pre-line' }}>{gym_opening_hours}</span>
            </div>
          )}
          <div className="buttons">
            {this.renderBookingButton()}
            {link && (
              <div>
                <a target="_blank" href={website_tracking_url || link}><button className="btn btn-default btn-block btn-rounded">Open website</button></a>
              </div>
            )}
          </div>
          <a className="map-popup-container-close" onClick={this.props.hide}>&nbsp;</a>
        </div>
      </div>
    );
  }
}

export default CommercialMarkerPopup;

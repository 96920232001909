import axios from 'axios';
import { reset } from 'redux-form';
import * as types from '../common/actionTypes';
import {authenticityToken, checkEmbed} from '../common/util';
axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken();

export const fetchFeedItems = url => (
  (dispatch) => {
    dispatch({
      type: `${types.FETCH_FEED_ITEMS}_PENDING`,
    });
    axios.get(url)
      .then((result) => {
        dispatch({
          type: `${types.FETCH_FEED_ITEMS}_FULFILLED`,
          payload: result,
        });
      })
      .catch((err) => {
        dispatch({
          type: `${types.FETCH_FEED_ITEMS}_REJECTED`,
          payload: err,
        });
      });
  }
);

export const toggleFeedItemLike = ({ likeRequest, type, id }) => (
  (dispatch, getState) => {
    dispatch({
      type: types.TOGGLE_FEED_ITEM_LIKE,
      payload: axios.post(`${getState().feed.apiUrl}${likeRequest}`, `type=${type}&id=${id}`),
    });
  }
);

export const addFeedItem = text => (
  (dispatch, getState) => {
    dispatch({
      type: `${types.ADD_FEED_ITEM}_PENDING`,
    });
    checkEmbed(text, (embedData) => {
      let queryParams = `feed_item[title]=${encodeURIComponent(text)}`;
      if (embedData) {
        queryParams += `&feed_item[description]=${encodeURIComponent(JSON.stringify(embedData))}`;
      }
      axios.post(`${getState().feed.apiUrl}feed_items`, `${queryParams}`)
        .then((result) => {
          dispatch(reset('addFeed'));
          dispatch({
            type: `${types.ADD_FEED_ITEM}_FULFILLED`,
            payload: result,
          });
        })
        .catch((err) => {
          dispatch({
            type: `${types.ADD_FEED_ITEM}_REJECTED`,
            payload: err,
          });
        });
    });
  }
);

export const deleteFeedItem = id => (
  (dispatch, getState) => {
    dispatch({
      type: types.DELETE_FEED_ITEM,
      payload: axios.delete(`${getState().feed.apiUrl}feed_items/${id}`),
    });
  }
);

export const toggleDeleteDialogVisibility = feedItemId => (
  (dispatch) => {
    dispatch({
      type: types.TOGGLE_DELETE_DIALOG_VISIBILITY,
      payload: feedItemId,
    });
  }
);

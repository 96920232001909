import React, { Component } from "react";
import { holdHash } from "../../common/util.js";
import DropDownFilter from '../../common/components/DropDownFilter';
import ToggleFilterButton from "../../common/components/ToggleFilterButton";
import TagSpan from "../../common/components/TagSpan";

class HoldsButton extends Component {
  render() {
    const { changeFilters, filters } = this.props;
    const btnClass = Object.keys(holdHash).some(key => filters.includes(key))
      ? "btn btn-primary"
      : "btn btn-default";
    return (
      <DropDownFilter
        titleButton={"Holds"}
        titleHeader={"Holds"}
        titleClose={"Apply"}
        showDropdown={this.props.showDropdown}
        hideDropdown={this.props.hideDropdown}
        btnClass={btnClass}
      >
        <ul className="premium-search-filter__checkbox-container">
          {Object.keys(holdHash).map((key, i) => {
            return (
              <ToggleFilterButton
                active={filters.includes(key)}
                key={i}
                value={key}
                onChange={changeFilters}
                className="premium-search__checkbox-item"
              >
                <TagSpan
                  spanClass={key}
                  iconClass={key}
                  title={holdHash[key]}
                />
                {holdHash[key]}
              </ToggleFilterButton>
            );
          })}
        </ul>
      </DropDownFilter>
    );
  }
}

export default HoldsButton;

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import VisitsChart from "./components/VisitsChart";
import {
  getVisitsData,
} from "./actions.jsx";

const stateToProps = (state) => ({
  crags: state.visitsReducerParams.crags,
  visits: state.visitsReducerParams.visits,
  paramId: state.visitsReducerParams.paramId,
  startDate: state.visitsReducerParams.startDate,
  endDate: state.visitsReducerParams.endDate,
  loading: state.visitsReducerParams.loading
});

const visitsDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getVisitsData,
    },
    dispatch
  );

export default connect(
  stateToProps,
  visitsDispatchToProps
)(VisitsChart);

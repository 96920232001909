import React, { Component } from 'react';
import LocationResults from './LocationResults';

class Information extends Component {
  render() {
    const {
      climberLocationId,
      sex,
      location,
      changeSex,
      searchLocations,
      changeLocation,
      searchedLocations,
      moveForward,
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-md-7 col-sm-8">
            <div className="onboarding__header-description">STEP 3 OF 4</div>
            <h3>Tell other climbers about you</h3>
            <LocationResults
              searchedLocations={searchedLocations}
              location={location}
              changeLocation={changeLocation}
              searchLocations={searchLocations}
              hideLocationResults={() => this.setState({ showLocationResults: false })}
            />
            <div className="form-group">
              <label>{"I'm a"}</label>
              <div>
                <button onClick={() => changeSex('male')} className={sex === 'male' ? 'selected btn-rounded' : 'btn-rounded'}>Guy</button>
                <button onClick={() => changeSex('female')} className={sex === 'female' ? 'selected btn-rounded' : 'btn-rounded'}>Girl</button>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-4">
            <div className="onboarding__forward-container--top">
              <button className="btn btn-primary btn-primary--onboarding" onClick={() => moveForward()}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Information;

// The MapMarkerFactory is associated with one map at a time.
window.MapMarkerFactory = function() { // executed immediately
  
  function extract_marker_options(json) {
    var defaults = {map: app.g_map, raiseOnDrag: !json.kind};
    defaults.position = json.latlng || new google.maps.LatLng(json.latitude, json.longitude);
    if ( json.kind == 'boulder' ) defaults.icon = MapMarkerIcons.boulder();
    if ( json.kind == 'sector'  ) defaults.icon = MapMarkerIcons.sector_end();
    if ( json.kind == 'parking_space' ) defaults.icon = MapMarkerIcons.parking_space();
    return defaults;
  }
  
  function extract_start_point(json) {
    return $.extend({}, json, {end_latlng: false, end_latitude: false, end_longitude: false});
  }
  
  function extract_end_point(json) {
    var end_point_values = {latlng: json.end_latlng, latitude: json.end_latitude, longitude: json.end_longitude};
    return $.extend(extract_start_point(json), end_point_values);
  }
  
  function new_single_point_marker(json, tooltip) {
    try {
      var marker = SinglePointMarker(extract_marker_options(json));
      // now the map is passed in the json // marker.g_marker.setMap(app.g_map);
      marker.tooltip = tooltip || MapTooltip(json.url);
      marker.tooltip.bind(marker.g_marker);
      return marker;
    } catch(error) {
      console.log(error);
      return null;
    }
  }
  
  function new_symbol(json, markers) {
    markers = new MarkerCollection(markers);
    var symbol = new MapSymbol(json, markers);
    symbol.markers.set_observer(MapPointObserver(symbol));
    return symbol;
  }
  
  function new_sector(json) {
    var marker = new_single_point_marker(extract_start_point(json));
    console.log(marker);
    var end_marker = new_single_point_marker(extract_end_point(json), marker.tooltip);
    if ( marker && end_marker ) {
      var sector_symbol = new_symbol(json, [marker, end_marker]);
      sector_symbol.set_overlay(SectorOverlay(sector_symbol));
      marker.tooltip.bind(sector_symbol.overlay().g_polyline);
      return sector_symbol;
    }
    return null;
  }
  
  // public class methods
  return {
    
    new_crag: function(json) {
      return new_single_point_marker(json);
    },
    
    new_marker: function(json) {
      if ( json.kind == 'sector' ) return new_sector(json);
      var map_point = new_single_point_marker(json);
      if ( map_point ) {
        return new_symbol(json, [map_point]);
      } else {
        return null;
      }
    }
    
  };
}();

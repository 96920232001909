import React, { Component } from "react";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "./sliderComponents";

const sliderStyle = {
  position: "relative",
  width: "100%"
};

class TwoPointSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
      updates: props.values
    };
  }
  onUpdate(updates) {
    this.setState({
      updates
    });
    if (this.props.onUpdate) {
      this.props.onUpdate(updates);
    }
  }

  render() {
    return (
      <Slider
        mode={2}
        step={this.props.stepSize}
        domain={this.props.valueRange}
        values={this.state.values}
        rootStyle={sliderStyle}
        onChange={this.props.onChange}
        onUpdate={this.onUpdate.bind(this)}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={this.props.valueRange}
                  getHandleProps={getHandleProps}
                  labelValue={
                    this.props.labelValue
                      ? this.props.labelValue(handle.value)
                      : ""
                  }
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    );
  }
}

export default TwoPointSlider;

window.MyDom = {
  introduce: function(options, content) {
    if ( $('#'+options.id).size() == 0 )
      $('#hidden_html_container').append( $('<div>'+(content||'')+'</div>').attr(options) );
  },
  waiting: function() {
    MyDom.introduce({id: 'my_dom_loading'}, MyDom.loading);
    return $('#my_dom_loading')[0];
  },
  too_long: function(max, your) {
    return 'Your post is too long. The maximum is ' + max + ' characters, but it is ' + your + ' characters long. You may want to write a blog post instead.';
  },
  login: '<a class="btn btn-primary join-btn" href="https://' + location.host + '/login">Log in</a>',
  logout: '<ul class="nav navbar-nav navbar-right"><li class="dropdown">'+
  '<a aria-expanded ="true" class="hidden-xs dropdown dropdown-toggle' + ($.cookie('js_user_photo') ? '' : ' show-tooltip') + '" data-toggle="dropdown" role="button" ' + ($.cookie('js_user_photo') ? '' : 'data-placement="bottom" title="Login again to see your profile image here."') + '>' +
  '<img class="img-circle" src="' + ($.cookie('js_user_photo') ? $.cookie('js_user_photo') : 'icons/user_40x40.png') + '">' +
  '</a>'+
  '<ul class="dropdown-menu" role="menu">'+
  // '<li><a rel="facebox[modal]" href="/climbers/'+$.cookie('js_user')+'/photos/new">Share a climbing photo</a></li>' +
  // '<li class="divider"></li>' +
  '<li><a href="/climbers/'+$.cookie('js_user')+'">My profile</a></li>' +
  '<li><a href="/climbers/'+$.cookie('js_user')+'/ascents">Ticks</a></li>' +
  '<li class="divider hidden-xs"></li>' +
  '<li><a rel="facebox[modal]" href="/climbers/'+$.cookie('js_user')+'/settings">Settings</a></li>' +
  '<li><a href="/logout">Log out</a></li>' +
  '</ul></li></ul>',
  loading: '<div class="loading"><img src="'+$.facebox.settings.loadingImage+'"/></div>',
  photo: '<p>Uploading and processing the photo may take a while.<br />Please be patient.</p>',
  error500: "<h3>We're sorry, but something went wrong.</h3><p>We've been notified about this issue and we'll take a look at it shortly.</p>",
  error: "<h3>We're sorry, but something went wrong.</h3><p>Please reload the page or try again later.</p>"
};

// app is a namespace for application specific javascript helpers
window.app = {
  go_home: function() {
    window.location = 'https://' + window.location.host + ($.cookie('js_user') ? '/climbers/' + $.cookie('js_user') : '/');
  },
  user_menu: function() {
    // require js_user_photo cookie
    return $.cookie('js_user') ? MyDom.logout : MyDom.login;
  },
  log: function() {
    if ( window.console ) console.debug('WARNING: ', arguments);
  },
  click: function(selector, highlight) {
    app.autoclick = true;
    setTimeout(function() {
      app.highlight(highlight||selector);
      setTimeout(function(){ $(selector).click(); }, 450);
    }, 300);
  },
  highlight: function(selector) {
    var jq = $(selector);
    if ( jq.size() == 0 ) return false;
    var original = app.bg_color(jq);
    setTimeout(function(){jq.animate({backgroundColor:'black'}, 800).animate({backgroundColor:original}, 800);}, 200);
  },
  bg_color: function(jq) {
    var color = jq.css('backgroundColor');
    if ( color && color != 'transparent') return color;
    else if ( ! jq.is('body') ) return app.bg_color(jq.parent());
    else return 'white';
  },
  prepare_for_dialog: function(){
    if ( $('#facebox .keep').is(':visible') ) {
      $.facebox.blockBody();
    } else {
      $.blockUI();
      $.facebox.hide_tooltip();
      // autocomplete.hide_all();
    }
    if ( ! app.autoclick ) app.messages.clear();
  },
  dialog: function(url) {
    app.prepare_for_dialog();
    var options = {};
    $.facebox.get(url, options);
  },
  ajax_error: function(e, xhr, settings, exception) {
    app.log(e, xhr, settings, exception);
    if ( xhr.responseText.match('Action Controller: Exception caught') )
      $.newfacebox(xhr.responseText);
    else if ( xhr.status == 500 ) {
      $.newfacebox(MyDom.error500, {error: true});
    } else if ( xhr.status != 0 ) {
      $.newfacebox(MyDom.error, {error: true});
    }
    app.close_info_window();
  },
  after_ajax: function(response, status, xhr, $form) {
    if ( !response ) return $.facebox(MyDom.error, {error: true});
    if ( typeof(response) == 'string' ) {
      if ( response.indexOf('<textarea>') == 0 || response.indexOf('<TEXTAREA>') == 0 ) {
        response = response.slice(10, response.length - 11);
        response = app.unescape(response);
        if ( response.indexOf('{') == 0) { // assume the response is json and try to eval
          try {
            response = eval('(' + response + ')');
          } catch(e) {
            app.log('eval failed:', e);
          }
        }
      }
    }
    if ( typeof(response) == 'string' ) {
      if ( $form.hasClass('share-an-update') ) {
        $('.feed-items .refreshable').replaceWith(response);
        $('.feed-items li:first').hide().fadeIn(300);
        return true;
      }
      if ( $form.hasClass('inline_ajax') ) {
        $form.closest('.refreshable').replaceWith(response);
        $('.feed .cards:first').hide().fadeIn(300);
        return true;
      }
      if ( response == '{}' )               { app.close_and_reload();   return true; }
      if ( response.match(/^http.*login/) ) { top.location =  response; return true; }
      if ( response.indexOf('<!DOCTYPE') == 0 || response.indexOf('<html') == 0 ) $.newfacebox(response);
      else $.facebox(response);
      app.messages.show_js_messages();
    } else {
      if ( response.error ) {
        $.facebox(response.error, {error: true});
      } else {
        if ( response.html ) app.replace_html(response.html);
        if ( response.code ) eval(response.code);
        if ( response.url  ) top.location = response.url;
        if ( response.messages ) app.notice(response.messages);
        if ( !response.html && !response.code && !response.url && !response.messages ) app.close_and_reload();
      }
    }
  },
  unescape: function(text) {
    return text.replace(/!lt!/g, '<').replace(/!gt!/g, '>');
  },
  replace_html: function(html) {
    if ( html.facebox ) $.facebox(html.facebox);
    else
      $.each(html, function(selector, content) {
        $(selector).html(content);
      });
  },
  close_and_reload: function() {
    app.add_many_routes.clear_local_storage();
    // $.facebox.close();
    window.location.reload();
  },
  close_dialog: function() {
    app.add_many_routes.clear_local_storage();
    $.facebox.close();
  },
  notice: function(messages) {
    app.messages.append(messages);
    $.facebox.close();
  },
  before_ajax: function(arr, form_jq) {
    if ( form_jq.hasClass('inline_ajax') ) {
      form_jq.block({opacity:'0.4'});
      if ( app.too_long(form_jq, 'feed_item\\[title\\]',  420) ) return false;
      if ( app.too_long(form_jq, 'comment\\[content\\]', 3000) ) return false;
      form_jq.animate({opacity: '0.3'}, 400);
      return true;
    }
    app.wait(arr);
    if ( $.linebox.topo_editor ) {
      // add a topo line to the form
      arr.push({name: 'route[topo_line]', value: JSON.stringify($.linebox.topo_editor.getNewLine())}); 
    }
    /*var s; while ( s = autocomplete.selections.pop() ) {
      // clear the value_field if the user edited the autocomplete suggestion after selection
      var text_field = $.grep(arr, function(a){ return a.name == s.text_field; })[0];
      if ( text_field && text_field.value != s.selected_text ) {
        $.grep(arr, function(a){ return a.name == s.value_field; })[0].value = '';
      }
    }*/
    if ( app.lookup_feed ) {
      app.lookup_feed = false;
      var feed_url = $('#feed_url').val();
      if ( feed_url.match(/\S+\.\S+/) ) {
        google.feeds.lookupFeed(feed_url, function(result){
          if ( !result.error && result.url != feed_url ) $('#feed_url').val(result.url);
          form_jq.submit();
        });
        return false;
      }
    }
    if ( form_jq.hasClass('use_local_storage') ) {
      app.add_many_routes.save_in_local_storage();
      app.add_many_routes.wait_for_reload();
    }
  },
  too_long: function(form_jq, field, max) {
    var txt = form_jq.find('[name=' + field + ']').val();
    if ( txt && txt.length > max ) {
      app.prepare_for_dialog();
      $.facebox(MyDom.too_long(max, txt.length));
      form_jq.unblock();
      return true;
    }
    return false;
  },
  wait: function(arr) {
    arr = arr || [];
    $('input').blur();
    app.prepare_for_dialog();
    var msg = '<img src="'+$.facebox.settings.loadingImage+'"/>';
    var file_field = $.grep(arr, function(a){ return a.name == "photo[image]" || a.name == "sector[topo_image]"; })[0];
    if ( file_field && file_field.value != '' ) msg = msg + MyDom.photo;
    $.facebox.loading(msg);
  },
  toggle_next: function() {
    var $this = $(this);
    // height() is not realible on hidden block if there's text spanning multiple rows,
    // but defining the height manually in css/html will help - not very good though for dynamic text
    // another option is to define fixed width for the element in css/html so that hidden element
    // text wrap will calculate right height
    var height_difference = $this.next().height();
    // if we are in a mapbox, we have to resize the info window...
    if ( $this.parents('.mapbox').size() == 1 ) {
      $('.mapbox').prepend('<div id="googleInfoWorkAround"></div>');
      if ( $this.next().css('display') == 'none' ) {
        $('.mapbox #googleInfoWorkAround').height(height_difference);
        app.resize_current_info_window();
      }
    }
    $this.next().animate({height: 'toggle'}, {duration: 200, step: function(now, fx) {
      $('#googleInfoWorkAround').height(height_difference - now);
    }, complete: app.clean_up_info_window});
    $this.toggleClass('toggle_open').toggleClass('toggle_closed');
    return false;
  },
  clean_up_info_window: function() {
    $('#googleInfoWorkAround').remove();
    app.resize_current_info_window();
  },
  resize_current_info_window: function() {
    if ( app.map ) app.map.resize_info_window();
  },
  close_info_window: function() {
    if ( app.map ) app.map.close_info_window();
  },
  remove_hint: function() {
    $('.headline h2 span').remove();
    $('.headline h2').removeClass('hint');
  },
  hint: function(text) {
    app.remove_hint();
    if ( text ) {
      $('.headline h2').addClass('hint').append('<span>' + text + '</span>');
    }
  },
  start_autosave: function() {
    if ( !app.autosave_started ) app.autosave();
  },
  autosave: function() {
    if ( app.autosave_started ) {
      if ( $('form.autosave').is(':visible') ) {
        $('form.autosave').ajaxSubmit({data:{autosave:true}, dataType:'json', success:app.after_autosave});
      }
    } else {
      app.autosave_started = true;
    }
    setTimeout(app.autosave, 60000);
  },
  after_autosave: function(response) {
    if ( response.lock_version ) $('#post_lock_version').val(response.lock_version);
    if ( response.messages ) app.messages.append(response.messages);
    else rails_helpers.new_to_edit(response);
  },
  show_feed_snippet: function(event) {
    var content = $(event.target).parents('.gfc-result').html();
    $.facebox.show_tooltip(event, content);
  },
  activate_tooltip: function(element) {
    var jq = $(element);
    var tip = jq.attr('title');
    jq.removeAttr('title');
    jq.mouseover(function(event){ $.facebox.show_tooltip(event, tip); });
    jq.mousemove($.facebox.move_tooltip);
    jq.mouseout($.facebox.hide_tooltip);
  },
  /*search_box_focus: function() {
    if ( !app.search_box_ac ) {
      app.search_box_ac = autocomplete.activate('#searchbox .search_text', 'search', function(o){
        document.location.href = o.data;
      });
    }
    app.search_box_ac.fixPosition();
  },*/
  /*search_box_blur: function(event) {
    //if ($(event.target).val() == '' && $('#searchbox').width() == 350) $('#searchbox').animate({width:'186px'},{duration:225});
  },*/
  // adjust width by js only if css does not support the needed feature;
  // appending a style tag applies also to elements later loaded with ajax requests
  dyn_css: function() {
    // NOTICE: the google maps api appends style tags to head
    if ( $('.grid').is(':visible') ) { // the photo grid
      var box_width = '33.33%';
      if ( $('.grid').width() > 840 ) box_width = ($('.grid').width() > 1060 ? '20%' : '25%');
      app.set_width('.grid > .left', box_width);
    }
  },
  set_width: function(selector, width) {
    $('head').append('<style type="text/css">' + selector + '{width:' + width + '}</style>');
  },
  update_grade_labels: function(genre, trigger) {
    // if ( typeof(boulder_grade_labels) == 'undefined' ) return;
    var container = (trigger ? $(trigger).closest('tr, form') : $('body'));
    if ( typeof(genre) != 'string' ) genre = container.find('.js-route_genre').val();
    var system = 'Font';
    switch (genre) {
        case "Boulder": system = window.PREFERRED_GRADING_SYSTEM_BOULDER; break;
        case "Sport": system = window.PREFERRED_GRADING_SYSTEM_SPORT; break;
        case "Trad": system = window.PREFERRED_GRADING_SYSTEM_TRAD; break;
    }
    var system_index = window.GRADE_SYSTEMS.indexOf(system);
    container.find('.js-grade_select').each(function(i, se){
      var select = $(se);
      select.find('option').each(function(i, el){
        var grade_int = $(el).val();
        if(typeof window.GRADES_HASH[grade_int] != "undefined") {
          $(el).text(window.GRADES_HASH[grade_int][system_index]);
        }
      });
    });
  }
};


function setMapCenterOffset(googleMap, latlng, offsetx, offsety) {
  var point1 = googleMap.getProjection().fromLatLngToPoint(googleMap.getCenter());

  var point2 = new google.maps.Point(
    ( (typeof(offsetx) == 'number' ? offsetx : 0) / Math.pow(2, googleMap.getZoom()) ) || 0,
    ( (typeof(offsety) == 'number' ? offsety : 0) / Math.pow(2, googleMap.getZoom()) ) || 0
  );

  return googleMap.getProjection().fromPointToLatLng(new google.maps.Point(
    point1.x - point2.x,
    point1.y + point2.y
  ));
}

function escapeRegExp(str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

// application specific interface to the autocomplete plugin
window.new_autocomplete = function(){
  var autocomplete_width = ($(window).width() > 450 ? 300 : 250);


  var autocomplete = {
    active: [],
    selections: [],
    url: function(action){ return '/autocomplete/' + action; },
    options: function(action) {
      return {
        serviceUrl: this.url(action),
        zIndex: 1070,
        deferRequestBy: 100,
        minChars: 3,
        width: autocomplete_width,
        maxHeight: 400,
        formatResult: this.formatResult,
        suggestionItem: this.suggestionItem
      };
    },
    activate: function(element, action, function_or_text_field, value_field, updates) {
      var self = this;
      if ( updates ) {
        var text_field = function_or_text_field;
        function_or_text_field = function(o){
          var data = o;
          $.each(updates, function(element_id, attr) {
            $('#'+element_id).val(attr == 'data' ? data : data[attr]);
          });
          if ( data['genre'] ) app.update_grade_labels(data['genre'], element);
          self.record(o.value, text_field, value_field);
        }
      }
      this.active.unshift($(element).dAutocomplete($.extend({onSelect: function_or_text_field}, this.options(action))));
      return this.active[0].data('autocomplete');
    },
    record: function(value, text_field, value_field) {
      var obj = $.grep(this.selections, function(o, i){ return (o.text_field == text_field); })[0];
      if ( obj ) obj.selected_text = value;
      else this.selections.push({selected_text: value, text_field: text_field, value_field: value_field});
    },
    hide_all: function() {
      for (i in this.active) { this.active[i].hide(); }
    },
    formatResult: function (suggestion, currentValue) {
      var reEscape = new RegExp('(\\' + ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\'].join('|\\') + ')', 'g'),
        pattern = '(' + currentValue.replace(reEscape, '\\$1') + ')';

      var value = suggestion.name.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>');

      return value;
    },
    suggestionItem: function(i, suggestion, value, className) {
      var getSuggestionType = function(suggestion){
        if(suggestion.path.indexOf('countries') > 0) {
          return "Country";
        }
        if(suggestion.path.indexOf('areas') > 0) {
          return "Area";
        }
        return suggestion.type;
      };

      var getSuggestionDescription = function(suggestion){
        var type = getSuggestionType(suggestion);
        var desc = suggestion.description ? suggestion.description : '';
        var posS, posE = 0;

        if(type == 'Crag') {
          var stripAway =  'in the area of';
          //desc = desc.replace(suggestion.name, '');
          desc = desc.replace(type + ':', '');
          posS = desc.indexOf(stripAway);
          if(posS > 0) {
            posS += stripAway.length;
          }
          posE = desc.length;
        } else if (type == 'Route') {

          // remove route name from the begining of description
          var r = new RegExp('^' + escapeRegExp(suggestion.name), 'g');
          var gradeBeforeLocation = '), ';
          var locationAsString = desc.substring(
            desc.indexOf(gradeBeforeLocation) + gradeBeforeLocation.length,
            desc.length
          );
          var grade = desc.substring(desc.indexOf('(')+1, desc.indexOf(gradeBeforeLocation));

          desc = grade + ', ' + suggestion.genre + ', ' + locationAsString;

          posE = desc.length;
        } else if (type == 'Climber') {
          posE = desc.length;
        }
        return desc.substring(posS, posE);
      };

      return '<div class="' + className + '" data-index="' + i + '">' +
        '<div class="cont">' +
        '<div class="text">' +
        '<span class="headline">' + this.formatResult(suggestion, value) + '</span> ' +
        '<span class="description">' + getSuggestionDescription(suggestion) + '</span>' +
        '</div>' +
        '<div class="badges">' +
        '<span class="type">' + getSuggestionType(suggestion) + '</span>' +
        '</div>' +
        '</div>' +
        '</div>';
    }
  };
  return autocomplete;
}


// worldmap specific interface to the autocomplete plugin
var worldMapSearch = {
  active: [],
  selections: [],
  url: function(action){
    return '/autocomplete/' + action;
  },
  options: function(action) {
    return {
      serviceUrl: this.url(action),
      deferRequestBy: 100,
      minChars: 3,
      zIndex: 1070,
      width: 300,
      maxHeight: 400,
      formatResult: worldMapSearch.formatResult,
      suggestionItem: worldMapSearch.suggestionItem
    };
  },
  suggestionItem: function(i, suggestion, value, className) {
    var getSuggestionType = function(suggestion){
      if(suggestion.path.indexOf('countries') > 0) {
        return "Country";
      }
      if(suggestion.path.indexOf('areas') > 0) {
        return "Area";
      }
      return suggestion.type;
    };

    var getSuggestionDescription = function(suggestion){
      var type = getSuggestionType(suggestion);
      var desc = suggestion.description;
      var posS, posE = 0;

      if(type == 'Crag') {
        var stripAway =  'in the area of';
        //desc = desc.replace(suggestion.name, '');
        desc = desc.replace(type + ':', '');
        posS = desc.indexOf(stripAway);
        if(posS > 0) {
          posS += stripAway.length;
        }
        posE = desc.length;
      }
      return desc.substring(posS, posE);
    };

    return '<div class="' + className + '" data-index="' + i + '">' +
        '<div class="cont">' +
          '<div class="text">' +
            '<span class="headline">' + this.formatResult(suggestion, value) + '</span> ' +
            '<span class="description">' + getSuggestionDescription(suggestion) + '</span>' +
          '</div>' +
          '<div class="badges">' +
            '<span class="type">' + getSuggestionType(suggestion) + '</span>' +
          '</div>' +
        '</div>' +
      '</div>';
  },
  activate: function(element, action, function_or_text_field, value_field, updates) {
    if ( updates ) {
      var text_field = function_or_text_field;
      function_or_text_field = function(o){
        var data = o.data;
        $.each(updates, function(element_id, attr) {
          $('#'+element_id).val(attr == 'data' ? data : data[attr]);
        });
        if ( data['genre'] ) app.update_grade_labels(data['genre'], element);
        worldMapSearch.record(o.value, text_field, value_field);
      }
    }
    worldMapSearch.active.unshift($(element).dAutocomplete($.extend({onSelect: function_or_text_field}, this.options(action))));
    return worldMapSearch.active[0].data('autocomplete');
  },
  record: function(value, text_field, value_field) {
    var obj = $.grep(worldMapSearch.selections, function(o, i){ return (o.text_field == text_field); })[0];
    if ( obj ) obj.selected_text = value;
    else worldMapSearch.selections.push({selected_text: value, text_field: text_field, value_field: value_field});
  },
  hide_all: function() {
    for (i in worldMapSearch.active) { worldMapSearch.active[i].hide(); }
  },
  formatResult: function (suggestion, currentValue) {
    var reEscape = new RegExp('(\\' + ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\'].join('|\\') + ')', 'g'),
      pattern = '(' + currentValue.replace(reEscape, '\\$1') + ')';

    var value = suggestion.name.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>');

    return value;
  }
};

// ror is a namespace for Ruby on Rails helpers
window.rails_helpers = function() {
  
  function object_to_params(object) {
    var params = {};
    $.each(object, function(name, values){
      $.each(values, function(attr_name, value){
        params[name + '['+ attr_name +']'] = value;
      });
    });
    return params;
  }
  
  function update(url, object, on_success) {
    var params = object_to_params(object);
    params['_method'] = 'put';
    params['authenticity_token'] = $('form input[name=authenticity_token]').val();
    if ( typeof(on_success) == 'string' ) {
      var message = on_success;
      on_success = function(){$.facebox(message);};
    }
    $.ajax({dataType: 'json', type: 'POST', url: url, data: params, success: on_success});
  }
  
  // reload if the user clicks back and the token might have been used
  function reload_if_visited() {
    if ( $('input[name=authenticity_token]').val() == $.cookie('authenticity_token') ) {
      $.cookie('authenticity_token', null);
      window.location.reload();
    } else {
      $.cookie('authenticity_token', $('input[name=authenticity_token]').val());
    }
  }
  
  function new_to_edit(response) {
    if ( $('form.autosave input[name=_method]').val() != 'put' ) {
      $('form.autosave').attr('action', response.path);
      $('form.autosave input[name=authenticity_token]').after('<input type="hidden" name="_method" value="put"/>');
    }
  }
  
  return {update: update, reload_if_visited: reload_if_visited, new_to_edit: new_to_edit};
}();

function isSmallscreen() {
  if( $(window).width() < 400 ){
    return true;
  }
  else {
    return false;
  }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Feed from './components/Feed';
import {
  fetchFeedItems,
  toggleFeedItemLike,
  deleteFeedItem,
  addFeedItem,
} from './actions';
import { fetchComments, toggleCommentsVisibility } from '../common/actions';

const setupFeedItems = (feedItems, climberId) => {
  for (let i = 0; i < feedItems.length; i += 1) {
    if (feedItems[i].climber_id === climberId) {
      feedItems[i].isOwn = true;
    }
  }
};

const mapStateToProps = (state, props) => {
  setupFeedItems(state.feed.feedItems, state.feed.currentClimber.id);
  return ({
    currentClimber: state.feed.currentClimber,
    feedItems: state.feed.feedItems,
    fetching: state.feed.fetching,
    fetched: state.feed.fetched,
    error: state.feed.error,
    fetchUrl: `${state.feed.apiUrl}${props.fetchUrlSuffix}?page=${state.feed.page}`,
    showFeedForm: props.showFeedForm,
    feedItemsReceived: state.feed.feedItemsReceived,
    addFeedItemPending: state.feed.addFeedItemPending,
    addCommentPending: state.feed.addCommentPending,
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchFeedItems,
  toggleFeedItemLike,
  deleteFeedItem,
  addFeedItem,
  fetchComments,
  toggleCommentsVisibility,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Feed);

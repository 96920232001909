import mouseImage from '../../../javascript/images/appicon_mouse.png'

var extendObj = function(childObj, parentObj) {
  childObj.prototype = parentObj.prototype;
};

var MapButton = function() {};
MapButton.prototype = {
  controlUI: null,
  controlDiv: null,
  g_map: null,
  init: function(controlDiv, g_map){
    this.g_map = g_map;
    this.controlDiv = controlDiv;
    this.controlUI = document.createElement('div');
    this.controlDiv.appendChild(this.controlUI);

    this.controlUI.style.width = '28px';
    this.initStyle(this.controlUI);
  },
  initStyle: function(element){
    element.style.backgroundColor = '#fff';
    element.style.borderRadius = '2px';
    element.style.boxShadow = '0 1px 2px rgba(0,0,0,.3)';
    element.style.cursor = 'pointer';
    element.style.marginRight = '10px';
    element.style.marginBottom = '10px';
    element.style.marginLeft = '10px';
    element.style.padding = '5px';
    element.style.textAlign = 'center';
  }
};


window.MapButtonScrollZoom = function(controlDiv, g_map) {
  var self = this;
  this.init(controlDiv, g_map);
  self.controlUI.title = 'Click to toggle scroll zoom';

  // Set CSS for the control interior.
  var controlText = document.createElement('img');
  controlText.src = mouseImage;
  controlText.style.maxHeight = '20px';
  self.controlUI.appendChild(controlText);

  var setActive = function(active) {
    if (active) {
      self.controlUI.style.backgroundColor = '#fff';
      self.g_map.set('scrollwheel', true);
    }
    else {
      self.controlUI.style.backgroundColor = '#F0F0F0';
      self.g_map.set('scrollwheel', false);
    }
  };

  if(app.preferences.get('zoom') != null) {
    setActive(true);
  } else {
    setActive(false);
  }

  self.controlUI.addEventListener('click', function() {
    if(self.g_map.get('scrollwheel') == true) {
      setActive(false);
      app.preferences.delete('zoom');
    }else {
      setActive(true);
      app.preferences.set('zoom');
    }
  });
};
extendObj(MapButtonScrollZoom, MapButton);


window.MapButtonShareLocation = function(controlDiv, g_map) {
  var self = this;
  this.init(controlDiv, g_map);
  self.controlUI.title = 'Share location';

  var mainImg = document.createElement('i');
  mainImg.className = "glyphicon glyphicon-share-alt";
  mainImg.style.maxHeight = '20px';
  self.controlUI.appendChild(mainImg);
  self.controlUI.className = 'map-control-container';

  var buttons = document.createElement('div');
  buttons.className = 'map-sharebuttons';
  self.initStyle(buttons);

  var chevron = document.createElement('div');
  chevron.className = 'map-sharebuttons-chevron';
  buttons.appendChild(chevron);

  self.controlUI.appendChild(buttons);

  var createButton = function(class_name, click) {
    var link = document.createElement('a');
    buttons.appendChild(link);

    var text = document.createElement('i');
    text.className = class_name;
    text.onclick = click;
    link.appendChild(text);
  };

  createButton("icon27 icon-facebook", function(){
    var url = 'https://www.facebook.com/sharer.php?u=' + encodeURI(window.location);
    openSharerWindow(url);
    self.hideShare();
  });
  createButton("icon27 icon-twitter", function(){
    var url = 'https://twitter.com/share?url=' + encodeURI(window.location);
    openSharerWindow(url);
    self.hideShare();
  });

  this.showShare = function(){
    $(buttons).fadeIn(100);
  };
  this.hideShare = function(){
    $(buttons).fadeOut(100);
  };

  this.toggleShare = function () {
    if($(buttons).css('display') == 'none') {
      self.showShare();
    }
    else {
      self.hideShare();
    }
  };

  self.controlUI.addEventListener('click', function() {
    self.toggleShare();
  });
};
extendObj(MapButtonShareLocation, MapButton);
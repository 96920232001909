// a namespace for app.preferences()
(function() {
  
  var initializing = true;
  var switches = {
    empirical: { // show empirical units on the weather page
      on  : function() { initializing||app.wait(); }, // just let the browser follow the link
      off : function() { initializing||app.wait(); }, // when the cookie has been set
      'return': true
    },
    grade_sort: {
      on  : function() { initializing||app.wait(); },
      off : function() { initializing||app.wait(); },
      multi: true,
      'return': true
    },
    zoom: {
      on  : function() { app.g_map.set('scrollwheel', true); },
      off : function() { app.g_map.set('scrollwheel', false); }
    },
    // on ==> hide featured markers (default is off)
    markers: {
      on  : function() { app.map.hide_markers(); },
      off : function() { app.map.show_markers(); }
    }
  };
  
  function cookie() {
    return $.cookie('preferences') || '';
  }
  
  function set_cookie(value) {
    $.cookie('preferences', value, {path:'/', expires: 30});
  }
  
  function split() {
    return $.grep(cookie().split(','), function(a){return(a.length > 0);});
  }
  
  function join(arr) {
    return arr.join(',');
  }
  
  function split_without(name) {
    return $.grep(split(), function(a){return(a != name)});
  }
  
  function cookie_with(name) {
    // split_without(name) to avoid duplicates
    return join(split_without(name).concat([name]));
  }
  
  function cookie_without(name) {
    return join(split_without(name));
  }
  
  function check(name) {
    return cookie().match(name);
  }
  
  function turn_on(name) {
    switches[name].on();
    set_cookie(cookie_with(name));
  }
  
  function turn_off(name) {
    switches[name].off();
    set_cookie(cookie_without(name));
  }

  
  app.preferences = function(name, on_selector, off_selector) {
    
    // define a separate toggle function for each preference
    function toggle() {
      if ( !switches[name]['multi'] ) {
        $(on_selector).toggle();
        $(off_selector).toggle();
      }
    }
    
    // initialize the page
    if ( check(name) ) {
      $(on_selector).toggle();
      turn_on(name);
    } else {
      $(off_selector).toggle();
      turn_off(name);
    }
    
    // define click events
    $(on_selector).click( function(){ toggle(); turn_on(name);  return (switches[name]['return']||false); });
    $(off_selector).click(function(){ toggle(); turn_off(name); return (switches[name]['return']||false); });
  };
  
  app.preferences.init = function() {
    for (var i = 0, len = arguments.length; i < len; i++) {
      app.preferences(arguments[i], '#' + arguments[i] + '_on', '#' + arguments[i] + '_off');
    }
    initializing = false;
  };

  app.preferences.get = function(cname){
    return check(cname);
  };

  app.preferences.set = function(cname){
    set_cookie(cookie_with(cname));
  };

  app.preferences.delete = function(cname){
    set_cookie(cookie_without(cname));
  };
  
})();

import React, { Component } from 'react';
import Switch from 'rc-switch';
import { debounce } from 'throttle-debounce';
import Climber from './Climber';

class Follow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.search = debounce(500, this.search);
  }

  componentDidMount() {
    if (this.props.locationId) {
      this.props.fetchClimbersByLocation(this.props.locationId);
    }
  }

  handleInputChange(e) {
    this.setState({
      query: e.target.value,
    });
    this.search(e.target.value);
  }

  search(query) {
    if (query.length > 2) {
      this.props.searchClimbers(query);
    }
  }

  render() {
    const {
      location,
      locationId,
      following,
      moveForward,
      followEnabled,
      toggleFollowEnabled,
      searchedClimbers,
      searchDone,
      toggleFollow,
    } = this.props;
    
    const followText = () => {
      if (!followEnabled) return '0 climbers';
      switch (following.length) {
        case 0:
          return '0 climbers';
        case 1:
          return '1 climber';
        default:
          return `${following.length} climbers`;
      }
    };

    const searchResultText = () => {
      if (locationId && !searchDone) {
        return `Climbers in the area of ${location}`;
      } else if (searchDone) {
        return 'Search results';
      }
      return null;
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-lg-5 col-sm-7">
            <div className="onboarding__header-description">STEP 4 OF 4</div>
            <h3>Follow your friends {!followEnabled && '☹️'}</h3>
            <div>
              <div className="row">
                <div className="col-xs-8">
                  <label>
                    Following
                    <div className="no-bold">
                      {followEnabled ? (
                        'Allow others to follow my activities on 27 Crags'
                      ) : (
                        "Others can't follow you and you can not follow anyone"
                      )}
                    </div>
                  </label>
                </div>
                <div className="col-xs-4">
                  <div className="text-left">
                    <Switch
                      checked={followEnabled}
                      onChange={toggleFollowEnabled}
                      checkedChildren={'Enabled'}
                      unCheckedChildren={'Disabled'}
                    />
                  </div>
                </div>
              </div>

              {followEnabled && (
                <div className="onboarding__search-container">
                  <p>
                    <input
                      className="form-control input-lg"
                      type="text"
                      placeholder="Search to follow someone"
                      value={this.state.query}
                      onChange={this.handleInputChange}
                    />
                  </p>
                  <h3>
                    {searchResultText()}
                  </h3>
                  <div>
                    {searchedClimbers.map(climber => <Climber key={climber.id} {...climber} toggleFollow={toggleFollow} />)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-7 col-sm-5">
            <div className="onboarding__forward-container--top">
              <div>Following {followText()}</div>
              <button className="btn btn-primary btn-primary--onboarding" onClick={() => moveForward()}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Follow;

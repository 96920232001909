import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FeedItem from '../feed/components/FeedItem';
import {
  toggleFeedItemLike,
  deleteFeedItem,
  toggleDeleteDialogVisibility
} from '../feed/actions';
import { fetchComments, toggleCommentsVisibility } from '../common/actions';

const setupFeedItem = feed => {
  if (feed.feedItem.climber_id === feed.currentClimber.id) {
    feed.feedItem.isOwn = true;
  }
  return {
    ...feed.feedItem,
    comments: feed.comments,
    commentsVisible: feed.commentsVisible
  };
};

const mapStateToProps = state => ({
  currentClimber: state.feed.currentClimber,
  feedItem: setupFeedItem(state.feed),
  deleteDialogVisible: state.feed.deleteDialogVisible ? state.feed.feedItem.id : 0,
  fetching: state.feed.fetching,
  fetched: state.feed.fetched,
  error: state.feed.error,
  deleted: state.feed.deleted,
  waitingForDelete: state.feed.waitingForDelete,
  addCommentPending: state.feed.addCommentPending
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onLikeClick: data => toggleFeedItemLike(data),
  onDeleteClick: deleteFeedItem,
  deleteFeedItem,
  fetchComments,
  toggleCommentsVisibility,
  toggleDeleteDialogVisibility
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedItem);

import axios from 'axios';
import { reset, change } from 'redux-form';
import * as types from './actionTypes';
import {authenticityToken} from "./util";
axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken()

export const fetchComments = (url, feedItemId) => (
  (dispatch) => {
    dispatch({
      type: `${types.FETCH_COMMENTS}_PENDING`,
      payload: feedItemId,
    });
    axios.get(url)
      .then((result) => {
        dispatch({
          type: `${types.FETCH_COMMENTS}_FULFILLED`,
          payload: { feedItemId, result },
        });
      }).catch((err) => {
        dispatch({
          type: `${types.FETCH_COMMENTS}_REJECTED`,
          payload: err,
        });
      });
  }
);

export const toggleCommentsVisibility = feedItemId => (
  (dispatch) => {
    dispatch({
      type: types.TOGGLE_COMMENTS_VISIBILITY,
      payload: feedItemId,
    });
  }
);

export const addComment = (url, body, feedItemId) => (
  (dispatch) => {
    dispatch({
      type: `${types.ADD_COMMENT}_PENDING`,
    });
    axios.post(url, body)
      .then((result) => {
        dispatch(reset(`commentForm-${feedItemId}`));
        dispatch({
          type: `${types.ADD_COMMENT}_FULFILLED`,
          payload: { feedItemId, result },
        });
      }).catch((err) => {
        dispatch({
          type: `${types.ADD_COMMENT}_REJECTED`,
          payload: err,
        });
      });
  }
);

export const startCommentEditing = (commentId, comment, feedItemId) => (
  (dispatch) => {
    dispatch({
      type: types.START_COMMENT_EDITING,
      payload: { commentId, feedItemId },
    });
    dispatch(change(`commentForm-${feedItemId}`, 'comment', comment));
  }
);

export const editComment = (url, { commentId, content }, feedItemId) => (
  (dispatch) => {
    dispatch({
      type: `${types.EDIT_COMMENT}_PENDING`,
    });
    axios.patch(url + commentId, `comment=${encodeURIComponent(content)}`)
      .then((result) => {
        dispatch(reset(`commentForm-${feedItemId}`));
        dispatch({
          type: `${types.EDIT_COMMENT}_FULFILLED`,
          payload: { feedItemId, result },
        });
      }).catch((err) => {
        dispatch({
          type: `${types.EDIT_COMMENT}_REJECTED`,
          payload: err,
        });
      });
  }
);

export const deleteComment = (url, id, feedItemId) => (
  (dispatch) => {
    dispatch({
      type: `${types.DELETE_COMMENT}_PENDING`,
    });
    axios.delete(`${url}${id}`)
      .then((result) => {
        dispatch({
          type: `${types.DELETE_COMMENT}_FULFILLED`,
          payload: { feedItemId, result },
        });
      }).catch((err) => {
        dispatch({
          type: `${types.DELETE_COMMENT}_REJECTED`,
          payload: err,
        });
      });
  }
);

(function( $ ) {
  /**
   * @example $('.text-container').read-more()
   *
   * @param opts
   * @returns {*}
   */


  $.fn.readmore = function(opts) {

    return this.each(function() {
      var options = {
        maxlines: 6,
        moretext: 'Read more',
        lesstext: 'Hide',
        ellipsis: '...'
      };

      var init = function($elem){
        jQuery.extend(options, $elem.data());

        var $first_child = $elem.children().first();

        if($first_child[0] && ! $first_child.is('br')) {
          $.extend(options, {
            lineheight: $first_child.height()
          });
        }
        else {
          $elem.append('<span class="readmore-lh" style="display:none;">&nbsp;</span>');
          $.extend(options, {
            lineheight: ($('.readmore-lh').css('height').replace('px', ''))
          });
        }
        $elem.append('<div class="readmore-gradient" style="height: ' + options.lineheight + 'px"></div>');

        if(typeof options.maxheight == 'undefined'){
          $.extend(options, {
            maxheight: (options.maxlines * options.lineheight)
          });
        }

        options = $.extend(options, opts);
      };

      var $elem = $(this);
      init($elem);

      if ($elem.height() > options.maxheight) {
        $elem.css({
          'overflow': 'hidden',
          'height': options.maxheight + 'px'
        });

        var link = $('<a href="#" class="readmore-toggle">' + options.moretext + '</a>');
        var wrapDiv = $('<div class="readmore-wrap" />');

        $elem.wrap(wrapDiv);
        $elem.after(link);
        $elem.addClass('readmore__text-clipped');

        link.click(function() {
          if (link.text() == options.moretext) {
            link.text(options.lesstext);
            $elem.css('height', 'auto');
            $elem.removeClass('readmore__text-clipped');
          } else {
            link.text(options.moretext);
            $elem.css('height', options.maxheight + 'px');
            $elem.addClass('readmore__text-clipped');
          }
          return false;
        });
      }
    });
  };

}( jQuery ));

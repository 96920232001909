
window.CragMapViews = function() {
  
  function in_a_div(content, class_name) {
    var div_options = class_name ? " class='" + class_name + "'" : '';
    return '<div' + div_options + '>' + (content || '') + '</div>';
  }
  
  function save_button(label, class_name) {
    return in_a_div("<input type='submit' class='" + class_name + "' name='commit' value='" + label + "'/>", 'button blue');
  }
  
  function mapbox_footer(content) {
    content = "<div class='button grey'><input class='btn btn-default btn-sm' type='button' name='c' onclick='app.close_info_window();return false;' value='Cancel'/></div>" + content
    return in_a_div(content, 'footer');
  }
  
  function zoom_controls() {
    return  "<div class='zoomIn2'>+ +</div>" +
            "<div class='zoomIn1'>+</div>" +
            "zoom" +
            "<div class='zoomOut1'>-</div>" +
            "<div class='zoomOut2'>- -</div>";
  }
  
  function layout_with_zoom_controls(content) {
    var html =  "<table class='mapbox'>" +
                  "<tr><td class='zoom'>" + zoom_controls() + "</td><td>" + content + "</td></tr>" +
                "</table>";
    return html;
  }
  
  function area_location(crag_name) {
    var html =  "<h3>Save this location for " + crag_name + "</h3>" +
                mapbox_footer(save_button('Save', 'saveAreaLocation btn btn-primary btn-sm'));
    return layout_with_zoom_controls(html);
  }
  
  function marker_buttons() {
    var html =  "<div class='map_marker_buttons'>" +
                  save_button('Add crag sector starting point marker', 'addStartingPoint btn btn-default btn-sm btn-block') +
                  save_button('Add boulder marker', 'addBoulder btn btn-default btn-sm btn-block') +
                  save_button('Add parking space marker', 'addParkingSpace btn btn-default btn-sm btn-block') +
                "</div>"
    return layout_with_zoom_controls(html);
  }
  
  return {
    area_location: area_location,
    marker_buttons: marker_buttons
  };
}
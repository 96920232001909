import React, { Component } from 'react';
import FeedItems from '../feed/components/FeedItems';

class GenericFeed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogVisible: 0,
    };
  }

  render() {
    const {
      currentClimber,
      feedItems,
      toggleFeedItemLike,
      deleteFeedItem,
      fetchComments,
      toggleCommentsVisibility,
      addCommentPending,
    } = this.props;

    const handleToggleDeleteDialogVisibilityClick = (id) => {
      if (this.state.deleteDialogVisible === id) {
        this.setState({ deleteDialogVisible: 0 });
      } else {
        this.setState({ deleteDialogVisible: id });
      }
    };

    return (
      <div>
        <FeedItems
          currentClimber={currentClimber}
          items={currentClimber.paramId ? feedItems : feedItems.slice(0, 4)}
          onLikeClick={data => toggleFeedItemLike(data)}
          onDeleteClick={id => deleteFeedItem(id)}
          fetchComments={fetchComments}
          toggleCommentsVisibility={toggleCommentsVisibility}
          toggleDeleteDialogVisibility={handleToggleDeleteDialogVisibilityClick}
          deleteDialogVisible={this.state.deleteDialogVisible}
          addCommentPending={addCommentPending}
        />
      </div>
    );
  }
}

export default GenericFeed;

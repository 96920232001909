import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NotificationsMenuComponent from "./NotificationsMenuComponent";
import { fetchNotifications } from "./actions";

const mapStateToProps = state => {
  return { notifications: state.notifications, fetching: state.fetching };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNotifications
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsMenuComponent);

import * as types from "../common/actionTypes";

const regularSearchReducer = () => {
  const initialState = {
    results: []
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case types.SET_RESULTS:
        return {
          ...state,
          results: payload
        };
      case `${types.SET_RESULTS}_FULFILLED`:
        return {
          ...state,
          results: payload
        };
      default:
        return state;
    }
  };
};
export default regularSearchReducer;

/* The first argument can be a json hash:
 * map:     {latitude, longitude, zoom, editable},
 * crag:    {name, latitude, longitude, url, photo_url},
 * crags:   [{crag0}...],
 * markers: [{latitude, longirude, icon_url}...] ??????????
 */
window.CragMap = function() {
  var json = arguments[0] || {};
  var map = AreaMap(json);
  var g_map = map.g_map;
  var general_marker;
  var markers = {};
  var edit_mode = false;
  var detail_limit = 14;
  var details_present = false;

  map.to_go_limit = 10;

  function has_location() {
    return json.crag && json.crag.latitude && json.crag.longitude;
  }

  function each_marker(message) {
    $.each(markers, function(url, marker) {
      marker[message]();
    });
  }

  function each_marker_except(single_point_marker, message) {
    $.each(markers, function(url, marker) {
      marker.each_element_except(single_point_marker, message);
    });
  }

  function show_details() {
    if (g_map.getZoom() >= detail_limit && !details_present) {
      each_marker("show");
      details_present = true;
    }
  }

  function hide_details() {
    if (details_present) {
      each_marker("hide");
      if (general_marker) general_marker.show();
      details_present = false;
    }
  }

  function highlightSector(sector_url) {
    if (typeof markers[sector_url] != "undefined") {
      for (var key in markers) {
        if (typeof markers[key].resetHighlight != "undefined") {
          markers[key].resetHighlight();
        }
      }
      markers[sector_url].highligh();
    }
  }

  function show_or_hide_details() {
    g_map.getZoom() < detail_limit ? hide_details() : show_details();
  }

  function add_marker(object) {
    var marker = MapMarkerFactory.new_marker(object);
    if (marker) markers[object.url] = marker;
    else app.log("add_marker failed: ", object);
    return marker;
  }

  function add_general_marker(marker) {
    return (general_marker = add_marker(marker));
  }

  function add_markers(arr, options) {
    if (arr) {
      $.each(arr, function(i, marker) {
        add_marker($.extend({}, options || {}, marker));
      });
    }
  }

  function draw_trails(arr) {
    if (!arr) {
      return;
    }

    var points_to_coordinates = function(points) {
      var arr = points.split(";").filter(point => point);
      var coordinates = [];
      for (var j = 0; j < arr.length; j++) {
        var latlng = arr[j].split(",");
        coordinates.push({
          lat: parseFloat(latlng[0]),
          lng: parseFloat(latlng[1])
        });
      }
      return coordinates;
    };

    var draw_trail = function(trail) {
      var dashLine = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        strokeWeight: 2,
        scale: 3
      };
      var doubleDashLine = {
        path: "M 0.5,-1 0.5,1 M -0.5,-1 -0.5,1",
        strokeOpacity: 1,
        strokeWeight: 2,
        scale: 5
      };

      var coordinates = points_to_coordinates(trail.points);

      var trailPolyline = new google.maps.Polyline({
        path: coordinates,
        strokeOpacity: 0,
        strokeColor: trail.color != "" ? trail.color : "#D0021B",
        icons: [
          {
            icon: dashLine,
            offset: "0",
            repeat: "10px"
          }
        ],
        map: g_map
      });

      var infowindow = new google.maps.InfoWindow({
        content:
          '<span style="background-color:' +
          trail.color +
          '; display: inline-block; height: 12px; width: 12px; line-height: 14px; border-radius: 50%;"></span> <b>' +
          trail.name +
          "</span>"
      });

      var infoWindowTimeout = null;
      google.maps.event.addListener(trailPolyline, "mouseover", function(
        event
      ) {
        infowindow.setPosition(event.latLng);
        infowindow.open(g_map);
        clearTimeout(infoWindowTimeout);
      });
      google.maps.event.addListener(trailPolyline, "mouseout", function(event) {
        infoWindowTimeout = setTimeout(function() {
          infowindow.close(g_map);
        }, 1500);
      });
    };

    var c = arr.length;
    for (var i = 0; i < c; i++) {
      var trail = arr[i];
      draw_trail(trail);
    }
  }

  // The constructor
  if (has_location()) add_general_marker(json.crag);
  add_markers(json.boulders, { kind: "boulder" });
  add_markers(json.crag_sectors, { kind: "sector" });
  // add_markers(json.crags);
  add_markers(json.map_markers);
  draw_trails(json.trails);
  show_or_hide_details();
  google.maps.event.addListener(g_map, "zoom_changed", show_or_hide_details);

  highlightSector(window.location.pathname.replace("cragmap", "sectors"));

  return $.extend(map, {
    markers: markers,
    each_marker: each_marker, // overrides map.each_marker
    each_marker_except: each_marker_except,
    has_location: has_location,
    add_marker: add_marker,
    add_general_marker: add_general_marker,
    show_details: show_details,
    tiles: function() {
      return false;
    }, // tiles are not initialized for crag maps
    set_edit_mode: function(val) {
      edit_mode = val;
    },
    highlightSector: highlightSector
  });
}

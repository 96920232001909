import foggyMountains from '../../../javascript/images/foggy-mountains.png'
import foggyMountains100x100 from '../../../javascript/images/foggy-mountains-100x100.png'

window.CragDetailsCache = (function(){
  var instance;

  function __CragDetails(){

    var cragDetails = {};

    if(typeof(Storage) !== "undefined") {
      var d = new Date();
      if(! window.localStorage.getItem('cragDetails') || getCurrentExpireDate() < d.getTime() ) {
        updateToLocalStorage();
        window.localStorage.setItem('cragDetailsExpire', getNewExpireDate());
      }
      else {
          cragDetails = JSON.parse(window.localStorage.getItem('cragDetails'));
      }
    }
    var self = this;

    function getNewExpireDate(){
      var d = new Date();
      d.setHours(d.getHours()+1);
      return d.getTime();
    }

    function getCurrentExpireDate(){
      if(! window.localStorage.getItem('cragDetailsExpire') ){
        window.localStorage.setItem('cragDetailsExpire', getNewExpireDate());
      }
      return window.localStorage.getItem('cragDetailsExpire');
    }

    function updateToLocalStorage(){
      window.localStorage.removeItem('cragDetails');
      window.localStorage.setItem('cragDetails', JSON.stringify(cragDetails));
    }

    function getUnCachedCragIds(arr_crag_ids){
      var ids = '';
      $.each(arr_crag_ids, function(i, id){
        if(! self.exists(id) ) {
          ids = ids + id + ',';
        }
      });
      return ids;
    }

    function getByIds(arr_ids) {
      var crags = [];
      $.each(arr_ids, function(i, id){
        crags.push(self.getById(id));
      });
      return crags;
    }

    this.add = function(crag){
      cragDetails[crag.id] = crag;
      updateToLocalStorage();
    };

    this.getById = function(id, loadCallBack){
      if(typeof loadCallBack === 'function') {
        this.loadCragDetails([id], function(crags){
          loadCallBack(crags[0]);
        });
        return;
      }

      if(this.exists(id)) {
        return cragDetails[id];
      }
      else {
        return null;
      }
    };

    this.exists = function(id){
      return ((typeof cragDetails[id] === 'undefined') ? false : true);
    };


    this.loadCragDetails = function(arr_ids, onSuccess){
      var ids = getUnCachedCragIds(arr_ids);

      if(! ids ) {
        if(typeof onSuccess === 'function') {
          onSuccess(getByIds(arr_ids));
        }
        return;
      }

      var url = '/api/web01/crags?ids=' + ids;

      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        beforeSend: function () {
        },
        complete: function () {
        },
        error: function () {
        },
        success: function (data) {
          $.each(data.crags, function(i, crag){
            cragDetails[crag.id] = crag; // do not update Storage every time
          });
          updateToLocalStorage();
          if(typeof onSuccess === 'function') {
            onSuccess(getByIds(arr_ids));
          }
        }
      });
    };
  }

  function createInstance() {
    var object = new __CragDetails();
    return object;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();


function CragCard(crag){
  var self = this;

  this.getRouteCountHTML = function(){
    return '' +
      (crag.sport_count > 0 ? '<span class="route-count"><i class="icon27 icon-sport"></i> ' + crag.sport_count + ' </span>' : '') +
      (crag.trad_n_p_count > 0 ? '<span class="route-count"><i class="icon27 icon-trad"></i> ' + crag.trad_n_p_count + ' </span>' : '') +
      (crag.boulder_count > 0 ? '<span class="route-count"><i class="icon27 icon-boulder"></i> ' + crag.boulder_count + ' </span>' : '') +
      (crag.likes_count > 0 ? '<span class="route-count"><i class="glyphicon glyphicon-heart-empty"></i> ' + crag.likes_count + ' </span>' : '');
  };

  this.getPopupLayout = function(){
    var photoUrl = crag.photo ? crag.photo.styles.size_m.url : foggyMountains; //'/assets/bg/foggy-mountains.png';
    var chart = new GradeChart(crag.route_counts, {height:'49'});

    return '<div class="popup-crag-card" data-crag-id="' + crag.id + '">' +
      '<a href="/crags/' + crag.param_id + '" class="location">' +
        '<span class="img" style="background-image: url(' + photoUrl + ')">' +
          '<span class="crag-summary">' +
            (crag.premium_topo_active ? '<span class="tag-premium-topo">Premium</span>' : '') +
            '<span class="crag-name">' + crag.name + '</span>' +
            '<span class="route-count-container">' +
              self.getRouteCountHTML() +
            '</span>' +
            '<span class="crag-summary-chart">' +
              chart.getHTML() +
            '</span>' +
          '</span>' +
        '</span>' +
      '</a>' +
      '</div>';
  };

  this.getHTML = function(){
    var photoUrl = crag.photo ? crag.photo.styles.size_s.url : foggyMountains100x100;
    var chart = new GradeChart(crag.route_counts, {height:'49'});

    return '<div class="crag-card" data-crag-id="' + crag.id + '"><a href="/crags/' + crag.param_id + '">' +
      '<span class="thumbnail">' +
        '<span class="img" style="background-image: url(' + photoUrl + ')">' +
          (crag.premium_topo_active ? '<span class="tag-premium-topo">Premium</span>' : '') +
        '</span></span>' +
      '<span class="details-container">' +
      '<span class="title">' +
      '<span class="name">' +
      crag.name +
      '</span>' +
      '<span class="area">, ' +
      (crag.location != null ? crag.location.name : '') +
      '</span>' +
      '</span>' +
      '<span class="route-count-container">' +
        self.getRouteCountHTML() +
      '</span>' +
      '</span>' +
      '<span class="chart">' +
      chart.getHTML() +
      '</span>' +
      '<span class="clearfix"></span></a></div>';
  };

}


window.WorldMapCragList = function(selector){
  var HARDLIMIT = 50;
  var self = this;
  var sorterFunction = null;

  var cragsDetailsCache = CragDetailsCache.getInstance();

  var $list = $(selector);

  function sortCrags(cragsArr){
    if(typeof sorterFunction == 'function'){
      sorterFunction(cragsArr);
    }
  }

  function resizeLayout(){

    $list.css('max-height',
      (
        $('#mapcontainer').outerHeight()
        - 20
        //- parseInt($('#mapControlsContainer .controls-page').first().css('padding-bottom'))
        - parseInt($('#mapControlsContainer .controls-header').first().outerHeight())
      ) + 'px'
    );
  }

  function getZoominLabel() {
    return '<div class="help-label">' +
    'Zoom in and explore ' +
    'or browse' +
    ' <a href="/crags/all" class="crag-list-link">crag list</a>.' +
    '</div>';
  }

  function highlightOnMap(crag_id) {
    app.map_markers.highlightMarker(crag_id)
  }

  this.setSorting = function(_sorterFunction){
    sorterFunction = _sorterFunction;
  };

  this.renderFromMarkers = function(markers_arr){
    var crags_arr = [];

    $.each(
      markers_arr.slice(0, HARDLIMIT),
      function(i, marker){
        crags_arr.push(marker.data.id);
      }
    );
    if(crags_arr.length > 0) {
      cragsDetailsCache.loadCragDetails(crags_arr, this.render);
    }
    else {
      this.render([]);
    }
  };


  this.render = function(crags_arr){
    if(isSmallscreen()) {
      return;
    }
    if(crags_arr.length < 1) {
        $('#mapControlsContainer').removeClass("full-height");
        $list.empty();
        $list.append(getZoominLabel());
      return;
    }
    $list.empty();
    resizeLayout();
    $('#mapControlsContainer').addClass("full-height");

    var cached_crags_arr = [];
    $.each(crags_arr, function(i, crag) {
      if(crag) {
        cached_crags_arr.push(cragsDetailsCache.getById(crag.id));
      }
    });

    sortCrags(cached_crags_arr);

    $.each(cached_crags_arr, function(i, cragCached){
      var card = new CragCard(cragCached);
      var $item = $(card.getHTML());
      $item.on('mouseover', function(e){
        highlightOnMap($(this).data('crag-id'));
      });
      /*
      var $bg = $item.find('.thumbnail .img');
      $bg.hide();
      $bg.imagesLoaded({ background: true }, function(){
        $bg.fadeIn(100);
      });*/
      $list.append($item);

    });
  }
}
import * as types from '../../common/actionTypes';

const initialState = {
  map: null,
  cragMarkers: {"type": "FeatureCollection", "features": [] },
  otherMarkers: {"type": "FeatureCollection", "features": [] },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_MAPBOX:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;

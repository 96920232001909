import React from 'react';

const GradeBarChart = (props) => {
  const defaultOptions = {
    height: 100,
    showLabels: false,
    className: 'barchart'
  };

  const options = {
    ...defaultOptions,
    ...props.options,
  };

  const height = options.height;
  const maxCount = props.chartData.reduce((result, count) => count['value'] > result ? count['value'] : result, 0);
  const labelHeight = 10;
  const emptyBarHeight = 10;

  const getBarHeight = (count) => {
    if(count > 0) {
      return (count / maxCount * 0.8 * height) + emptyBarHeight;
    }
    return emptyBarHeight;
  }

  const getColorClass = (grade_group) => {
    return `grade-color-${Math.floor(parseFloat(grade_group))}`;
  }

  return (
    <span className={options.className}>
      <div className="bars" style={{ height: `${height}%` }}>
        {props.chartData.map((count, i) => (
          <span key={i} className="col">
            <span className="count-label">{count['value'] > 0 ? count['value'] : ''}</span>
            <span className={`bar ${(count['value'] > 0 ? getColorClass(count['grade_group']) : '')}`} style={{height: `${getBarHeight(count['value'])}%`}} />
          </span>
        ))}
      </div>
      <div className={'labels'}>
        {props.chartData.map((value, index, arr) => (
          <span key={index} className="col">
            <span className={'grade-label'}>{value['label']}</span>
          </span>
        ))}
      </div>
    </span>
  );
};

export default GradeBarChart;

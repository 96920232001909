window.app.MapNavigator = function(map, g_map) {
  
  var clicked_latlng;
  var click_count = 0;
  var double_click_count = 0;

  var zoom; // recorded in the beginning of click_zoom and usable during the current click or double click
  var handled_by_click_zoom; // assigned by click_zoom and cleared on zoom_changed
  var old_zoom = g_map.getZoom(); // for access on zoom_changed

  var hinter;

  map.stale = false;

  function pan_duration_from_distance(event) {
    if ( event.pixel && event.pixel.x && event.pixel.y ) { // event.pixel is undocumented
      var div = g_map.getDiv();
      var distance = Math.abs(div.offsetWidth / 2 - event.pixel.x) + Math.abs(div.offsetHeight / 2 - event.pixel.y);
      return Math.round(distance / 3.5);
    } else {
      return 10;
    };
  }

  function go_to(latlng, delay, zoom_to_keep) {
    /*map.stale = true;
    if ( $.blockUI.active() ) $('.blockUI').css({opacity:'0.5'});
    else $.blockUI();
    setTimeout(function(){ // give time to display the pan animation
      var path = '/areas/' + latlng.lat().toFixed(2) + ',' + latlng.lng().toFixed(2);
      if ( zoom_to_keep ) path = path + '?zoom=' + zoom_to_keep;

      top.location = path;
    }, delay || 0);*/
  }
  
  function double_click_handler() {
    double_click_count++;
    if ( click_count > double_click_count && zoom > 3 ) go_to(clicked_latlng, 25);
    else if ( hinter && zoom > 1 ) hinter.double_clicked();
  };
  
  function add_double_click_listener() {
    $.blockUI({opacity:'0.0'});
    $('.blockUI').click(double_click_handler);
    setTimeout(function(){
      if ( !map.stale ) {
        $('.blockUI').unbind('click', double_click_handler);
        $.unblockUI();
        if ( hinter && click_count > double_click_count ) hinter.single_clicked();
      }
    }, 800);
  }

  function click_zoom(event) {
    if ( event.latLng ) {
      click_count++;
      clicked_latlng = event.latLng;
      zoom = g_map.getZoom();
      add_double_click_listener();
      g_map.panTo(event.latLng);
      if ( zoom >= 7 && map.new_area(event.latLng) ) {
        go_to(event.latLng, pan_duration_from_distance(event));
      } else {
        handled_by_click_zoom = true;
        g_map.setZoom(zoom + 1);
      }
    }
  }
  
  function to_area() {
    var new_zoom = g_map.getZoom();
    if ( !handled_by_click_zoom && old_zoom < new_zoom && new_zoom > 7 && map.new_area() ) go_to(g_map.getCenter(), null, new_zoom);
    else old_zoom = new_zoom;
    handled_by_click_zoom = false;
  }
  
  function away_from_crag() {
    if ( g_map.getZoom() <= map.to_go_limit ) go_to(g_map.getCenter());
  }
  
  // the public interface
  return {
    enable_single_click_hint: function(h){ hinter = h; },
    click_zoom      : click_zoom,
    to_area         : to_area,
    to_crag         : null,
    away_from_crag  : away_from_crag
  };
  
};

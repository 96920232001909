import React, { Component } from "react";
import { routeListFieldHash } from "../../common/util.js";
import DropDownFilter from '../../common/components/DropDownFilter';
import ToggleFilterButton from "../../common/components/ToggleFilterButton";

class OrderButton extends Component {
  orderBy(e) {
    this.props.orderBy(e.target.value);
  }
  render() {
    const { orderBy, order_by, arrow } = this.props;
    const btnClass = Object.keys(routeListFieldHash).some(
      key => key == order_by
    )
      ? "btn btn-primary"
      : "btn btn-default";
    return (
      <div className="visible-xs-inline">
        <DropDownFilter
          titleButton={"Order"}
          titleHeader={"Order by"}
          titleClose={"Apply"}
          showDropdown={this.props.showDropdown}
          hideDropdown={this.props.hideDropdown}
          btnClass={btnClass}
        >
          <ul className="premium-search-filter__checkbox-container">
            {Object.keys(routeListFieldHash).map((key, i) => {
              return (
                <ToggleFilterButton
                  active={key == order_by}
                  key={i}
                  value={key}
                  onChange={this.orderBy.bind(this)}
                >
                  {routeListFieldHash[key]} {key == order_by ? arrow : ""}
                </ToggleFilterButton>
              );
            })}
          </ul>
        </DropDownFilter>
      </div>
    );
  }
}

export default OrderButton;

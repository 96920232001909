import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RegularSearchContainer from "./components/RegularSearchContainer";
import { findResults, clearResults } from "./actions";

const mapStateToProps = (state, ownProps) => ({
  results: state.results,
  active: ownProps.active
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      findResults,
      // clearResults
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegularSearchContainer);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Map from './Map';
import {
  initMapboxMaps,
  updateMapBounds,
  updateVisibleCrags,
  updateVisibleMarkers,
  highlightCrag,
  setMarkerEditPopup,
  setCommercialMarkerPopup,
} from '../../actions';

const mapStateToProps = state => ({
  useHashLocation: state.map.useHashLocation,
  locationId: state.map.locationId,
  location: state.map.location,
  zoom: state.map.zoom,
  icons: state.map.icons,
  crags: state.crag.crags,
  markers: state.marker.markers,
  editable: state.map.editable,
  bounds: { center: state.map.center, zoom: state.map.zoom },
  filters: state.map.filters,
  mapboxMaps: state.mapbox,
  cragPopupVisible: state.map.cragPopupVisible,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  initMapboxMaps,
  updateVisibleCrags,
  updateVisibleMarkers,
  updateMapBounds,
  highlightCrag,
  setMarkerEditPopup,
  setCommercialMarkerPopup,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Map);

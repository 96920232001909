/* == CONCEPT DEFINITIONS ==
 * marker:    a symbol attatched to a map
 * symbol:    a composite that can contain several map_points, images and lines
 * map_point: a single point with latitude and longitude and a g_marker
 * g_marker:  Google map marker (icon attatched to a latlng)
 * latlng:    Google map point with latitude and longitude
 */

/* The first argument can be a json hash:
 * map:     {latitude, longitude, zoom},
 * crags:   [{name, latitude, longitude, url, photo_url}...],
 */
window.WorldMap = function(json) {
  var highlighted_marker = null;
  var markers = [];
  var default_zoom = 2;
  var offsetX = ($('#mapControlsContainer').outerWidth() != 'undefined' ? $('#mapControlsContainer').outerWidth()/2 : 0);
  var offsetY = 0;

  var initPosition = _getPositionFromHash();
  if (initPosition == null) {
   initPosition = [{lat: 30, lng: 12}, default_zoom];
  }
  // Accessors
  function center_latitude()  { return (json.map && json.map.latitude  ? json.map.latitude  : 30); }

  function center_longitude() { return (json.map && json.map.longitude ? json.map.longitude : 12); }

  function center() {
    /*if(initPosition) {
      return new google.maps.LatLng(initPosition[0].lat, initPosition[0].lng);
    }*/

    return new google.maps.LatLng(center_latitude(), center_longitude());
  }

  function zoom(){
    /*if(initPosition) {
      return initPosition[1];
    }*/
    return (json.map && json.map.zoom ? json.map.zoom : default_zoom);
  }

  function map_type() { return (2 < zoom() && zoom() <= 10 ? google.maps.MapTypeId.TERRAIN : google.maps.MapTypeId.ROADMAP); }

  function each_marker(message) {
    $.each(markers, function(i, marker) {
      marker[message]();
    });
  }

  function add_marker(object) {
    var marker = MapMarkerFactory.new_crag(object);
    if ( marker ) markers[object.param_id] = marker;
    else app.log('add_marker failed: ', object);
    return marker;
  }
  /*
  function add_crags() {
    if ( json.crags ) {
      $.each(json.crags, function(i, crag){
        if ( crag && crag.latitude && crag.longitude ) {
          add_marker(crag)
        }
      });
    }
  }*/

  function _getPositionFromHash(){
    if(location.hash.length < 1) {
      return null;
    }

    var position_as_string = location.hash.substring(1);
    var args = position_as_string.split(',');
    if(args.length < 3){
      return null;
    }

    return [{lat: args[0],lng: args[1]}, parseInt(args[2])];
  }

  function _getPositionAsString(){
    var center = _getMapCenter();
    return center.lat()+','+center.lng()+','+g_map.getZoom()+'z';
  }

  function _getMapCenter(latlng, zoomlevel){
    latlng = typeof latlng !== 'undefined' ? new google.maps.LatLng(latlng.lat, latlng.lng) : g_map.getCenter();
    zoomlevel = typeof zoomlevel !== 'undefined' ? zoomlevel : g_map.getZoom();

    var point1 = g_map.getProjection().fromLatLngToPoint(latlng);

    var _offsetX = offsetX;

    var point2 = new google.maps.Point(
      ( -_offsetX / Math.pow(2, zoomlevel) ) || 0,
      ( offsetY / Math.pow(2, zoomlevel) ) || 0
    );

    var projection = g_map.getProjection().fromPointToLatLng(new google.maps.Point(
      point1.x - point2.x,
      point1.y + point2.y
    ));
    return projection;
  }

  function _setMapCenter(latlng, zoomlevel){
    if(typeof latlng == 'undefined') {
      latlng = initPosition[0];
    }
    if(typeof zoomlevel == 'undefined') {
      zoomlevel = initPosition[1];
    }

    var _latlng = new google.maps.LatLng(latlng.lat, latlng.lng);

    var point1 = g_map.getProjection().fromLatLngToPoint(_latlng);

    var _offsetX = ($('#mapControlsContainer').outerWidth() != 'undefined' ? $('#mapControlsContainer').outerWidth()/2 : 0);

    var point2 = new google.maps.Point(
      ( _offsetX / Math.pow(2, zoomlevel) ) || 0,
      ( offsetY / Math.pow(2, zoomlevel) ) || 0
    );
    var proj = g_map.getProjection().fromPointToLatLng(new google.maps.Point(
      point1.x - point2.x,
      point1.y + point2.y
    ));

    g_map.setCenter({lat: proj.lat(), lng: proj.lng()});
    g_map.setZoom(zoomlevel);
  }

  // The constructor
  var g_map = app.g_map = new google.maps.Map($('#map')[0], {
    center: center(),
    zoom: zoom(),
    mapTypeId: map_type(),
    disableDefaultUI: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP
    },
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    }
  });


  var scrollDiv = document.createElement('div');
  var scrollControl = new MapButtonScrollZoom(scrollDiv, g_map);
  scrollDiv.index = 1;
  g_map.controls[google.maps.ControlPosition.RIGHT_TOP].push(scrollDiv);

  var shareDiv = document.createElement('div');
  var shareControl = new MapButtonShareLocation(shareDiv, g_map);
  shareDiv.index = 1;
  g_map.controls[google.maps.ControlPosition.RIGHT_TOP].push(shareDiv);
  
  return {
    g_map       : g_map,
    markers     : markers,
    json        : json,
    each_marker : each_marker,
    new_area    : function() { return true; },
    hide_markers: function() { each_marker('hide'); },
    show_markers: function() { each_marker('show'); },
    open_info_window  : function() {;}, // to be compatible with EditableCragMap
    close_info_window : function() {;},
    resize_info_window: function() {;},
    getMapCenter: function(latlng, zoomlevel) {
      return _getMapCenter(latlng, zoomlevel);
    },
    setMapCenter: function(latlng, zoomlevel){
      _setMapCenter(latlng, zoomlevel);
    },
    getPositionAsString: function(){
      return _getPositionAsString();
    },
    getPositionFromHash: function(){
      return _getPositionFromHash();
    }
  };
}

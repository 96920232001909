// jQuery plugin to localize the grade in: <span class="grade" data-grade="B7a">7A</span>
// ('B' stands for boulders)

//function getGradesArray(system) {
//  var arr = [];
//  for (var grade in GRADES_HASH) {
//    localizedGrade = getLocalizedGrade(system, grade);
//    if (grade != 0 && localizedGrade != null) {
//      arr.push(localizedGrade);
//    }
//  }
//  return [...new Set(arr)];
//}

window.getGradingSystem = function(genre = null) {
  if (["T", "P"].includes(genre)) {
    return $.cookie("gsys_trad") == null ? "French" : $.cookie("gsys_trad");
  } else if (genre == "B") {
    return $.cookie("gsys_boulder") == null ? "Font" : $.cookie("gsys_boulder");
  } else
    return $.cookie("gsys_sport") == null ? "French" : $.cookie("gsys_sport");
}

window.getLocalizedGrade = function(system, grade, genre = null) {
  if (system == null) {
    system = getGradingSystem(genre);
  }
  var grade_int = 0;
  if (typeof grade === "string") {
    for (var g in window.GRADES_HASH) {
      if (g === grade || window.GRADES_HASH[g].includes(grade)) {
        grade_int = parseInt(g);
        break;
      }
    }
  } else {
    grade_int = grade;
  }
  var grade_row = window.GRADES_HASH[grade_int];
  if (!grade_row) {
    return "?";
  }
  var index = 4;
  if (system && window.GRADE_SYSTEMS.indexOf(system) > -1) {
    index = window.GRADE_SYSTEMS.indexOf(system);
  }
  return grade_row[index];
}

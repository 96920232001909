import React from "react";

class RouteProperty extends React.Component {
  render() {
    return (
      <span
        className={"tag " + this.props.type + " show-tooltip"}
        title={this.props.name}
      >
        <i className={"icon icon27 icon-" + this.props.type} />
      </span>
    );
  }
}

export default RouteProperty;

window.GradeChart = function (route_counts, options) {

  let opts = {
    height: "54",
    labels: false,
    accuracy: 1, // 1 || 2
    routecounts: true,
    barminheight: 20
  };
  $.extend(opts, options);

  const height = opts.height;
  const barMaxHeight = height - (opts.labels ? 18 : 0) - (opts.routecounts ? 18 : 0);
  const barMinHeightPercent = opts.barminheight / height * 100;
  const barMaxHeightPercent = barMaxHeight / height * 100;

  function sumOfProperties(object) {
    let sum = 0
    for (let key in object) {
      sum += object[key]
    }
    return sum
  }

  let genre = "S";
  if (sumOfProperties(route_counts.Boulder) >= sumOfProperties(route_counts["Partially Bolted"]) + sumOfProperties(route_counts.Traditional) && sumOfProperties(route_counts.Boulder) > sumOfProperties(route_counts["DWS"]) + sumOfProperties(route_counts.Sport)) {
    genre = "B"
  } else if (sumOfProperties(route_counts.Boulder) <= sumOfProperties(route_counts["Partially Bolted"]) + sumOfProperties(route_counts.Traditional) && sumOfProperties(route_counts["Partially Bolted"]) + sumOfProperties(route_counts.Traditional) > sumOfProperties(route_counts["DWS"]) + sumOfProperties(route_counts.Sport)) {
    genre = "T"
  }

  function getChartData() {
    let routesCountsByType = {
      boulder: route_counts.Boulder,
      pbolted: route_counts["Partially bolted"],
      sport: route_counts.Sport,
      trad: route_counts.Traditional,
      dws: route_counts["DWS"] //for Deep Water Solo
    };

    let maxCount = 0;

    function getCountOf(lowerLimit, upperLimit) {
      let count = 0;
      $.each(routesCountsByType, function (i, obj) {
        if (typeof obj === "undefined") {
          return;
        }
        let points = Object.keys(obj);
        for (let i = 0; i < points.length; i++) {
          if (lowerLimit <= points[i] && upperLimit > points[i]) {
            count += obj[points[i]];
          }
        }
      });
      maxCount = Math.max(maxCount, count);
      return {
        count: count,
        height: 0
      };
    }

    let routes;
    if (opts.accuracy == 1) {
      routes = {
        "..250": getCountOf(1, 300),
        "300..": getCountOf(300, 400),
        "400..": getCountOf(400, 700),
        "700..": getCountOf(700, 1000),
        "1000..": getCountOf(1000, 1300),
        "1300..": getCountOf(1300, 1500)
      };
    } else if (opts.accuracy == 2) {
      routes = {
        100: getCountOf(100, 200),
        200: getCountOf(200, 300),
        300: getCountOf(300, 350),
        350: getCountOf(350, 400),
        400: getCountOf(400, 500),
        500: getCountOf(500, 600),
        600: getCountOf(600, 700),
        700: getCountOf(700, 800),
        800: getCountOf(800, 900),
        900: getCountOf(900, 1000),
        1000: getCountOf(1000, 1100),
        1100: getCountOf(1100, 1200),
        1200: getCountOf(1200, 1300),
        1300: getCountOf(1300, 1501)
      };
    }

    $.each(routes, function (i, obj) {
      obj.height = obj.count < 1 ? barMinHeightPercent :
        Math.max(
          (
            ((obj.count / maxCount) * (barMaxHeightPercent - barMinHeightPercent)) + barMinHeightPercent),
          barMinHeightPercent);
    });

    return routes;
  }

  this.getHTML = function () {
    let chartData = getChartData();

    function getChartCell(name) {
      let label;
      let pos = name.indexOf("..");
      let grade_int;
      if (pos == 0) {
        grade_int = parseInt(name.substr(2));
        label = ".." + getLocalizedGrade(null, grade_int, genre);
      } else if (pos > 0) {
        grade_int = parseInt(name.substr(0, pos));
        label = getLocalizedGrade(null, grade_int, genre) + "..";
      } else {
        grade_int = parseInt(name);
        label = getLocalizedGrade(null, grade_int, genre);
      }

      let grade = getLocalizedGrade("French", grade_int);
      grade = grade.match(/\d+/); //replace( /(^.+\D)(\d+)(\D.+$)/i,'$2');
      if (grade != null) {
        grade = grade[0];
      }

      return (
        '<span class="col">' +
        '<span class="col__route-count">' +
        (chartData[name].count > 0 && opts.routecounts ? chartData[name].count : "") +
        '</span>' +
        "<span class='cell'" +
        'style="height: ' + chartData[name].height + '%">' +
        "<span " +
        'class="bar grade-color-' +
        grade +
        (chartData[name].count > 0 ? "" : " bar--empty") + '"' +
        (label ? 'title="' + label + '"' : "") +
        '"' +
        ">" +
        '</span>' +
        '</span>' +
        (opts.labels
          ? '<span class="grade-label">' + label + "</span>"
          : "") +
        '</span>'
      );
    }

    let html = '<div class="bars" style="height: ' + height + 'px;">';
    $.each(chartData, function (key) {
      html += getChartCell(key);
      margin = true;
    });
    html += "</div>";
    return html;
  };
}

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PremiumSearchForm from "./components/PremiumSearchForm";
import {
  updategradeMinInt,
  updategradeMaxInt,
  updateArea,
  updateOrderBy
} from "./actions.jsx";

const mapStateToProps = (state, ownProps) => ({
  gradeMinInt: state.premiumSearchParams.gradeMinInt,
  gradeMaxInt: state.premiumSearchParams.gradeMaxInt,
  area: state.premiumSearchParams.selectedArea,
  areas: ownProps.areas,
  gradingSystem: ownProps.gradingSystem,
  orderBy: state.premiumSearchParams.orderBy
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updategradeMinInt,
      updategradeMaxInt,
      updateArea,
      updateOrderBy
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremiumSearchForm);

import * as types from '../common/actionTypes';
import commentReducer from '../common/reducers/commentReducer';

const routeCommentsReducer = (_currentClimber, photoUrl, apiUrl, routeId, editable) => {
  const initialState = {
    currentClimber: {..._currentClimber, photoUrl: photoUrl},
    apiUrl,
    routeId,
    editable,
    comments: [],
    fetching: false,
    error: null,
    isEditing: false,
    editableCommentId: null,
    addCommentPending: false
  };

  return (state = initialState, {type, payload}) => {
    switch (type) {
      case `${types.FETCH_COMMENTS}_PENDING`:
        return {
          ...state,
          fetching: true
        };
      case `${types.EDIT_COMMENT}_PENDING`:
      case `${types.ADD_COMMENT}_PENDING`:
        return {
          ...state,
          addCommentPending: true
        };
      case `${types.FETCH_COMMENTS}_FULFILLED`:
      case `${types.ADD_COMMENT}_FULFILLED`:
      case `${types.DELETE_COMMENT}_FULFILLED`:
        const data = payload.result ? payload.result : payload;
        return {
          ...state,
          comments: commentReducer(state.comments, type, data, state.currentClimber),
          error: null,
          fetching: false,
          addCommentPending: false
        };
      case types.START_COMMENT_EDITING:
        return {
          ...state,
          isEditing: true,
          editableCommentId: payload.commentId,
        };
      case `${types.EDIT_COMMENT}_FULFILLED`:
        return {
          ...state,
          comments: commentReducer(state.comments, type, payload.result, state.currentClimber),
          error: null,
          isEditing: false,
          addCommentPending: false
        };
      case `${types.FETCH_COMMENTS}_REJECTED`:
      case `${types.ADD_COMMENT}_REJECTED`:
      case `${types.EDIT_COMMENT}_REJECTED`:
      case `${types.DELETE_COMMENT}_REJECTED`:
        return {...state, error: payload, fetching: false, addCommentPending: false};
      default:
        return state;
    }
  }
};

export default routeCommentsReducer;

import React, { Component } from "react";
import NavDesktop from "./components/NavDesktop";
import NavMobile from "./components/NavMobile";

class Nav extends Component {
  componentDidMount() {
    this.props.syncUser();
  }
  render() {
    return (
      <div>
        <NavMobile {...this.props} />
        <NavDesktop {...this.props} />
      </div>
    );
  }
}

export default Nav;

import React, { Component } from 'react';
import CragsSelect from "../../components/CragsSelect";
import {Line} from 'react-chartjs-2';
import DatePicker from "../../../common/components/DatePicker";
import DateRangePicker from "react-bootstrap-daterangepicker"
import moment from "moment";

class VisitsChart extends Component {
  constructor(props) {
    super(props);
    var cragFilter = [];
    this.props.crags.forEach(crag => {
      cragFilter.push(crag['id'].toString());
    });
    this.state = {
      cragFilter: [...cragFilter],
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      paramId: this.props.paramId,
      visits: this.props.visits
    };

    this.changeCragFilters = this.changeCragFilters.bind(this);
    this.buildChartData = this.buildChartData.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onDateChange = this.onDateChange.bind(this);

    this.props.getVisitsData(
      this.state.cragFilter,
      this.state.startDate,
      this.state.endDate,
      this.state.paramId,
    );
  }

  changeCragFilters(selectedCragIds) {
    this.setState({
      cragFilter: selectedCragIds,
    }, () => {
      this.pullChartData();
    });
  }

  onDateChange(event, picker){
    this.setState({
      startDate: picker.startDate.format("YYYY-MM-DD"),
      endDate: picker.endDate.format("YYYY-MM-DD"),
    }, () => {
      this.pullChartData();
    });
  }

  onUpdateClick(){
    this.pullChartData();
  }

  pullChartData(){
    this.props.getVisitsData(
        this.state.cragFilter,
        this.state.startDate,
        this.state.endDate,
        this.state.paramId,
    );
  }

  buildChartData(visits) {

    const freeVisits = [];
    const labels = [];
    const premiumVisits = [];

    if (typeof visits['daily_visits'] != 'undefined') {
      visits['daily_visits'].forEach(visit => {
        freeVisits.push(visit['free']);
        labels.push(visit['date']);
        premiumVisits.push(visit['premium']);
      });
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Downloads',
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(255, 99, 132)',
          data: premiumVisits,
        },
        {
          label: 'Free visits',
          backgroundColor: 'rgb(99,151,255)',
          borderColor: 'rgb(99,151,255)',
          data: freeVisits,
        }
      ]
    };

    return data;
  }

  render() {
    return (
      <div className="visits-container">
        <div className="panel panel-default">
          <div className="panel-body">
            <div className={"row"}>
              <div className="col-sm-4">
                <CragsSelect
                  crags={this.props.crags}
                  onFilterChange={this.changeCragFilters}
                />
              </div>
              <div className="col-sm-4">
                <DateRangePicker
                  initialSettings={{
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    locale: {
                      format: 'YYYY-MM-DD',
                    },
                    maxSpan: {
                      months: 3
                    },
                    ranges: {
                      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                      'Last 3 Months': [moment().subtract(3, 'months'), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                  }}
                  onApply={this.onDateChange}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
            </div>
          </div>
        </div>

        <div className="panel panel-default">
          <div className="panel-body">
            <div className="row">
              <div className="col-xs-4">
                <div className="number-liftup number-liftup__white-bg">
                  <div className="name">Downloads</div>
                  <div className="number">{this.props.visits['premium']}</div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="number-liftup number-liftup__white-bg">
                  <div className="name">Free visits</div>
                  <div className="number">{this.props.visits['free']}</div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="number-liftup number-liftup__white-bg">
                  <div className="name">Unique users</div>
                  <div className="number">{this.props.visits['unique']}</div>
                </div>
              </div>
            </div>
            <hr />
            <div className={"chart-wrapper"}>
              <div className={`chart-loader-animation ${(this.props.loading ? "" : "hidden")}`}>
                <div className="lds">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <Line
                data={this.buildChartData(this.props.visits)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VisitsChart;

import React from "react";
import {convertGrade} from "../common/util";

const AscentHighlight = (props) => {
    const { ascent, year, gradingSystem, onNextClick } = props;

    return (
        <div className={'slide-container'}>
            <div className={'slide-content pyro'}>
                <div className="pyro__before"></div>
                <h2>
                    Your {ascent.route_genre == 'Traditional' ? 'Trad' : ascent.route_genre}
                    <br />
                    highlight of {year}
                </h2>
                {ascent.route_photo_url != null &&
                    <img className={'route-photo'} src={ascent.route_photo_url}/>
                }
                <p>{ascent.route_name} {convertGrade(ascent.route_grade_int, gradingSystem)} at {ascent.crag_name}.</p>
                <a className={'btn-continue'} onClick={onNextClick}>
                    Continue
                </a>
                <div className="pyro__after"></div>
            </div>
        </div>
    );
}

export default AscentHighlight;
import React from 'react';
import Switch from 'react-switch';

const ToggleSwitch = props => {
  const { id, label, onChange, checked } = props;
  return (
    <label htmlFor={id} className="toggle-switch">
      {label}
      <Switch
        onChange={onChange}
        checked={checked}
        id={id}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={20}
        width={40}
        height={24}
        onColor="#00cc00"
      />
    </label>
  )
}

export default ToggleSwitch;

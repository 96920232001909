import React from 'react';
import {combineReducers} from 'redux';
import {Provider} from 'react-redux';
import configureStore from '../common/store';
import {reducer as formReducer} from 'redux-form';
import routeCommentsReducer from './reducer';
import Container from './Container';

const RouteCommentsApp = (railsProps, _railsContext) => {
  const reducer = combineReducers({
    comments: routeCommentsReducer(
      JSON.parse(railsProps.currentClimber),
      railsProps.photoUrl,
      railsProps.apiUrl,
      railsProps.routeId,
      railsProps.editable
    ),
    form: formReducer
  });

  return (
    <Provider store={configureStore(reducer)}>
      <Container/>
    </Provider>
  );
};

export default RouteCommentsApp;

import React, { Component } from "react";
import Results from "./Results";
import SearchField from "./SearchField";

class RegularSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      timeout: 0
    };
    this.updateQuery = this.updateQuery.bind(this);
  }

  updateQuery(e) {
    this.setState({
      query: e.target.value
    });
    if (this.state.timeout) clearTimeout(this.state.timeout);
    this.setState({
      timeout: setTimeout(() => {
        this.searchResults(this.state.query);
      }, 300)
    });
  }

  searchResults(query) {
    if (query.length >= 3) {
      this.props.findResults(query);
    }
  }

  render() {
    const { results, active } = this.props;
    return (
      <div className="regular-search">
        <SearchField
          updateQuery={this.updateQuery}
          query={this.state.query}
          active={active}
        />
        <Results
          show={this.state.query.length > 2}
          results={results}
          query={this.state.query}
        />
      </div>
    );
  }
}

export default RegularSearchContainer;

import axios from 'axios';
import localforage from 'localforage';
import * as types from '../common/actionTypes';
import {
  getFilteredAdditionalMarkers,
  getFilteredMarkers,
  getVisibleCrags,
  getVisibleOtherMarkers,
  addSource,
  editSource,
} from './util';

export const initMapboxMaps = mapbox => (
  dispatch => {
    dispatch({
      type: types.SET_MAPBOX,
      payload: mapbox,
    });
  }
);

export const toggleView = () => (
  dispatch => {
    dispatch({
      type: types.TOGGLE_MAP_VIEW,
    });
  }
);

export const toggleFiltersVisibility = () => (
  dispatch => {
    dispatch({
      type: types.TOGGLE_FILTERS_VISIBILITY,
    });
  }
);

export const updateMapBounds = (center, zoom) => (
  dispatch => {
    dispatch({
      type: types.UPDATE_MAP_BOUNDS,
      payload: { center, zoom },
    });
  }
);

const syncCrags = async (dispatch, oldString, visibleCrags, sort) => {
  if (visibleCrags.length === 0) {
    dispatch({
      type: types.SET_VISIBLE_CRAGS_STRING,
      payload: '',
    });
    return;
  }
  let crags = visibleCrags.reduce((result, crag) => {
    result[crag.id] = crag;
    return result;
  }, {});
  let storageCrags = await localforage.getItem('crags');
  if (!storageCrags) {
    storageCrags = {};
  }
  let fetchCragsString = '';
  let dispatchCragsString = '';
  let storageCragsString = '';
  for (const cragId in crags) {
    const crag = crags[cragId];
    const storageCrag = storageCrags[crag.id];
    if (!storageCrag && crag.route_count === undefined) {
      fetchCragsString += `,${crag.id}`;
    }
    if (!storageCrag) {
      storageCragsString += `,${crag.id}`;
    }
    if (crag.route_count === undefined) {
      dispatchCragsString += `,${crag.id}`;
    }
  }
  let fetchedCrags = [];
  if (fetchCragsString) {
    const result = await axios.get(`/api/web01/crags?ids=${fetchCragsString.substr(1)}`);
    fetchedCrags = result.data.crags;
  }

  if (dispatchCragsString) {
    const dispatchCrags = getCrags(dispatchCragsString, fetchedCrags, storageCrags);
    crags = { ...crags, ...dispatchCrags };
    dispatch({
      type: types.STORE_CRAG_DETAILS,
      payload: dispatchCrags,
    });
  }

  if (storageCragsString) {
    const newStorageCrags = getCrags(storageCragsString, fetchedCrags, crags);
    crags = { ...crags, ...newStorageCrags };
    localforage.setItem('crags', { ...storageCrags, ...newStorageCrags });
  }

  const cragsArray = [];

  visibleCrags.forEach(crag => {
    cragsArray.push(crags[crag.id]);
  });

  if (sort.sort !== 'distance') {
    cragsArray.sort((a, b) => {
      if (sort.sort === 'routes') {
        return sortByGrades(a, b, sort.sortGrades);
      }
      return a.likes_count > b.likes_count ? -1 : b.likes_count > a.likes_count ? 1 : 0;
    });
  }
  let newString = '';

  cragsArray.forEach(crag => {
    newString += `,${crag.id}`;
  });

  dispatch({
    type: types.SET_VISIBLE_CRAGS_STRING,
    payload: newString.substr(1),
  });
};

const sortByGrades = (cragA, cragB, sortGrades) => {
  let a = 0;
  let b = 0;
  for (const grade in sortGrades) {
    if (sortGrades[grade]) {
      a += cragA[`grade${grade}_count`];
      b += cragB[`grade${grade}_count`];
    }
  }
  return a > b ? -1 : b > a ? 1 : 0;
};

const getCrags = (cragsString, cragsA, cragsB) => {
  const crags = {};
  for (const cragId of cragsString.substr(1).split(',')) {
    const cragA = cragsA.find(c => c.id === parseInt(cragId, 10));
    if (cragA) {
      crags[cragA.id] = cragA;
    } else {
      const cragB = cragsB[parseInt(cragId, 10)];
      if (cragB) {
        crags[cragB.id] = cragB;
      }
    }
  }
  return crags;
};

export const updateVisibleCrags = () => (
  (dispatch, getState) => {
    const state = getState();
    const { crags } = state.crag;
    const { sort, sortGrades } = state.map;
    const { map, cragMarkers } = state.mapbox;
    const visibleCrags = getVisibleCrags(map, cragMarkers, crags);
    syncCrags(dispatch, state.map.visibleCrags, visibleCrags, { sort, sortGrades });
  }
);

export const updateVisibleMarkers = () => (
  (dispatch, getState) => {
    const { map, otherMarkers } = getState().mapbox;
    const markerIds = getVisibleOtherMarkers(map, otherMarkers);
    dispatch({
      type: types.SET_VISIBLE_MARKERS_STRING,
      payload: markerIds.reduce((acc, id, i) => `${acc}${i > 0 ? ',' : ''}${id}`, ''),
    });
  }
);

export const toggleFilter = filter => (
  dispatch => {
    dispatch({
      type: types.TOGGLE_FILTER,
      payload: filter,
    });
  }
);

export const filterMarkers = markerClickListener => (
  (dispatch, getState) => {
    const state = getState();
    const { crags } = state.crag;
    const { filters, icons, sort, sortGrades } = state.map;
    const { map } = state.mapbox;
    const cragMarkers = getFilteredMarkers(crags, filters, icons, markerClickListener);
    dispatch({
      type: types.SET_MAPBOX,
      payload: {
        cragMarkers: {
          "type": "FeatureCollection",
          "features": cragMarkers.features.reduce((result, marker) => {
            result[marker.properties.crag.id] = marker;
            return result;
          }, {}),
        },
      },
    });
    if (map.getSource("crag-markers")) {
      editSource(map, cragMarkers);
    } else {
      addSource(map, true, false, cragMarkers);
    }
    if (map.zoom > 6) {
      const visibleCrags = getVisibleCrags(map, cragMarkersObject, crags);
      syncCrags(dispatch, state.map.visibleCrags, visibleCrags, { sort, sortGrades });
    }
  }
);

export const filterAdditionalMarkers = () => (
  (dispatch, getState) => {
    const { markers } = getState().marker;
    const { map } = getState().mapbox;
    const { filters, icons, editable } = getState().map;
    const mapboxMarkers = getFilteredAdditionalMarkers(markers, filters, icons, editable);
    const mapboxMarkersObj = { "type": "FeatureCollection", "features": mapboxMarkers.features.reduce((acc, m) => ({ ...acc, [m.properties.id]: m }), []) };
    dispatch({
      type: types.SET_MAPBOX,
      payload: {
        otherMarkers: mapboxMarkersObj,
      },
    });
    if (map.getSource("other-markers")) {
      editSource(map, undefined, mapboxMarkers);
    } else {
      addSource(map, false, true, null, mapboxMarkers);
    }
    const markerIds = getVisibleOtherMarkers(map, mapboxMarkersObj);
    dispatch({
      type: types.SET_VISIBLE_MARKERS_STRING,
      payload: markerIds.reduce((acc, id, i) => `${acc}${i > 0 ? ',' : ''}${id}`, ''),
    });
  }
);

export const setSort = (sort, isGradeSort) => (
  dispatch => {
    dispatch({
      type: types.SET_CRAG_SORT,
      payload: {
        sort,
        isGradeSort,
      },
    });
  }
);

export const sortCrags = () => (
  (dispatch, getState) => {
    const state = getState();
    const { crags } = state.crag;
    const { map, cragMarkers } = state.mapbox;
    const { sort, sortGrades } = state.map;
    const visibleCrags = getVisibleCrags(map, cragMarkers, crags);
    syncCrags(dispatch, state.map.visibleCrags, visibleCrags, { sort, sortGrades });
  }
);

export const highlightCrag = (cragId) => (
  async (dispatch, getState) => {
    const state = getState();
    const { map, cragMarkers } = state.mapbox;
    const { selectedCrag } = state.map;
    const { crags } = state.crag;
    const selectedMarker = cragMarkers.features[selectedCrag];
    if (selectedMarker) {
      map.getSource('highlighted').setData({ "type": "FeatureCollection", "features": [] });
    }
    dispatch({
      type: types.HIGHLIGHT_CRAG,
      payload: cragId,
    });
    const crag = crags[cragId];
    if (crag) {
      const markerToHighlight = cragMarkers.features[cragId];
      markerToHighlight.properties.icon = markerToHighlight.properties.iconHighlight;
      map.getSource('highlighted').setData({ "type": "FeatureCollection", "features": [markerToHighlight] });
      if (crag.route_count === undefined) {
        const result = await axios.get(`/api/web01/crags?ids=${cragId}`);
        const crag = {
          [result.data.crags[0].id]: result.data.crags[0],
        };
        dispatch({
          type: types.STORE_CRAG_DETAILS,
          payload: crag,
        });
        let storageCrags = await localforage.getItem('crags');
        if (!storageCrags) {
          storageCrags = {};
        }
        if (!storageCrags[cragId]) {
          localforage.setItem('crags', { ...storageCrags, ...crag });
        }
      }
    }
  }
);

export const setMarkerEditPopup = (visible, mapboxMarker) => (
  (dispatch, getState) => {
    const { map } = getState().mapbox;
    if (visible) {
      map.addSource("new-marker", {
        type: "geojson",
        data: { "type": "FeatureCollection", "features": [mapboxMarker] },
      });
      map.addLayer({
        id: 'new-marker-layer',
        type: "symbol",
        source: "new-marker",
        layout: {
          "icon-image": "{icon}",
          "icon-size": 1,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
        }
      });
    }
    else {
      map.removeLayer('new-marker-layer');
      map.removeSource('new-marker');
    }
    dispatch({
      type: types.SET_MARKER_EDIT_POPUP,
      payload: { visible, mapboxMarker },
    });
  }
);

export const setCommercialMarkerPopup = (visible, mapboxMarker) => (
  dispatch => {
    dispatch({
      type: types.SET_COMMERCIAL_MARKER_POPUP,
      payload: { visible, mapboxMarker },
    });
  }
);

import React from 'react'
import Switch from 'rc-switch'

const First = ({ firstName, moveForward }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-5 col-sm-6">
          <div className="onboarding__header-description">
            Climb &bull; Track &bull; Share &bull; Follow &bull; Enjoy
          </div>
          <h3>{`It's great to have you here, ${firstName}!`}</h3>
          <p>
            <b>Complete your profile</b> to follow your friends.
          </p>
          <div className="onboarding__forward-container--bottom">
            <button onClick={() => moveForward()} className="btn btn-primary btn-primary--onboarding">
              {"Let's get started"}
            </button>
          </div>
        </div>
        <div className="col-md-7 col-sm-6 hidden-xs">
          <div className="onboarding__main-photo"></div>
        </div>
      </div>
    </div>
  )
}

export default First

import React from 'react';
import PropTypes from 'prop-types';
import FeedItem from './FeedItem';
import timeago from 'timeago.js';

const FeedItems = (props) => {

  const time = new timeago();
  const items = props.items.map((item) => {
    return (
      <li className="item" key={item.id}>
        <FeedItem
          feedItem={item}
          timeago={time.format}
          onLikeClick={(data) => props.onLikeClick(data)}
          onDeleteClick={(id) => props.onDeleteClick(id)}
          fetchComments={props.fetchComments}
          toggleCommentsVisibility={props.toggleCommentsVisibility}
          currentClimber={props.currentClimber}
          toggleDeleteDialogVisibility={(id) => props.toggleDeleteDialogVisibility(id)}
          deleteDialogVisible={props.deleteDialogVisible}
          addCommentPending={props.addCommentPending}
        />
      </li>
    )
  });

  return (
    <ul className="feed-items">
      {items}
    </ul>
  );
};

FeedItems.propTypes = {
  items: PropTypes.array
};

export default FeedItems;

// add many for routes on the crag page (ascents below)
(function() {

  var form_jq = null;
  var row_template_jq = null;
  var row_id_sequence = 0;

  function get_row_id() {
    return row_id_sequence += 1;
  }

  function prepare_dialog() {
    app.prepare_for_dialog();
    form_jq = $('#add_many_template form').clone();
    row_template_jq = form_jq.find('td.row_template');
  }

  function add_row(css_class) {
    css_class = css_class || 'fresh';
    var new_row = row_template_jq.clone();
    new_row.removeClass('row_template').addClass(css_class);
    activate_row(new_row);
    row_template_jq.before(new_row);
    return new_row;
  }

  function activate_row(row_jq) {
    var row_id = get_row_id();
    row_jq.find('[name^="route["]').attr('name', function(){ return this.name.replace('route[', 'routes['+row_id+']['); });
    row_jq.find('.f_row [name^="routes["]').attr('id', function(){ return this.name.split('[').join('_').replace(/\]/g, ''); });
    row_jq.find('.js-route_genre').change(function(){
      var fresh = row_jq.parent().find('.fresh');
      fresh.find('.js-route_genre').prop('selectedIndex', $(this)[0].selectedIndex);
    });
    row_jq.find('.js-route_sector').change(function(){
      var fresh = row_jq.parent().find('.fresh');
      fresh.find('.js-route_sector').prop('selectedIndex', $(this)[0].selectedIndex);
    });
  }

  function activate_event_listeners() {
    form_jq.delegate('.fresh input, .fresh select', 'focus', function() {
      $(this).closest('.fresh').removeClass('fresh');
      add_row();
      app.add_many_routes.save_in_local_storage();
    });
  }

  app.add_many_routes = {
    initialize: function() {
      if ( $('#add_many_link_routes')[0] ) {
        if ( window.localStorage && window.JSON ) {
          var unsaved = JSON.parse(localStorage.getItem('unsaved'));
          if ( unsaved ) {
            if ( unsaved.page == window.location.href ) {
              prepare_dialog();
              $.each(unsaved.routes, function(i, route){
                var row = add_row('unsaved');
                for (var attr in route){
                  row.find('[name$="['+attr+']"]').val(route[attr]);
                }
              });
              add_row();
              activate_event_listeners();
              $.facebox(form_jq);
            } else {
              $(document).on('click', '#add_many_link_routes', app.add_many_routes.open);
              //$('tr#more_routes').attr('id', 'add_routes').find('td').removeClass('lfont');
              //$('tr#add_routes td').html('<a href="' + unsaved.page + '">YOU HAVE UNSAVED CHANGES AT ' + unsaved.page + '!</a>')
            }
          } else {
            $(document).on('click', '#add_many_link_routes', app.add_many_routes.open);
          }
        } else {
          $('#add_many_link_routes').hide();
        }
      }
    },
    open: function(event) {
      event.preventDefault();
      prepare_dialog();
      add_row('first');
      add_row();
      activate_event_listeners();
      $.facebox(form_jq);
    },
    activate: function() {
      form_jq = $('#facebox form');
      form_jq.find('tr.rejected').each(function(i, e) {
        activate_row($(e));
      })
    },
    save_in_local_storage: function() {
      if ( window.localStorage && window.JSON ) {
        var unsaved = {page: window.location.href, routes: []};
        form_jq.find('td.ascent-cell').each(function(i, e){
          var row_jq = $(e);
          if ( row_jq.find('input')[0] && row_jq.find('input:first').val() != '' ) {
            var row_content = {};
            // NOTICE: we don't use checkboxes or radios
            row_jq.find('input, select, textarea').each(function(sub_i, input_e){
              var input_jq = $(input_e);
              row_content[input_jq.attr('name').match(/routes\[\d+\]\[(.+)\]/)[1]] = input_jq.val();
            });
            unsaved.routes.push(row_content);
          }
        });
        localStorage.setItem('unsaved', JSON.stringify(unsaved));
      }
    },
    wait_for_reload: function() {
      if ( window.localStorage && window.JSON ) {
        $('#add_many_alert').html('Unsaved changes are kept in local storage.<div>Wait a few seconds and reload the page if you think saving failed.</div>');
        $('#add_many_alert').hide().delay(2000).fadeIn(8000);
      }
    },
    clear_local_storage: function() {
      if ( window.localStorage ) localStorage.removeItem('unsaved');
    }
  };

})();

// add table rows dynamically so users can quickly add many ascents
(function() {

  var form_jq = null;
  var row_template_jq = null;
  var row_id_sequence = 0;

  function get_row_id() {
    return row_id_sequence += 1;
  }

  function add_row(css_class) {
    css_class = css_class || 'fresh';
    var new_row = row_template_jq.clone();
    new_row.removeClass('row_template').addClass(css_class);
    activate_row(new_row);
    row_template_jq.before(new_row);
  }

  function field_name() { return 'ascents[' + $.makeArray(arguments).join('][') + ']'; }
  function field_id()   { return 'ascents_' + $.makeArray(arguments).join('_'); }

  function activate_autocomplete(element, row_id, action) {
    if ( element ) {
      var replacements = {};
      var name_field, id_field;
      if ( action == 'routes' ) {
        name_field = field_name(row_id, 'route_name');
        id_field   = field_name(row_id, 'route_id');
        replacements[field_id(row_id, 'route_id')] = 'id';
        replacements[field_id(row_id, 'grade_opinion')] = 'grade';
      } else {
        name_field = field_name(row_id, 'new_route', 'crag_name');
        id_field   = field_name(row_id, 'new_route', 'crag_id');
        replacements[field_id(row_id, 'new_route', 'crag_id')] = 'crag_id';
      }
      new_autocomplete().activate(element, action, name_field, id_field, replacements);
    }
  }

  function activate_row(row_jq) {
    var row_id = get_row_id();
    row_jq.find('[name^="ascent["]').attr('name', function(){ return this.name.replace('ascent[', 'ascents['+row_id+']['); });
    row_jq.find('.form-group [name^="ascents["]').attr('id', function(){ return this.name.split('[').join('_').replace(/\]/g, ''); });
    row_jq.find('.check_row [name^="ascents["], .radio_row [name^="ascents["]').attr('id', function(){ return this.name.split('[').join('_').replace(/\]/g, '') + '_' + $(this).attr('value'); });
    row_jq.find('label').attr('for', function(){ return $(this).closest('.check_row, .radio_row').find(':checkbox, :radio').attr('id'); });
    activate_autocomplete(row_jq.find('#' + field_id(row_id, 'route_name'))[0], row_id, 'routes');
    activate_autocomplete(row_jq.find('#' + field_id(row_id, 'new_route', 'crag_name'))[0], row_id, 'crags');
    //row_jq.find('#' + field_id(row_id, 'date')).pickadate({selectYears: true, selectMonths: true});
    app.bindDatePicker(row_jq);
    row_jq.find('.ascent_rating').each(function(i, element) {
      var jq = $(element);
      jq.find('select').rating().change(function(){ jq.find('.rating-description').html(jq.find('option:selected').html()); });
    });
  }

  function activate_event_listeners() {
    form_jq.delegate('.fresh input, .fresh select', 'focus', function() {
      $(this).closest('.fresh').removeClass('fresh');
      add_row();

      var elem = $(this);
      var $container = form_jq.find('.add-ascents-container');
      var width = $container.find('table.add_many').width();
      $container.animate({scrollLeft: width}, 400, 'swing', function(){
        elem.blur();
        elem.focus();
      });


    });
  }

  app.add_many = {
    open: function(event) {
      event.preventDefault();
      app.prepare_for_dialog();
      form_jq = $('#add_many_template form').clone();
      row_template_jq = form_jq.find('.row_template');
      add_row('first');
      add_row();
      activate_event_listeners();
      $.facebox(form_jq);
    },
    activate: function() {
      form_jq = $('#facebox form');
      form_jq.find('.rejected').each(function(i, e) {
        activate_row($(e));
      })
    }
  };

})();

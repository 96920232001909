import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { persistStore, persistCombineReducers } from "redux-persist";
import localforage from "localforage";

const middlewares = [thunk, promise()];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const middleware = applyMiddleware(...middlewares);

const configureStore = combinedReducer => {
  return createStore(combinedReducer, middleware);
};

export default configureStore;

export const configurePersistStore = (key, reducers, transforms, whitelist) => {
  localforage.config({
    name: "root"
  });
  const config = {
    key: key,
    storage: localforage,
    whitelist: whitelist,
    transforms
  };

  const reducer = persistCombineReducers(config, reducers);
  const store = createStore(reducer, middleware);
  const persistor = persistStore(store);

  return { store, persistor };
};

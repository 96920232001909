import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';


const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: false,
    },
  },
};

class GradeOpinions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: props.labels,
        datasets: [
          {
            barMinHeight: 20,
            maxBarThickness: 30,
            barThickness: 'flex',
            label: 'Opinions',
            data: props.values,
            borderColor: '#F8E71C',
            backgroundColor: '#F8E71C',
          },
        ],
      },
    };

    console.log('GradeOpinions', props);
  }


  render() {
    return (
      <div>
        <Bar options={options} data={this.state.data}/>
      </div>
    );
  }
};

export default GradeOpinions;

import * as types from '../common/actionTypes'

const premiumSearchReducer = () => {
  const initialState = {
    gradeMinInt: 400,
    gradeMaxInt: 900,
    selectedArea: null,
    orderBy: 'rating',
  }

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case types.UPDATE_GRADE_MIN:
        return {
          ...state,
          gradeMinInt: payload,
        }
      case types.UPDATE_GRADE_MAX:
        return {
          ...state,
          gradeMaxInt: payload,
        }
      case types.UPDATE_AREA:
        return {
          ...state,
          selectedArea: payload,
        }
      case types.UPDATE_ORDER_BY:
        return {
          ...state,
          orderBy: payload,
        }

      default:
        return state
    }
  }
}
export default premiumSearchReducer

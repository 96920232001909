import React from 'react';

const GradeChart = (props) => {
  const defaultOptions = {
    height: 54,
    showLabels: false,
    accuracy: 1, // 1 || 2
    routeCounts: true,
    barMinHeight: 20,
  };

  const options = {
    ...defaultOptions,
    ...props.options,
  };

  const maxCount = props.gradeCounts.reduce((result, count) => count > result ? count : result, 0);
  const height = options.height;
  const barMaxHeight = height - (options.showLabels ? 12 : 0) - (options.routeCounts ? 12 : 0);
  const barMinHeightPercent = options.barMinHeight/height * 100;
  const barMaxHeightPercent = barMaxHeight/height * 100;

  //const height = options.showLabels ? options.height - 18 : options.height;

  const getBarHeight = (count) => {
    return Math.max(
      (
        ((count / maxCount) * (100-barMinHeightPercent)) + barMinHeightPercent),
      barMinHeightPercent)
  }

  return (
    <span className="summary-chart summary-chart--map">
      <div className="bars" style={{ height: `${options.height}px` }}>
        {props.gradeCounts.map((count, i) => (
          <span key={i} className="col">
            {options.routeCounts && (
              <span className="col__route-count">{(count > 0 ? count : "")}</span>
            )}
            <span className={`cell`} style={{ height: `${barMaxHeightPercent}%` }}>
              <span
                className={`bar grade-color-${i + 4} ${count > 0 ? "": "bar--empty"}`}
                style={{ height: `${getBarHeight(count)}%` }}
              />
            </span>
            {options.showLabels && (
              <span className="grade-label"></span>
            )}
          </span>
        ))}
      </div>
    </span>
  );
};

export default GradeChart;

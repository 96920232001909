import React, { Component } from "react";
import localforage from "localforage";
import moment from "moment";
import { Provider } from "react-redux";
import { createTransform } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { combineReducers } from "redux";
import configureStore, { configurePersistStore } from "../common/store";
import mapboxReducer from "./reducers/mapboxReducer";
import mapReducer from "./reducers/mapReducer";
import cragReducer from "./reducers/cragReducer";
import markerReducer from "./reducers/markerReducer";
import Container from "./Container";

class MapApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expireChecked: false
    };
    localforage.config({
      name: "root"
    });
  }

  async componentDidMount() {
    const cragsExpire = await localforage.getItem("cragsExpire");
    if (!cragsExpire || moment().isAfter(moment(cragsExpire))) {
      await localforage.removeItem("crags");
      await localforage.setItem(
        "cragsExpire",
        moment()
          .add(1, "hour")
          .toISOString()
      );
    }
    this.setState({ expireChecked: true });
  }

  render() {
    if (this.state.expireChecked) {
      const transforms = [
        createTransform((inboundState, key) => {
          if (key === "map") {
            return {
              filters: {
                ...inboundState.filters,
                visible: false
              },
              sort: inboundState.sort,
              sortGrades: inboundState.sortGrades
            };
          }
          return inboundState;
        })
      ];

      const reducer = {
        mapbox: mapboxReducer,
        map: mapReducer(this.props),
        crag: cragReducer(this.props),
        marker: markerReducer(this.props)
      };

      if (this.props.usePersist) {
        const { persistor, store } = configurePersistStore(
          "map",
          reducer,
          transforms,
          ["map"]
        );

        return (
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Container />
            </PersistGate>
          </Provider>
        );
      }

      return (
        <Provider store={configureStore(combineReducers(reducer))}>
          <Container />
        </Provider>
      );
    }
    return null;
  }
}

export default MapApp;

window.app.initialize_map_events = function($map, map, g_map) {
  
  var navigator = app.MapNavigator(map, g_map);
  var hinter = app.MapHinter(map, g_map);
  var crags_ready = false;
  var map_projection_ready = false;

  function add_listener(event, handler) {
    google.maps.event.addListener(g_map, event, handler);
  }

  function getCurrentPath(){
    var location = window.location.pathname.split('@');
    return location[0];
  }

  function refreshLocation(){
    var position_s = map.getPositionAsString();
    var filters_s = app.map_filters.getFiltersAsString();
    var path = getCurrentPath();
    window.history.pushState({}, document.title, path+'#'+position_s+','+filters_s);
  }

  function locationHashChanged() {
    //var position = map.getPositionFromHash();
    //map.setMapCenter(position[0], position[1])
    //console.log('Location changed:', location.hash);
  }

  function initReady(){
    if(crags_ready && map_projection_ready) {
      console.log('init_ready', app.map_filters.getFilters());

      app.map_markers.setFilters(app.map_filters.getFilters());
      app.map_markers.refresh();
      //app.map_results.renderFromMarkers(app.map_markers.getVisibleMarkers());
      onMapBoundsChanged();
    }
  }

  function onMapBoundsChanged(){
    app.map_markers.refreshVisibleMarkers();
    app.map_results.renderFromMarkers(app.map_markers.getVisibleMarkers())
  }
  
  if ( $map.hasClass('world')) {
    app.initialize_map_tiles(map, g_map, app.map_markers, function(){
      crags_ready = true;
      initReady();
    });
    window.onhashchange = locationHashChanged;
    add_listener('idle', function(){
      refreshLocation();
      onMapBoundsChanged();
    });

    google.maps.event.addListener(g_map, 'projection_changed', function(){
      map.setMapCenter();
      map_projection_ready = true;
      initReady();
    });
    navigator.enable_single_click_hint(hinter);
    app.preferences('markers', '#hide_markers', '#show_markers');
  }
  
  if ( $map.hasClass('area') ) {

    app.initialize_map_tiles(map, g_map, app.map_markers, function(){
      crags_ready = true;
      initReady();
    });
    window.onhashchange = locationHashChanged;
    add_listener('idle', function(){
      refreshLocation();
      onMapBoundsChanged();
    });

    google.maps.event.addListener(g_map, 'projection_changed', function(){
      map_projection_ready = true;
      initReady();
    });
    navigator.enable_single_click_hint(hinter);
    app.preferences('markers', '#hide_markers', '#show_markers');


    /*app.initialize_map_tiles(map, g_map, app.map_markers, function(){
      crags_ready = true;
      initReady();
      //onMapBoundsChanged();
    });
    add_listener('idle', function(){
      refreshLocation();
      onMapBoundsChanged();
    });
    navigator.enable_single_click_hint(hinter);
    app.preferences('markers', '#hide_markers', '#show_markers');*/
  }
  
  if ( $map.hasClass('crag') ) {
    add_listener('zoom_changed', navigator.away_from_crag);
    add_listener('drag', hinter.dragging);

    app.initialize_map_tiles(map, g_map, app.map_markers, function(){
    });
    app.preferences('markers', '#hide_markers', '#show_markers');
  }
  
  // common to all maps
  app.preferences('zoom', '#scroll_zoom_on', '#scroll_zoom_off');
  
};

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LikeButton from '../../common/components/LikeButton'
import FeedEmbed from './FeedEmbed'
import ConfirmationDialog from './ConfirmationDialog'
import CommentsContainer from '../../common/containers/CommentsContainer'
import { convertGrade } from '../../common/util'

const FeedItemGeneric = ({ title, description, commentable_url }) => {
  const _description = description
    ? description.split('\n').map((item, i) => (
        <span key={i}>
          {item}
          <br />
        </span>
      ))
    : null
  return (
    <div>
      <div className="title">
        {title.split('\n').map((item, i) => (
          <span key={i}>
            {item}
            <br />
          </span>
        ))}
      </div>
      <div className="description">
        {commentable_url ? (
          <a href={commentable_url} rel={commentable_url.substr(1, 6) === 'photos' ? 'facebox[modal]' : ''}>
            {_description}
          </a>
        ) : (
          _description
        )}
      </div>
    </div>
  )
}

const FeedItemTeam = ({ title, description, source_param_id }) => (
  <div>
    <div className="title">
      <a href={`/teams/${source_param_id}`}>{title}</a>
    </div>
    <div className="description">{description}</div>
  </div>
)

const FeedItemAscent = ({ title, description, climber }) => {
  let link
  if (/to-do list/.test(title)) {
    title = title.replace(/to-do list/, '')
    link = <a href={`/climbers/${climber.param_id}/ascents/todo`}>to-do list</a>
  } else if (/tick list/.test(title)) {
    title = title.replace(/tick list/, '')
    link = <a href={`/climbers/${climber.param_id}/ascents`}>tick list</a>
  }

  description = description.split('\n').map((row, i) =>
    row ? (
      <span key={i}>
        {row}
        <br />
      </span>
    ) : null,
  )

  return (
    <div>
      <div className="title">
        {title}
        {link}
      </div>
      <div className="description">{description}</div>
    </div>
  )
}

const FeedItemHardAscent = ({ title, description }) => {
  const matchArray = description.match(/\[(.*?)\]/g)
  let ascents = []
  for (let i = 0; i < matchArray.length; i += 2) {
    const route = matchArray[i].substr(0, matchArray[0].length).replace('[', '').replace(']', '')
    const href = matchArray[i + 1].replace('[', '').replace(']', '')
    ascents.push(<a href={href}>{route}</a>)
  }

  description = ascents.map((row, i) => (
    <span key={i}>
      - {row}
      <br />
    </span>
  ))

  return (
    <div>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </div>
  )
}

const FeedItemCrag = ({ title, description, source_param_id }) => (
  <div>
    <div className="title">
      <a href={`/crags/${source_param_id}`}>{title}</a>
    </div>
    <div className="description">{description}</div>
  </div>
)

const FeedItemBlog = ({ title, description, climber, source_id }) => {
  const descriptionLink = <a href={`/posts/${source_id}`}>{description}</a>
  return (
    <div>
      <div className="title">{title}</div>
      <div className="description">{descriptionLink}</div>
    </div>
  )
}

const PhotoLink = ({ id, href, group, thumbnail }) => (
  <a href={href} rel="facebox[modal]" data-id={id} data-group={group}>
    <img src={thumbnail} />
  </a>
)

const FeedItemVideo = ({ id, source_id, photo }) => {
  const url = `/videos/${source_id}`
  const photo_url = photo ? photo.styles.huge.url : ''

  var className = 'photo'
  var style = {
    backgroundImage: `url(${photo_url})`,
  }

  if (!photo_url) {
    className = 'photo photo--missing'
    style = {}
  }

  const imgElement = photo_url ? <img src={photo_url} /> : ''

  return (
    <div>
      <div className={className} style={style}>
        <a href={url} data-id={id}>
          <span className="video-overlay">
            <div className="wrapper">
              <i className="glyphicon glyphicon-play" />
            </div>
          </span>
          {imgElement}
        </a>
      </div>
    </div>
  )
}

const FeedItemTopoImage = ({ id, photo, source_param_id }) => {
  const style = {
    backgroundImage: `url(${photo ? photo.styles.huge.url : ''})`,
  }

  return (
    <div className="photo" style={style}>
      <a href={source_param_id} data-id={id}>
        <img src={photo ? photo.styles.huge.url : ''} />
      </a>
    </div>
  )
}

const FeedItemPhoto = ({ id, photos }) => {
  const firstPhoto = photos[0]

  if (firstPhoto) {
    const mainUrl = `/photos/${firstPhoto.id}`
    const group = `feed-#${id}`
    const style = {
      backgroundImage: `url(${firstPhoto.styles.huge.url})`,
    }
    let morePhotos = []
    if (photos.length > 1) {
      for (let i = 1; i < photos.length; i += 1) {
        const photo = photos[i]
        const url = `/photos/${photo.id}`
        morePhotos.push(
          <PhotoLink key={photo.id} href={url} group={group} id={photo.id} thumbnail={photo.styles.small.url} />,
        )
      }
    }

    return (
      <div>
        <div className="photo" style={style}>
          <PhotoLink href={mainUrl} group={group} id={firstPhoto.id} thumbnail={firstPhoto.styles.huge.url} />
        </div>
        <div className="more-photos">{morePhotos}</div>
      </div>
    )
  }
  return <div />
}

const FeedItem = (props) => {
  const {
    feedItem,
    timeago,
    onDeleteClick,
    onLikeClick,
    fetchComments,
    toggleCommentsVisibility,
    currentClimber,
    deleteDialogVisible,
    toggleDeleteDialogVisibility,
    deleted,
    waitingForDelete,
    addCommentPending,
  } = props

  if (deleted) window.location = '/'
  if (waitingForDelete) return <div>Deleting...</div>

  const climberPath = `/climbers/${feedItem.climber.param_id}`
  const feedItemPath = `/feed_items/${feedItem.id}`
  let itemDetails = ''
  let itemContent = ''
  let fetchCommentsUrl = '/api/web01/comments/'
  let commentableId
  let feedDescription = null

  const convertGrades = () => {
    const regex = /\(\[(.{2,5})\]\)/gim
    let match = regex.exec(feedItem.description)
    while (match !== null) {
      const prefix = match[1][0]
      const grade = match[1].slice(1)
      let system = currentClimber.gradingSystemSport == null ? 'French' : currentClimber.gradingSystemSport
      if (prefix == 'B')
        system = currentClimber.gradingSystemBoulder == null ? 'Font' : currentClimber.gradingSystemBoulder
      else if (['T', 'P'].includes(prefix))
        system = currentClimber.gradingSystemTrad == null ? 'French' : currentClimber.gradingSystemTrad
      const convertedGrade = convertGrade(grade, system)
      if (convertedGrade) {
        feedItem.description = feedItem.description.replace(match[0], convertedGrade)
      }
      match = regex.exec(feedItem.description)
    }
  }

  if (feedItem.description) convertGrades()

  switch (feedItem.category) {
    case 'Photo':
      const firstPhoto = feedItem.photos[0]
      const description = firstPhoto ? firstPhoto.caption : ''
      itemDetails = <FeedItemGeneric title={feedItem.title} description={description} />
      itemContent = <FeedItemPhoto {...feedItem} />
      fetchCommentsUrl = `${fetchCommentsUrl}by_photo/${feedItem.photos[0].id}`
      commentableId = feedItem.photos[0].id
      break
    case 'Video':
      itemDetails = <FeedItemGeneric title={feedItem.title} description={feedItem.description} />
      itemContent = <FeedItemVideo {...feedItem} />
      fetchCommentsUrl = `${fetchCommentsUrl}by_video/${feedItem.source_id}`
      commentableId = feedItem.source_id
      break
    case 'Comment':
      itemDetails = (
        <FeedItemGeneric
          title={feedItem.title}
          description={feedItem.description}
          commentable_url={feedItem.commentable_url}
        />
      )
      fetchCommentsUrl = `${fetchCommentsUrl}by_comment/${feedItem.source_id}`
      commentableId = feedItem.source_id
      break
    case 'Route':
      itemDetails = <FeedItemGeneric title={feedItem.title} description={feedItem.description} />
      break
    case 'Post':
      itemDetails = <FeedItemBlog {...feedItem} />
      fetchCommentsUrl = `${fetchCommentsUrl}by_post/${feedItem.source_id}`
      commentableId = feedItem.source_id
      break
    case 'Ad':
      itemDetails = <FeedItemGeneric title={feedItem.title} description={feedItem.description} />
      break
    case 'HardAscent':
      itemDetails = <FeedItemHardAscent {...feedItem} />
      break
    case 'Ascent':
      itemDetails = <FeedItemAscent {...feedItem} />
      break
    case 'Crag':
      itemDetails = <FeedItemCrag {...feedItem} />
      break
    case 'Team':
      itemDetails = <FeedItemTeam {...feedItem} />
      break
    case 'TopoImage':
      itemContent = <FeedItemTopoImage {...feedItem} />
      feedDescription = feedItem.description
    default:
      itemDetails = <FeedItemGeneric title={feedItem.title} description={feedDescription} />
      break
  }

  switch (feedItem.category) {
    case 'Ascent':
    case 'HardAscent':
    case 'Crag':
    case 'TopoImage':
    case 'Team':
    case 'NilClass':
      fetchCommentsUrl = `${fetchCommentsUrl}by_feed_item/${feedItem.id}`
      commentableId = feedItem.id
      break
  }

  const climberPhoto = feedItem.climber.photo_url ? feedItem.climber.photo_url : ''

  const embedData = () => {
    if (feedItem.category === 'NilClass' && feedItem.description) {
      return <FeedEmbed {...JSON.parse(feedItem.description)} />
      //return feedItem.embedData.map(embedData => <FeedEmbed key={feedItem.id} {...embedData} />);
    }
    return null
  }

  const renderCommentsButton = () => {
    const commentSumText = () => {
      switch (feedItem.comment_sum) {
        case 0:
          return 'Comment'
        case 1:
          return `${feedItem.comment_sum} Comment`
        default:
          return `${feedItem.comment_sum} Comments`
      }
    }

    const onCommentsClick = () => {
      if (currentClimber.paramId && feedItem.commentsVisible == null) {
        fetchComments(fetchCommentsUrl, feedItem.id)
      } else {
        toggleCommentsVisibility(feedItem.id)
      }
    }

    return (
      <a onClick={onCommentsClick} className="btn-comments">
        <i className="glyphicon glyphicon-conversation"></i>
        <span className="text">{commentSumText()}</span>
      </a>
    )
  }

  const feedItemUrl = `${location.protocol}//${window.location.hostname}/feed_items/${feedItem.id}`
  const fbShareurl = `https://www.facebook.com/sharer.php?u=${encodeURI(feedItemUrl)}&amp;t=`
  const twitterShareurl = `https://twitter.com/share?url=${encodeURI(feedItemUrl)}&amp;text=`

  const renderComments = () =>
    currentClimber.paramId ? (
      <CommentsContainer
        comments={feedItem.comments}
        currentClimber={currentClimber}
        commentableId={commentableId}
        feedItemId={feedItem.id}
        type={feedItem.category}
        refeshable={false}
        isEditing={feedItem.isEditing}
        editableCommentId={feedItem.editableCommentId}
        fetching={feedItem.fetchingComments}
        commentSum={feedItem.comment_sum}
        addCommentPending={addCommentPending}
      />
    ) : (
      <p>
        <a href="/login">Sign up</a> to read and write comments
      </p>
    )

  return (
    <div className="feed-item">
      <div className="item-wrapper">
        <div className="edit-tool">
          {feedItem.isOwn && deleteDialogVisible !== feedItem.id && (
            <button title="Delete" onClick={() => toggleDeleteDialogVisibility(feedItem.id)} className="s-corners">
              <i className="glyphicon glyphicon-remove" />
            </button>
          )}
        </div>
        {deleteDialogVisible === feedItem.id ? (
          <ConfirmationDialog
            onConfirm={() => onDeleteClick(feedItem.id)}
            onCancel={() => toggleDeleteDialogVisibility(feedItem.id)}
          />
        ) : (
          <div className="item-content">
            <div className="user-image">
              <a href={climberPath}>
                <img alt={feedItem.climber.name} className="climber-thumb gray-border" src={climberPhoto} />
              </a>
            </div>
            <div className="item-details">
              <a className="climber-name" href={climberPath}>
                {feedItem.climber.name}
              </a>
              <span className="date">
                {' '}
                &bull;{' '}
                <a className="ago" href={feedItemPath}>
                  {timeago(feedItem.created_at_ms)}
                </a>
              </span>
              {itemDetails}
              {embedData()}
            </div>
            <div className="clearfix" />
            {itemContent}
            <div className="reactions">
              <div className="pull-left">
                <LikeButton
                  currentClimber={currentClimber}
                  likes={feedItem.likes}
                  likeStatus={feedItem.likeStatus}
                  onLikeClick={(data) => onLikeClick(data)}
                />
              </div>
              <div className="pull-left">{renderCommentsButton()}</div>
              <div className="pull-right sharetools">
                <a className="sharer-window" href={fbShareurl} target="_blank">
                  <i className="icon27 icon-facebook" />
                </a>
                <a className="sharer-window" href={twitterShareurl} target="_blank">
                  <i className="icon27 icon-twitter" />
                </a>
                <a
                  className="copytoclipboard"
                  title="Copy link to clipboard"
                  data-href={feedItemUrl}
                  data-msg-clicked="Link has been copied to clipboard"
                >
                  <i className="glyphicon glyphicon-link" />
                </a>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        )}
        {deleteDialogVisible !== feedItem.id && (
          <div className="comments">{feedItem.commentsVisible && renderComments()}</div>
        )}
      </div>
    </div>
  )
}

FeedItem.propTypes = {
  feedItem: PropTypes.object,
}

export default FeedItem

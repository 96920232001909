import React, { Component } from 'react'
import TwoPointSlider from '../../common/components/TwoPointSlider'
import { routeListFieldHash, convertGrade } from '../../common/util.js'

class PremiumSearchForm extends Component {
  constructor(props) {
    super(props)
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }
    const distinctCountries = this.props.areas.map((a) => a.country).filter(distinct)
    this.state = {
      selectedCountry: this.props.area ? this.props.area.country : null,
      countries: distinctCountries,
    }
    this.changeGrades = this.changeGrades.bind(this)
  }
  onCountryChange(event) {
    const selectedCountry = event.target.value
    this.setState({
      selectedCountry: selectedCountry,
    })
    const areasInCountry = this.props.areas.filter((area) => {
      return area.country == selectedCountry
    })
    const firstAreaInCountry = areasInCountry.sort((a, b) => (a['name'] > b['name'] ? 1 : -1))[0]
    this.props.updateArea(firstAreaInCountry)
  }

  onAreaChange(event) {
    const param_id = event.target.value
    const selectedArea = this.props.areas.find((area) => {
      return area['param_id'] == param_id
    })
    this.props.updateArea(selectedArea)
  }

  onOrderByChange(event) {
    const value = event.target.value
    this.props.updateOrderBy(value)
  }

  changeGrades(values) {
    let min_val = values[0] >= values[1] ? 100 : values[0]
    let max_val = values[1]
    const gradePoints = Object.keys(GRADES_HASH)
    const index_min = gradePoints.indexOf(min_val.toString())
    const index_max = gradePoints.indexOf(max_val.toString())
    const grade_min = gradePoints[index_min]
    const grade_max = gradePoints[index_max]
    this.props.updategradeMinInt(parseInt(grade_min))
    this.props.updategradeMaxInt(parseInt(grade_max))
  }

  localizedGrade(gradePoints) {
    return convertGrade(gradePoints, this.props.gradingSystem)
  }

  render() {
    const areasInSelectedCountry = this.props.areas
      .filter((a) => a.country === this.state.selectedCountry)
      .sort((a, b) => (a['name'] > b['name'] ? 1 : -1))
    const selecedAreaParamId = this.props.area ? this.props.area['param_id'] : ''

    return (
      <div className="premium-search-form">
        <div className="form-group">
          <label className="premium-search-form__label">Where</label>
          <div className="clearfix" />
          <select
            className="form-control input-lg premium-search-form-select--country"
            value={this.state.selectedCountry ? this.state.selectedCountry : ''}
            onChange={this.onCountryChange.bind(this)}
          >
            <option>Select a country</option>
            {this.state.countries.map((country, index) => {
              return (
                <option key={'country' + index} value={country}>
                  {country}
                </option>
              )
            })}
          </select>
          <select
            className="form-control input-lg premium-search-form-select--area"
            value={selecedAreaParamId}
            onChange={this.onAreaChange.bind(this)}
          >
            <option>Select a climbing area</option>
            {areasInSelectedCountry.map((area, index) => {
              return (
                <option key={'area' + index} value={area['param_id']}>
                  {area['name']}
                </option>
              )
            })}
          </select>
        </div>

        <div className="form-group">
          <label className="premium-search-form__label">Grade range</label>
          <div className="slider-container premium-search-field-container">
            <TwoPointSlider
              stepSize={50}
              valueRange={[100, 1500]}
              values={[this.props.gradeMinInt, this.props.gradeMaxInt]}
              onChange={this.changeGrades.bind(this)}
              labelValue={this.localizedGrade.bind(this)}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="premium-search-form__label">Order by</label>
          <select
            className="form-control input-lg"
            onChange={this.onOrderByChange.bind(this)}
            value={this.props.orderBy}
          >
            {Object.keys(routeListFieldHash).map((key, value) => {
              return (
                <option key={'order_by' + value} value={key}>
                  {routeListFieldHash[key]}
                </option>
              )
            })}
          </select>
        </div>
        <div className="premium-search-form__footer">
          <div className="premium-search-form__tagline">{this.props.tagline}</div>
          <a
            href={
              selecedAreaParamId != ''
                ? '/areas/' +
                  selecedAreaParamId +
                  '/routelist?grade_min=' +
                  this.props.gradeMinInt +
                  '&grade_max=' +
                  this.props.gradeMaxInt +
                  '&order_by=' +
                  this.props.orderBy +
                  '_desc'
                : null
            }
            className={`btn btn-primary premium-search-form__btn ${selecedAreaParamId == '' ? 'disabled' : ''}`}
          >
            Search
          </a>
        </div>
      </div>
    )
  }
}

export default PremiumSearchForm

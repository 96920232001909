(function(activation_function) {

  jQuery.fn.print_page = function() {
    if ( this.length > 0 ) {
      activation_function();
    }
    return this;
  }

})(function(){

  function split_list(ol_one, ol_two, target_height, keep_list_height_below_target) {
    var sum = 0;
    ol_one.find('li').each(function(index, li) {
      sum += $(li).outerHeight();
      if ( sum >= target_height ) {
        var items_to_move = $(li).nextAll();
        var start_number = parseInt(ol_one.attr('start') || '1') + index + 1;
        if ( keep_list_height_below_target ) {
          start_number -= 1;
          items_to_move = items_to_move.addBack();
        }
        ol_two.attr('start', start_number);
        items_to_move.remove().prependTo(ol_two);
        return false;
      }
    });
  }

  function balance_lists(container) {
    var ol_left  = container.find('.primary ol');
    var ol_right = container.find('.secondary ol');
    var target_height = ol_left.height() / 2;
    split_list(ol_left, ol_right, target_height, false);
  }

  // a logical page contains one topo image and a list of routes
  // but it may be split on multiple sheets of paper
  function LogicalPage(page_div, page_id) {

    var $page = $(page_div);
    var $content = $page.find('.content');
    var $original_ol = $page.find('.route_list .primary ol');
    var $routes = $original_ol.find('li');
    var overflow_id = 'pp_overflow_' + page_id;
    var overflow_page_id = overflow_id + '_page';

    function overflow_container(){ return $('#' + overflow_id); }

    function previous_pagebox(pagebox) {
      pagebox = pagebox || $page.closest('.pagebox');
      pagebox = pagebox.prev();
      if ( pagebox.length == 1 && pagebox.hasClass('excluded') ) return previous_pagebox(pagebox);
      else return pagebox;
    }

    function next_pagebox(pagebox) {
      pagebox = pagebox || $page.closest('.pagebox');
      pagebox = pagebox.next();
      if ( pagebox.length == 1 && pagebox.hasClass('excluded') ) return next_pagebox(pagebox);
      else return pagebox;
    }

    function reset() {
      if ( $page.closest('.pagebox').hasClass('merged') ) {
        overflow_container().children().remove().appendTo($content);
      }
      $routes.remove().appendTo($original_ol);
      overflow_container().remove();
    }

    function balance() {
      balance_lists($page.find('.route_list'));
    }

    // NOTICE: all pages must be reset and balanced before the reflow can start
    function reflow() {

      if ( $page.closest('.pagebox').hasClass('excluded') ) return;

      var $next_page = next_pagebox().find('.page');
      var $next_page_content = $next_page.find('.content');
      $next_page_content.addClass('squeezed');

      // check for overflow
      if ( $content.outerHeight() > $page.height() ) {
        $content.addClass('squeezed');
        if ( $content.outerHeight() > $page.height() ) {
          $content.addClass('super_squeezed');
          if ( $content.outerHeight() > $page.height() ) {
            // have to use the next page anyway so forget about super squeezing
            $content.removeClass('super_squeezed');

            $content.append('<div id="' + overflow_id + '" class="pp_overflow container"><div class="primary"><ol></ol></div><div class="secondary"><ol></ol></div></div>');

            // reduce first the left list and then the right list to fit on the page
            var target_height = $content.find('.route_list').height() - ($content.outerHeight() - $page.height());
            var ol_right = $content.find('.route_list .secondary ol');
            var ol_next = overflow_container().find('.primary ol');
            split_list($original_ol, ol_right, target_height, true);
            split_list(ol_right, ol_next, target_height, true);

            // balance the content on the next page
            balance_lists(overflow_container());

            if ( $next_page.length == 1 && ($next_page.height() - $next_page_content.outerHeight()) > overflow_container().outerHeight() ) {
              // move overflow to the next page
              overflow_container().remove().prependTo($next_page_content);
            } else {
              $next_page_content.removeClass('squeezed');
              // add a page in the preview
              if ( $page.closest('.pagebox').find('.pp_overflow_page').length == 0 ) {
                $page.closest('.pagebox').addClass('double').find('.a4, .letter').append('<div id="' + overflow_page_id + '" class="page pp_overflow_page"><div class="content"></div></div>');
              }
              var $overflow_page = $('#' + overflow_page_id);
              var $overflow_page_content = $overflow_page.find('.content');
              overflow_container().remove().prependTo($overflow_page_content);
              if ( $overflow_page_content.outerHeight() > $overflow_page.height() ) {
                $overflow_page_content.addClass('squeezed');
                if ( $overflow_page_content.outerHeight() > $overflow_page.height() ) {
                  $overflow_page_content.addClass('super_squeezed');
                }
              }
            }
          }
        }
      } else {
        // check if all content fits on the next page
        // 16.12 pixel (4mm + 1px) is needed for the space between the two logical pages
        if ( $next_page.length == 1 && ($content.height() + 16.12 + $next_page_content.outerHeight()) < $next_page.height() ) {
          $next_page_content.prepend('<div id="' + overflow_id + '" class="pp_overflow container"></div>');
          $content.children().remove().appendTo(overflow_container());
          $page.closest('.pagebox').nextUntil($next_page.closest('.pagebox')).addBack().addClass('merged');
          // remove the squeeze, it will be added back if necessary when the next page is processed
          $next_page_content.removeClass('squeezed');
        } else {
          $page.closest('.pagebox').removeClass('merged');
          $next_page_content.removeClass('squeezed');
        }
      }

      // remove unused overflow page
      if ( $('#' + overflow_page_id + ' .content:empty').length > 0 ) {
        $page.closest('.pagebox').removeClass('double');
        $('#' + overflow_page_id).remove();
      }

    }

    return {
      reset:   reset,
      balance: balance,
      reflow:  reflow
    }
  }

  function mark_last_printable() {
    $('.lastpage').removeClass('lastpage');
    $('.pagebox:not(.excluded)').last().addClass('lastpage');
  }

  function reflow() {
    for (var i = 0; i < pages.length; i++) { pages[i].reset(); }
    // wait for the height transition to complete before reflowing
    setTimeout(function() {
      // balance all pages in a separate loop before reflowing!
      for (var i = 0; i < pages.length; i++) { pages[i].balance(); }
      for (var i = 0; i < pages.length; i++) { pages[i].reflow(); }
    }, 310);
  }

  // initialize
  var pages = [];
  $('.pagebox .page').each(function(i, e) {
    var p = LogicalPage(e, i + 1);
    p.balance(); // balance all before reflowing
    pages.push(p);
  });
  for (var i = 0; i < pages.length; i++) { pages[i].reflow(); }
  mark_last_printable();

  $('.control').click(function() {
    $(this).parent().toggleClass('excluded');
    reflow();
    mark_last_printable();
  });

  $('#paper_size_radio').on('click', '.off', function() {
    $('.pagebox .a4, .pagebox .letter').toggleClass('a4 letter');
    $('#paper_size_radio .option').toggleClass('off');
    $.cookie('paper', $(this).data('value'), {path:'/', expires: 30});
    reflow();
  });

  $('#page_margin_radio').on('click', '.off', function() {
    $('.preview').toggleClass('big_margins');
    $('#page_margin_radio .option').toggleClass('off');
    reflow();
  });

  $('#preview_zoom_radio').on('click', '.off', function() {
    $('.pagebox .z75, .pagebox .z50, .pagebox .z25').removeClass('z75 z50 z25').addClass($(this).data('value'));
    $('#preview_zoom_radio .option').addClass('off');
    $(this).removeClass('off');
  });

  $('#print_button').click(function(){ window.print(); });

  if ( $.browser.msie ) {
    $('.nbr').append('<div class="iebg"></div>');
    setTimeout(function(){
      $('.leaflet-popup-content .sector, .leaflet-popup-content .parking').append('<div class="iebg"></div>');
    }, 100);
  }

});

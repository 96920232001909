/* A MapSymbol has
 * - a marker collection with one or more SinglePointMarkers and
 * - possibly an overlay (eg. SectorOverlay).
 */
window.MapSymbol = function (attributes, markers) {

  var div_id;
  var div_ref;
  var dom_node;
  var overlay;
  var waiting;
  
  function jq()   { return $(div_ref); }
  
  function each_element(message) {
    markers[message]();
    if ( overlay ) overlay[message]();
  }

  function each_element_except(single_point_marker, message) {
    markers.each_marker_except(single_point_marker, message);
    if ( overlay ) overlay[message]();
  }

  function initialize() {
    try { // assigning private variables
      div_id = attributes.url.replace(/\//g, '_') + '_ajax_target';
      div_ref = '#' + div_id;
    } catch(error) {
      app.log(error);
      return false;
    }
    return true;
  }
  
  function edit_action() {
    if ( attributes.url.split('/').pop() == 'new' ) return '';
    return '/edit';
  }
  
  // public (needed only by observer or marker collection) -----------------
  
  function ajaxify_form() {
    $(div_ref + ' form').ajaxForm({
      beforeSubmit: app.wait,
      closeKeepAlive: ($.browser.safari ? '/close_connection' : null),
      data: {is_ajax_request: true},
      dataType: 'json',
      success: function(response) {
        $('#sector_topo_image').val('');
        markers.close_info_window();
        update_attributes(response);
        $.unblockUI();
      }
    });
  }
  
  function update_form(marker, prefix) {
    // the name of the form field can be "sector[latitude]" for example
    // we do not want to match prefixed fields unless prefix is given
    if ( !prefix ) prefix = '';
    $(div_ref+' input[name*=\\['+prefix+'latitude\\]]').val(marker.latitude());
    $(div_ref+' input[name*=\\['+prefix+'longitude\\]]').val(marker.longitude());
  }
  
  // public ----------------------------------------------------------------
  
  function to_dom(action) {
    if ( !dom_node ) {
      MyDom.introduce({id: div_id, 'class': 'mapbox'});
      jq().load(attributes.url + (action || edit_action()), function() {
        dom_node = this;
      });
    }
  }
  
  function reveal(current_marker) {
    current_marker = current_marker || markers.last();
    if ( !dom_node ) {
      if ( !waiting ) current_marker.open_info_window(MyDom.waiting());
      waiting = true;
      setTimeout(function(){ reveal(current_marker); }, 100);
    } else {
      waiting = false;
      current_marker.open_info_window(dom_node);
    }
  }
  
  function update_attributes(params, success_message) {
    if ( !params ) params = {error: MapMarkerViews.error};
    if ( params.error ) {
      reset_or_remove();
      $.facebox(app.unescape(params.error), {error: true});
    } else {
      if ( attributes.url != params.url ) dom_node = null;
      $.extend(attributes, params);
      if ( initialize() ) {
        $.facebox.close();
        var cragLocationMarkerUpdated = function(){
          return (typeof attributes.kind == 'undefined');
        };
        if ( $('#todo_set_crag_location_and_sectors')[0] && ! cragLocationMarkerUpdated() ){
          $('#todo_set_crag_location_and_sectors').hide(200);
        }
        app.messages.clear();
        app.messages.append('<div class="notice">' + (success_message || MapMarkerViews.saved) + '</div>');
        return true;
      }
    }
    return false;
  }
  
  function reset_or_remove() {
    if ( attributes.latitude && attributes.longitude ) {
      markers.first.set_latlng(attributes.latitude, attributes.longitude);
      if ( overlay ) overlay.reset(attributes);
    } else {
      remove();
    }
  }
  
  function remove() { each_element('remove'); }
  
  // Constructor
  initialize();

  this.highligh           = function () { markers.highlight(); };
  this.resetHighlight    = function () { markers.resetHighlight() };
  this.markers            = markers;
  this.overlay            = function() { return overlay; }
  this.set_overlay        = function(o){ overlay = o; } 
  this.enable_dragging    = function() { markers.enable_dragging(); }
  this.ajaxify_form       = ajaxify_form;
  this.update_form        = update_form;
  this.to_dom             = to_dom;
  this.reveal             = reveal;
  this.update_attributes  = update_attributes;
  this.reset_or_remove    = reset_or_remove;
  this.remove             = remove;
  this.hide               = function() { each_element('hide'); }
  this.show               = function() { each_element('show'); }
  // because sector lines interfere with mousemove events:
  this.make_unobtrusive   = function() { each_element('make_unobtrusive'); }
  this.make_responsive    = function() { each_element('make_responsive');  }
  this.each_element_except = each_element_except
  
}

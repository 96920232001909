(function($) {
  $.linebox = {
    line: { curvy: true, dashed: false, dash_bg: "#000", width: 2 },
    color: {
      normal: "rgba(227,27,20,0.7)",
      emphasized: "rgba(246,225,24,1.0)",
      de_emphasized: "rgba(145,22,11,0.6)"
    },
    css: { position: "absolute", top: 0, left: 0 },
    boxes: []
  };

  $.fn.linebox = function(options) {
    options = $.extend({}, $.linebox, options);
    // help with the css if it was forgotten
    if (this.css("position") == "static") this.css("position", "relative");

    this.each(function(i, element) {
      var box = new LineBox(element, options);
      $.linebox.boxes.push(box);
      // give easy access to boxes that have a suitable id
      if ($.inArray(element.id, ["line", "color", "css", "boxes"]) == -1)
        $.linebox[element.id] = box;
    });

    return this;
  };

  // ---------------------------------------------------------------------
  function LineBox(element, options) {
    var jq = $(element);
    var self = this;

    // read lines and options from the html
    var data = eval("(" + jq.find(".js-data").html() + ")");
    if (data.options) options = $.extend({}, options, data.options);

    // initialize the canvas
    var canvas = create_canvas(jq);

    // new_line has to be public
    var new_line = [];
    var strong_line = data.strong_line
      ? data.strong_line.map(p => ({
          x: getXpx(p.x),
          y: getYpx(p.y)
        }))
      : null;
    var lines = data.lines.map(line =>
      line.map(p => ({ x: getXpx(p.x), y: getYpx(p.y) }))
    );

    var ctx = canvas.getContext("2d");
    ctx.lineWidth = options.line.width;

    if (!ctx.setLineDash) {
      ctx.setLineDash = function(arr) {
        ctx.mozDash = arr;
        ctx.webkitLineDash = arr;
      };
    }

    this.getNewLine = function() {
      return new_line.map(p => ({
        x: getXpercent(p.x),
        y: getYpercent(p.y)
      }));
    };

    this.highlightLine = function(index) {
      if ($.inArray(index, lines)) {
        strong_line = lines[index];
      }
      redraw();
    };

    this.drawStrongLine = function(line) {
      ctx.lineWidth = options.line.width + 1;
      ctx.shadowColor = "rgba(0,0,0,0.8)";
      ctx.shadowBlur = 10;
      draw([line], "emphasized");
      ctx.shadowColor = "";
      ctx.shadowBlur = 0;
      ctx.lineWidth = options.line.width;
    };

    if (options.editable) {
      enable_drawing();
      this.undo = function() {
        new_line.pop();
        redraw();
      };
    }
    reveal();

    $(window).resize(function() {
      redraw();
    });
    // private functions -------------------------------------------------

    function create_canvas(jq) {
      var width = jq.children('img')[0].width;
      var height = jq.children('img')[0].height;
      var width_and_height = {
        width: width,
        height: height
      };
      // we have to append and find because excanvas needs access to the parent
      // (we used to do $('<canvas></canvas>') but it doesnt work with the new excanvas)
      jq.append(
        $(document.createElement("canvas"))
          .css($.linebox.css)
          .attr(width_and_height)
      );
      // use excanvas for Internet Explorer
      return jq.find("canvas")[0];
    }

    function enable_drawing() {
      // we need to add a listener on top of the explorer canvas
      var listener = jq.append($("<div></div>").css($.linebox.css));
      listener.click(function(e) {
        var offset = $(this).offset();
        new_line.push({
          x: e.pageX - offset.left,
          y: e.pageY - offset.top
        });
        redraw();
      });
    }

    // reveal is an alias for redraw
    function reveal() {
      redraw();
    }

    function redraw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      draw(lines);
      if (options.editable) {
        draw([new_line], "emphasized");
      }
      if (strong_line) {
        self.drawStrongLine(strong_line);
      }
    }

    function draw(lines, style) {
      ctx.beginPath();
      add(lines, style);
      stroke(style);
    }

    function add(lines, style) {
      $.each(lines, function(i, line) {
        if (line.length == 0) return "next";
        ctx.moveTo(line[0]["x"], line[0]["y"]);
        if (options.editable) {
          ctx.fillStyle = color(style);
          ctx.fillRect(line[0]["x"] - 6, line[0]["y"], 12, 12);
        }
        options.line.curvy ? add_curve(line) : add_line(line);
      });
    }

    function add_line(points) {
      $.each(points, function(i, a) {
        ctx.lineTo(a["x"], a["y"]);
      });
    }

    function add_curve(points) {
      $.each(points, function(i, a) {
        var to = anchor(a, points[i + 1]);
        ctx.quadraticCurveTo(a["x"], a["y"], to["x"], to["y"]);
      });
    }

    function anchor(p1, p2) {
      if (!p2) return p1;
      var x1 = p1["x"],
        x2 = p2["x"],
        y1 = p1["y"],
        y2 = p2["y"];
      var sc = Math.min(0.5, scale(x1, x2), scale(y1, y2));
      var ax = x1 + sc * (x2 - x1);
      var ay = y1 + sc * (y2 - y1);
      return { x: ax, y: ay };
    }

    function scale(x1, x2) {
      // 20.0 is the maximum distance of the curve from the actual point
      return 20.0 / Math.abs(x2 - x1);
    }

    function stroke(style) {
      if (options.line.dashed) {
        ctx.strokeStyle = options.line.dash_bg;
        ctx.lineWidth = options.line.width + 1;
        ctx.stroke();
        ctx.lineWidth = options.line.width;
        ctx.setLineDash([15]);
      }
      ctx.strokeStyle = color(style);
      ctx.stroke();
    }

    function color(style) {
      return options.color[
        style ||
          options.style ||
          (options.editable ? "de_emphasized" : "normal")
      ];
    }

    function getXpx(x_percent) {
      return x_percent * canvas.width;
    }
    function getYpx(y_percent) {
      return y_percent * canvas.height;
    }
    function getXpercent(x_px) {
      return x_px / canvas.width;
    }
    function getYpercent(y_px) {
      return y_px / canvas.height;
    }
  }
})(jQuery);

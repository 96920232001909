import React, { Component } from "react";

class Results extends Component {
  render() {
    const { results, query, show } = this.props;

    return (
      <div className="autocomplete-suggestions">
        {show && (
          <div className="items-container">
            {results.map((obj, index) => {
              return (
                <div
                  className="autocomplete-suggestion"
                  key={index}
                  onClick={() => {
                    window.location.href = obj.path;
                  }}
                >
                  <div className="cont">
                    <div className="text">
                      <span className="headline">
                        <strong>{obj.name.slice(0, query.length)}</strong>
                        {obj.name.slice(query.length, obj.name.length)}
                      </span>
                      {obj.description && (
                        <span className="description">{obj.description}</span>
                      )}
                    </div>
                    <div className="badges">
                      <span className="type">{obj.searchable_type}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default Results;

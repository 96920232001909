import React , { Component } from 'react';

const Thanks = (props) => {
    const { climber_name, year, has_used_premium } = props;

    return (
        <div className={'slide-content'}>
            <h1>We’re ready<br/>for 2023!</h1>
            <p>Thanks to you and all the other climbers around the world, <b>27 Crags</b> is getting better every year. More than 750 new topos were added in 2022!</p>
            <p>We’re looking forward to continue our journey together.</p>
            <p className={'italic'}>-- the 27 Crags team <br/>Ville, Tim, Hanna, Mikael, Teemu</p>
            <p className={'help-block'}>Share your stats in Instagram and tag us with<br /><a href={"https://www.instagram.com/27cragsofficial/"} target={'_blank'}>@27cragsofficial</a></p>
        </div>
    );
}

export default Thanks;
import React from 'react';

const ConfirmationDialog = ({ onConfirm, onCancel }) => (
  <div className="confirmation-dialog">
    <h3>Delete this item?</h3>
    <p>You cannot undo this action.</p>
    <div className="footer">
      <div className="button">
        <button className="btn btn-default" onClick={onCancel}>Cancel</button>
      </div>
      <div className="button">
        <button className="btn btn-default btn-danger" onClick={onConfirm}>Delete</button>
      </div>
    </div>
  </div>
);

export default ConfirmationDialog;

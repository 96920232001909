import React from 'react';

const LikeButton = ({ currentClimber, likes, likeStatus, onLikeClick }) => {
  const likeClick = () => {
    if (!currentClimber.paramId) {
      window.location = '/login';
      return;
    }
    let likeRequest;
    if (likeStatus === 'liked' || likeStatus === 'like') {
      likeRequest = 'unlike';
    } else {
      likeRequest = 'like';
    }
    const data = {
      id: likes.id,
      type: likes.type,
      likeRequest,
    };
    onLikeClick(data);
  };

  const likeClass = () => {
    if (likeStatus === 'liked') {
      return 'btn-like btn-like-remove';
    } else if (likeStatus === 'like') {
      return 'btn-like btn-like-remove animation-like';
    } else if (likeStatus === 'unlike') {
      return 'btn-like btn-animation-remove-like';
    }
    return 'btn-like';
  };

  const likeCount = () => {
    if (likes.likes.length >= 1) {
      const url = likes.type === 'Photo' ? `/likes/for_votable?photo_id=${likes.id}` : `/likes/for_votable?feed_item_id=${likes.id}`;
      return (
        <a href={url} rel="facebox[modal]" title="Click to see likes">
          {likes.likes.length === 1 ? '1 like' : `${likes.likes.length} likes`}
        </a>
      );
    }
    return null;
  };

  return (
    <div>
      <a className={likeClass()} onClick={likeClick}>
        <span className="icons">
          <span className="like" title="Click to like">
            <i className="glyphicon glyphicon-heart-empty" />
            <span className="text">Like</span>
          </span>
          <span className="liked" title="Click to remove like">
            <i className="glyphicon glyphicon-heart" />
            <span className="text">Liked</span>
          </span>
        </span>
      </a>
      <div className="likes show-tooltip">{likeCount()}</div>
    </div>
  );
};

export default LikeButton;

import axios from "axios";
import * as types from "../common/actionTypes";
import * as apiEndpoints from "../common/apiEndpoints";

export const findResults = query => async (dispatch, getState) => {
  const payload = await axios.get(apiEndpoints.search_path(query));
  dispatch({
    type: types.SET_RESULTS,
    payload: payload.data.search_keys
  });
};

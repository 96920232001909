import React, { Component } from "react";

class ToggleCheckbox extends Component {
  render() {
    const { active, value, onChange, content, className } = this.props;
    const activeClass = active ? "active" : "";
    return (
      <li className={(className || "toggle-filter-button")}>
        <div className="checkbox">
          <label className={activeClass}>
            <input
              type="checkbox"
              value={value}
              checked={active}
              onChange={e => onChange(e)}
            />
            {this.props.children}
          </label>
        </div>
      </li>
    );
  }
}

export default ToggleCheckbox;

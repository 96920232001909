import React from 'react'
import ToggleSwitch from './ToggleSwitch'

const getSortClasses = (sort, buttonSort) => {
  let classes = 'btn btn-default sort-crags-by'
  if (buttonSort === sort) {
    classes += ' btn-primary'
  }
  return classes
}

const getGradeSortClasses = (sortGrades, buttonGradeSort) => {
  let classes = 'btn btn-default sort-crags-by'
  if (sortGrades[buttonGradeSort]) {
    classes += ' btn-primary'
  }
  return classes
}

const switchLabelImgStyle = {
  marginTop: -14,
  width: 30,
  height: 30,
}

class Filters extends React.Component {
  hide = () => {
    const { visible, toggleFiltersVisibility } = this.props
    if (visible) {
      toggleFiltersVisibility()
    }
  }

  handleOutsideClick = e => {
    if (e.target.className === 'modal') {
      this.hide()
    }
  }

  render() {
    const { visible, filters, icons, sort, sortGrades, toggleFilter, toggleCommercialFilter, setSort } = this.props
    return (
      <div className="map-toggles">
        <div
          className={visible ? 'modal' : 'modal fade in'}
          style={{ display: visible ? 'block' : 'none' }}
          onClick={this.handleOutsideClick}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.hide}>
                  <span aria-hidden="true">×</span>
                </button>
                <h4 className="modal-title">FILTER CRAGS</h4>
              </div>
              <div className="modal-body">
                <div className="filters">
                  <div className="filter-group">
                    <ToggleSwitch
                      id="boulder"
                      label={
                        <span>
                          <img style={switchLabelImgStyle} src={icons.boulder.normal} /> Boulder
                        </span>
                      }
                      onChange={() => toggleFilter('boulder')}
                      checked={filters.boulder}
                    />
                    <ToggleSwitch
                      id="sport"
                      label={
                        <span>
                          <img style={switchLabelImgStyle} src={icons.sport.normal} /> Sport
                        </span>
                      }
                      onChange={() => toggleFilter('sport')}
                      checked={filters.sport}
                    />
                    <ToggleSwitch
                      id="trad"
                      label={
                        <span>
                          <img style={switchLabelImgStyle} src={icons.trad.normal} /> Traditional
                        </span>
                      }
                      onChange={() => toggleFilter('trad')}
                      checked={filters.trad}
                    />
                    <ToggleSwitch
                      id="dws"
                      label={
                        <span>
                          <img style={switchLabelImgStyle} src={icons.dws.normal} /> DWS
                        </span>
                      }
                      onChange={() => toggleFilter('dws')}
                      checked={filters.dws}
                    />
                    <ToggleSwitch
                      id="freeCrags"
                      label={
                        <span>
                          <img style={{ ...switchLabelImgStyle, visibility: 'hidden' }} src={icons.trad.normal} /> Free
                          topos
                        </span>
                      }
                      onChange={() => toggleFilter('freeCrags')}
                      checked={filters.freeCrags}
                    />
                  </div>
                </div>
                <div className="travel-info">
                  <h4 className="miniheader">RECOMMENDED SERVICES</h4>
                  <ToggleSwitch
                    id="accommodation"
                    label={
                      <span>
                        <img style={switchLabelImgStyle} src={icons.accommodation.normal} /> Accommodation
                      </span>
                    }
                    onChange={() => toggleCommercialFilter('accommodation')}
                    checked={filters.accommodation}
                  />
                  <ToggleSwitch
                    id="climbingShop"
                    label={
                      <span>
                        <img style={switchLabelImgStyle} src={icons.climbing_shop.normal} /> Climbing shops
                      </span>
                    }
                    onChange={() => toggleCommercialFilter('climbingShop')}
                    checked={filters.climbingShop}
                  />
                  <ToggleSwitch
                    id="climbingGuide"
                    label={
                      <span>
                        <img style={switchLabelImgStyle} src={icons.climbing_guide.normal} /> Climbing guides
                      </span>
                    }
                    onChange={() => toggleCommercialFilter('climbingGuide')}
                    checked={filters.climbingGuide}
                  />
                  <ToggleSwitch
                    id="gym"
                    label={
                      <span>
                        <img style={switchLabelImgStyle} src={icons.gym.normal} /> Climbing gyms
                      </span>
                    }
                    onChange={() => toggleCommercialFilter('gym')}
                    checked={filters.gym}
                  />
                </div>
                <div className="sorts">
                  <h4 className="miniheader">SORT LIST BY</h4>
                  <div className="sort-group">
                    <button onClick={() => setSort('distance')} className={getSortClasses(sort, 'distance')}>
                      Distance
                    </button>{' '}
                    <button onClick={() => setSort('popularity')} className={getSortClasses(sort, 'popularity')}>
                      Popularity
                    </button>{' '}
                    <button onClick={() => setSort('routes')} className={getSortClasses(sort, 'routes')}>
                      Routes total
                    </button>
                  </div>
                  {sort === 'routes' && (
                    <div className="sort-group">
                      <br />
                      <button onClick={() => setSort(4, true)} className={getGradeSortClasses(sortGrades, 4)}>
                        ..4+
                      </button>{' '}
                      <button onClick={() => setSort(5, true)} className={getGradeSortClasses(sortGrades, 5)}>
                        5..
                      </button>{' '}
                      <button onClick={() => setSort(6, true)} className={getGradeSortClasses(sortGrades, 6)}>
                        6a..
                      </button>{' '}
                      <button onClick={() => setSort(7, true)} className={getGradeSortClasses(sortGrades, 7)}>
                        7a..
                      </button>{' '}
                      <button onClick={() => setSort(8, true)} className={getGradeSortClasses(sortGrades, 8)}>
                        8a..
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={visible ? 'modal-backdrop fade in' : 'hidden fade in'} />
      </div>
    )
  }
}

export default Filters

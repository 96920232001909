import React, {Component} from 'react'
import mapboxgl from 'mapbox-gl'
import {throttle} from 'throttle-debounce'
import {getFilteredAdditionalMarkers, getFilteredMarkers, addSource, removeSource} from '../../util'
import {markerIsCommercial} from '../../util'

class Map extends Component {
  constructor(props) {
    super(props)
    this.mapBoundsChanged = throttle(2000, this.mapBoundsChanged)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.crags.length === 0 && this.props.crags.length > 0) {
      const cragMarkers = this.getMarkers()
      removeSource(map, true, false)
      this.props.initMapboxMaps({
        cragMarkers: {
          type: 'FeatureCollection',
          features: cragMarkers.features.reduce((result, marker) => {
            result[marker.crag.id] = marker
            return result
          }, {}),
        },
      })
    }
  }

  componentDidMount() {
    this.initMapboxMaps()
  }

  getMarkers = () => {
    return getFilteredMarkers(this.props.crags, this.props.editable ? undefined : this.props.filters)
  }

  initMapboxMaps = () => {
    let center = this.props.location || {lat: 18.5, lng: 8.7}
    let zoom = this.props.zoom || 2
    if (window.location.hash && this.props.useHashLocation) {
      const locationParams = window.location.hash.substr(1).split(',')
      center = {
        lng: parseFloat(locationParams[0]),
        lat: parseFloat(locationParams[1]),
      }
      zoom = parseInt(locationParams[2], 10)
    }
    mapboxgl.accessToken = 'pk.eyJ1IjoiMjdjcmFncyIsImEiOiJjandxZGNmN2IwcDM2NDRxcjlhcDMzdTZ1In0.gOytbbAlL2j4qZML5HTDxQ'
    const map = new mapboxgl.Map({
      container: this.map,
      center: center,
      zoom: zoom,
      touchZoomRotate: false,
      style: 'mapbox://styles/27crags/cjxesee7407jw1cryakj0px32',
    })

    map.on('click', e => this.mapClickEvent(map, e))
    map.on('idle', () => this.mapIdleEvent(map))

    let otherMarkers = {type: 'FeatureCollection', features: []}
    if (this.props.markers && this.props.markers.length > 0) {
      otherMarkers = this.getAdditionalMarkers(map, this.props.markers)
    }

    const cragMarkers = this.getMarkers()

    map.on('load', () => {
      addSource(map, true, true, cragMarkers, otherMarkers)
      const nav = new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: true,
      })
      map.addControl(nav, 'bottom-right')
      const layers = ['clusters', 'unclustered-crag', 'other-clusters', 'unclustered-other', 'highlighted-marker']
      map.on('click', 'unclustered-crag', e => {
        var features = map.queryRenderedFeatures(e.point, {layers: layers})
        if (features[0].layer.id == 'unclustered-crag') {
          var cragId = features[0].properties.cragId
          this.props.highlightCrag(cragId)
        }
      })
      map.on('click', 'clusters', e => {
        var features = map.queryRenderedFeatures(e.point, {layers: layers})
        if (features[0].layer.id == 'clusters') {
          var clusterId = features[0].properties.cluster_id
          map.getSource('crag-markers').getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return
            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom + 1,
            })
          })
        }
      })
      map.on('click', 'other-clusters', e => {
        var features = map.queryRenderedFeatures(e.point, {layers: layers})
        if (features[0].layer.id == 'other-clusters') {
          var clusterId = features[0].properties.cluster_id
          map.getSource('other-markers').getClusterExpansionZoom(clusterId, function (err, zoom) {
            if (err) return
            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom + 1,
            })
          })
        }
      })
      map.on('mousemove', e => {
        var features = map.queryRenderedFeatures(e.point, {layers: layers})
        if (features.length > 0) {
          map.getCanvas().style.cursor = 'pointer'
        } else map.getCanvas().style.cursor = ''
      })
      map.on('click', 'unclustered-other', e => {
        var features = map.queryRenderedFeatures(e.point, {layers: layers})
        if (features[0].layer.id == 'unclustered-other') {
          var marker = features[0]
          this.props.highlightCrag(null)
          if (this.props.editable) {
            marker.properties.marker = JSON.parse(marker.properties.marker)
            this.props.setMarkerEditPopup(true, marker)
          } else if (markerIsCommercial(marker.properties.kind)) {
            this.closeInfoWindow()
            this.props.setCommercialMarkerPopup(true, marker)
          } else {
            this.closeInfoWindow()
            const popupContent = JSON.parse(marker.properties.marker)
            new mapboxgl.Popup({
              className: 'google-maps__info-window-content',
              closeButton: false,
            })
              .setLngLat(marker.geometry.coordinates)
              .setHTML(
                `<div class="google-maps__info-window-content">
                  <h3>${marker.properties.title}</h3>
                  ${popupContent.info ? `<p>${popupContent.info}</p>` : ''}
                  ${
                  popupContent.link
                    ? `<p><a href='${popupContent.link}' class='btn btn-primary btn-block' target='_blank'>Read more</a></p>`
                    : ''
                }
                </div>`,
              )
              .addTo(map)
          }
        }
      })
    })

    this.props.initMapboxMaps({
      map,
      cragMarkers: {
        type: 'FeatureCollection',
        features: cragMarkers.features.reduce((result, marker) => {
          result[marker.properties.crag.id] = marker
          return result
        }, {}),
      },
      otherMarkers: {
        type: 'FeatureCollection',
        features: otherMarkers.features.reduce((result, marker) => {
          result[marker.properties.id] = marker
          return result
        }, {}),
      },
    })
  }

  mapClickEvent = (map, e) => {
    const {locationId, cragPopupVisible, highlightCrag, editable, setMarkerEditPopup} = this.props
    const layers = ['clusters', 'unclustered-crag', 'other-clusters', 'unclustered-other', 'highlighted-marker']
    var features = map.queryRenderedFeatures(e.point, {layers: layers})
    if (features.length > 0) return
    this.closeInfoWindow()
    if (cragPopupVisible) {
      highlightCrag(null)
    } else if (editable) {
      const newMarkerObj = {
        properties: {
          icon: 'map_marker_parking_space',
          iconNormal: 'map_marker_parking_space',
          iconHighlight: 'map_marker_parking_space',
          marker: {
            title: 'New marker',
            id: null,
            location_id: locationId,
            name: 'New marker',
            info: '',
            kind: 'parking_space',
          },
        },
        geometry: {
          coordinates: e.lngLat.toArray(),
          type: 'Point',
        },
      }
      setMarkerEditPopup(true, newMarkerObj)
    }
  }

  mapIdleEvent = map => {
    if (!this.props.editable) {
      this.mapBoundsChanged()
    }
    if (this.props.useHashLocation) {
      const location = window.location.pathname.split('@')
      const position = `${map.getCenter().lng},${map.getCenter().lat}`
      window.history.replaceState({}, document.title, `${location[0]}#${position},${map.getZoom()}`)
    }
  }

  closeInfoWindow = () => {
    if (this.props.infoWindow) {
      this.props.infoWindow.close()
    }
  }

  getAdditionalMarkers = (map, markers) => {
    const {filters, icons, editable} = this.props
    return getFilteredAdditionalMarkers(markers, filters, icons, editable)
  }

  mapBoundsChanged = () => {
    const {mapboxMaps, updateVisibleCrags, updateVisibleMarkers} = this.props
    const {map, cragMarkers, otherMarkers} = mapboxMaps
    if (map) {
      if (cragMarkers) {
        updateVisibleCrags()
      }
      if (otherMarkers) {
        updateVisibleMarkers()
      }
    }
  }

  render() {
    return (
      <div
        ref={map => {
          this.map = map
        }}
        className="map"
      />
    )
  }
}

export default Map

window.app.initialize_area_focus = function(g_map, sw, ne, limit) {
  
  var nw = new google.maps.LatLng(ne.lat(), sw.lng());
  var se = new google.maps.LatLng(sw.lat(), ne.lng());
  var np = new google.maps.LatLng( 85, sw.lng());
  var sp = new google.maps.LatLng(-85, ne.lng());
  
  function east(latlng, dist) {
    var lng = latlng.lng() + dist;
    if ( lng >  180 ) lng = lng - 360;
    if ( lng < -180 ) lng = lng + 360;
    return new google.maps.LatLng(latlng.lat(), lng);
  }
  
  var ne_poly = [np, nw, ne, sp, east(sp, 90), east(np, 90), np];
  var sw_poly = [np, sw, se, sp, east(sp, -90), east(np, -90), np];
  var ne_bg = new google.maps.Polygon({map: g_map, paths: ne_poly, strokeWeight: 0, fillColor: '#000000', fillOpacity: 0.4, clickable: false});
  var sw_bg = new google.maps.Polygon({map: g_map, paths: sw_poly, strokeWeight: 0, fillColor: '#000000', fillOpacity: 0.4, clickable: false});
  
  function show() {
    ne_bg.getVisible() || ne_bg.setVisible(true);
    sw_bg.getVisible() || sw_bg.setVisible(true);
  }
  
  function hide() {
    ne_bg.getVisible() && ne_bg.setVisible(false);
    sw_bg.getVisible() && sw_bg.setVisible(false);
  }
  
  function show_or_hide() {
    g_map.getZoom() > limit ? show() : hide();
  }
  
  if ( g_map.getZoom() <= limit ) hide();
  google.maps.event.addListener(g_map, 'zoom_changed', show_or_hide);
  
};

/* 
 * SinglePointMarker manages a g_marker and its info_window.
 * 
 * g_marker:  Google map marker (represents a single point)
 * observer:  MapSymbol (can include several single_point_markers).
 */
window.SinglePointMarker = function(marker_options) {
  
  var observer;
  var latlng = marker_options.position;
  
  function latitude()  { return g_marker.getPosition().lat().toFixed(6); }
  function longitude() { return g_marker.getPosition().lng().toFixed(6); }
  
  function set_observer(sym) { observer = sym; }
  
  function set_latlng(lat_or_latlng, longitude) {
    if ( longitude ) latlng = new google.maps.LatLng(lat_or_latlng, longitude);
    else latlng = lat_or_latlng;
    if ( g_marker ) g_marker.setPosition(latlng);
  }
  
  function enable_dragging() {
    g_marker.setOptions({draggable: true, optimized: false});
    google.maps.event.addListener(g_marker, 'dragstart', function() {
      observer.dragging_started(that);
    });
    google.maps.event.addListener(g_marker, 'dragend',   function() {
      observer.dragging_stopped(that);
    });
  }
  
  function close_info_window_only() {
    app.map.close_info_window(true);
    observer.info_window_closed();
  }
  
  function open_info_window(content) {
    app.map.open_info_window(content, g_marker, observer.info_window_closed_unexpectedly, observer.info_window_is_open());
    observer.info_window_opened();
  }
  
  // Constructor
  var g_marker = new google.maps.Marker(marker_options);

  var that = {
    // directly accessible variables
    g_marker            : g_marker,
    // accessor methods
    set_observer        : set_observer,
    set_latlng          : set_latlng,
    latitude            : latitude,
    longitude           : longitude,
    latlng              : function() { return g_marker.getPosition(); },
    // public methods
    show                : function() { g_marker.setVisible(true); },
    hide                : function() { g_marker.setVisible(false); },
    highlight           : function() {
      if(typeof marker_options.icon != 'undefined') {
        if(typeof marker_options.icon.url_highlight != 'undefined') {
          marker_options.icon.url = marker_options.icon.url_highlight;
          g_marker.setIcon(marker_options.icon);
          g_marker.setZIndex(2);
          g_marker.map.panTo(g_marker.getPosition());
        }
      }
    },
    resetHighlight     : function() {
      if(typeof marker_options.icon != 'undefined') {
        if(typeof marker_options.icon.url_default != 'undefined') {
          marker_options.icon.url = marker_options.icon.url_default;
          g_marker.setIcon(marker_options.icon);
          g_marker.setZIndex(1);
        }
      }
    },
    open_info_window    : open_info_window,
    close_info_window   : close_info_window_only,
    enable_dragging     : enable_dragging,
    make_unobtrusive    : function(){ g_marker.setOptions({draggable: false, clickable: false}); },
    make_responsive     : function(){ g_marker.setOptions({draggable: true,  clickable: true }); }
  };
  
  return that;
  
}

import React, { Component } from "react";
import StarSpan from "../../common/components/StarSpan";

class StarRatingSelectField extends Component {
  hoverStars(e, lower, touch) {
    const target = e.currentTarget;
    const offset = $(target).offset();
    const width = $(target).width();
    let x = touch ? e.touches[0].clientX - offset.left : e.pageX - offset.left;
    let rating = Math.floor(x / (width / 7)) / 2.0;
    if (rating < 0) rating = 0;
    if (rating > 3) rating = 3;
    if (lower) {
      let upperVal;
      if (rating == 3) upperVal = 3;
      else {
        upperVal =
          rating >= this.props.rating[1] ? rating + 0.5 : this.props.rating[1];
      }
      this.props.updateHoveredRating([rating, upperVal]);
    } else {
      let lowerVal;
      if (rating == 0) lowerVal = 0;
      else {
        lowerVal =
          rating <= this.props.rating[0] ? rating - 0.5 : this.props.rating[0];
      }
      this.props.updateHoveredRating([lowerVal, rating]);
    }
  }

  render() {
    const { updateRating, isLower } = this.props;
    const ratingIndex = isLower ? 0 : 1;

    return (
      <span
        onTouchMove={e => {
          this.hoverStars(e, isLower, true);
        }}
        onTouchEnd={() => {
          updateRating(this.props.hoveredRating);
        }}
        onMouseMove={e => this.hoverStars(e, isLower, false)}
        onClick={e => {
          updateRating(this.props.hoveredRating);
        }}
        onMouseLeave={e => this.props.updateHoveredRating(this.props.rating)}
      >
        <StarSpan
          rating={parseFloat(this.props.hoveredRating[ratingIndex])}
          includeEmptyStars={true}
          spanClass={"stars"}
        />
      </span>
    );
  }
}

export default StarRatingSelectField;

import React from 'react';
import TextArea from '../../common/components/TextArea';
import { Field, reduxForm } from 'redux-form';

const FeedForm = ({ currentClimberParamId, handleSubmit, disabled, pristine }) => {

  const addAscentUrl = `/climbers/${currentClimberParamId}/ascents/new`;
  const sharePhotoUrl = `/photos/new`;
  const shareVideoUrl = `/videos/new`;

  return (
    <div className="user-share-box">
      <div className="share-update-container">
        <form id="js-status" className="share-an-update" onSubmit={handleSubmit}>
          <div className="textarea-container">
            <Field
              className="comment"
              name="text"
              cols="60"
              maxLength="500"
              placeholder="Share something about climbing"
              submit={handleSubmit}
              component={TextArea}
            />
          </div>
          <div className="button-container">
            <div className="button">
              <input className="btn btn-primary" value="Publish" type="submit" disabled={disabled || pristine} />
            </div>
          </div>
        </form>
      </div>
      <div className="share-actions-container">
        <a className="btn btn-sm btn-link" rel="facebox[modal]" href={addAscentUrl}>
          <i className="icon27 icon-arm" /> Ascent
        </a>
        <a className="btn btn-sm btn-link" rel="facebox[modal]" href={sharePhotoUrl}>
          <i className="glyphicon glyphicon-picture" /> Photo
        </a>
        <a id="add_many_link" className="btn btn-sm btn-link" rel="facebox[modal]" href={shareVideoUrl}>
          <i className="glyphicon glyphicon-facetime-video" /> Beta
        </a>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'addFeed'
})(FeedForm);

var mapMarkers = [];

window.app.initialize_map_tiles = function(map, g_map, map_markers, onCragsLoaded) {
  var url = "/api/web01/crags/all";
  var $map = $("#map");
  var onCragsLoaded = onCragsLoaded;

  var $mapLoadingCrags = $("#mapLoadingOverlay");

  var showLoadingCrags = function() {
    $mapLoadingCrags.show();
  };

  var hideLoadingCrags = function() {
    $mapLoadingCrags.hide();
  };

  var setCrags = function(json) {
    if (typeof json.crags != "undefined" && map_markers) {
      map_markers.setCrags(json.crags);
      return true;
    }
    return false;
  };

  var onSuccess = function(data) {
    hideLoadingCrags();
    $map.find(".load-indicator").hide();
    var texts = {
      not_found: "No crags",
      no_geolocation: "Geo location disabled"
    };
    setCrags(data);
    if (onCragsLoaded) {
      onCragsLoaded();
    }
  };

  /*if(setCrags(map.json)) {
    if (onCragsLoaded) {
      onCragsLoaded();
    }
  }
  else {*/

  google.maps.event.addListenerOnce(g_map, "idle", function() {
    var cragsAll = LocalStorage.getInstance("cragsAll");
    if (cragsAll.get() !== null) {
      showLoadingCrags();
      onSuccess(cragsAll.get());
    } else {
      $.ajax({
        type: "GET",
        url: url,
        dataType: "json",
        beforeSend: function() {
          showLoadingCrags();
        },
        complete: function() {
          hideLoadingCrags();
        },
        error: function() {
          $map.find(".load-indicator").hide();
          $map.find(".load-error-message").show();
        },
        success: function(data) {
          cragsAll.set(data);
          var d = new Date();
          d.setMinutes(d.getMinutes() + 30);
          cragsAll.setExpireDate(d.getTime());
          onSuccess(data);
        }
      });
    }
  });
};

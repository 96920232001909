import React, { Component } from 'react'
import TwoPointSlider from '../../common/components/TwoPointSlider'
import DropDownFilter from '../../common/components/DropDownFilter'
import { convertGrade } from '../../common/util.js'

class GradeButton extends Component {
  localizedGrade(gradePoints) {
    return convertGrade(gradePoints, this.props.gradingSystem)
  }

  render() {
    const { gradeMin, gradeMax, changeGrades, gradingSystem } = this.props
    return (
      <DropDownFilter
        titleButton={convertGrade(gradeMin, gradingSystem) + '..' + convertGrade(gradeMax, gradingSystem)}
        titleHeader={'Grade'}
        titleClose={'Apply'}
        showDropdown={this.props.showDropdown}
        hideDropdown={this.props.hideDropdown}
        btnClass={'btn btn-primary'}
      >
        <div className="slider-container">
          <TwoPointSlider
            stepSize={50}
            valueRange={[100, 1500]}
            values={[gradeMin, gradeMax]}
            onChange={changeGrades}
            labelValue={this.localizedGrade.bind(this)}
          />
        </div>
      </DropDownFilter>
    )
  }
}

export default GradeButton

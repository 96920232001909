import axios from 'axios'
import {point} from '@turf/helpers'
import distance from '@turf/distance'
import bboxPolygon from '@turf/bbox-polygon'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'

const defaultFilters = {
  boulder: true,
  sport: true,
  dws: true,
  trad: true,
  freeCrags: false,
  accommodation: true,
  climbingShop: true,
  climbingGuide: true,
  gym: true,
}

export const getFilteredMarkers = (crags, filters = defaultFilters) => {
  const markers = {
    type: 'FeatureCollection',
    features: [],
  }
  for (const cragId in crags) {
    const crag = crags[cragId]
    if (
      crag.latitude &&
      (filters.freeCrags ? true : crag.premium_topo_active) &&
      ((filters.trad && filters.sport && filters.boulder && filters.dws) ||
        (!filters.trad && !filters.sport && !filters.boulder && !filters.dws) ||
        (filters.trad && crag.trad_n_p_count > 0) ||
        (filters.sport && crag.sport_count > 0) ||
        (filters.dws && crag.dws_count > 0) ||
        (filters.boulder && crag.boulder_count > 0))
    ) {
      var marker = {
        properties: {
          cragId: crag.id,
          icon: getMarkerIcon(crag, false),
          iconNormal: getMarkerIcon(crag, false),
          iconHighlight: getMarkerIcon(crag, true),
          title: crag.name,
          crag: crag,
        },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(crag.longitude), parseFloat(crag.latitude)],
        },
      }
      markers.features.push(marker)
    }
  }
  return markers
}

export const addMarker = (map, source, newMarker) => {
  const data = map.getSource(source)._data
  data.features = data.features.filter(marker => {
    return marker.properties.marker.id !== newMarker.properties.marker.id
  })
  data.features.push(newMarker)
  map.getSource(source).setData(data)
}

export const removeMarker = (map, source, markerId) => {
  const data = map.getSource(source)._data
  data.features = data.features.filter(marker => {
    return marker.properties.marker.id !== markerId
  })
  map.getSource(source).setData(data)
}

export const getFilteredAdditionalMarkers = (markers, _filters, icons, editable) => {
  const filters = {
    ...defaultFilters,
    ..._filters,
  }
  const mapboxMarkers = {type: 'FeatureCollection', features: []}
  for (const marker of markers) {
    if (
      (filters.accommodation && marker.kind === 'accommodation') ||
      (filters.climbingGuide && marker.kind === 'climbing_guide') ||
      (filters.climbingShop && marker.kind === 'climbing_shop') ||
      (filters.gym && marker.kind === 'gym') ||
      !markerIsCommercial(marker.kind)
    ) {
      const mapboxMarker = {
        properties: {
          id: marker.id,
          kind: marker.kind,
          icons: icons,
          icon: 'map_marker_' + marker.kind,
          iconNormal: 'map_marker_' + marker.kind,
          iconHighlight: 'map_marker_' + marker.kind + '_active',
          draggable: editable,
          title: marker.name || markerKindOptions[kind],
          marker: marker,
        },
        geometry: {
          coordinates: [parseFloat(marker.longitude), parseFloat(marker.latitude)],
          type: 'Point',
        },
      }
      mapboxMarkers.features.push(mapboxMarker)
    }
  }
  return mapboxMarkers
}

const getMarkerIcon = (crag, active) => {
  const {boulder_count, sport_count, trad_n_p_count, dws_count} = crag
  if (sport_count > trad_n_p_count && sport_count > boulder_count && sport_count > dws_count) {
    return active ? 'map_marker_sport_active' : 'map_marker_sport'
  } else if (dws_count > sport_count && dws_count > boulder_count && dws_count > trad_n_p_count) {
    return active ? 'map_marker_dws_active' : 'map_marker_dws'
  } else if (trad_n_p_count > sport_count && trad_n_p_count > boulder_count && trad_n_p_count > dws_count) {
    return active ? 'map_marker_trad_active' : 'map_marker_trad'
  }
  return active ? 'map_marker_boulder_active' : 'map_marker_boulder'
}

export const getVisibleCrags = (map, markers, crags) => {
  const bounds = map.getBounds().toArray()
  const poly = bboxPolygon([bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1]])
  const visibleCrags = []
  for (const ind in markers.features) {
    try {
      const p = point(markers.features[ind].geometry.coordinates)
      if (booleanPointInPolygon(p, poly)) {
        visibleCrags.push(crags[markers.features[ind].properties.crag.id])
      }
    } catch (e) {
      console.log('Invalid crag marker', markers.features[ind], e);
    }
  }
  if (visibleCrags.length > 50 && map.getZoom() <= 6) {
    return []
  }
  const center = point(map.getCenter().toArray())
  visibleCrags.sort((a, b) => {
    const distanceA = distance(center, point([parseFloat(a.longitude), parseFloat(a.latitude)]))
    const distanceB = distance(center, point([parseFloat(b.longitude), parseFloat(b.latitude)]))
    return distanceA > distanceB ? 1 : distanceB > distanceA ? -1 : 0
  })
  return visibleCrags.slice(0, 50)
}

export const getVisibleOtherMarkers = (map, markers) => {
  if (map.getZoom() <= 6) {
    return []
  }
  const bounds = map.getBounds().toArray()
  const poly = bboxPolygon([bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1]])
  const markerIds = []
  Object.entries(markers.features).forEach(([markerId, mapboxMarker]) => {
    const p = point(mapboxMarker.geometry.coordinates)
    if (markerIsCommercial(mapboxMarker.properties.kind) && booleanPointInPolygon(p, poly)) {
      markerIds.push(markerId)
    }
  })
  return markerIds
}

export const saveMarker = async (mapboxMarker, updateData, map, source) => {
  const data = new FormData()
  data.append('map_marker[name]', updateData.name)
  if (updateData.info) data.append('map_marker[info]', updateData.info)
  data.append('map_marker[kind]', updateData.kind)
  if (updateData.link) data.append('map_marker[website_url]', updateData.link)
  data.append('map_marker[latitude]', updateData.lat)
  data.append('map_marker[longitude]', updateData.lng)
  data.append('map_marker[location_id]', updateData.location_id)
  if (markerIsCommercial(updateData.kind)) {
    if (updateData.email) data.append('map_marker[email]', updateData.email)
    if (updateData.address) data.append('map_marker[address]', updateData.address)
    if (updateData.openingHours) data.append('map_marker[gym_opening_hours]', updateData.openingHours)
    if (updateData.distanceToCrag) data.append('map_marker[distance_to_crag]', updateData.distanceToCrag)
    if (updateData.urlBookingCom) data.append('map_marker[url_bookingcom]', updateData.urlBookingCom)
    else if (updateData.urlAirbnb) data.append('map_marker[url_airbnb]', updateData.urlAirbnb)
    else if (updateData.urlOtherBooking) data.append('map_marker[url_otherbooking]', updateData.urlOtherBooking)
    if (updateData.photos[0]) data.append('map_marker[photo]', updateData.photos[0])
    if (updateData.deletePhotos && updateData.deletePhotos.length > 0) {
      const deletePhotos = updateData.deletePhotos.reduce((acc, p) => `${acc},${p}`, '')
      data.append('delete_photos', deletePhotos.substr(1))
    }
  }
  try {
    let result = null
    if (updateData.id) {
      result = await axios.patch(`/api/web01/map_markers/${updateData.id}`, data)
    } else {
      result = await axios.post('/api/web01/map_markers', data)
    }
    mapboxMarker.properties.marker = result.data.map_marker
    mapboxMarker.properties.icon = 'map_marker_' + mapboxMarker.properties.marker.kind
    mapboxMarker.geometry.coordinates = [parseFloat(updateData.lng), parseFloat(updateData.lat)]
    mapboxMarker.properties.title = updateData.name
  } catch (e) {
    if (updateData.id) {
      mapboxMarker.geomerty.coordinates = [
        parseFloat(mapboxMarker.properties.marker.longitude),
        parseFloat(mapboxMarker.properties.marker.latitude),
      ]
    }
  }
  await addMarker(map, source, mapboxMarker)
}

export const deleteMarker = async (mapboxMarker, map, source, id) => {
  removeMarker(map, source, id)
  try {
    await axios.delete(`/api/web01/map_markers/${mapboxMarker.properties.marker.id}`)
  } catch (e) {
  }
}

export const markerIsCommercial = kind => {
  return ['accommodation', 'climbing_shop', 'climbing_guide', 'gym'].includes(kind)
}

export const markerKindOptions = {
  parking_space: 'Parking',
  swim: 'Swim',
  mtb: 'MTB',
  cycle: 'Cycle',
  cafe: 'Cafe',
  restaurant: 'Restaurant',
  bank: 'Bank',
  shop: 'Shop',
  hotel: 'Hotel',
  sightseeing: 'Sightseeing',
  ski: 'Ski',
  camp: 'Camp',
  well: 'Well',
  wc: 'WC',
  bus_stop: 'Bus stop',
  accommodation: 'Accommodation',
  climbing_shop: 'Climbing shop',
  climbing_guide: 'Climbing guide',
  gym: 'Gym',
}

export const addSource = (map, crags = false, others = false, cragMarkers = null, otherMarkers = null) => {
  const clusterRadius = 50

  if (others) {
    map.addSource('other-markers', {
      type: 'geojson',
      data: otherMarkers,
      cluster: true,
      clusterMaxZoom: 10,
      clusterRadius: clusterRadius,
    })

    map.addLayer({
      id: 'other-clusters',
      type: 'circle',
      source: 'other-markers',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#D30018',
        'circle-radius': 15,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#2C3117',
      },
    })

    map.addLayer({
      id: 'other-cluster-count',
      type: 'symbol',
      source: 'other-markers',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 10,
      },
      paint: {
        'text-color': 'white',
      },
    })

    map.addLayer({
      id: 'unclustered-other',
      type: 'symbol',
      source: 'other-markers',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': '{icon}',
        'icon-size': 1,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    })
  }
  if (crags) {
    map.addSource('crag-markers', {
      type: 'geojson',
      data: cragMarkers,
      cluster: true,
      clusterMaxZoom: 10,
      clusterRadius: clusterRadius,
    })

    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'crag-markers',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#F2DE00',
        'circle-radius': 15,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#2C3117',
      },
    })

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'crag-markers',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    })

    map.addLayer({
      id: 'unclustered-crag',
      type: 'symbol',
      source: 'crag-markers',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': '{icon}',
        'icon-size': 1,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    })
  }
  map.addSource('highlighted', {
    type: 'geojson',
    data: {type: 'FeatureCollection', features: []},
  })

  map.addLayer({
    id: 'highlighted-marker',
    type: 'symbol',
    source: 'highlighted',
    layout: {
      'icon-image': '{icon}',
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  })
}

export const editSource = (map, crags, others) => {
  if (others) {
    map.getSource('other-markers').setData(others)
  } else {
    map.getSource('crag-markers').setData(crags)
  }
}

export const removeSource = (map, crags = false, others = false) => {
  if (crags) {
    map.removeLayer('clusters')
    map.removeLayer('unclustered-crag')
    map.removeLayer('cluster-count')
    map.removeSource('crag-markers')
  }
  if (others) {
    map.removeLayer('other-clusters')
    map.removeLayer('other-cluster-count')
    map.removeLayer('unclustered-other')
    map.removeSource('other-markers')
  }
  map.removeLayer('highlighted-marker')
  map.removeSource('highlighted')
}

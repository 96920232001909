import React from 'react';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import configureStore from '../common/store';
import { reducer as formReducer } from 'redux-form';
import feedReducer from '../feed/reducer';
import FeedContainer from '../feed/Container';

const CragFeedApp = (railsProps) => {

  const reducer = combineReducers({
    feed: feedReducer(
      JSON.parse(railsProps.currentClimber),
      railsProps.photoUrl,
      railsProps.apiUrl
    ),
    form: formReducer
  });

  return (
    <Provider store={configureStore(reducer)}>
      <FeedContainer
        showFeedForm={false}
        fetchUrlSuffix={`crags/${railsProps.cragId}/feed_items`}
      />
    </Provider>
  );
};

export default props => <CragFeedApp {...props} />;

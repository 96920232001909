import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore, { configurePersistStore } from "../common/store";
import routeListReducer from "./reducer";
import userReducer from "../user/reducer";
import { PersistGate } from "redux-persist/lib/integration/react";
import Container from "./Container";
import { BrowserRouter as Router, Route } from "react-router-dom";

class RouteListApp extends Component {
  constructor(props) {
    super(props);
    const reducer = {
      routeList: routeListReducer(
        this.props.routes,
        this.props.noImageUrl,
        this.props.area
      ),
      user: userReducer()
    };

    const { store, persistor } = configurePersistStore("user", reducer, null, [
      "user"
    ]);
    this.state = {
      store: store,
      persistor: persistor
    };
  }
  render() {
    return (
      <Provider store={this.state.store}>
        <PersistGate persistor={this.state.persistor}>
          <Router>
            <Route path="/" component={Container} />
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default RouteListApp;

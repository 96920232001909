import React, { Component } from 'react';
import { debounce } from 'throttle-debounce';
import onClickOutside from 'react-onclickoutside';
import Location from './Location';

class LocationResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLocationResults: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLocationClick = this.handleLocationClick.bind(this);
    this.search = debounce(500, this.search);
  }

  handleClickOutside() {
    this.setState({
      showLocationResults: false,
    });
  }

  handleInputChange(e) {
    this.setState({
      showLocationResults: e.target.value,
    });
    this.props.changeLocation(e.target.value);
    this.search(e.target.value);
  }

  handleLocationClick(location, locationId) {
    this.setState({
      showLocationResults: false,
    });
    this.props.changeLocation(location, locationId);
  }

  search(query) {
    if (query.length > 1) {
      this.props.searchLocations(query);
    }
  }

  render() {
    const {
      searchedLocations,
      location,
      changeLocation,
    } = this.props;

    const style = {
      position: 'absolute',
      width: '300px',
    };

    return (
      <div className="form-group">
        <label>Home city, area or country</label>
        <input className="form-control" type="text" value={location} onChange={this.handleInputChange} />
        {this.state.showLocationResults && (
          <div className="autocomplete-suggestions" style={style}>
            <div className="items-container">
              {searchedLocations.map(searchedLocation => (
                <Location
                  {...searchedLocation}
                  searchText={location}
                  key={searchedLocation.searchable_id}
                  changeLocation={this.handleLocationClick}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(LocationResults);

import React, {Component} from 'react';
import CommentsContainer from '../common/containers/CommentsContainer';

class RouteComments extends Component {

  componentDidMount() {
    this.props.fetchComments(`${this.props.apiUrl}by_route/${this.props.routeId}`, 0);
  }

  render() {
    const {
      apiUrl,
      routeId,
      currentClimber,
      comments,
      isEditing,
      editable,
      fetching,
      editableCommentId,
      fetchComments,
      addCommentPending
    } = this.props;

    return (
      <div className="post">
        <div className="miniheader">COMMENTS</div>
        <CommentsContainer
          comments={comments}
          editable={editable}
          currentClimber={currentClimber}
          refreshable={true}
          feedItemId={routeId}
          commentableId={routeId}
          fetchComments={() => fetchComments(`${apiUrl}by_route/${routeId}`, 0)}
          type="Route"
          isEditing={isEditing}
          fetching={fetching}
          displayCommentsLoading={true}
          editableCommentId={editableCommentId}
          addCommentPending={addCommentPending}
        />
      </div>
    );
  }
}

export default RouteComments;

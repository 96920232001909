import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Onboarding from './components/Onboarding';
import {
  continueOnboarding,
  changeGenreSelected,
  changeGrade,
  changeGradingSystem,
  changeLocation,
  changeSex,
  toggleFollowEnabled,
  searchClimbers,
  searchLocations,
  toggleFollow,
  fetchClimbersByLocation,
} from './actions';

const setupFollows = (searchedClimbers, followedClimbers) => (
  searchedClimbers.map((searchedClimber) => {
    for (let i = 0; i < followedClimbers.length; i += 1) {
      if (searchedClimber.id === followedClimbers[i].followee_id) {
        return { ...searchedClimber, followed: true };
      }
    }
    return searchedClimber;
  })
);

const mapStateToProps = state => ({
  climber: state.climber,
  grades: state.grades,
  gradingSystems: state.gradingSystems,
  following: state.following,
  searchedClimbers: setupFollows(state.searchedClimbers, state.following),
  searchedLocations: state.searchedLocations,
  showLocationResults: state.showLocationResults,
  searchDone: state.searchDone,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  continueOnboarding,
  changeGenreSelected,
  changeGrade,
  changeGradingSystem,
  changeLocation,
  changeSex,
  toggleFollowEnabled,
  searchClimbers,
  searchLocations,
  toggleFollow,
  fetchClimbersByLocation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);

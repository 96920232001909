import * as types from "../common/actionTypes";

export const updategradeMinInt = gradeMinInt => dispatch => {
  dispatch({
    type: types.UPDATE_GRADE_MIN,
    payload: gradeMinInt
  });
};

export const updategradeMaxInt = gradeMaxInt => dispatch => {
  dispatch({
    type: types.UPDATE_GRADE_MAX,
    payload: gradeMaxInt
  });
};

export const updateArea = area => dispatch => {
  dispatch({
    type: types.UPDATE_AREA,
    payload: area
  });
};
export const updateOrderBy = orderBy => dispatch => {
  dispatch({
    type: types.UPDATE_ORDER_BY,
    payload: orderBy
  });
};

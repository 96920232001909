import React from 'react';
import Crag from './Crag';
import CommercialMarker from "./CommercialMarker";

const List = (props) => {
  const { mapbox, visible, crags, commercialMarkers, icons, onCragMouseEnter, onCragMouseLeave, commercialMarkerClick, selectedCrag, cragPopupVisible, newTab } = props;

  const classNames = 'full-height list';
  const style = visible ? { display: 'block' } : {};

  return (
    <div className="controls-page search" >
      <div className="controls-content results" style={{ maxHeight: '100%' }}>
        {crags && crags.length === 0 && commercialMarkers && commercialMarkers.length === 0 && (
          <div className="help-label">Zoom in and explore the rock climbing topo map. 🌍</div>
        )}
        {crags && crags.map(crag => (
          <Crag key={crag.id} {...crag} newTab={newTab} onMouseEnter={onCragMouseEnter} onMouseLeave={onCragMouseLeave} />
        ))}
        {commercialMarkers && commercialMarkers.length > 0 && (
          <div className="travel-information">
            <h4 className="miniheader">Recommended services for climbers</h4>
            {commercialMarkers.map(marker => (
              <CommercialMarker key={marker.properties.id} {...marker.properties.marker} mapboxMarker={marker} selectedCrag={selectedCrag} icon={icons[marker.properties.kind].normal} mapbox={mapbox} cragPopupVisible={cragPopupVisible} onClick={() => commercialMarkerClick(marker)} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default List;

$("#new_csv_import").livequery(function () {
  $('#crag-radio-button').click(event => {
    $('#js-csv-format').text('id;param_id;name;latitude;longitude');
    $('#import-session-id-select').hide();
    $('#import-session-id-text').show();
  });

  $('#sector-radio-button').click(event => {
    $('#js-csv-format').text('id;crag_id;name;latitude;longitude');
    $('#import-session-id-select').show();
    $('#import-session-id-text').hide();
  });
  $('#route-radio-button').click(event => {
    $('#js-csv-format').text('id;sector_id;crag_id;name;grade;grade_info;genre;height;setting_year;route_setter;editors_description');
    $('#import-session-id-select').show();
    $('#import-session-id-text').hide();
    $('#js-csv-format').css({
      'white-space': 'pre-wrap',
      'word-wrap': 'break-word'
    });
  });
  $('#topo-image-radio-button').click(event => {
    $('#js-csv-format').text('id;sector_id;image_url;order');
    $('#import-session-id-select').show();
    $('#import-session-id-text').hide();
  });
  $('#topo-item-radio-button').click(event => {
    $('#js-csv-format').text('route_id;topo_image_id;json;order');
    $('#import-session-id-select').show();
    $('#import-session-id-text').hide();
  });
});
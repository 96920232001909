(function($) {
  
  var default_css = {position: 'absolute', top: 0, left: 0, 'z-index': 1049, opacity: '0.7'};
  
  function new_overlay(options) {
    return $('<div class="blockUI"></div>').css($.extend({}, default_css, options||{}));
  }
  
  $.fn.block = function(options) {
    options = options || {};
    return this.each(function() {
      var $this = $(this);
      if ( $this.children('.blockUI').is(':visible') ) return false;
      var pos = $this.position();
      $this.append(new_overlay($.extend({}, options, {top: pos.top, left: pos.left, width: $this.outerWidth(true), height: $this.outerHeight(true)})));
    });
  }
  $.fn.unblock = function() {
    return this.each(function() {
      $(this).children('.blockUI').remove();
    });
  }
  $.blockUI = function(options) {
    if ( $.blockUI.active() ) return false;
    var overlay = new_overlay(options);
    // BEGIN IFRAME HACK - all windows browsers need the iframe to cover flash content
    var ie_overlay = $('<iframe id="ie_overlay" style="z-index:1048;position:absolute;top:0;left:0;border:none"></iframe>').css({opacity:0});
    $.blockUI.resize(ie_overlay);
    $('body').append(ie_overlay);
    // firefox on windows needs this:
    if ( $.browser.mozilla && navigator.appVersion.indexOf("Win")!=-1 ) $('#flowplayer').hide();
    // END IFRAME HACK
    $.blockUI.resize(overlay);
    $('body').append(overlay);
		$(window).resize(function () { $.blockUI.resize(); });
		$(document).bind('afterReveal.facebox', function(){ $.blockUI.resize(); });
  }
  $.unblockUI = function() {
    $('.blockUI').fadeOut(function(){
      $('#ie_overlay').remove();
      $('.blockUI').remove();
    });
    // firefox on windows needs this:
    if ( $.browser.mozilla && navigator.appVersion.indexOf("Win")!=-1 ) $('#flowplayer').show();
  }
  $.blockUI.resize = function(overlay) {
    overlay = overlay || $('body > .blockUI');
    overlay.width('100%');
    overlay.height($.dimensions.max('height'));
  }
  $.blockUI.active = function() {
    return $('body > .blockUI').is('div');
  }
})(jQuery);

(function(){ // just a namespace

  var is_on = true;
  var lower_tooltip_layer = [];
  var upper_tooltip_layer = [];
  var contents = {};

  function start_showing(url) {
    if ( is_on ) {
      $.facebox.prepare_tooltip(contents[url], {});
      $('#map').bind('mousemove.marker', function(e){ $.facebox.show_tooltip(e, false); });
    }
  }

  function stop_showing() {
    $('#map').unbind('mousemove.marker');
    $.facebox.hide_tooltip();
  }

  window.MapTooltip = function(selector, url) {

    function both_tooltip_layers() {
      return lower_tooltip_layer.concat(upper_tooltip_layer);
    }

    function active(arr) {
      return ($.inArray(url, (arr || both_tooltip_layers())) != -1);
    }

    function bind(g_marker) {
      if ( !contents[url] ) return;
      var is_lower = !!g_marker.strokeColor;
      var current_layer = (is_lower ? lower_tooltip_layer : upper_tooltip_layer);
      google.maps.event.addListener(g_marker, 'mouseover', function(e){
        current_layer.push(url);
        if ( is_lower && both_tooltip_layers().length > 1 ) return;
        start_showing(url);
      });
      google.maps.event.addListener(g_marker, 'mouseout', function(e){
        current_layer.splice($.inArray(url, current_layer), 1);
        if ( both_tooltip_layers().length == 0 ) stop_showing();
        else if ( !active() ) start_showing(both_tooltip_layers().pop());
      });
      google.maps.event.addListener(g_marker, 'click', function(e){
        if ( window.location.pathname.match('.embeddable') && window.location.search.match('popup=on') ) {
          window.open(url);
        } else {
          top.location = url;
        }
        e.stop();
      });
    }

    // Constructor
    if ( !url ) { url = selector; selector = '#' + url.replace(/\//g, '_'); }
    if ( $(selector).size() > 0 ) {
      if ( $(selector + ' a').size() == 1 ) url = $(selector + ' a').attr('href');
      contents[url] = $(selector).clone().removeAttr('id');
    }

    return {
      bind: bind
    };

  }

  window.MapTooltip.on = function(){
    app.map.each_marker('make_responsive');
    lower_tooltip_layer.splice(0, lower_tooltip_layer.length);
    upper_tooltip_layer.splice(0, upper_tooltip_layer.length);
    is_on = true;
  };

  window.MapTooltip.off = function(draggable){
    app.map.each_marker_except(draggable, 'make_unobtrusive');
    is_on = false;
    stop_showing();
  };

})();

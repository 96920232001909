import React, { Component } from 'react';
import GradeBarChart from "./GradeBarChart";
import {convertGrade} from "../common/util";

const frenchGradeGroups = [
    [200, 4.1],
    [300, 4.2],
    [400, 5.2],
    [500, 6.1],
    [600, 6.2],
    [700, 6.3],
    [800, 7.1],
    [900, 7.2],
    [1000, 7.3],
    [1100, 8.1],
    [1200, 8.2],
    [1300, 8.3],
    [1400, 9.1],
    [1500, 9.2],
    [1600, 9.3],
];

const usGradeGroups = [
    [200, 4.1],
    [300, 5.1],
    [380, 6.1],
    [450, 6.2],
    [550, 6.3],
    [650, 6.4],
    [750, 7.1],
    [850, 7.2],
    [950, 7.3],
    [1050, 7.4],
    [1150, 8.1],
    [1250, 8.2],
    [1350, 9.1],
    [1600, 9.2],
];

const huecoGradeGroups = [
    [300, 5.1],
    [400, 5.2],
    [450, 6.1],
    [550, 6.2],
    [650, 6.3],
    [750, 7.1],
    [800, 7.2],
    [850, 7.3],
    [900, 7.4],
    [950, 8.1],
    [1000, 8.2],
    [1050, 8.3],
    [1100, 8.4],
    [1150, 8.5],
    [1200, 9.1],
    [1250, 9.2],
    [1600, 9.3],
];
const australianGradeGroups = [
    [200, 4.1],
    [275, 4.2],
    [300, 5.1],
    [380, 5.2],
    [450, 6.10],
    [550, 6.2],
    [650, 6.3],
    [750, 7.1],
    [850, 7.2],
    [950, 7.3],
    [1000, 8.1],
    [1100, 8.2],
    [1200, 8.3],
    [1300, 9.1],
    [1400, 9.2],
    [1600, 9.3],
];
const uiaaGradeGroups = [
    [200, 4.1],
    [250, 4.2],
    [300, 5.1],
    [350, 5.2],
    [380, 5.3],
    [400, 5.4],
    [500, 6.1],
    [550, 6.2],
    [700, 7.1],
    [800, 7.2],
    [900, 7.3],
    [950, 8.1],
    [1100, 8.2],
    [1200, 8.3],
    [1300, 9.1],
    [1350, 9.2],
    [1500, 9.3],
    [1600, 9.3]
];
class GenreSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gradeCounts: [],
            highlightSentence: ''
        };
    }

    getSentence(type, summary, old_summary) {
        switch (type) {
            case 'routes':
                return `Cool! You sent ${summary['routes'] - old_summary['routes']} routes more than last year`;
            case 'onsights':
                return `Nice! You did ${summary['onsights'] - old_summary['onsights']} onsights more than last year! 💪`;
            case 'flashes':
                return `Good job! You did ${summary['flashes'] - old_summary['flashes']} flash ascents more than last year! 💪`;
            case 'redpoints':
                return `Wow! You did ${summary['redpoints'] - old_summary['redpoints']} redpoints more than last year! 💪`;
            default:
                if(summary['favouriteLocation'] != null) {
                    return `You did most of your ascents (${summary['favouriteLocation'][1]}) in ${summary['favouriteLocation'][0]}. ❤️`;
                } else {
                    return '';
                }
        }
    }

    getCountOfLocations(ascents){
        let locations = {};
        ascents.forEach((item) => {
            if(typeof locations[item['crag_name']] == 'undefined'){
                locations[item['crag_name']] = 0;
            }
            locations[item['crag_name']] += 1
        });
        return locations;
    }

    filterByGenre(ascents, genre){
        return ascents.filter((ascent) => {
            return ascent.route_genre == genre;
        });
    }

    getFavouriteLocation(ascents){
        let locations = Object.entries(this.getCountOfLocations(ascents));
        return locations.length > 0 ? locations.reduce((a, b) => {
            return (a[1] > b[1] ? a : b);
        }) : null;
    }

    getSummary(ascents) {
        let favouriteLocation = this.getFavouriteLocation(ascents)
        return {
            routes: ascents.length,
            onsights: ascents.filter((ascent) => { return ascent.ascent_type == 'onsight'} ).length,
            flashes: ascents.filter((ascent) => { return ascent.ascent_type == 'flash'} ).length,
            redpoints: ascents.filter((ascent) => { return ascent.ascent_type == 'redpoint'} ).length,
            favouriteLocation: favouriteLocation
        }
    }

    getComparisonValue(current_val, old_val){
        let percent = old_val > 0 ? current_val / old_val : 0;
        let diff = current_val - old_val;
        return percent * diff;
    }

    getHighlightSentence(){
        const summary = this.getSummary(this.filterByGenre(this.props.ascents, this.props.genre));
        const old_summary = this.getSummary(this.filterByGenre(this.props.last_year_ascents, this.props.genre));
        let comparisonArr = [];

        let route_diff = this.getComparisonValue(summary['routes'], old_summary['routes']);
        let flash_diff = this.getComparisonValue(summary['flashes'], old_summary['flashes']);
        let onsight_diff = this.getComparisonValue(summary['onsights'], old_summary['onsights']);
        let redpoint_diff = this.getComparisonValue(summary['redpoints'], old_summary['redpoints']);

        comparisonArr.push(['routes', route_diff]);
        comparisonArr.push(['flashes', flash_diff]);
        comparisonArr.push(['onsights', onsight_diff]);
        comparisonArr.push(['redpoints', redpoint_diff]);

        let max = comparisonArr.reduce((a, b) => {
            return (a[1] > b[1] ? a : b);
        });
        let sentence_type = max[1] > 0 ? max[0] : 'location';

        return this.getSentence(sentence_type, summary, old_summary);
    }

    getBgImage(){
        let ascents = this.props.ascents.filter((ascent) => {
            return ascent.route_genre == this.props.genre && ascent.crag_photo_url != '';
        });
        if(ascents.length > 0) {
            let location_name = this.getFavouriteLocation(ascents)[0];
            let ascent = ascents.find((a) => {
                return a.crag_name == location_name;
            });
            return ascent.crag_photo_url;
        }
        return '';
    }

    componentDidMount() {
        this.initGradeCounts();

        this.setState({
            highlightSentence: this.getHighlightSentence(),
            bgImage: this.getBgImage()
        });
    }

    gradesHashWithGradeGroup(){
        let gradeGroups = frenchGradeGroups;
        if(this.props.gradingSystem == 'French' || this.props.gradingSystem == 'Font') {
            gradeGroups = frenchGradeGroups;
        } else if(this.props.gradingSystem == 'US') {
            gradeGroups = usGradeGroups;
        } else if(this.props.gradingSystem == 'Hueco') {
            gradeGroups = huecoGradeGroups;
        } else if(this.props.gradingSystem == 'Australian') {
            gradeGroups = australianGradeGroups;
        } else if(this.props.gradingSystem == 'UIAA') {
            gradeGroups = uiaaGradeGroups;
        }

        return Object.entries(GRADES_HASH).map((item) => {
            let grade_group = 0;
            grade_group = gradeGroups.filter((gg_item) => {
                return parseInt(item[0]) < gg_item[0];
            })[0];
            let arr = item;
            if(grade_group.length > 0) {
                arr.push(grade_group[1]);
            }
            return arr;
        });
    }

    groupGradeCounts(gradeCounts){
        let grouped = [];
        gradeCounts.forEach((item, index, array) => {
            let lastIndex = grouped.length-1;
            if(grouped.length > 0 && grouped[lastIndex]['grade_group'] == item['grade_group']) {
                grouped[lastIndex]['value'] = grouped[lastIndex]['value'] + item['value'];
                grouped[lastIndex]['label'] = grouped[lastIndex]['label'];
            } else {
                grouped.push(item);
            }
        });
        return grouped;
    }

    initGradeCounts() {
        let gradeCounts = this.gradesHashWithGradeGroup().map((item) => {
            const grade = item[0];
            const grade_group = item[2];
            let count = 0;
            this.props.ascents.forEach((ascent) => {
                if(ascent.route_genre == this.props.genre) {
                    if(ascent.route_grade_int == grade) {
                        count += 1;
                    }
                }
            });
            return { label: convertGrade(grade, this.props.gradingSystem), value: count, grade_int: grade, grade_group: grade_group };
        });

        this.setState({
            gradeCounts: this.groupGradeCounts(gradeCounts).filter((value) => {
                return value['label'] != '?'
            })
        });
    }

    render(){
        return (
            <div>
                <div className={'slide-container'}>
                    <div className={'slide-content'}>
                        <div className={'full-width'}>
                            <h2>{this.props.genre == 'Traditional' ? 'Trad' : this.props.genre} ascents ({this.props.genre_counts[this.props.genre]})</h2>
                            <p>{this.state.highlightSentence}</p>
                            <GradeBarChart chartData={this.state.gradeCounts} />
                            <a className={'btn-continue'} onClick={this.props.onNextClick}>Continue</a>
                        </div>
                    </div>
                </div>
                <div className={'slide__background-photo'} style={{backgroundImage: (this.state.bgImage ? `url('${this.state.bgImage}')` : 'none')}}></div>
            </div>
        );
    }
}

export default GenreSummary;
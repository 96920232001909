import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MapContainer from './components/MapContainer';
import {
  toggleView,
  toggleFiltersVisibility,
  toggleFilter,
  filterMarkers,
  filterAdditionalMarkers,
  setSort,
  sortCrags,
  highlightCrag,
  setMarkerEditPopup,
  setCommercialMarkerPopup,
} from './actions';

const getVisibleCrags = (crags, visibleCrags) => {
  if (!crags || !visibleCrags) {
    return [];
  }
  const cragIds = visibleCrags.split(',');
  return cragIds.map(id => crags[parseInt(id, 10)]);
};

const getVisibleMarkers = (markers, visibleMarkers) => {
  if (!markers || !visibleMarkers) {
    return [];
  }
  const markerIds = visibleMarkers.split(',');
  return markerIds.reduce((acc, id) => {
    if (markers[id]) {
      acc.push(markers[id]);
    }
    return acc;
  }, []);
}

const mapStateToProps = state => ({
  listCrags: getVisibleCrags(state.crag.crags, state.map.visibleCrags),
  listCommercialMarkers: getVisibleMarkers(state.mapbox.otherMarkers.features, state.map.visibleMarkers),
  selectedCrag: state.crag.crags[state.map.selectedCrag],
  cragPopupVisible: state.map.cragPopupVisible,
  sort: state.map.sort,
  sortGrades: state.map.sortGrades,
  view: state.map.view,
  filters: state.map.filters,
  icons: state.map.icons,
  markerEditPopup: state.map.markerEditPopup,
  commercialMarkerPopup: state.map.commercialMarkerPopup,
  mapbox: state.mapbox,
  showList: state.map.showList,
  showFilters: state.map.showFilters,
  newTab: state.map.newTab,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleFilter,
  setSort,
  setMarkerEditPopup,
  setCommercialMarkerPopup,
  toggleView,
  toggleFiltersVisibility,
  filterMarkers,
  filterAdditionalMarkers,
  sortCrags,
  highlightCrag,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);

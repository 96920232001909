import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Nav from "./Nav";
import { syncUser } from "../user/actions";

const mapStateToProps = state => ({
  climber: state.user,
  is_loggedin: state.user.id === undefined ? false : true,
  gradingSystem:
    state.user.grading_system_sport === undefined
      ? "French"
      : state.user.grading_system_sport
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ syncUser }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nav);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Comments from '../components/Comments';
import {
  addComment,
  startCommentEditing,
  editComment,
  deleteComment,
} from '../actions';

const mapDispatchToProps = dispatch => bindActionCreators({
  addComment,
  startCommentEditing,
  editComment,
  deleteComment,
}, dispatch);

export default connect(null, mapDispatchToProps)(Comments);

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TopoValueChart from "./components/TopoValueChart";
import {
  getTopoValueData,
} from "./actions.jsx";

const stateToProps = (state) => ({
  crags: state.topoValueReducerParams.crags,
  topoValue: state.topoValueReducerParams.topoValue,
  paramId: state.topoValueReducerParams.paramId,
  startMonth: state.topoValueReducerParams.startMonth,
  endMonth: state.topoValueReducerParams.endMonth,
  loading: state.topoValueReducerParams.loading,
});

const topoValueDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTopoValueData: getTopoValueData,
    },
    dispatch
  );

export default connect(
  stateToProps,
  topoValueDispatchToProps
)(TopoValueChart);

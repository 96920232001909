import React, { Component } from "react";

class NotificationsMenuComponent extends Component {
  openDropDown() {
    this.props.fetchNotifications(this.props.climberId);
  }

  render() {
    const {
      notifications,
      fetching,
      unopenedCount,
      climberId,
      climberParamid
    } = this.props;

    const notificationsUrl = "/climbers/" + climberParamid + "/notifications/";

    return (
      <div className="dropdown notifications-menu-component">
        <a
          className="dropdown dropdown-toggle nav-item"
          aria-expanded="false"
          data-toggle="dropdown"
          role="button"
          id="notificationsDropdownMenu"
          onClick={this.openDropDown.bind(this)}
        >
          <div className="nav-item-container nav-item-container--notifications">
            <div className="nav-item-icon">
              <i className="glyphicon glyphicon-bell" />
            </div>
            <div className="nav-item-label">Notifications</div>
            {unopenedCount > 0 ? (
              <span className="label-notification_count">{unopenedCount}</span>
            ) : (
              ""
            )}
          </div>
        </a>
        <ul
          className="dropdown-menu dropdown-menu--notifications"
          role="menu"
          aria-labelledby="notificationsDropdownMenu"
        >
          <li className="dropdown-custom-container">
            <a href={notificationsUrl} className="pull-right read-more">
              View more
            </a>
            <div className="miniheader">Notifications</div>
            <div className="notification-items items-list items-list--shrinked">
              {fetching ? (
                <div>Loading...</div>
              ) : notifications.length > 0 ? (
                notifications.map(notification => (
                  <a
                    className="notification-item items-list__container"
                    href={notification.url}
                    key={notification.id}
                  >
                    <div className="items-list__item items-list__item--thumbnail">
                      <img
                        src={notification.photo_url}
                        className="climber-thumb"
                      />
                    </div>
                    <div className="items-list__item items-list__item--details">
                      <div className="description">{notification.title}</div>
                    </div>
                  </a>
                ))
              ) : (
                <div className="no-items-text">You have no Notifications</div>
              )}
            </div>
            <div className="close-footer">
              <a className="btn btn-link btn-block close-footer-btn">Close</a>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

NotificationsMenuComponent.defaultProps = {
  notifications: [],
  fetching: false
};

export default NotificationsMenuComponent;

import React, { Component } from "react";
import { steepnessHash } from "../../common/util.js";
import DropDownFilter from '../../common/components/DropDownFilter';
import ToggleFilterButton from "../../common/components/ToggleFilterButton";
import TagSpan from "../../common/components/TagSpan";

class SteepnessButton extends Component {
  render() {
    const { changeFilters, filters } = this.props;
    const btnClass = Object.keys(steepnessHash).some(key =>
      filters.includes(key)
    )
      ? "btn btn-primary"
      : "btn btn-default";
    return (
      <DropDownFilter
        titleButton={"Steepness"}
        titleHeader={"Steepness"}
        titleClose={"Apply"}
        showDropdown={this.props.showDropdown}
        hideDropdown={this.props.hideDropdown}
        btnClass={btnClass}
      >
        <ul className="premium-search-filter__checkbox-container">
          {Object.keys(steepnessHash).map((key, i) => {
            return (
              <ToggleFilterButton
                active={filters.includes(key)}
                key={i}
                value={key}
                onChange={changeFilters}
              >
                <TagSpan
                  spanClass={key}
                  iconClass={key}
                  title={steepnessHash[key]}
                />
                {steepnessHash[key]}
              </ToggleFilterButton>
            );
          })}
        </ul>
      </DropDownFilter>
    );
  }
}

export default SteepnessButton;

import * as types from '../../common/actionTypes';

const reducer = ({ markers }) => {
  const initialState = {
    markers,
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      default:
        return state;
    }
  };
};

export default reducer;

import React, { Component } from "react";
import Container from "./Container";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import premiumSearchReducer from "./reducer.jsx";
import { configurePersistStore } from "../common/store";

class PremiumSearchApp extends Component {
  constructor(props) {
    super(props);
    const reducer = {
      premiumSearchParams: premiumSearchReducer()
    };
    const { store, persistor } = configurePersistStore(
      "search",
      reducer,
      null,
      ["premiumSearchParams"]
    );
    this.state = {
      persistor: persistor,
      store: store
    };
  }
  render() {
    return (
      <Provider store={this.state.store}>
        <PersistGate persistor={this.state.persistor}>
          <Container
            areas={this.props.areas}
            gradingSystem={this.props.gradingSystem}
            tagline={this.props.tagline}
          />
        </PersistGate>
      </Provider>
    );
  }
}
export default PremiumSearchApp;

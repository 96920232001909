import map_marker_boulder from '../../../../javascript/images/map_marker_boulder.png'
import map_marker_boulder_active from '../../../../javascript/images/map_marker_boulder_active.png'
import sector_end from '../../../../javascript/images/sector_end.png'
import sector_end_active from '../../../../javascript/images/sector_end_active.png'
import parking from '../../../../javascript/images/parking.png'

window.MapMarkerViews = {
  saved: '<h3>Thanks for your contribution!</h3><p>The map marker was successfully saved.</p>',
  error: '<h3>Sorry, something went wrong.</h3><p>Please, reload the page and try again.</p>'
};

window.MapMarkerIcons = {
  boulder: function() {
    return {
      url: map_marker_boulder,
      url_default: map_marker_boulder,
      url_highlight: map_marker_boulder_active,
      size: new google.maps.Size(40, 40),
      //anchor: new google.maps.Point(40, 20)
    };
    // icon.dragCrossAnchor = new google.maps.Point(7, 0);
    // icon.maxHeight = 14; // how much it is rised when dragging
  },
  sector_end: function() {
    return {
      url: sector_end,
      url_default: sector_end,
      url_highlight: sector_end_active,
      size: new google.maps.Size(30, 20),
      anchor: new google.maps.Point(15, 10)
    };
    // icon.dragCrossAnchor = new google.maps.Point(8, 8);
    // icon.maxHeight = 17; // how much it is rised when dragging
  },
  sector_line: function() {
    return {
      strokeColor: '#2F261C',
      strokeWeight: 10,
      strokeOpacity: 0.75
    };
  },
  parking_space: function() {
    return {
      url: parking,
      size: new google.maps.Size(22, 22),
      anchor: new google.maps.Point(11, 11)
    };
    // icon.dragCrossAnchor = new google.maps.Point(7, 0);
    // icon.maxHeight = 14; // how much it is rised when dragging
  }
};

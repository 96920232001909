import * as types from "../common/actionTypes";
import * as apiEndpoints from "../common/apiEndpoints";
import axios from "axios";

let lastFetch = 0;

export const syncUser = () => async dispatch => {
  let nextFetch = lastFetch + 1000 * 60 * 3; // 3min flood prevention
  if (nextFetch < Date.now()) {
    lastFetch = Date.now();
    const user = await fetchUser();
    const payload = user ? user : {};
    dispatch({
      type: types.SET_USER,
      payload: payload
    });
  }
};

export const fetchUser = async () => {
  let climber = null;
  let data;
  const user_param_id = $.cookie("js_user");
  if (user_param_id) {
    data = await axios.get(apiEndpoints.climber_path(user_param_id));
    climber = await data.data.climber;
  }
  try {
    return await climber;
  } catch (e) {
    return null;
  }
};

import * as types from '../../common/actionTypes';

const reducer = railsProps => {
  const getCrags = () => {
    try {
      return JSON.parse(railsProps.crags).crags;
    } catch(e) {
      return railsProps.crags;
    }
  };

  const initialState = {
    crags: getCrags().reduce((result, crag) => {
      if (crag.latitude) {
        result[crag.id] = crag;
      }
      return result;
    }, {}),
    fetching: false,
    fetched: false,
    error: null,
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case types.STORE_CRAG_DETAILS:
        if (Object.keys(state.crags).length === 0) {
          return {
            ...state,
            crags: payload,
          };
        }
        return {
          ...state,
          crags: {
            ...state.crags,
            ...payload,
          },
        };
      default:
        return state;
    }
  };
};

export default reducer;

import React, { Component } from 'react'
import NameField from './NameField'
import RouteProperty from '../../common/components/RouteProperty'
import StarSpan from '../../common/components/StarSpan'
import { convertGrade } from '../../common/util.js'
import { filterHash } from '../../common/util.js'

class TableRow extends Component {
  render() {
    const { obj, findPhoto, noImageUrl, gradingSystem } = this.props

    const localizedGrade = convertGrade(obj.grade_int, gradingSystem)

    return (
      <tr role="row">
        <td>
          <NameField obj={obj} noImageUrl={noImageUrl} photo={findPhoto} localizedGrade={localizedGrade} />
        </td>
        <td className="hidden-xs">{localizedGrade}</td>
        <td className="hidden-xs">{obj.genre}</td>
        <td className="hidden-xs">{obj.ascents_done_count}</td>
        <td className="hidden-xs">
          <div className="tags">
            {Object.keys(filterHash).map((key, index) => {
              return obj[key] == true ? <RouteProperty key={index} type={key} name={filterHash[key]} /> : ''
            })}
          </div>
        </td>
        <td className="hidden-xs">
          <StarSpan rating={obj.rating} spanClass={'stars star-span'} />
        </td>
        <td className="hidden-xs">
          <a className="lfont" href={'/crags/' + obj.crag_param_id}>
            {obj.crag_name}
          </a>
        </td>
      </tr>
    )
  }
}

export default TableRow

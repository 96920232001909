import React, { PropTypes, Component } from 'react';
import FeedItems from './FeedItems';
import FeedForm from './FeedForm';

class Feed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteDialogVisible: 0,
    };
  }

  componentDidMount() {
    this.props.fetchFeedItems(this.props.fetchUrl);
  }

  render() {
    const {
      currentClimber,
      feedItems,
      fetching,
      fetched,
      fetchFeedItems,
      fetchUrl,
      toggleFeedItemLike,
      deleteFeedItem,
      addFeedItem,
      fetchComments,
      toggleCommentsVisibility,
      showFeedForm,
      feedItemsReceived,
      addFeedItemPending,
      addCommentPending
    } = this.props;

    const handleLoadMoreClick = () => {
      if (fetched && !fetching) {
        fetchFeedItems(fetchUrl);
      }
    };

    const handleToggleDeleteDialogVisibilityClick = (id) => {
      if (this.state.deleteDialogVisible === id) {
        this.setState({ deleteDialogVisible: 0 });
      } else {
        this.setState({ deleteDialogVisible: id });
      }
    };

    const renderLoadMore = () => {
      if (!fetched) return null;
      if (!currentClimber.paramId) return <p><a href="/login">Sign up</a> to load more</p>;
      if (feedItemsReceived) return <a className="btn btn-block btn-default" onClick={handleLoadMoreClick}>Load more</a>;
      return <p className="text-center">Find other climbers and start following.</p>;
    };

    return (
      <div>
        {showFeedForm &&
          <FeedForm
            currentClimberParamId={currentClimber.paramId}
            onSubmit={values => addFeedItem(values.text)}
            disabled={!fetched || addFeedItemPending}
          />
        }
        {!fetched &&
          <p style={{ margin: '24px' }}>Loading feed items...</p>
        }
        <FeedItems
          currentClimber={currentClimber}
          items={feedItems}
          onLikeClick={data => toggleFeedItemLike(data)}
          onDeleteClick={id => deleteFeedItem(id)}
          fetchComments={fetchComments}
          toggleCommentsVisibility={toggleCommentsVisibility}
          toggleDeleteDialogVisibility={handleToggleDeleteDialogVisibilityClick}
          deleteDialogVisible={this.state.deleteDialogVisible}
          addCommentPending={addCommentPending}
        />
        {renderLoadMore()}
      </div>
    );
  }
}

export default Feed;

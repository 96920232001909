(function ($) {

  $.fn.stickToTop = function (opts) {

    return this.each(function() {
      var $elem = $(this);
      var $spy = $('<div class="stick-to-top__spy" style="height: 0; visibility: hidden;"/>');
      $elem.before($spy);
      var offset = $elem.data('offset');
      var fixed = false;

      var onScroll = function() {
        var pos = $spy.offset().top - $(window).scrollTop();
        if(pos < offset) {
          if(!fixed) {
            $elem.addClass('stick-to-top--fixed');
            $elem.css({
              "top": offset+'px'
            });
            $spy.css('height', $elem.height());
            fixed = true;
          }
        }
        else {
          if(fixed) {
            $elem.removeClass('stick-to-top--fixed');
            $elem.css({
              "top": ''
            });
            $spy.css('height', 0);
            fixed = false;
          }
        }
      };

      onScroll();

      $(window).scroll(function () {
        onScroll();
      });
    });
  };

}(jQuery));
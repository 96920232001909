import React from 'react';

const Climber = (props) => {
  const {
    id,
    name,
    enjoys,
    photo_url,
    followed,
    toggleFollow,
  } = props;

  const handleButtonClick = () => {
    if (followed) {
      toggleFollow(id, 'unfollow');
    } else {
      toggleFollow(id, 'follow');
    }
  };

  return (
    <div className="items-list__container">
      <div className="items-list__item items-list__item--thumbnail">
        <div className="user-image">
          <a>
            <img alt={name} className="climber-thumb" src={photo_url}/>
          </a>
        </div>
      </div>
      <div className="items-list__item items-list__item--details">
        <a className="climber-name">{name}</a>
        <div className="description">{enjoys}</div>
      </div>
      <div className="items-list__item items-list__item--action">
        {followed ? (
          <button className="btn btn-follow btn-following" onClick={handleButtonClick}>Following</button>
        ) : (
          <button className="btn btn-follow" onClick={handleButtonClick}>Follow</button>
        )}
      </div>
    </div>
  );
};

export default Climber;

import React, { Component } from "react";

class TagSpan extends Component {
  render() {
    const { spanClass, iconClass, title } = this.props;
    return (
      <div className="tags">
        <span className={"tag " + spanClass + " input-tag"} title={title}>
          <i className={"icon icon27 icon-" + iconClass} />
        </span>
      </div>
    );
  }
}

export default TagSpan;

import React, { Component } from "react";
import { typesArr } from "../../common/util.js";
import DropDownFilter from '../../common/components/DropDownFilter';
import ToggleFilterButton from "../../common/components/ToggleFilterButton";
import TagSpan from "../../common/components/TagSpan";

class TypeButton extends Component {
  render() {
    const { changeFilters, filters } = this.props;
    const btnClass = typesArr.some(val => filters.includes(val))
      ? "btn btn-primary"
      : "btn btn-default";
    return (
      <DropDownFilter
        titleButton={"Genre"}
        titleHeader={"Genre"}
        titleClose={"Apply"}
        showDropdown={this.props.showDropdown}
        hideDropdown={this.props.hideDropdown}
        btnClass={btnClass}
      >
        <ul className="premium-search-filter__checkbox-container">
          {typesArr.map((value, i) => {
            return (
              <ToggleFilterButton
                active={filters.includes(value)}
                key={i}
                value={value}
                onChange={changeFilters}
              >
                <TagSpan
                  spanClass={"genre"}
                  iconClass={value.toLowerCase()}
                  title={value}
                />
                {value}
              </ToggleFilterButton>
            );
          })}
        </ul>
      </DropDownFilter>
    );
  }
}

export default TypeButton;

export const API_ROOT_PATH = "/api/web01";
export const climber_path = param_id => {
  return `${API_ROOT_PATH}/climbers/${param_id}`;
};
export const climber_notifications_path = param_id => {
  return `${API_ROOT_PATH}/climbers/${param_id}/notifications`;
};
export const search_path = query => {
  return `${API_ROOT_PATH}/search?query=${query}`;
};
export const photo_search_path = ids => {
  return `${API_ROOT_PATH}/search/photos?ids=${ids}`;
};
export const crag_visit_analytics_path = (param_id, crag_ids, start_date, end_date) => {
  return `${API_ROOT_PATH}/teams/${param_id}/analytics/visits?crag_ids=${crag_ids}&start_date=${start_date}&end_date=${end_date}`;
};
export const crag_topo_value_analytics_path = (param_id, crag_ids, start_month, end_month) => {
  return `${API_ROOT_PATH}/teams/${param_id}/analytics/topo_value?crag_ids=${crag_ids}&start_month=${start_month}&end_month=${end_month}`;
};

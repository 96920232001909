(function ($) {
  $.fn.textareaExpand = function (options) {
    return this.filter("textarea").each(function(){
      this.addEventListener('keyup', function() {
        this.style.overflow = 'hidden';
        this.style.height = 0;
        this.style.height = this.scrollHeight + 'px';
      }, false);
    });
  }
})(jQuery);


import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {saveMarker, deleteMarker, markerKindOptions, markerIsCommercial, addMarker, removeMarker} from '../util';

class MarkerEditPopup extends Component {

  constructor(props) {
    super(props);
    const { marker, map } = props;
    this.state = {
      inputs: {
        name: marker.properties.marker.name || 'New marker',
        info: marker.properties.marker.info || '',
        kind: marker.properties.marker.kind || 'parking_space',
        link: marker.properties.marker.link || '',
        lat: marker.geometry.coordinates[1],
        lng: marker.geometry.coordinates[0],
        distanceToCrag: marker.properties.marker.distance_to_crag || '',
        address: marker.properties.marker.address || '',
        email: marker.properties.marker.email || '',
        openingHours: marker.properties.marker.gym_opening_hours || '',
        urlBookingCom: marker.properties.marker.url_bookingcom || '',
        urlAirbnb: marker.properties.marker.url_airbnb|| '',
        urlOtherBooking: marker.properties.marker.url_otherbooking || '',
        photos: [],
      },
      markerPhotos: marker.properties.marker.photos || [],
      deletePhotos: [],
      confirmationDialogVisible: false,
    };
  }

  handleInputChange = (input, e) => {
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        [input]: e.target.value,
      },
    });
  }

  handleOutsideClick = e => {
    if (e.target.className === 'map-popup-container') {
      this.cancel();
    }
  }

  cancel = e => {
    const { marker } = this.props;
    this.props.hide();
  }

  save = e => {
    const { marker, map } = this.props;
    saveMarker(
      marker,
      {
        ...marker.properties.marker,
        ...this.state.inputs,
        deletePhotos: this.state.deletePhotos,
      },
      map,
      'other-markers',
    );
    this.props.hide();
  }

  delete = e => {
    e.preventDefault(); // because reasons
    const { marker, map } = this.props;
    const id = this.props.marker.properties.marker.id;
    deleteMarker(this.props.marker, map, 'other-markers', id);
    this.props.hide();
  }


  deletePhoto = (photo, newPhoto, photoId) => {
    if (newPhoto) {
      this.setState({
        ...this.state,
        inputs: {
          ...this.state.inputs,
          photos: this.state.inputs.photos.filter((p, i) => i !== photo),
        },
      });
    } else {
      this.setState({
        ...this.state,
        markerPhotos: this.state.markerPhotos.filter((p, i) => i !== photo),
        deletePhotos: this.state.deletePhotos.concat(photoId),
      });
    }
  }

  onDropFiles = acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      this.setState({
        ...this.state,
        inputs: {
          ...this.state.inputs,
          photos: acceptedFiles,
        },
      })
    }
  }

  render() {
    const { inputs, markerPhotos } = this.state;
    const renderKindOptions = () => Object.entries(markerKindOptions).map(([key, value]) => (
      <option key={key} value={key}>{value}</option>
    ));

    return (
      <div className="map-popup-container" onClick={this.handleOutsideClick}>
        <div className="mapbox" style={{ maxHeight: 630, overflow: 'auto' }}>
          <h4>{this.props.marker.properties.marker.id ? 'Edit marker info' : 'Add new marker'}</h4>
          <div className="form_row">
            <label htmlFor="map-marker-name">Name</label>
            <input type="text" name="map-marker-name" id="map-marker-name" value={inputs.name} onChange={this.handleInputChange.bind(this, 'name')} />
          </div>
          <div className="form_row">
            <label htmlFor="map-marker-info">Info</label>
            <input type="text" name="map-marker-info" id="map-marker-info" value={inputs.info} onChange={this.handleInputChange.bind(this, 'info')} />
          </div>
          <div className="form_row">
            <label htmlFor="map-marker-info">Link (full url)</label>
            <input className="form-control" type="text" name="map-marker-link" id="map-marker-link" value={inputs.link} onChange={this.handleInputChange.bind(this, 'link')} />
          </div>
          <div className="form_row">
            <label htmlFor="map-marker-kind">Kind</label>
            <select name="map-marker-kind" id="map-marker-kind" value={inputs.kind} onChange={this.handleInputChange.bind(this, 'kind')}>
              {renderKindOptions()}
            </select>
          </div>
          {markerIsCommercial(inputs.kind) && (
            <div>
              <div className="form_row">
                <label htmlFor="map-marker-distance_to_crag">Distance to nearest sector</label>
                <input type="text" name="map-marker-distance_to_crag" id="map-marker-distance_to_crag" value={inputs.distanceToCrag} onChange={this.handleInputChange.bind(this, 'distanceToCrag')} />
              </div>
              <div className="form_row">
                <label htmlFor="map-marker-address">Address</label>
                <input type="text" name="map-marker-address" id="map-marker-address" value={inputs.address} onChange={this.handleInputChange.bind(this, 'address')} />
              </div>
              <div className="form_row">
                <label htmlFor="map-marker-email">Email</label>
                <input type="text" name="map-marker-email" id="map-marker-email" value={inputs.email} onChange={this.handleInputChange.bind(this, 'email')} />
              </div>
              {inputs.kind === 'gym' && (
                <div className="form_row">
                  <label htmlFor="map-marker-opening-hours">Opening hours</label>
                  <textarea rows="4" name="map-marker-opening-hours" id="map-marker-opening-hours" value={inputs.openingHours} onChange={this.handleInputChange.bind(this, 'openingHours')} />
                </div>
              )}
              <div className="form_row">
                <label htmlFor="map-marker-url_bookingcom">Booking.com url</label>
                <input type="text" name="map-marker-url_bookingcom" id="map-marker-url_bookingcom" value={inputs.urlBookingCom} onChange={this.handleInputChange.bind(this, 'urlBookingCom')} />
              </div>
              <div className="form_row">
                <label htmlFor="map-marker-url_airbnb">Airbnb.com url</label>
                <input type="text" name="map-marker-url_airbnb" id="map-marker-url_airbnb" value={inputs.urlAirbnb} onChange={this.handleInputChange.bind(this, 'urlAirbnb')} />
              </div>
              <div className="form_row">
                <label htmlFor="map-marker-url_otherbooking">Other booking url</label>
                <input type="text" name="map-marker-url_otherbooking" id="map-marker-url_otherbooking" value={inputs.urlOtherBooking} onChange={this.handleInputChange.bind(this, 'urlOtherBooking')} />
              </div>
              <div className="form_row">
                <label htmlFor="map-marker-photos">Photos</label>
                <Dropzone
                  multiple={false}
                  className="dropzone"
                  name="map-marker-photos"
                  id="map-marker-photos"
                  accept="image/*"
                  onDrop={this.onDropFiles}
                >
                  Drag photo here or click to search...
                </Dropzone>
                {markerPhotos && markerPhotos.length > 0 && markerPhotos.map((p, i) => (
                  <div
                    className="marker-photo"
                    title="Delete photo"
                    key={i}
                    style={{ background: `url(${p.styles.size_s.url}) center / cover` }}
                    onClick={this.deletePhoto.bind(this, i, false, p.id)}
                  >
                    <i className="glyphicon glyphicon-remove" />
                  </div>
                ))}
                {inputs.photos.map((p, i) => (
                  <div
                    className="marker-photo"
                    title="Delete photo"
                    key={i}
                    style={{ background: `url(${p.preview}) center / cover` }}
                    onClick={this.deletePhoto.bind(this, i, true)}
                  >
                    <i className="glyphicon glyphicon-remove" />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="row-container">
            <div className="row">
              <div className="col-xs-6">
                <div className="form_row">
                  <label htmlFor="map-marker-lat">Latitude</label>
                  <input type="text" name="map-marker-lat" id="map-marker-lat" value={inputs.lat} onChange={this.handleInputChange.bind(this, 'lat')} />
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form_row">
                  <label htmlFor="map-marker-lng">Longitude</label>
                  <input type="text" name="map-marker-lng" id="map-marker-lng" value={inputs.lng} onChange={this.handleInputChange.bind(this, 'lng')} />
                </div>
              </div>
            </div>
          </div>
          <div className="marker-info-footer">
            {this.state.confirmationDialogVisible && (
              <div className="confirmation-dialog">
                <h4>Are you sure?</h4>
                <button className="btn btn-danger" onClick={this.delete}>
                  Delete
                </button>
                <button className="btn btn-default" onClick={() => this.setState({ confirmationDialogVisible: false })}>Cancel</button>
              </div>
            )}
            {this.props.marker.properties.marker.id && (
              <div className="button button-delete">
                <button
                  className="btn btn-danger"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ confirmationDialogVisible: true });
                  }}
                >
                  Delete
                </button>
              </div>
            )}
            <div className="button">
              <button className="btn btn-primary" onClick={this.save}>Save</button>
            </div>{' '}
            <div className="button">
              <button className="btn btn-default" onClick={this.cancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MarkerEditPopup;

import axios from "axios";
import * as types from "../common/actionTypes";
import * as apiEndpoints from "../common/apiEndpoints";

let lastFetch = 0;

export const fetchNotifications = climber_id => dispatch => {
  let url = apiEndpoints.climber_notifications_path(climber_id);
  let nextFetch = lastFetch + 1000 * 60 * 1; // 1min flood prevention
  if (nextFetch < Date.now()) {
    lastFetch = Date.now();
    dispatch({
      type: `${types.FETCH_NOTIFICATIONS}_PENDING`
    });
    axios
      .get(url)
      .then(result => {
        dispatch({
          type: `${types.FETCH_NOTIFICATIONS}_FULFILLED`,
          payload: result
        });
      })
      .catch(err => {
        dispatch({
          type: `${types.FETCH_NOTIFICATIONS}_REJECTED`,
          payload: err
        });
      });
  }
};

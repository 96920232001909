app.MapHinter = function(map) {
  
  var zoom_out_event;
  var single_click_hint_activated = false;
  
  function levels_to_s() {
    var levels = map.levels_to_go();
    return '' + levels + ' level' + (levels == 1 ? '':'s')
  }
  
  function show_zoom_out_hint() {
    app.hint(' &mdash; zoom out at least ' + levels_to_s() + ' to see other markers');
  }
  
  function show_single_click_hint() {
    setTimeout(function() {
      var text = 'Try zooming in with a single click. Doing so enables the fetching of new markers with a double click.';
      single_click_hint_activated = true;
      if ( !map.stale ) $('.headline h2').html(text).addClass('hint');
    }, 500);
  }

  function show_double_click_hint() {
    if ( single_click_hint_activated && !map.stale ) $('.headline h2').html('New markers can be fetched with a double click.');
  }
  
  function update_zoom_out_hint() {
    if ( map.tiles() ) {
      app.remove_hint();
      if ( zoom_out_event ) google.maps.event.removeListener(zoom_out_event);
      zoom_out_event = null;
    } else {
      show_zoom_out_hint();
    }
  }
  
  function dragging() {
    if ( !map.tiles() && map.out_of_area() && !zoom_out_event ) {
      show_zoom_out_hint();
      zoom_out_event = google.maps.event.addListener(map.g_map, 'zoom_changed', update_zoom_out_hint);
    }
  }
  
  return {
    double_clicked : show_single_click_hint,
    single_clicked : show_double_click_hint,
    dragging       : dragging
  };
  
}

(function ($) {
  $.fn.localizeGrade = function (system) {
    this.each(function () {
      var jq = $(this);
      var data = jq.data("grade");
      if (data) {
        var genre = data[0];
        var grade_int = data.slice(1);
        jq.html(getLocalizedGrade(system, grade_int, genre));
      }
      return this;
    });
  };
})(jQuery);
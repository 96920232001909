import React, { Component } from "react";
import Container from "./Container";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import visitsReducer from "./reducer.jsx";
import { configurePersistStore } from "../../common/store";

class Visits extends Component {
  constructor(props) {
    super(props);
    const reducer = {
      visitsReducerParams: visitsReducer(this.props.crags, this.props.param_id)
    };
    const { store, persistor } = configurePersistStore(
      "visits",
      reducer,
      null,
      ["Visits"]
    );
    this.state = {
      persistor: persistor,
      store: store
    };
  }
  render() {
    return (
      <Provider store={this.state.store}>
        <PersistGate persistor={this.state.persistor}>
          <Container
          />
        </PersistGate>
      </Provider>
    );
  }
}
export default Visits;

import no_image from '../../../javascript/images/appicons_noimage.png'

(function($){

  $.newfacebox = function(data, options) {
    var id = ($('#facebox').is(':visible') && !$('#facebox .loading').is(':visible')) ? '#subfacebox' : '#facebox';
    $.facebox(data, options, id);
  };

  $.facebox.blockBody = function() {
    $('#facebox .content').block({'z-index':109});
  };

  $.facebox.get = function(url, options, id) {
    $.facebox.loading(); // login redirect may call $facebox.get directly
    $.get(url, function(data) {
      // check for login redirect first (because http cannot do ajax to https)
      if ( data.match(/^http.*login/) ) top.location = data;
      else $.facebox.reveal(data, options || {}, id);
    });
  };

  var loadingMorePhotosFor = null;
  var expectedImage = null;
  var photoCache = [];

  $.facebox.revealPhoto = function(url, options, link_element) {
    $.facebox.loading(); // login redirect may call $facebox.get directly
    $.get(url, function(data) {
      // check for login redirect first (because http cannot do ajax to https)
      if ( data.match(/^http.*login/) ) top.location = data;
      else {
        var $link = $(link_element);

        var $data = $('<div/>').append(data);
        var $image_container = $data.find('.image');

        // photo browsing
        if ( $link.data('group') ) {
          var groupName = $link.data('group');
          var $moreLink = $('#' + groupName + '-more');
          if ( $moreLink.data('url') && loadingMorePhotosFor != groupName ) {
            loadingMorePhotosFor = groupName;
            $.ajax({
              url: $moreLink.data('url'),
              global: false,
              success: function(resp){ $moreLink.replaceWith(resp); },
              complete: function(){ if ( loadingMorePhotosFor == groupName ) loadingMorePhotosFor = null; }
            });
          }
          var group = $('a[data-group=' + groupName + ']');
          if ( group.size() > 1 ) {
            var lastIndex = group.size() - 1;
            var currIndex = group.index(link_element);
            var prevIndex = (currIndex == 0 ? lastIndex : currIndex - 1);
            var nextIndex = (currIndex == lastIndex ? 0 : currIndex + 1);
            createNavigationLink($image_container, 'prev', group[prevIndex], options);
            createNavigationLink($image_container, 'next', group[nextIndex], options);
            $.facebox.activateArrowKeyNavigation();
          }
        }

        var preLoadImages = function(arrOfImages, index, onDone) {
          if(arrOfImages.length <= index) {
            onDone();
            return;
          }
          if(typeof photoCache[arrOfImages[index].src] == 'undefined') {
            var img = new Image();
            img.onload = function(){
              preLoadImages(arrOfImages, index+1, onDone);
              photoCache[arrOfImages[index].src] = arrOfImages[index];
              this.remove();
            };
            img.onerror = function(){
              arrOfImages[index].src = no_image;
              preLoadImages(arrOfImages, index+1, onDone);
            };
            img.src = arrOfImages[index].src;
          }
          else {
            preLoadImages(arrOfImages, index+1, onDone);
          }
        };

        preLoadImages($data.find('img'), 0, function(){
          $.facebox.reveal($data, options || {});
        });
      }
    });
    // init likes

  };



  $.facebox.revealImage = function(image, options, that) {
    var link = $(that);
    // photo browsing
    if ( link.data('group') ) {
      var groupName = link.data('group');
      var $moreLink = $('#' + groupName + '-more');
      if ( $moreLink.data('url') && loadingMorePhotosFor != groupName ) {
        loadingMorePhotosFor = groupName;
        $.ajax({
          url: $moreLink.data('url'),
          global: false,
          success: function(resp){ $moreLink.replaceWith(resp); },
          complete: function(){ if ( loadingMorePhotosFor == groupName ) loadingMorePhotosFor = null; }
        });
      }
      var group = $('a[data-group=' + groupName + ']');
      if ( group.size() > 1 ) {
        var lastIndex = group.size() - 1;
        var currIndex = group.index(that);
        var prevIndex = (currIndex == 0 ? lastIndex : currIndex - 1);
        var nextIndex = (currIndex == lastIndex ? 0 : currIndex + 1);
        createNavigationLink(content, 'prev', group[prevIndex]);
        createNavigationLink(content, 'next', group[nextIndex]);
        $.facebox.activateArrowKeyNavigation();
      }
    }
    $.facebox.reveal(content, options);
  };

  function createVotingLink(content, klass, photo_id) {
    // var link = $('<div class="'+klass+'"><div class="label">' + (klass == 'up' ? 'Give a star' : 'Remove a star') + '</div></div>');
    var link = $('.photofooter .up', content);

    link.click(function(e) {
      var $this = $(this);
      $.post('/photos/' + photo_id + '/vote', {vote: (klass == 'up' ? '1' : '-1'), authenticity_token: $('meta[name=csrf-token]').attr('content')}, function(response) {
        var $feedback = $('.response', $this);
        if(! $feedback.length ) {
          $feedback = $('<span class="voted"></span>');
          $feedback.hide().appendTo($this);
        }
        //$this.addClass('done');
        $feedback.html(response);
        var feedbackDelay = (response.length > 15 ? 600 : 200);
        $this.find('.label').hide();
        $feedback.fadeIn(200).delay(feedbackDelay);
        $this.off('click');
      });
    });
    if($('.voted', link).length) {
      link.off('click');
    }
    //content.find('.brc').append(link);
  }

  function createNavigationLink(content, klass, sourceLink, options) {
    var link = $('<div class="'+klass+'"><div class="arrow"></div></div>');
    var clicked = false; // timing in IE9 requires extra care
    link.click(function() {
      $.facebox.fillFaceboxFromHref($(sourceLink).attr('href'), options, sourceLink);
      //$.facebox.revealPhoto($(sourceLink).attr('href'), options, sourceLink);
    });
    content.append(link);

  }

  function switchImage(image, klass, link, waitingFor) {
    waitingFor = waitingFor || loadingMorePhotosFor;
    // return if the user opened another photo before this one got loaded
    if ( expectedImage != image.src ) return;
    // return if the user closed and re-opened the photo viewer
    if ( waitingFor && loadingMorePhotosFor && loadingMorePhotosFor != waitingFor ) return;
    // wait for more links only if we are on the edge (invisible edge links don't contain img tags)
    if ( loadingMorePhotosFor && $(link).find('img').size() == 0 ) {
      wait();
      setTimeout(function(){ switchImage(image, klass, link, waitingFor); }, 90);
    } else {
      $.facebox.revealPhoto(image, klass, link);
    }
  }

  function wait() {
    var $image = $('#facebox .image');
    if ( !$image.hasClass('bgloading') ) $image.addClass('bgloading').find('img').fadeTo(600, 0);
  }

  var arrowKeyNavigationActivated = false;
  $.facebox.activateArrowKeyNavigation = function() {
    if ( !arrowKeyNavigationActivated ) {
      arrowKeyNavigationActivated = true;
      $(document).bind('keydown.faceboxphotos', function(e) {
        if (arrowKeyNavigationActivated ) {
          var selector = null;
          if ( e.which == 38 ) selector = '#facebox .up'  ; else
          if ( e.which == 40 ) selector = '#facebox .down'; else
          if ( e.which == 37 ) selector = '#facebox .prev'; else
          if ( e.which == 39 ) selector = '#facebox .next';
          if ( selector && $(selector).size() == 1 ) {
            $(selector).click();
            e.preventDefault();
          } else {
            return true;
          }
        }
      });
    }
  };

  $.facebox.deActivateArrowKeyNavigation = function() {
    arrowKeyNavigationActivated = false;
  };



  $(document).bind('afterClose.facebox', function() {
    $.facebox.deActivateArrowKeyNavigation;
    $(document).unbind('keydown.faceboxphotos');
    arrowKeyNavigationActivated = false;
  });

})(jQuery);

import React from 'react';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import configureStore from '../common/store';
import feedReducer from './reducer';
import FeedContainer from './Container';

const FeedApp = (railsProps) => {
  const reducer = combineReducers({
    feed: feedReducer(
      JSON.parse(railsProps.currentClimber),
      railsProps.photoUrl,
      railsProps.apiUrl
    ),
    form: formReducer
  });

  return (
    <Provider store={configureStore(reducer)}>
      <FeedContainer
        showFeedForm={true}
        fetchUrlSuffix="feed_items"
      />
    </Provider>
  );
};

export default FeedApp;

import React from "react";
import {convertGrade} from "../common/util";

const HardestAscents = (props) => {
    const { ascents, year, grading_system_trad, grading_system_sport, grading_system_boulder, genre_counts, onNextClick } = props;

    const sortByGrade = (ascents_arr) => {
        return ascents_arr.sort((a, b) => {
            return b.route_grade_int - a.route_grade_int;
        });
    };

    const getHardestByGenreAndType = (genre, type) => {
        let filtered = ascents.filter((ascent) => {
            return ascent.route_genre == genre && ascent.ascent_type == type
        });

        if(filtered.length > 0) {
            return sortByGrade(filtered)[0];
        }
        return null;
    };

    const removeNull = (arr) => {
        return arr.filter(function (el) {
            return el != null;
        });
    };

    const renderAscent = (ascent, grade_system) => {
        return(
          <li key={`ascent-${ascent.id}`}>{ascent.route_name} {convertGrade(ascent.route_grade_int, grade_system)} at {ascent.crag_name} <span className={`ascent-type ${ascent.ascent_type}`}>{ascent.ascent_type}</span></li>
        );
    }

    const getBoulders = () => {
        let ascents = removeNull([
            getHardestByGenreAndType('Boulder', 'flash'),
            getHardestByGenreAndType('Boulder', 'redpoint')
        ]);
        return sortByGrade(ascents);
    }

    const getSports = () => {
        let ascents = removeNull([
            getHardestByGenreAndType('Sport', 'onsight'),
            getHardestByGenreAndType('Sport', 'flash'),
            getHardestByGenreAndType('Sport', 'redpoint')
        ]);
        return sortByGrade(ascents);
    };

    const getTrad = () => {
        let ascents = removeNull([
            getHardestByGenreAndType('Traditional', 'onsight'),
            getHardestByGenreAndType('Traditional', 'flash'),
            getHardestByGenreAndType('Traditional', 'redpoint')
        ]);
        return sortByGrade(ascents);
    };

    const getDWS = () => {
        let ascents = removeNull([
            getHardestByGenreAndType('DWS', 'onsight'),
            getHardestByGenreAndType('DWS', 'flash'),
            getHardestByGenreAndType('DWS', 'redpoint')
        ]);
        return sortByGrade(ascents);
    };

    const getToprope = () => {
        let topropes = ascents.filter((ascent) => {
            return ascent.ascent_type == 'toprope'
        });
        if(topropes.length > 0) {
            return sortByGrade(topropes)[0];
        }
        return null;
    };

    return (
        <div className={'slide-container'}>
            <div className={'slide-content'}>
                <h2>
                    Your hardest ascents
                </h2>
                {genre_counts['Boulder'] > 0 &&
                    <div className={'hard-ascents'}>
                        <h5>Boulder</h5>
                        <ul className={'hard-ascents__list'}>
                            {getBoulders().map((ascent) => {
                                return renderAscent(ascent, grading_system_boulder);
                            })}
                        </ul>
                    </div>
                }
                {genre_counts['Sport'] > 0 &&
                    <div className={'hard-ascents'}>
                        <h5>Sport</h5>
                        <ul className={'hard-ascents__list'}>
                            {getSports().map((ascent) => {
                                return renderAscent(ascent, grading_system_sport);
                            })}
                        </ul>
                    </div>
                }
                {genre_counts['Traditional'] > 0 &&
                    <div className={'hard-ascents'}>
                        <h5>Trad</h5>
                        <ul className={'hard-ascents__list'}>
                            {getTrad().map((ascent) => {
                                return renderAscent(ascent, grading_system_trad);
                            })}
                        </ul>
                    </div>
                }
                {genre_counts['DWS'] > 0 &&
                    <div className={'hard-ascents'}>
                        <h5>DWS</h5>
                        <ul className={'hard-ascents__list'}>
                            {getDWS().map((ascent) => {
                                return renderAscent(ascent, grading_system_sport);
                            })}
                        </ul>
                    </div>
                }
                {getToprope() != null &&
                    <div className={'hard-ascents'}>
                        <h5>Top rope</h5>
                        <ul className={'hard-ascents__list'}>
                            {renderAscent(getToprope(), grading_system_sport)}
                        </ul>
                    </div>
                }

                <a className={'btn-continue'} onClick={onNextClick}>
                    Continue
                </a>
            </div>
        </div>
    );
}

export default HardestAscents;
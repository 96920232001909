import LocalStorage from './localstorage'
import ClipboardJS from 'clipboard'
import Swiper, {Autoplay, EffectFade, Pagination, Lazy} from 'swiper'

Swiper.use([Autoplay, EffectFade, Pagination, Lazy]);

window.forceRedirectToHttps = function () {
  if (window.location.protocol != "https:") {
    window.location.href =
      "https:" +
      window.location.href.substring(window.location.protocol.length);
    return true;
  }
}
window.redirectToMainDomain = function () {
  if (window.location.hostname == "www.27crags.com") {
    window.location.href = "https://27crags.com" + window.location.pathname;
    return true;
  }
}

window.scrollToAnchor = function (aid) {
  var aTag = $("[name='" + aid + "']");
  if (!aTag[0]) {
    aTag = $("[id='" + aid + "']");
  }
  $("html,body").animate({scrollTop: aTag.offset().top}, 500, "swing");
}

// forceRedirectToHttps(); // moved to layouts/header

if (top.location != location && location.href.search(".embeddable") == -1)
  top.location = location;
//var addthis_share = {templates:{twitter:'Check out {{url}} on 27crags.com'}};
//var addthis_disable_flash=true;

window.getWindowDeviceMode = function () {
  var width = $(window).width();
  if (width < 768) return "xs";
  if (768 <= width && width < 992) return "sm";
  if (992 <= width && width < 1200) return "md";
  if (1200 <= width) return "lg";
}

window.openSharerWindow = function (href) {
  var leftPosition, topPosition;
  //Allow for borders.
  var width = 450;
  var height = 300;
  leftPosition = window.screen.width / 2 - (width / 2 + 10);
  //Allow for title and status bars.
  topPosition = window.screen.height / 2 - (height / 2 + 50);
  var windowFeatures =
    "status=no,height=" +
    height +
    ",width=" +
    width +
    ",resizable=yes,left=" +
    leftPosition +
    ",top=" +
    topPosition +
    ",screenX=" +
    leftPosition +
    ",screenY=" +
    topPosition +
    ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
  var url = href;
  return window.open(url, "sharer", windowFeatures);
}

window.moveToImportedList = function ($failure_row) {
  $failure_row.detach();
  $failure_row.hide(100);
  $failure_row.appendTo(".imported_ascents table tbody");
  $failure_row.show(100);
  var imported_ascents_count = $(".imported_ascents table tbody tr").length;
  $("#imported_ascents_count").html(imported_ascents_count);
  $(".imported_ascents:hidden").show(200);

  var unimported_count = $(".un_imported_ascents table tbody tr").length;
  $("#imported_ascents_failed_count").html(unimported_count);
  if (unimported_count < 1) {
    $(".un_imported_ascents").hide(200);
  }
}

var localstorage_force_refresh = LocalStorage.getInstance("force_refresh");

if (localstorage_force_refresh.get() != "1468818895563") {
  window.localStorage.clear();
  localstorage_force_refresh.set("1468818895563");
}

$(window).load(function () {

  $(".my-privacy-container").livequery(function () {
    setTimeout(function () {
      $(".blockUI").height($(".body-container").height());
    }, 500);
    const terms = $("#climber_terms_accepted");
    const privacy = $("#climber_privacy_accepted");
    const emails = $("#climber_promotional_emails");
    const agreeAll = $("#agree_all");
    const continueButton = $("#continue_button");
    const disagree = $(".disagree a");

    function checkboxClick(requiredCheckbox) {
      if (requiredCheckbox) {
        continueButton.attr(
          "disabled",
          !(terms.is(":checked") && privacy.is(":checked"))
        );
      }
      agreeAll.prop(
        "checked",
        terms.is(":checked") && privacy.is(":checked") && emails.is(":checked")
      );
    }

    terms.on("click", function () {
      checkboxClick(true);
    });
    privacy.on("click", function () {
      checkboxClick(true);
    });
    emails.on("click", function () {
      checkboxClick(false);
    });
    agreeAll.on("click", function (e) {
      const checked = e.target.checked;
      terms.prop("checked", checked);
      privacy.prop("checked", checked);
      emails.prop("checked", checked);
      continueButton.attr("disabled", !checked);
    });
    disagree.on("click", function () {
      if (!$(".disagree-info").is(":visible")) {
        $(".disagree-info").show();
      } else {
        $(".disagree-info").hide();
      }
    });
  });
  $("#ads-new").livequery(function () {
    var hideFromFeedItem = function (val) {
      if (val == "feed_item") {
        $("#ads-new .hidden-from-feed-item").hide();
      } else {
        $("#ads-new .hidden-from-feed-item").show();
      }
    };

    $("#ads-new .select-category select").change(function () {
      hideFromFeedItem($(this).val());
    });

    $("#ads-new .action-with-explanation").click(function () {
      $("#ads-new .select-category select").val($(this).data("select"));
      hideFromFeedItem($(this).data("select"));
    });
  });

  $(".js-toggle-navmode").click(function () {
    if ($("header.navbar").hasClass("search-mode")) {
      $("header.navbar").removeClass("search-mode");
    } else {
      $("header.navbar").addClass("search-mode");
      $("header.navbar .search_text").focus();
    }
  });

  $(".select-show").livequery(function () {
    var __onchange = function ($obj) {
      var arr_vals = $obj.data("show").toString().split(",");
      var target = $obj.data("target");
      if ($.inArray($obj.val(), arr_vals) < 0) {
        $(target).css("display", "none");
      } else {
        $(target).css("display", "block");
      }
    };
    $(this).change(function (event) {
      __onchange($(this));
    });
    __onchange($(this));
  });

  $(".select-payment-type").livequery(function () {
    var __onchange = function (val) {
      if (val == "iban") {
        $("#payment-type-iban").css("display", "block");
        $("#payment-type-paypal").css("display", "none");
      } else if (val == "paypal") {
        $("#payment-type-iban").css("display", "none");
        $("#payment-type-paypal").css("display", "block");
      } else {
        $("#payment-type-iban").css("display", "none");
        $("#payment-type-paypal").css("display", "none");
      }
    };

    $(this).change(function (event) {
      __onchange($(this).val());
    });
    __onchange($(this).val());
  });

  $("#continue-import").click(function () {
    var $btn = $(this).button("loading");
  });

  $(".ignore_failure").click(function (event) {
    var $td = $(this).closest("td.matching-route");
    var $failure_row = $td.closest("tr");
    $td.html($(this).html($(this).find(".route-crag").html()));
    moveToImportedList($failure_row);
    return false;
  });

  $(".ascent_option").click(function (event) {
    var $this = $(this);
    var direct = "no";
    if ($this.hasClass("requireDialog")) app.wait();
    else {
      direct = "yes";
      $this.closest(".dropdown button").html("Saving...");
    }
    if ($this.hasClass("hasExistingCrag")) {
      $this
        .closest("form")
        .attr("action", $this.closest("form").attr("action") + "/create_route");
    }
    $this.closest("form").ajaxSubmit({
      data: {
        is_ajax_request: true,
        direct: direct,
        "ascent[route_id]": $this.find(".id").html() || "",
        "route[crag_id]": $this.find(".crag_id").html() || "",
        "route[sector_id]": $this.find(".sector_id").html() || "",
      },
      success: function (response, status, xhr, $form) {
        app.after_ajax(response, status, xhr, $form);
        //moveToImportedList($('#failure_'+response.failure_id+'_container'));
      },
    });
    return false;
  });

  // class hide should be hidden only if js is enabled
  // $('.hide').hide(); *** not in use

  $(".crag-description .quote").dotdotdot({
    watch: "window",
  });
  $(".sector-properties").dotdotdot({
    watch: "window",
  });
  $(".dotdotdot").dotdotdot({});

  $("#js-user_menu").html(app.user_menu());

  $(".oembed > a")
    .addClass("done")
    .embedly({key: "4cd00f5ceb5911e0a3cf4040d3dc5c07", maxWidth: 560});
  setTimeout(function () {
    $(".oembed > a:not(.done)").livequery(function () {
      $(this).embedly({
        key: "4cd00f5ceb5911e0a3cf4040d3dc5c07",
        maxWidth: 560,
      });
    });
  }, 50);

  // general AJAX handlers
  $(document).ajaxError(app.ajax_error);

  // over the top
  app.messages.show_js_messages();

  $(".select-photo").livequery(function () {
    $(this).click(function () {
      var target = $(this).data("target");
      var id = $(this).data("id");
      $("#" + target).val(id);
      $(".select-photo").removeClass("active");
      $(this).addClass("active");
    });
  });

  // star rating
  $("select.rating").livequery(function () {
    $(this).rating();
    var $rating_select = $(this);
    $rating_select.on("change", function () {
      $rating_select
        .closest(".rating-container")
        .find(".rating-description")
        .html($rating_select.find("option:selected").html());
    });
  });

  // autocomplete...
  $(".js_searchbox .search_text").each(function () {
    new_autocomplete().activate(this, "search", function (o) {
      top.location = o.path;
    });
  });

  if ($("#worldmap_search")[0]) {
    worldMapSearch.activate("#worldmap_search", "map_locations", function (o) {
      var zoomLevels = {
        Crag: 12,
        Sector: 13,
        Route: 14,
        Location: 7,
      };
      var zoomLevel =
        typeof zoomLevels[o.type] !== "undefined" ? zoomLevels[o.type] : 7;

      app.map.setMapCenter({lat: o.latitude, lng: o.longitude}, zoomLevel);
      $("#worldmap_search").val("");
    });
  }

  $("form.prevent-default").submit(function (event) {
    event.preventDefault();
  });

  // ...for /crags/all
  if ($("#crag_list_search")[0])
    new_autocomplete().activate("#crag_list_search", "areas", function (o) {
      top.location = o.data;
    });
  // ...when adding crag relations or moving routes
  $("#new_crag_name").livequery(function () {
    new_autocomplete().activate(this, "crags", "crag_name", "_crag_id", {
      crag_id: "crag_id",
    });
  });
  // ...when adding an ascent of a new route
  $("#route_crag_name").livequery(function () {
    new_autocomplete().activate(
      this,
      "crags",
      "route[crag_name]",
      "route[crag_id]",
      {route_crag_id: "data"}
    );
  });
  // ...when adding member
  $(".jq-autocomplete-member_climber_name").livequery(function () {
    new_autocomplete().activate(
      this,
      "climbers",
      "membership_invitation_climber_name",
      "membership_invitation[invitee_id]",
      {
        membership_invitation_invitee_id: "id",
        membership_invitation_climber_name: "name",
      }
    );
  });
  // ...when adding an ascent
  $("#ascent_route_name").livequery(function () {
    new_autocomplete().activate(
      this,
      "routes",
      "ascent[route_name]",
      "ascent[route_id]",
      {
        ascent_route_id: "id",
        ascent_grade_opinion: "grade",
      }
    );
  });
  // ...when adding a video
  $("#video_route_name").livequery(function () {
    new_autocomplete().activate(
      this,
      "routes",
      "video[route_name]",
      "video[route_id]",
      {
        video_route_id: "id",
      }
    );
  });
  // ...when adding a photo
  $("#feed_photo_album").livequery(function () {
    new_autocomplete().activate(this, "crags", function (o) {
      $("#album_type").val(o.type);
      $("#album_id").val(o.crag_id);
    });
  });

  function addItemToProductAreas(id, name) {
    var item = $(
      '<div class="product_area_item" data-id="' +
      id +
      '">' +
      '<div class="name">' +
      name +
      "</div>" +
      '<a class="product_areas_item_remove"><i class="glyphicon glyphicon-remove"></i></a>' +
      '<input type="hidden" name="product[locations][]" value="' +
      id +
      '" />' +
      "</div>"
    );
    $(".product_areas_items").append(item);
  }

  $(".product_areas_item_remove").livequery(function () {
    $(this).click(function () {
      $(this).parent(".product_area_item").remove();
    });
  });

  $("#product_area_search").livequery(function () {
    new_autocomplete().activate(this, "crag_areas", function (o) {
      $("#product_area_search").val("");
      addItemToProductAreas(o.searchable_id, o.name);
    });
  });

  // nav animation
  $(window).scroll(function () {
    if ($(document).scrollTop() > 50) {
      $("header").addClass("shrinked");
    } else {
      $("header").removeClass("shrinked");
    }
  });

  var showItems = function (filters) {
    var active_selector = "active";
    $("#filter-items .filter-item." + active_selector).removeClass(
      active_selector
    );
    var $active_items = $("#filter-items .filter-item");
    if (filters != "") {
      $active_items = $active_items.filter(filters);
    }
    $active_items = $active_items.slice(0, 20);
    $active_items.addClass(active_selector);
    // $.wall.filter('.' + active_selector);
  };

  var window_width = $(window).width();
  // front page feed
  if ($("ul.latest-updates-wall")[0]) {
    var newsWallReset = function () {
      var reset = function () {
        $.wall.reset({
          selector: ".item",
          animate: false,
          transition: false,
          cellW: 280,
          cellH: "auto",
          onResize: function () {
            if (window_width != $(window).width()) {
              window_width = $(window).width();
              $.wall.fitWidth();
            }
          },
          onComplete: function () {
            $("ul.latest-updates .item.active").velocity("transition.fadeIn", {
              display: "block",
              duration: 700,
              stagger: 30,
            });
          },
        });
      };

      if ($.wall.container.find(".item img").length) {
        $.wall.container.imagesLoaded(function () {
          $("ul.latest-updates").show();
          $("#feed_items_loading").hide();
          reset();
          $.wall.fitWidth();
        });
      } else {
        $("ul.latest-updates").show();
        $("#feed_items_loading").hide();
        reset();
        $.wall.fitWidth();
      }
    };

    $("ul.latest-updates-wall").hide();
    $("#feed_items_loading").show();
    $.wall = new freewall("ul.latest-updates-wall");
    newsWallReset();
    // showNewsItems('');
  }

  // crag / photos
  if ($("ul.photo-wall")[0]) {
    var wallReset = function () {
      var cellW = 190;
      var cellH = 190;

      if ($(window).width() < 768 - 1) {
        cellW = 130;
        cellH = 130;
      }

      var reset = function () {
        $.wall.reset({
          selector: ".news",
          animate: false,
          transition: false,
          tweenmax: true, // commercial plugin that makes transitions faster
          cellW: cellW,
          cellH: cellH,
          onResize: function () {
            if (window_width != $(window).width()) {
              window_width = $(window).width();
              $.wall.fitWidth();
            }
          },
        });
      };

      if ($.wall.container.find(".item img").length) {
        $.wall.container.imagesLoaded(function () {
          reset();
          $.wall.fitWidth();
        });
      } else {
        reset();
        $.wall.fitWidth();
      }
    };

    $.wall = new freewall("ul.photo-wall");
    wallReset();
  }

  $(".crag-todos .open").click(function () {
    var $parent = $(this).parent();
    var toggleOpen = function () {
      if ($parent.hasClass("active")) {
        var $elemToShow = $parent.hasClass("opened")
          ? $(".crag-todos .closed")
          : $(".crag-todos .opened");
        $parent.fadeOut(100, function () {
          $parent.removeClass("active");
          $elemToShow.fadeIn(200, function () {
            $elemToShow.addClass("active");
          });
        });
      }
    };
    toggleOpen();
  });

  // front page news wall filter
  $("#filter-nav a").click(function () {
    var filters = $(this).data("filter");

    // nav controlling
    $("#filter-nav li").removeClass("active");
    $(this).parent().addClass("active");

    var showItems = function (filters) {
      var active_selector = "active";
      $("#filter-items .filter-item." + active_selector).removeClass(
        active_selector
      );
      var $active_items = $("#filter-items .filter-item");
      if (filters != "") {
        $active_items = $active_items.filter(filters);
      }
      $active_items = $active_items.slice(0, 20);
      $active_items.addClass(active_selector);
    };

    showItems(filters);
  });

  // some pages must be reloaded if the user presses back
  if ($("#refresh").size() > 0) rails_helpers.reload_if_visited();

  // Mapbox dialog footers and toggle boxes
  $(document).on(
    "click",
    ".mapbox .footer input[name=cancel]",
    app.close_info_window
  );
  $(document).on("click", ".toggle_title", app.toggle_next);

  // Facebox links (all facebox links are now modal)
  $("a[rel*=facebox]").facebox(null, app.prepare_for_dialog);
  $(document).bind("close.facebox", function () {
    $("#subfacebox").is(":visible")
      ? $("#facebox .content").unblock()
      : $.unblockUI();
  });

  $("span.grade").localizeGrade(null);
  
  // avoid accidental repeated submission of the same form
  $("form:not(.inline_ajax, .no-jquery)").submit(function () {
    app.wait([]);
  });

  // Facebox dialogs and inline ajax forms (status update and comments)
  $(".inline_ajax, .facebox form").livequery(function () {
    if ($(this).hasClass("empty-before-reload")) {
      $(this).ajaxForm({
        beforeSubmit: app.before_ajax,
        closeKeepAlive: $.browser.safari ? "/close_connection" : null,
        data: {is_ajax_request: true},
        success: function (response, status, xhr, $form) {
          $form.html("");
          app.after_ajax(response, status, xhr, $form);
        },
      });
    } else if (!$(this).hasClass("no-ajax-submit")) {
      $(this).ajaxForm({
        beforeSubmit: app.before_ajax,
        closeKeepAlive: $.browser.safari ? "/close_connection" : null,
        data: {is_ajax_request: true},
        success: app.after_ajax,
      });
    }
  });

  // link to refresh comments
  $(document).on("click", ".refresh a", function () {
    var refreshable = $(this).closest(".refreshable");
    var txt = refreshable.find("textarea:not(.example)").val();
    refreshable.find("tr td.refresh").html(MyDom.loading);
    // refreshable.block({opacity:'0.4'});
    $.get(this.href, function (response) {
      var $response = $(response);
      if (txt) $response.find("textarea").val(txt);
      refreshable.replaceWith($response);
    });
    return false;
  });

  $(document).on("click", "a.js-slow_top_link", function (e) {
    app.wait();
    top.location = this.href;
    return false;
  });

  // Keep some livequeries alive across multiple ajax requests
  $(document).ajaxComplete(function () {
    $.fn.example.boundClassNames["example"] = false;
    var selectors = ["input[title]"];
    $.each(selectors, function (i, selector) {
      var previous_query = $.grep($.livequery.queries, function (a) {
        return a.selector == selector;
      })[0];
      if (previous_query) previous_query.elements = [];
    });
  });

  // Facebox footer buttons
  $(document).on(
    "click",
    ".facebox .footer input[name=cancel]",
    app.close_dialog
  );
  $(document).on("click", ".facebox .footer a.cancel", function () {
    app.close_dialog();
    return false;
  });
  // not live == not in facebox
  $("form .footer input[name=cancel]").click(function () {
    app.go_home();
  });
  // close if overlay is clicked and no cancel button is present
  $(document).on("click", ".blockUI", function () {
    if (
      $(
        ".facebox .footer input[name=cancel], .facebox .footer a.cancel"
      ).size() == 0
    )
      $.facebox.close();
  });
  // hide or show the close x

  $(document).bind("loading.facebox", function () {
    var jq = $("#facebox");
    if (jq.find(".keep").is(":visible")) jq = $("#subfacebox");
    jq.find(".close").removeClass("hidden");
  });

  $(document).on("change", "#climber_no_mail", function () {
    $("#mail_preferences").toggleClass("hidden");
  });

  // Blogs
  $(document).on("click", ".post .photos img", function () {
    $("#post_content").insertAtCaret("[PHOTO:" + $(this).attr("alt") + "]");
  });
  $(document).on("click", ".post .photos .pagination a", function () {
    $(".post .photos").load(
      $(this).attr("href").replace(/.*\?/, "/posts/photos.ajax?")
    );
    return false;
  });

  //$('textarea.elastic').livequery(function(){ $(this).elastic(); });
  //$('textarea.maxlength').livequery(function(){ $(this).maxlength(); });
  $('.plugin-sortable').change(function () {
    console.log('plugin-sortable CHANGE')
  });
  $('.plugin-sortable').livequery(function () {
    $('.plugin-sortable').sortable({
      onDrop: function ($item, container, _super, event) {
        _super($item, container, event);
        //$item.removeClass(container.group.options.draggedClass).removeAttr("style")
        //$("body").removeClass(container.group.options.bodyClass)
        console.log('onDrop', $item, container, _super, event);
        $('#btn-save-order').attr('disabled', false);
      }
    });
  });

  $(".elastic").textareaExpand();
  $("table.sortable")
    .tablesorter({
      sortList: function () {
        return $(this).data("sortlist");
      },
    })
    .tablesorterPager({
      container: $("#table-pager"),
      size: $("#table-pager").data("items")
        ? $("#table-pager").data("items")
        : 10,
      offset: 0,
    });

  if ($("canvas#ascent-chart")[0]) {
    function getMaxDataValue(dataset) {
      function getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
      }

      var max = 0;
      for (var i = 0; i < dataset.length; i++) {
        max = Math.max(max, getMaxOfArray(dataset[i].data));
      }
      return max;
    }

    function getMinDataValue(dataset) {
      function getMinOfArray(numArray) {
        var m = 1450;
        for (var j = 0; j < numArray.length; j++) {
          if (numArray[j] >= 100) {
            m = Math.min(numArray[j], m);
          }
        }
        return m;
      }

      var min = 1450;
      for (var i = 0; i < dataset.length; i++) {
        var val = getMinOfArray(dataset[i].data);
        if (val > 100) {
          min = Math.min(val, min);
        }
      }
      return min;
    }

    function getScaleStepsCount(min, max) {
      var steps_max = 27;
      var _min_grade = Math.max(100, min);
      var _max_grade = Math.min(1450, max);
      return (_max_grade - _min_grade) / 50;
    }

    var $canvas = $("canvas#ascent-chart");
    $canvas.attr("width", $canvas.parent().innerWidth());
    $canvas.attr("height", "300px");

    function getValueAsGrade(value, genre = null) {
      if (value == null) return null;
      var retval = null;
      Object.keys(GRADES_HASH).forEach(function (key) {
        var int_key = parseInt(key);
        var int_value = parseInt(value);
        if (int_key - 25 <= int_value && int_value < int_key + 25) {
          var prefix = genre == null ? "S" : genre[0].toUpperCase();
          retval = getLocalizedGrade(null, key, prefix);
          return;
        }
      });
      return retval;
    }

    var data = $canvas.data("summary");

    var max_grade = getMaxDataValue(data.datasets) + 100;
    var min_grade = getMinDataValue(data.datasets) - 100;
    // var scale_steps = getScaleStepsCount(min_grade, max_grade);

    var ctx = $("#ascent-chart").get(0).getContext("2d");
    var myLineChart = new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          mode: "label",
          callbacks: {
            label: function (obj, data) {
              return (
                data.datasets[obj.datasetIndex].label +
                " " +
                getValueAsGrade(obj.value, data.genre)
              );
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  return getValueAsGrade(value, data.genre);
                },
                min: Math.round(min_grade / 50) * 50,
                max: Math.round(max_grade / 50) * 50,
                stepSize: 50,
              },
            },
          ],
        },
      },
    });
  }

  // Topos
  if ($("#crags-print").length == 1) {
    $.linebox.color = {
      normal: "#fff",
      emphasized: "#fff",
      de_emphasized: "#fff",
    };
    $.linebox.line.dashed = true;
  }
  $(".topobox").livequery(function () {
    $(this).children('img').one('load', function () {
      $(this).parent('.topobox').linebox({
        line: {
          curvy: true,
          width: 3
        },
      });
    }).each(function () {
      if (this.complete) {
        $(this).load(); // For jQuery < 3.0
        // $(this).trigger('load'); // For jQuery >= 3.0
      }
    });
  });
  $("#topo_editor").livequery(function () {
    $(this).linebox({editable: true});
  });
  $(document).on("click", "#topo_undo", function () {
    $.linebox.topo_editor.undo();
  });

  // Hide the notices when clicked
  $(".notice").livequery(function () {
    var $notice = $(this);
    $notice.click(function () {
      $notice.fadeOut();
    });
    var timeoutHide = setTimeout(function () {
      $notice.fadeOut();
      clearTimeout(timeoutHide);
    }, 10000);
  });

  // Adjust width for wider screens
  // app.dyn_css();
  // $(window).resize(app.dyn_css);

  // Put focus on the first empty input element
  //$('form:not(.bg) :input:visible:enabled:first:not(.bg input):not(input[type=submit]):not([value])').livequery(function(){ $(this).focus(); });
  //$('.facebox form:not(.bg) :input:visible:enabled:first:not(.bg input):not(input[type=submit]):not([value])').livequery(function(){ $(this).focus(); });
  $(".focus").livequery(function () {
    $(this).focus();
  });

  $("#more_ascents a, #more_routes a").click(function () {
    var tr = $(this).closest("tr");
    tr.find("td").html(MyDom.loading);
    $.get($(this).attr("href"), function (response) {
      tr.before(response).remove();
    });
    return false;
  });

  $(".js-more a[onclick]").attr("onclick", "");
  $(".js-more a").click(function () {
    var link = $(this);
    link.html(MyDom.loading);
    $.getJSON(link.attr("href"), function (response) {
      $.each(response, function (key, value) {
        $(".js-more." + key).replaceWith(value);
        $(".js-feed." + key).html(value);
      });
      link.hide();
    });
    return false;
  });

  // the print page
  $("#crags-print").print_page();

  $(".js-route_genre").livequery(function () {
    app.update_grade_labels(null, this);
  });
  $(document).on("change", ".js-route_genre", function (e) {
    app.update_grade_labels(null, e.target);
  });

  // link scrubber from http://www.crismanich.de/jquery/linkscubber/
  // $('#engage').bind('focus', function(){ if(this.blur)this.blur(); });

  $(document).on("change", "#photo_image, #sector_topo_image", function (
    event
  ) {
    if (
      event.target.files &&
      event.target.files[0] &&
      event.target.files[0].size > 6291456
    ) {
      alert(
        "The file you selected seems to be bigger than the maximum size that we are able to process on the server. We recommend saving as jpeg with 100% quality, and width and height less than 1200 pixels before uploading."
      );
    }
  });

  if ($.datepicker)
    $.datepicker.setDefaults({
      dateFormat: "yy-mm-dd",
      showOtherMonths: true,
      selectOtherMonths: true,
      firstDay:
        $.inArray($.cookie("country_code"), ["US", "CA", "JP"]) != -1 ? 0 : 1,
    });

  app.messages.refresh();

  $(".upload_watcher").livequery(function () {
    let url = $(this).data('url');
    let failures = 0;

    let interval = setInterval(function () {
      let jqxhr = $.get(url, function (data) {
        clearInterval(interval);
        $(".upload_watcher__processing").hide();
        $(".upload_watcher__done").show();
      }).fail(function () {
        failures += 1
        if (failures > 8) {
          $(".upload_watcher__processing").hide();
          $(".upload_watcher__waiting").show();
          clearInterval(interval);
        }
      });

    }, 5000);
  });

  $(".show-tooltip").livequery(function () {
    $(this).tooltip();
  });

  var clipboard = new ClipboardJS(".copytoclipboard", {
    text: function (trigger) {
      return trigger.getAttribute("data-href");
    },
  });

  clipboard.on("success", function (e) {
    var $link = $(e.trigger);
    var orig_title = $link.attr("title");
    $link.attr("title", $link.data("msg-clicked"));
    $link
      .tooltip({
        placement: "top",
        trigger: "manual",
      })
      .tooltip("show");

    setTimeout(function () {
      $link.tooltip("destroy");
      $link.attr("title", orig_title);
    }, 2000);
  });
  clipboard.on("error", function (e) {
    window.location(e.clearSelection.boundThis.text);
  });

  $(".sharer-window").livequery(function () {
    $(this).click(function (e) {
      var url = $(this).attr("href");
      if (openSharerWindow(url)) {
        e.preventDefault();
      }
    });
  });

  $("textarea.nowrap").keypress(function (event) {
    if (event.which == "13") {
      return false;
    }
  });

  app.bindDatePicker = function ($container) {
    var $elements = $container.find(".hasDatepicker");
    if ($elements[0]) {
      $.each($elements, function (index, value) {
        var $element = $($elements[index]);
        var $btn = $element.parent().find("button");

        var $input = $element.pickadate({
          selectYears: true,
          selectMonths: true,
        });
        var picker = $input.pickadate("picker");

        if ($btn.length) {
          $btn.click(function (event) {
            event.stopPropagation();
            event.preventDefault();
            picker.open();
          });
        }
      });
    }
  };

  $(".timepicker").livequery(function () {
    $(this).pickatime({format: "HH:i"});
  });

  $(".load-more").livequery(function () {
    $(this).click(function (e) {
      e.preventDefault();
      var $btn = $(this);
      $btn.addClass("load-more--loading");
      var url = $btn.attr("href");
      var data = $btn.data();
      if (!$btn.data("offset-init")) {
        $btn.data("offset-init", $btn.data("offset"));
      }
      var $container = $($btn.data("container"));
      $.getJSON(url, data, function (response) {
        $btn.data(
          "offset",
          parseInt($btn.data("offset")) + parseInt($btn.data("offset-init"))
        );
        var $elements = $("<div/>").html(response.html);
        var childcount = 0;
        $elements.children().each(function (i, element) {
          var $element = $(element);
          $element.hide();
          if ($container.hasClass("photo-wall")) {
            $.wall.appendBlock($element);
          } else {
            $container.append($element);
          }
          $element.show(200);
          childcount++;
        });
        $btn.removeClass("load-more--loading");
        if (childcount < parseInt($btn.data("offset-init"))) {
          $btn.hide();
        }
      });
      return false;
    });
  });

  $(".__btn-follow").livequery(function () {
    $(this).click(function (e) {
      e.preventDefault();
      var $btn = $(this);
      $btn.addClass("follow-loading");
      if ($(this).hasClass("btn-following")) {
        $.post(
          "/unfollow",
          {
            follower_id: $btn.data("follower-id"),
            followee_id: $btn.data("followee-id"),
            authenticity_token: $("meta[name=csrf-token]").attr("content"),
          },
          function (response) {
            $btn.removeClass("btn-following");
            $btn.removeClass("follow-loading");
          }
        );
      } else {
        $.post(
          "/follow",
          {
            follower_id: $btn.data("follower-id"),
            followee_id: $btn.data("followee-id"),
            authenticity_token: $("meta[name=csrf-token]").attr("content"),
          },
          function (response) {
            $btn.addClass("btn-following");
            $btn.removeClass("follow-loading");
          }
        );
      }
    });
  });

  app.bindLikes = function (elements) {
    elements.click(function (e) {
      e.preventDefault();
      var likable_type = $(this).data("likable-type");
      var likable_id = $(this).data("likable-id");
      var path = "";
      if (likable_type == "FeedItem") {
        path = "feed_items";
      } else if (likable_type == "Photo") {
        path = "photos";
      } else if (likable_type == "Video") {
        path = "videos";
      } else if (likable_type == "Post") {
        path = "posts";
      } else if (likable_type == "Video") {
        path = "videos";
      } else {
        return;
      }
      var $btn = $(this);
      if (!$(this).hasClass("btn-like-remove")) {
        $.post(
          "/" + path + "/" + likable_id + "/vote",
          {
            vote: "1",
            authenticity_token: $("meta[name=csrf-token]").attr("content"),
          },
          function (response) {
            if (response.indexOf("Please log") == -1) {
              $("#" + likable_type + "_likes_" + likable_id).html(
                $(response).html()
              );
              $btn.removeClass("animation-like animation-remove-like");
              $btn.addClass("btn-like-remove animation-like");
            } else {
              alert(response);
            }
          }
        );
      } else {
        $.post(
          "/" + path + "/" + likable_id + "/vote_remove",
          {
            vote: "1",
            authenticity_token: $("meta[name=csrf-token]").attr("content"),
          },
          function (response) {
            if (response.indexOf("Please log") == -1) {
              $("#" + likable_type + "_likes_" + likable_id).html(
                $(response).html()
              );
              $btn.removeClass(
                "btn-like-remove animation-like animation-remove-like"
              );
              $btn.addClass("animation-remove-like");
            } else {
              alert(response);
            }
          }
        );
      }
    });
  };
  app.bindLikes($(".btn-like"));

  if ($(".summary-chart").length) {
    $(".summary-chart").each(function (i, obj) {
      var labels = $(obj).data("labels");
      var accuracy = $(obj).data("accuracy");
      var height = $(obj).data("height");
      var barminheight = $(obj).data("barminheight");
      var render_routecounts = $(obj).data("routecounts") === false ? false : true;
      var count_routes = $(obj).data("count-routes");
      if (count_routes) {
        var chart = new GradeChart(count_routes, {
          height: height,
          barminheight: barminheight,
          labels: labels,
          accuracy: accuracy,
          routecounts: render_routecounts,
        });
        $(obj).html(chart.getHTML());
      }
    });
  }

  $(".touch-hover").bind("mouseover mouseout", function (e) {
    e.preventDefault();
    $(this).toggleClass("hover");
  });
  var touchmoved;
  $(".touch-hover")
    .bind("touchend", function (e) {
      if (touchmoved != true) {
        if ($(this).hasClass("hover")) {
          var link = false;
          $(this)
            .find("a")
            .each(function (i, obj) {
              link = $(obj).attr("href");
            });
          if (link) {
            window.location = link;
          }
        } else {
          e.preventDefault();
          $(".touch-hover").removeClass("hover");
          $(this).addClass("hover");
        }
      }
    })
    .bind("touchmove", function () {
      touchmoved = true;
    })
    .on("touchstart", function () {
      touchmoved = false;
    });

  $(".btn-group-tabs .btn").on("click", function () {
    var $this = $(this);
    $this.parent().find(".active").removeClass("active");
    $this.addClass("active");
  });
  // used in route properties. Must do like this because of the from field names are different
  $(".checkbox-group .btn").livequery(function () {
    var $btn = $(this);
    var $checkbox = $btn.find('input[type="checkbox"]');

    function checkIn() {
      $btn.addClass("active");
      $btn.attr("aria-pressed", "true");
    }

    function checkout() {
      $btn.removeClass("active");
      $btn.attr("aria-pressed", "false");
    }

    function toggle() {
      if ($checkbox.is(":checked")) {
        $checkbox.prop("checked", false);
        checkout();
      } else {
        $checkbox.prop("checked", true);
        checkIn();
      }
    }

    $checkbox.each(function (i) {
      if ($(this).is(":checked")) {
        checkIn();
      } else {
        checkout();
      }
      $(this).hide();
    });

    $btn.on("click", function () {
      toggle();
    });
  });

  if ($(".facebox-open")[0]) {
    var $sender = $(".facebox-open").first();
    var url = $sender.data("url");
    app.dialog(url);
  }

  if ($(".facebox-open-onload")[0]) {
    var $sender = $(".facebox-open-onload").first();
    var openFacebox = function () {
      app.prepare_for_dialog();
      var options = {};
      $.facebox.loading();
      $.facebox.reveal($sender.html());
    };
    if ($sender.data("timeout")) {
      setTimeout(openFacebox, $sender.data("timeout"));
    } else {
      openFacebox();
    }
  }

  $("#climber_grading_system_sport").change(function () {
    //update preferred-grade
    var selected_system = $(this).val();
    var selected_grade = parseInt($("#climber_grade_int").val());
    $("#climber_grade_int").empty();
    var grades = [];
    var grade_ints = [];
    var new_selected_grade = 100;
    for (var grade in GRADES_HASH) {
      var localizedGrade = getLocalizedGrade(selected_system, grade);
      if (
        grade != 0 &&
        localizedGrade != null &&
        !grades.includes(localizedGrade)
      ) {
        grades.push(localizedGrade);
        grade_ints.push(grade);
        if (
          Math.abs(new_selected_grade - selected_grade) >=
          Math.abs(grade - selected_grade)
        ) {
          new_selected_grade = grade;
        }
      }
    }
    $.each(grades, function (i, val) {
      $("#climber_grade_int").append(
        $("<option></option>").val(grade_ints[i]).html(grades[i])
      );
    });
    $("#climber_grade_int").val(new_selected_grade);
  });

  $(document).bind("afterReveal.facebox", function () {
    $.each(["#facebox", "#subfacebox"], function (i, selector) {
      var jq = $(selector);
      if (jq.find(".footer input[name=cancel], .footer a.cancel").size() > 0)
        jq.find(".close").addClass("hidden");
      else jq.find(".close").removeClass("hidden");
    });
    app.bindLikes($(".btn-like"));

    $("#facebox #photo-details .show-edit-form").click(function () {
      $(this).parents(".photo-description").find(".edit-form").show();
      $(this).parents(".photo-description").find(".photo-details").hide();
      $.facebox.deActivateArrowKeyNavigation();
    });

    $("#facebox #photo-details-edit button[type=button]").click(function () {
      //$('#photo-details-edit').hide();
      //$('#photo-details').show();
      $(this).parents(".photo-description").find(".edit-form").hide();
      $(this).parents(".photo-description").find(".photo-details").show();
      $.facebox.activateArrowKeyNavigation();
    });

    /*$("#newphoto form").livequery(function () {
      if (!$(this).hasClass("dz-clickable")) {
        var name = $(this).find("input[type=file]").attr("name");
        var complete_url = $(this).data("complete-url");
        var dz = new Dropzone("#newphoto form", {
          url: $(this).attr("action"),
          maxFilesize: 8,
          paramName: name,
          dictDefaultMessage: "Drop images or click here to upload",
          queuecomplete: function () {
            window.location = complete_url;
          },
        });
      }
    });*/

    $("#photo-details-edit #photo_album").livequery(function () {
      var $self = $(this);
      new_autocomplete().activate(this, "search", function (o) {
        if (o.type == "Route") {
          $("#photo_route_id").val(o.searchable_id);
          $("#photo_album_type").val("Crag");
          $("#photo_album_id").val(o.crag_id);
        } else if (o.type == "Sector") {
          $("#photo_route_id").val("");
          $("#photo_album_type").val("Crag");
          $("#photo_album_id").val(o.crag_id);
        } else {
          $("#photo_route_id").val("");
          $("#photo_album_type").val(o.type);
          $("#photo_album_id").val(o.crag_id);
        }
      });
    });
  });

  // update cached item time
  $(".feed-item")
    .find("[data-ago]")
    .each(function () {
      var $ago = $(this).find(".ago");
      var current_time_ago = $(this).data("ago");
      $ago.text(current_time_ago);
    });

  $(document).on("click", "#add_many_link", app.add_many.open);
  app.add_many_routes.initialize();
  $(".scroll-nav").scrollNav();

  $(".multiline-read-more").readmore();

  $(".scroll-to").click(function (e) {
    e.preventDefault();
    var path = window.location.pathname;
    window.history.pushState({}, document.title, path + $(this).attr("href"));
    var $aTag = $("[id='" + $(this).attr("href").replace("#", "") + "']");
    $("html,body").animate(
      {scrollTop: $aTag.offset().top - 100},
      500,
      "swing"
    );
  });

  $(".stick-to-top").stickToTop();
  $(".full-screen-popup").fullScreenPopup();


  $('.quote-slides').livequery(function () {
    var swiper = new Swiper('.quote-slides', {
      autoplay: {
        delay: 5000
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      fadeEffect: {
        crossFade: true
      },
      loop: true
    });
  });

  $('.how-it-works-slides').livequery(function () {
    var swiper = new Swiper('.how-it-works-slides', {
      autoplay: {
        delay: 5000
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      loop: true
    });
  });

  $('.banner-slides').livequery(function () {
    var swiper = new Swiper('.banner-slides', {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true
    });
  });

  $(".jq-swiper-container").livequery(function () {
    var swiper = new Swiper(".jq-swiper-container", {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 0,
      grabCursor: true,
      preloadImages: false,
      updateOnImagesReady: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      lazy: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-nav--next",
        prevEl: ".swiper-nav--prev",
        disabledClass: "hidden",
      },
    });
  });

  $(".testimonials-swiper-container").livequery(function () {
    var swiper = new Swiper(".testimonials-swiper-container", {
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: "auto",
      spaceBetween: 40,
      breakpoints: {
        640: {
          slidesPerView: "auto",
          spaceBetween: 50
        }
      },
      speed: 800,
      grabCursor: true,
      preloadImages: false,
      updateOnImagesReady: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      lazy: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-nav--next",
        prevEl: ".swiper-nav--prev",
        disabledClass: "hidden",
      },
    });
  });
});

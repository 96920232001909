import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore from "../common/store";
import Container from "./Container";
import reducer from "./reducer";

class NotificationsMenu extends Component {
  constructor(props) {
    super(props);
    const store = configureStore(reducer(props.climberId));
    this.state = {
      store: store
    };
  }
  render() {
    return (
      <Provider store={this.state.store}>
        <Container
          climberId={this.props.climberId}
          climberParamid={this.props.climberParamid}
          unopenedCount={this.props.unopenedCount}
        />
      </Provider>
    );
  }
}

export default NotificationsMenu;

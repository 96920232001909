import React, { Component } from 'react';
import Landing from './Landing';
import GradeGenre from './GradeGenre';
import Information from './Information';
import Follow from './Follow';

class Onboarding extends Component {

  render() {
    const {
      climber,
      grades,
      gradingSystems,
      following,
      continueOnboarding,
      changeGenreSelected,
      changeGrade,
      changeGradingSystem,
      changeLocation,
      changeSex,
      toggleFollowEnabled,
      searchClimbers,
      searchLocations,
      showLocationResults,
      searchedClimbers,
      searchedLocations,
      searchDone,
      toggleFollow,
      fetchClimbersByLocation,
    } = this.props;

    const onboardingComponent = () => {
      switch (climber.onboarding_state) {
        default:
        case '0':
          return <Landing firstName={climber.first_name} moveForward={() => continueOnboarding('landing')} />;
        case '1':
          return (
            <GradeGenre
              climber={climber}
              grades={grades}
              gradingSystemsSport={gradingSystems.filter(system => system['Sport']).map(system => system['name'])}
              gradingSystemsBoulder={gradingSystems.filter(system => system['Boulder']).map(system => system['name'])}
              gradingSystemsTrad={gradingSystems.filter(system => system['Trad']).map(system => system['name'])}
              changeGenreSelected={changeGenreSelected}
              changeGrade={changeGrade}
              changeGradingSystem={changeGradingSystem}
              moveForward={() => continueOnboarding('grade_genre')}
            />
          );
        case '2':
          return (
            <Information
              climberLocationId={climber.location_id}
              sex={climber.sex}
              location={climber.home_town}
              changeLocation={changeLocation}
              changeSex={changeSex}
              searchLocations={searchLocations}
              showLocationResults={showLocationResults}
              searchedLocations={searchedLocations}
              moveForward={() => continueOnboarding('information')}
            />
          );
        case '3':
          return (
            <Follow
              location={climber.home_town}
              locationId={climber.location_id}
              fetchClimbersByLocation={fetchClimbersByLocation}
              following={following}
              followEnabled={climber.follow_enabled}
              toggleFollowEnabled={toggleFollowEnabled}
              searchClimbers={searchClimbers}
              searchedClimbers={searchedClimbers}
              searchDone={searchDone}
              toggleFollow={toggleFollow}
              moveForward={() => continueOnboarding('follow')}
            />
          );
        case '5':
          window.location.reload();
        case '4':
          return <div>Please wait...</div>;
      }
    };

    return onboardingComponent();
  }
}

export default Onboarding;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Feed from './GenericFeed';
import {
  toggleFeedItemLike,
  deleteFeedItem,
} from '../feed/actions';
import { fetchComments, toggleCommentsVisibility } from '../common/actions';

const setupFeedItems = (feedItems, climberId) => {
  for (let i = 0; i < feedItems.length; i += 1) {
    if (feedItems[i].climber_id === climberId) {
      feedItems[i].isOwn = true;
    }
  }
};

const mapStateToProps = (state) => {
  if (state.feed.currentClimber) {
    setupFeedItems(state.feed.feedItems, state.feed.currentClimber.id);
  }
  return ({
    currentClimber: state.feed.currentClimber,
    feedItems: state.feed.feedItems,
    addCommentPending: state.feed.addCommentPending,
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleFeedItemLike,
  deleteFeedItem,
  fetchComments,
  toggleCommentsVisibility,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Feed);

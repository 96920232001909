import * as types from "../common/actionTypes";

const userReducer = () => {
  const initialState = {};

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case types.SET_USER:
        return {
          ...state,
          id: payload.id,
          first_name: payload.first_name,
          last_name: payload.last_name,
          name: payload.name,
          grading_system_sport: payload.grading_system_sport,
          grading_system_boulder: payload.grading_system_boulder,
          grading_system_trad: payload.grading_system_trad,
          home_town: payload.home_town,
          location_id: payload.location_id,
          onboarding_state: payload.onboarding_state,
          param_id: payload.param_id,
          photo_url: payload.photo_url,
          climber_url: payload.climber_url,
          subscription: payload.subscription,
          onboardingProgressVisible: payload.onboarding_state < 4,
          notifications_unopened_count: payload.notifications_unopened_count
        };
      default:
        return state;
    }
  };
};

export default userReducer;

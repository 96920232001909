import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RouteListContainer from "./components/RouteListContainer";
import { filterRoutes, orderBy, searchPhotos } from "./actions";
import { syncUser } from "../user/actions";

const mapStateToProps = (state, ownProps) => ({
  routes: state.routeList.routes,
  filteredRoutes: state.routeList.filteredRoutes,
  query: ownProps.location.search,
  history: ownProps.history,
  noImageUrl: state.routeList.noImageUrl,
  searchedPhotos: state.routeList.searchedPhotos,
  gradingSystemSport: state.user.grading_system_sport,
  gradingSystemBoulder: state.user.grading_system_boulder,
  gradingSystemTrad: state.user.grading_system_trad
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      filterRoutes,
      orderBy,
      searchPhotos,
      syncUser
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteListContainer);

import React from 'react';
import Textarea from 'react-textarea-autosize';

const TextAreaComponent = ({ input, cols, maxLength, placeholder, submit }) => {
  const handleOnKeyDown = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      submit();
    }
  };

  return (
    <Textarea
      style={{ resize: 'none' }}
      value={input.value}
      onChange={e => input.onChange(e.target.value)}
      onKeyDown={handleOnKeyDown}
      cols={cols}
      maxLength={maxLength}
      type="text"
      placeholder={placeholder}
    />

  );
};

export default TextAreaComponent;


jQuery(document).ready(function ($) {
  $('.tab-bar__item').click(function(){
    var target = $(this).data('target');
    $('#'+target).siblings().removeClass('mobile-view--active');
    $(this).siblings().removeClass('tab-bar__item--active');
    
    $('#'+target).addClass('mobile-view--active');
    $(this).addClass('tab-bar__item--active');
  });
});
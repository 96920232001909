import React, { Component } from 'react'
import TableRow from './TableRow'
import GradeButton from './GradeButton'
import HoldsButton from './HoldsButton'
import StyleButton from './StyleButton'
import SteepnessButton from './SteepnessButton'
import RatingButton from './RatingButton'
import TypeButton from './TypeButton'
import OrderButton from './OrderButton'
import qs from 'query-string'
import { filterHash, routeListFieldHash } from '../../common/util.js'

class RouteList extends Component {
  constructor(props) {
    super(props)
    var filters = []
    const query = qs.parse(this.props.query)
    const queryKeys = Object.keys(query)
    for (let i = 0; i < queryKeys.length; i++) {
      if (Object.keys(filterHash).includes(queryKeys[i])) {
        filters.push(queryKeys[i])
      }
    }
    const orderQueries = query.order_by ? query.order_by.split('_') : ['name', 'asc']
    const orderDir = orderQueries[orderQueries.length - 1]
    const orderBy = orderQueries.length > 2 ? orderQueries.slice(0, -1).join('_') : orderQueries[0]
    const rating = query.rating ? query.rating.split(',') : [0, 3]
    const grades = Object.keys(GRADES_HASH)
    let gradeMin, gradeMax
    if (query.grade_min) {
      gradeMin = grades.includes(query.grade_min) ? parseInt(query.grade_min) : 100
    } else gradeMin = 100
    if (query.grade_max) {
      gradeMax = query.grade_max && grades.includes(query.grade_max) ? parseInt(query.grade_max) : 1500
    } else gradeMax = 1500
    this.state = {
      filters: [...filters],
      rating: rating,
      order_by: orderBy,
      order_dir: orderDir,
      grade_min: gradeMin,
      grade_max: gradeMax,
      photosFound: false,
      pageNo: 1,
      routesPerPage: 10,
    }
    this.changeGrades = this.changeGrades.bind(this)
    this.changeFilters = this.changeFilters.bind(this)
    this.changeRating = this.changeRating.bind(this)
    this.orderBy = this.orderBy.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (!qs.parse(this.props.query).system && 
      this.props.gradingSystemSport && 
      this.props.gradingSystemBoulder && 
      this.props.gradingSystemTrad) {
      const queryBeginning = this.props.query.length > 0 ? this.props.query + '&system=' : '?system='
      const systemQuery = queryBeginning + this.props.gradingSystemSport + 
          ',' + this.props.gradingSystemBoulder + ',' + this.props.gradingSystemTrad
      this.props.history.push(systemQuery)
    }
    if (!this.state.photosFound) {
      this.searchPhotos(this.state.pageNo)
    }
  }

  searchPhotos(pageNo) {
    const ids = this.props.filteredRoutes.slice(0, this.state.routesPerPage * pageNo).map((route) => {
      return route.id
    })
    if (ids.length > 0) {
      this.props.searchPhotos(ids)
      this.setState({ photosFound: true })
    }
  }

  changeRating(rating) {
    this.setState({
      rating: rating,
      photosFound: false,
      pageNo: 1,
    })
    this.props.filterRoutes(
      this.state.filters,
      rating,
      this.state.grade_min,
      this.state.grade_max,
      this.state.order_by,
      this.state.order_dir,
    )
    var filterQuery = this.filterQuery(this.state.filters)
    const ratingQuery = '&rating=' + rating
    const systemQuery = qs.parse(this.props.query).system ? '&system=' + qs.parse(this.props.query).system : ''
    this.props.history.push(
      '?grade_min=' +
        this.state.grade_min +
        '&grade_max=' +
        this.state.grade_max +
        '&order_by=' +
        this.state.order_by +
        '_' +
        this.state.order_dir +
        ratingQuery +
        filterQuery +
        systemQuery,
    )
    this.props.orderBy(this.state.order_by, this.state.order_dir)
  }

  changeFilters(e) {
    const filters = this.state.filters
    var newFilters = []
    if (e.target.checked) {
      if (!filters.includes(e.target.value)) {
        newFilters = [...this.state.filters, e.target.value]
      }
    } else {
      newFilters = this.state.filters.filter((item, i) => e.target.value !== item)
    }
    this.props.filterRoutes(
      newFilters,
      this.state.rating,
      this.state.grade_min,
      this.state.grade_max,
      this.state.order_by,
      this.state.order_dir,
    )
    var filterQuery = this.filterQuery(newFilters)
    const ratingQuery = this.state.rating.length > 0 ? '&rating=' + this.state.rating : ''
    const systemQuery = qs.parse(this.props.query).system ? '&system=' + qs.parse(this.props.query).system : ''
    this.props.history.push(
      '?grade_min=' +
        this.state.grade_min +
        '&grade_max=' +
        this.state.grade_max +
        '&order_by=' +
        this.state.order_by +
        '_' +
        this.state.order_dir +
        ratingQuery +
        filterQuery +
        systemQuery,
    )
    this.setState({
      photosFound: false,
      pageNo: 1,
      filters: newFilters,
    })
    this.props.orderBy(this.state.order_by, this.state.order_dir)
  }

  filterQuery(filters) {
    var filterQuery = ''
    for (let i = 0; i < filters.length; i++) {
      filterQuery += '&' + filters[i] + '=1'
    }
    return filterQuery
  }

  orderBy(orderer) {
    orderer = orderer == "grade" ? "grade_int" : orderer
    const order_dir = this.state.order_dir == 'asc' && this.state.order_by == orderer ? 'desc' : 'asc'
    this.props.orderBy(orderer, order_dir)
    this.setState({
      order_dir: order_dir,
      order_by: orderer,
      photosFound: false,
      pageNo: 1,
    })
    const ratingQuery = this.state.rating.length > 0 ? '&rating=' + this.state.rating : ''
    const systemQuery = qs.parse(this.props.query).system ? '&system=' + qs.parse(this.props.query).system : ''
    this.props.history.push(
      '?grade_min=' +
        this.state.grade_min +
        '&grade_max=' +
        this.state.grade_max +
        '&order_by=' +
        orderer +
        '_' +
        order_dir +
        ratingQuery +
        this.filterQuery(this.state.filters) +
        systemQuery,
    )
  }

  findPhoto(id) {
    const searchedPhotos = this.props.searchedPhotos
    for (let i = 0; i < searchedPhotos.length; i++) {
      if (searchedPhotos[i].map((photo) => photo.id).includes(id)) {
        return searchedPhotos[i][searchedPhotos[i].map((e) => e.id).indexOf(id)]
      }
    }
    return ''
  }

  showMore() {
    this.searchPhotos(this.state.pageNo + 1)
    this.setState({
      pageNo: this.state.pageNo + 1,
    })
  }

  changeGrades(values) {
    const gradePoints = Object.keys(GRADES_HASH)
    const index_min = gradePoints.indexOf(values[0].toString())
    const index_max = gradePoints.indexOf(values[1].toString())
    const grade_min = gradePoints[index_min]
    const grade_max = gradePoints[index_max]
    this.setState({
      grade_min: grade_min,
      grade_max: grade_max,
      photosFound: false,
      pageNo: 1,
    })
    this.props.filterRoutes(
      this.state.filters,
      this.state.rating,
      grade_min,
      grade_max,
      this.state.order_by,
      this.state.order_dir,
    )
    var filterQuery = this.filterQuery(this.state.filters)
    const ratingQuery = this.state.rating.length > 0 ? '&rating=' + this.state.rating : ''
    const systemQuery = qs.parse(this.props.query).system ? '&system=' + qs.parse(this.props.query).system : ''
    this.props.history.push(
      '?grade_min=' +
        grade_min +
        '&grade_max=' +
        grade_max +
        '&order_by=' +
        this.state.order_by +
        '_' +
        this.state.order_dir +
        ratingQuery +
        filterQuery +
        systemQuery,
    )
    this.props.orderBy(this.state.order_by, this.state.order_dir)
  }

  render() {
    const { 
      filteredRoutes, 
      noImageUrl, 
      gradingSystemSport, 
      gradingSystemBoulder, 
      gradingSystemTrad, 
      query 
    } = this.props

    const arrow =
      this.state.order_dir == 'asc' ? (
        <i className="glyphicon glyphicon-chevron-up" />
      ) : (
        <i className="glyphicon glyphicon-chevron-down" />
      )
    let systemSport = gradingSystemSport ? gradingSystemSport : 'French'
    let systemBoulder = gradingSystemBoulder ? gradingSystemBoulder : 'Font'
    let systemTrad = gradingSystemTrad ? gradingSystemTrad : 'French'
    if (qs.parse(query).system) {
      const systems = qs.parse(query).system.split(',')
      systemSport = systems[0]
      systemBoulder = systems[1]
      systemTrad = systems[2]
    }

    return (
      <div className="routelist-container">
        <div id="overlay-div" className="overlay-div" />
        <div className="routelist__filter-buttons">
          <GradeButton
            gradingSystem={systemSport}
            changeGrades={this.changeGrades}
            gradeMin={this.state.grade_min}
            gradeMax={this.state.grade_max}
          />
          <HoldsButton changeFilters={this.changeFilters} filters={this.state.filters} />
          <SteepnessButton changeFilters={this.changeFilters} filters={this.state.filters} />
          <StyleButton filters={this.state.filters} changeFilters={this.changeFilters} />
          <RatingButton changeRating={this.changeRating} rating={this.state.rating} />
          <TypeButton changeFilters={this.changeFilters} filters={this.state.filters} />
          <OrderButton orderBy={this.orderBy} order_by={this.state.order_by} arrow={arrow} />
        </div>
        <div>
          <table className="table table-hover route-list">
            <thead className="hidden-xs">
              <tr role="row" className="">
                {Object.keys(routeListFieldHash).map((value, i) => {
                  return (
                    <th key={'tr' + i}>
                      <div className="routelist-table-header">
                        <div
                          onClick={(e) => {
                            this.orderBy(
                              Object.keys(routeListFieldHash).find(
                                (key) => routeListFieldHash[key] === e.target.textContent,
                              ),
                            )
                          }}
                          className="table-header-text premium-search-table-header"
                        >
                          {routeListFieldHash[value]}
                          {this.state.order_by == value && arrow}
                        </div>
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody aria-live="polite" aria-relevant="all">
              {filteredRoutes.length > 0 &&
                filteredRoutes.slice(0, this.state.routesPerPage * this.state.pageNo).map((obj, i) => {
                  let system = systemSport
                  if (obj.genre == 'Boulder') system = systemBoulder
                  else if (obj.genre == 'Traditional') system = systemTrad
                  return (
                    <TableRow
                      gradingSystem={system}
                      key={obj.id}
                      obj={obj}
                      noImageUrl={noImageUrl}
                      findPhoto={this.findPhoto(obj.id)}
                    />
                  )
                })}
            </tbody>
          </table>
          <div className="text-center">
            {this.state.pageNo * this.state.routesPerPage < filteredRoutes.length && (
              <a
                className="readmore-toggle"
                onClick={() => {
                  this.showMore()
                }}
              >
                Show more routes
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default RouteList

import React from 'react';

const CommercialMarker = (props) => {
  const {
    mapbox,
    id,
    name,
    icon,
    info, 
    distance_to_crag,
    photos,
    onClick,
    mapboxMarker,
    selectedCrag,
    cragPopupVisible,
  } = props;

  const photoUrl = photos && photos[0] ? photos[0].styles.size_s.url : '';

  return (
    <div
      className="crag-card travel-card"
      onClick={() => onClick()}
      onMouseEnter={() => {
        mapboxMarker.properties.icon = mapboxMarker.properties.iconHighlight;
        if (cragPopupVisible) {
          const crag = mapbox.cragMarkers.features[selectedCrag.id];
          mapbox.map.getSource('highlighted').setData({"type": "FeatureCollection", "features": [mapboxMarker, crag]});
        }
        else {
          mapbox.map.getSource('highlighted').setData({"type": "FeatureCollection", "features": [mapboxMarker]});
        }
      }}
      onMouseLeave={() => {
        if (cragPopupVisible) {
          const crag = mapbox.cragMarkers.features[selectedCrag.id];
          mapbox.map.getSource('highlighted').setData({"type": "FeatureCollection", "features": [crag]});
        }
        else {
          mapbox.map.getSource('highlighted').setData({"type": "FeatureCollection", "features": []});
        }
      }}
    >
      <div className="crag-card__content-container">
        <span className="thumbnail" style={{ color: 'red' }}>
          <span className="img" style={{ backgroundImage: photoUrl ? `url(${photoUrl})` : '' }} />
        </span>
        <span className="details-container">
          <span className="title">
            <span className="name"><img src={icon} /> {name}</span>
          </span>
          {distance_to_crag && (
            <span className="distance"><i className="glyphicon glyphicon-person-walking" /> {distance_to_crag}</span>
          )}
          <span className="info">{info}</span>
        </span>
      </div>
    </div>
  );
};

export default CommercialMarker;

import * as types from '../common/actionTypes';
import commentReducer from '../common/reducers/commentReducer';

const feed = (feedItem, _currentClimber, photoUrl, apiUrl) => {

  const initialState = {
    feedItem,
    comments: [],
    commentsVisible: null,
    apiUrl,
    currentClimber: { ..._currentClimber, photoUrl },
    deleteDialogVisible: false,
    fetching: false,
    fetched: false,
    error: null,
    deleted: false,
    waitingForDelete: false,
    addCommentPending: false
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `${types.TOGGLE_FEED_ITEM_LIKE}_FULFILLED`:
        return {
          ...state,
          error: null,
          feedItem: {
            ...state.feedItem,
            likes: payload.data.feed_item.likes,
            vote_sum: payload.data.feed_item.vote_sum,
            likeStatus: !state.feedItem.likeStatus || state.feedItem.likeStatus === 'unlike' ? 'like' : 'unlike'
          }
        };
      case `${types.DELETE_FEED_ITEM}_PENDING`:
        return {
          ...state,
          waitingForDelete: true
        };
      case `${types.DELETE_FEED_ITEM}_FULFILLED`:
        return {
          ...state,
          error: null,
          deleted: true
        };
      case `${types.FETCH_COMMENTS}_PENDING`:
        return {
          ...state,
          feedItem: {
            ...state.feedItem,
            fetchingComments: true
          },
          commentsVisible: true
        };
      case `${types.FETCH_COMMENTS}_FULFILLED`:
        return {
          ...state,
          feedItem: { ...state.feedItem, fetchingComments: false },
          comments: commentReducer(state.comments, type, payload.result, state.currentClimber)
        };
      case `${types.TOGGLE_COMMENTS_VISIBILITY}`:
        return {
          ...state,
          commentsVisible: !state.commentsVisible,
        };
      case `${types.TOGGLE_DELETE_DIALOG_VISIBILITY}`:
        return {
          ...state,
          deleteDialogVisible: !state.deleteDialogVisible,
        };
      case `${types.ADD_COMMENT}_PENDING`:
        return {
          ...state,
          addCommentPending: true
        };
      case `${types.ADD_COMMENT}_FULFILLED`:
        return {
          ...state,
          addCommentPending: false,
          feedItem: {
            ...state.feedItem,
            comment_sum: state.feedItem.comment_sum + 1
          },
          comments: commentReducer(state.comments, type, payload.result, state.currentClimber)
        };
      case `${types.DELETE_COMMENT}_FULFILLED`:
        const sourceCommentDeleted = payload.result.data.comment.id === state.feedItem.source_id && state.feedItem.source_type === 'Comment';
        return {
          ...state,
          feedItem: {
            ...state.feedItem,
            comment_sum: state.feedItem.comment_sum === 0 ? 0 : state.feedItem.comment_sum - 1
          },
          comments: commentReducer(state.comments, type, payload.result, state.currentClimber),
          deleted: sourceCommentDeleted,
          waitingForDelete: sourceCommentDeleted
        };
      case `${types.EDIT_COMMENT}_PENDING`:
        return {
          ...state,
          addCommentPending: true
        };
      case `${types.EDIT_COMMENT}_FULFILLED`:
        return {
          ...state,
          addCommentPending: false,
          feedItem: {
            ...state.feedItem,
            isEditing: false
          },
          comments: commentReducer(state.comments, type, payload.result, state.currentClimber)
        };
      case types.START_COMMENT_EDITING:
        return {
          ...state,
          feedItem: {
            ...state.feedItem,
            isEditing: true,
            editableCommentId: payload.commentId
          }
        };
      case `${types.ADD_COMMENT}_REJECTED`:
      case `${types.EDIT_COMMENT}_REJECTED`:
        return { ...state, error: payload, addCommentPending: false };
      case `${types.ADD_FEED_ITEM}_REJECTED`:
      case `${types.DELETE_FEED_ITEM}'_REJECTED`:
      case `${types.FETCH_FEED_ITEMS}_REJECTED`:
      case `${types.TOGGLE_FEED_ITEM_LIKE}_REJECTED`:
        return {
          ...state,
          error: payload,
          deleted: false,
          waitingForDelete: false
        };
      default:
        return state;
    }
  }
};

export default feed;

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {fetchComments} from '../common/actions';
import RouteComments from './RouteComments';

const mapStateToProps = (state) => ({
  currentClimber: state.comments.currentClimber,
  apiUrl: state.comments.apiUrl,
  routeId: state.comments.routeId,
  comments: state.comments.comments,
  isEditing: state.comments.isEditing,
  editable: state.comments.editable,
  fetching: state.comments.fetching,
  editableCommentId: state.comments.editableCommentId,
  addCommentPending: state.comments.addCommentPending
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchComments,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RouteComments);

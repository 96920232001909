import React, { Component } from "react";
import { filterHash } from "../../common/util.js";
import StarSpan from "../../common/components/StarSpan";
class NameField extends Component {
  tags() {
    const parent = [];
    const filters = Object.keys(filterHash);
    const name = this.props.obj.name;
    for (let i = 0; i < filters.length; i++) {
      if (this.props.obj[filters[i]] == true) {
        parent.push(
          <span
            key={name + i}
            className={"tag " + filters[i] + " show-tooltip"}
            title={filterHash[filters[i]]}
          >
            <i className={"icon icon27 icon-" + filters[i]} />
          </span>
        );
      }
    }
    return parent;
  }

  render() {
    const { obj, stars, localizedGrade } = this.props;
    const noImageUrl = this.props.noImageUrl;
    const route_path =
      "/crags/" + obj.crag_param_id + "/routes/" + obj.param_id;
    const photo = this.props.photo.photo_url;

    return (
      <a href={route_path}>
        <div className="hidden">{obj.name}</div>
        <div className="tiny-topo-image">
          <img src={photo ? photo : noImageUrl} />
        </div>
        <div className="route-block">
          <div className="flex-container">
            <div className="route-block__name_container">
              <div className="route-block__name">
                {obj.name}
                <div className="visible-xs-inline-block">
                  , {localizedGrade}{" "}
                  <StarSpan rating={obj.rating} spanClass={"stars star-span"} />
                </div>
              </div>
              <div className="visible-xs-block route-block__description">
                <p className="route-details">
                  {obj.genre} at {obj.crag_name}
                </p>
              </div>
            </div>
            <div className="route-block__properties">
              <div className="visible-xs-inline-block route-property">
                <div className="tags visible-xs-inline-block">
                  {this.tags()}{" "}
                </div>
              </div>
              {false && (
                <div className="visible-xs-inline-block route-property tags">
                  <span className={"show-tooltip"} title="Ascents">
                    {obj.ascents_done_count}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default NameField;

import React from 'react';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import timeago from 'timeago.js';
import configureStore from '../common/store';
import feedItemReducer from './reducer';
import FeedItemContainer from './Container';

const FeedItemApp = (railsProps) => {
  const feedItem = JSON.parse(railsProps.feedItem);
  const currentClimber = JSON.parse(railsProps.currentClimber);

  if (currentClimber) {
    for (let i = 0; i < feedItem.likes.likes.length; i += 1) {
      if (feedItem.likes.likes[i].climber_id === currentClimber.id) {
        feedItem.likeStatus = 'liked';
        break;
      }
    }
  }

  const reducer = combineReducers({
    feed: feedItemReducer(
      feedItem,
      currentClimber,
      railsProps.photoUrl,
      railsProps.apiUrl,
    ),
    form: formReducer,
  });

  const time = new timeago();

  return (
    <Provider store={configureStore(reducer)}>
      <FeedItemContainer timeago={time.format} />
    </Provider>
  );
};

export default FeedItemApp;

import React , { Component } from 'react';

const Start = (props) => {
    const { climber_name, year, images, onNextClick } = props;

    return (
        <div className={'slide-content'}>
            <p className={'description'}>
                {climber_name}
            </p>
            <h1>
                Your Climbing Statistics {year}
            </h1>
            <img className={'icon-swipe'} src={images.swipe} />
            <p>Swipe to navigate or press continue.</p>
            <a className={'btn-continue'} onClick={onNextClick}>
                Continue
            </a>
        </div>
    );
}

export default Start;
/**
 * Created by villemuittari on 13/10/15.
 */
import map_marker_sport from '../../../javascript/images/map_marker_sport.png'
import map_marker_trad from '../../../javascript/images/map_marker_trad.png'
import map_marker_boulder from '../../../javascript/images/map_marker_boulder.png'
import map_marker_sport_active from '../../../javascript/images/map_marker_sport_active.png'
import map_marker_trad_active from '../../../javascript/images/map_marker_trad_active.png'
import map_marker_boulder_active from '../../../javascript/images/map_marker_boulder_active.png'
import map_marker_cluster from '../../../javascript/images/map_marker_cluster.png'

window.WorldMapMarkers = function(_map){
  /* Marker icons */
  var markerIconsArr = [
    [map_marker_sport, map_marker_sport_active],
    [map_marker_trad, map_marker_trad_active],
    [map_marker_boulder, map_marker_boulder_active.png]
  ];


  var allMarkers = [];
  var filteredMarkers = [];
  var visibleMarkers = [];
  var highlighted_marker = null;
  var offsetX = ($('#mapControlsContainer').outerWidth() != 'undefined' ? $('#mapControlsContainer').outerWidth() : 0);
  var sortingFunction = sortByDistance;
  var hiddenMarkers = [];

  var filters = {
    type: [], // just set: sport, trad, boulder
    premium: false // premium crag or not
  };

  var map = _map;
  var googleMap = map.g_map;
  var markerClusterer = new MarkerClusterer(googleMap, [], {
    maxZoom:11,
    styles: [{
      url: map_marker_cluster,
      height: 40,
      width: 40,
      anchor: [40, 40],
      textColor: '#333',
      textSize: 12
    }]
  });
  var infoWindow = new google.maps.InfoWindow({
    content: ''
  });


  // remove margins from infowindow
  if($('#map.world').length) {
    google.maps.event.addListener(infoWindow, 'domready', function() {
      // Reference to the DIV which receives the contents of the infowindow using jQuery
      var $iwOuter = $('.gm-style-iw');

     $iwOuter.each(function(index, item){
        if($(item).find('.popup-crag-card').length > 0) {
          var $iw_c = $(item).children(':nth-child(1)');
          $iw_c.css({'overflow' : 'hidden', 'display': 'block'});
          $iw_c.children(':nth-child(1)').css({'overflow' : 'hidden', 'display': 'block'});

          var $iwBackground = $(item).prev();
          // Remove the background shadow DIV

          $iwBackground.children(':nth-child(2)').css({'display' : 'none'});
          // Remove the white background DIV
          $iwBackground.children(':nth-child(4)').css({'display' : 'none'});

          // move center arrow
          $(item).parent().parent().css({left: '20px'});// Moves the shadow of the arrow 76px to the left margin


          $iwBackground.children(':nth-child(1)').attr('style', function(i,s){ return s + 'margin-left: -20px !important;'});
          $iwBackground.children(':nth-child(3)').attr('style', function(i,s){ return s + 'margin-left: -20px !important;'});

          var $iwCloseBtn = $(item).next();
          $iwCloseBtn.css({
            opacity: '1', // by default the close button has an opacity of 0.7
            right: '57px', top: '19px', // button repositioning
          });
        }
      });

      // remove useless margins and paddings


      // The API automatically applies 0.7 opacity to the button after the mouseout event.
      // This function reverses this event to the desired value.
      //iwCloseBtn.mouseout(function(){
      //  $(this).css({opacity: '1'});
      //});
    });

    google.maps.event.addListener(googleMap, 'click', function(){
      infoWindow.close();
    });
  }


  var cragDetailsCache = CragDetailsCache.getInstance();

  function getCragMarker(crag){
    var route_counts = [crag.sport_count, crag.trad_n_p_count, crag.boulder_count];

    var max = route_counts[0];
    var maxIndex = 0;
    for (var i = 0; i < route_counts.length; i++) {
      if (route_counts[i] > max) {
        maxIndex = i;
        max = route_counts[i];
      }
    }
    return markerIconsArr[maxIndex];
  }

  function getLikes(crag){
    return crag.favourite_count;
  }

  function getRouteCount(crag){
    return crag.boulder_count + crag.sport_count + crag.trad_n_p_count;
  }

  function sortByDistance(markers) {
    var centerPosition = map.getMapCenter();
    markers.sort(function(markerA, markerB){
      var distA = (google.maps.geometry.spherical.computeDistanceBetween(centerPosition, markerA.position) / 1000).toFixed(2);
      var distB = (google.maps.geometry.spherical.computeDistanceBetween(centerPosition, markerB.position) / 1000).toFixed(2);
      return distA - distB;
    });
  }

  function sortMarkers(markers){
    sortByDistance(markers);
  }

  function filter(){
    // do filtering
    function isOnlyPremium(crag){
      if(filters.premium == true && ! crag.data.premium_topo_active) {
        return false;
      }
      return true;
    }

    function canAddToFiltered(crag){
      if(typeof hiddenMarkers[crag.data.id] != 'undefined') {
        return false;
      }
      if(filters.type.length < 1 && isOnlyPremium(crag)) {
        return true;
      }
      for(var i=0; i<filters.type.length; i++) {
        var type = filters.type[i];
        if(type == 'sport' && crag.data.sport_count > 0 && isOnlyPremium(crag)){
          return true;
        }
        else if(type == 'boulder' && crag.data.boulder_count > 0 && isOnlyPremium(crag)) {
          return true;
        }
        else if(type == 'trad' && crag.data.trad_n_p_count > 0 && isOnlyPremium(crag)) {
          return true;
        }
      }

      return false;
    }

    var filteredArr = [];
    for(var key in allMarkers) {
      if(canAddToFiltered(allMarkers[key])) {
        filteredArr.push(allMarkers[key]);
      }
    }
    filteredMarkers = filteredArr;
  }

  function getOffsetLatLng(latlng, offsetx, offsety){
    var scale = Math.pow(2, googleMap.getZoom());

    var worldCoordinateCenter = googleMap.getProjection().fromLatLngToPoint(latlng);
    var pixelOffset = new google.maps.Point((offsetx/scale) || 0, (offsety/scale) || 0)

    var worldCoordinateNewCenter = new google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y
    );

    return googleMap.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
  }

  function getBounds(){
    var bounds = googleMap.getBounds();
    var sw = getOffsetLatLng(bounds.getSouthWest(), -offsetX, 0);

    var offset_bounds = new google.maps.LatLngBounds(sw, bounds.getNorthEast())

    return offset_bounds;
  }

  this.resortVisibleMarkerList = function(){
    sortMarkers(visibleMarkers);
  };

  this.refreshVisibleMarkers = function() {
    visibleMarkers = [];
    var bounds = getBounds();
    for(var i = 0; i < filteredMarkers.length; i++){ // looping through my Markers Collection
      if(bounds.contains(filteredMarkers[i].position)){
        visibleMarkers.push(filteredMarkers[i]);
      }
      if(visibleMarkers.length > 50 && googleMap.getZoom() < 7) {
        visibleMarkers = [];
        return;
      }
    }
    sortMarkers(visibleMarkers);
  };

  this.addCragMarker = function(crag){
    // do not insert crags with null coordinates
    if(crag.latitude == null && crag.longitude == null) {
      return;
    }
    var marker_icons = getCragMarker(crag);
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(crag.latitude, crag.longitude),
      icon: marker_icons[0],
      title: crag.name,
      icon_normal: marker_icons[0],
      icon_highlight: marker_icons[1],
      highligh: function(){
        this.setIcon(this.icon_highlight);
        this.setZIndex(200);
      },
      resetHighlight: function(){
        this.setIcon(this.icon_normal);
        this.setZIndex(1);
      }
    });
    marker.data = crag;
    marker.addListener('click', function(){
      var crag_id = this.data.id;
      cragDetailsCache.getById(crag_id, function(_crag){
        var card = new CragCard(_crag);
        infoWindow.setContent(card.getPopupLayout());
        infoWindow.open(googleMap, marker);
      });
    });
    allMarkers[crag.id] = marker;
  };

  this.setCrags = function(crags){
    var _self = this;
    $.each(crags, function(i, crag) {
      _self.addCragMarker(crag);
    });
    this.refresh();
  };

  this.getAllMarkers = function(){
    return allMarkers;
  };

  this.getVisibleMarkers = function(){
    return visibleMarkers;
  };

  this.getFilteredMarkers = function(){
    return filteredMarkers;
  };

  this.renderMarkers = function(){

  };

  this.setFilters = function(filter){
    if($.isArray(filter.type)) {
      filters.type = [];
      $.each(filter.type, function(i, val){
        var str = String(val).toLocaleLowerCase();
        filters.type.push(str);
      });
    }
    filters.premium = filter.premium;
  };

  this.refresh = function(){
    filter();
    this.refreshVisibleMarkers();
    markerClusterer.clearMarkers();
    markerClusterer.addMarkers(filteredMarkers);
  };

  this.highlightMarker = function(id){
    if(typeof allMarkers[id] != 'undefined') {
      if(highlighted_marker && highlighted_marker != allMarkers[id]) {
        if(typeof highlighted_marker.resetHighlight != 'undefined') {
          highlighted_marker.resetHighlight();
        }
      }
      highlighted_marker = allMarkers[id];

      if(typeof highlighted_marker.highligh != 'undefined') {
        highlighted_marker.highligh();
      }
    }
  };

  this.hideMarker = function(id){
    hiddenMarkers[id] = id;
  };

  this.showMarker = function(id){
    if(typeof hiddenMarkers[id] == 'undefined') {
      hiddenMarkers.splice(id, 1);
    }
  };

}
import React, {Component} from 'react';
import axios from 'axios';
import qs from 'qs';
import {authenticityToken} from "../common/util";

axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken()

class ShareButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareDialog: false,
      screenshotWatcher: false,
      screenshotFailed: false,
      screenshotSuccess: false,
      shareLink: false,
      userId: this.props.user_id,
      climberId: this.props.climber_id,
      shareUrl: null,
      shareImage: null,
      linkCopied: false,
      loginDialog: false
    };

    this.modal = React.createRef();
    this.closeShareButton = React.createRef();
    this.closeFailedButton = React.createRef();
    this.closeLoginDialogButton = React.createRef();
    this.closeLinkButton = React.createRef();
    this.copyButton = React.createRef();

    this.closeShareBox = this.closeShareBox.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.createShareImage = this.createShareImage.bind(this);
    this.openShareDialog = this.openShareDialog.bind(this);
    this.openShareLink = this.openShareLink.bind(this);
    this.closeShareLink = this.closeShareLink.bind(this);
    this.closeShareDialog = this.closeShareDialog.bind(this);
    this.openScreenshotWatcher = this.openScreenshotWatcher.bind(this);
    this.closeScreenshotWatcher = this.closeScreenshotWatcher.bind(this);
    this.openScreenshotSuccess = this.openScreenshotSuccess.bind(this);
    this.closeScreenshotSuccess = this.closeScreenshotSuccess.bind(this);
    this.openScreenshotFailed = this.openScreenshotFailed.bind(this);
    this.closeScreenshotFailed = this.closeScreenshotFailed.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeLoginDialog = this.closeLoginDialog.bind(this);
    this.openLoginDialog = this.openLoginDialog.bind(this);
  }

  hasClipboard(){
    return navigator.clipboard ? true : false;
  }

  hasNativeShare(){
    return navigator.share ? true : false;
  }

  closeShareLink() {
    this.setState({
      shareLink: false
    });
  }

  openShareLink() {
    if(this.hasNativeShare()) {
      navigator.share({
        url: this.props.share_url
      }).catch(console.error);
    } else {
      this.setState({
        shareLink: true
      });
    }
  }

  closeShareDialog() {
    this.setState({
      shareDialog: false
    });
  }

  openShareDialog() {
      this.setState({
        shareDialog: true
       });
  }

  closeLoginDialog() {
    this.setState({
      loginDialog: false
    });
  }

  openLoginDialog() {
    this.setState({
      loginDialog: true
    });
  }

  closeScreenshotWatcher() {
    this.setState({
      screenshotWatcher: false
    });
  }

  openScreenshotWatcher() {
    this.setState({
      screenshotWatcher: true
    });
  }

  closeScreenshotFailed() {
    this.setState({
      screenshotFailed: false
    });
  }

  openScreenshotFailed() {
    this.setState({
      screenshotFailed: true
    });
  }

  closeScreenshotSuccess() {
    this.setState({
      screenshotSuccess: false
    });
  }

  openScreenshotSuccess() {
    this.setState({
      screenshotSuccess: true
    });
  }

  redirectToLogin() {
    window.location.replace(`${window.location.origin}/login`);
  }

  async copyToClipboard() {
    await navigator.clipboard.writeText(this.props.share_url).then(() => {
      this.setState({
        linkCopied: true
      });
      setTimeout(() => {
        this.setState({
          linkCopied: false
        });
      }, 2000);
    });
  }


  async createShareItem() {
    if (this.hasNativeShare() && this.state.userId != null) {
      this.closeShareDialog();
      this.closeScreenshotFailed();
      this.openScreenshotWatcher();
      const data = qs.stringify({share_item: {climber_id: this.state.climberId, original_url: window.location.href}});
      await axios.post(`/api/web01/share_items?${data}`)
        .then(result => result.data)
        .then(data => {
          this.pollShareItem(data.share_item, 6);
        });
    } else {
      this.closeShareDialog();
      this.openLoginDialog();
    }
  }

  async pollShareItem(shareItem, pollCount) {
    await axios.get(`/api/web01/share_items/${shareItem.id}`)
      .then(result => result.data)
      .then(data => {
        if (data.share_item.share_url) {
          this.createShareImage(data.share_item.share_url);
        } else {
          if (pollCount > 0) {
            setTimeout(() => {
              this.pollShareItem(shareItem, pollCount - 1);
            }, 2000);
          } else {
            if (this.state.shareUrl == null) {
              this.closeScreenshotWatcher();
              this.openScreenshotFailed();
            }
          }
        }
      });
  }

  async createShareImage(url) {
    await fetch(url)
      .then(r => r.blob())
      .then(blobFile => {
        let file = new File([blobFile], "annual_summary.png", {type: "image/png"});
        this.setState({
          shareUrl: url,
          shareImage: file
        });
        this.closeScreenshotWatcher();
        this.openScreenshotSuccess();
      });
  }

  async openShareBox() {
    await navigator.share({
      files: [this.state.shareImage]
    }).catch(console.error);
  }

  closeShareBox() {
    this.setState({
      shareDialog: false
    });
  }

  closeModal(event) {
    if(event.target === this.modal.current ||
        event.target === this.closeShareButton.current ||
        event.target === this.closeFailedButton.current ||
        event.target === this.closeLoginDialogButton.current ||
        event.target === this.closeLinkButton.current) {
      this.closeScreenshotWatcher();
      this.closeScreenshotSuccess();
      this.closeScreenshotFailed();
      this.closeShareDialog();
      this.closeShareLink();
      this.closeLoginDialog();
    }
  }

  getModalVisibility(){
    return this.state.shareDialog || this.state.screenshotWatcher || this.state.screenshotFailed || this.state.screenshotSuccess || this.state.shareLink || this.state.loginDialog;
  }

  render() {
    return (
      <div className="annual-summary-2021__share-container">
        <div ref={this.modal} style={{display: this.getModalVisibility() ? 'block' : 'none'}} onClick={ (event) => this.closeModal(event)} className="modal">
          {this.state.shareDialog &&
            <div className="share-dialog">
              <button onClick={this.openShareLink} className="btn btn-share-method">
                <span className="glyphicon glyphicon-link"/>
                Get link
              </button>
              <button onClick={() => { this.createShareItem(); }} className="btn  btn-share-method">
                <span className="glyphicon glyphicon-picture"/>
                Get screenshot
              </button>
              <div className="text-center">
                <button ref={this.closeShareButton} onClick={ (event) => this.closeModal(event)} className="btn btn-close">Cancel</button>
              </div>
            </div>}

          {this.state.loginDialog &&
          <div className="share-dialog">
            <div className="dialog-body">Please login to create share image. If you have logged in and you see this screen please try a different browser.</div>
            <button onClick={this.redirectToLogin.bind(this)} className="btn btn-share-method">
              <span className="glyphicon glyphicon-log-in"/>
              Login
            </button>
            <div className="row col text-center">
              <button ref={this.closeLoginDialogButton} onClick={ (event) => this.closeModal(event)} className="btn btn-close">Close</button>
            </div>
          </div>}

          {this.state.screenshotWatcher &&
            <div className="share-dialog">
              <div className="dialog-title">Get screenshot</div>
              <div className="text-center">
                <div className="screenshot-container">
                  <div className="spinner-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="dialog-body text-center">Generating screenshot</div>
              <div className="dialog-body text-center">Please wait</div>
            </div>}

          {this.state.screenshotFailed &&
            <div className="share-dialog">
              <div className="dialog-title">Get screenshot</div>
              <div className="dialog-body text-center">Screenshot creation failed ⚠️</div>
              <div className="dialog-body text-center">
                <a onClick={(event) => {this.createShareItem();}}>Try again.</a>
              </div>
              <div className="row col text-center">
                <button ref={this.closeFailedButton} onClick={ (event) => this.closeModal(event)} className="btn btn-close">Close</button>
              </div>
            </div>}

          {this.state.screenshotSuccess &&
            <div className="share-dialog">
              <div className="dialog-title">Get screenshot</div>
              <div className="text-center">
                <div className="screenshot-container">
                  <img src={this.state.shareUrl}/>
                </div>
              </div>
              <div className="dialog-body text-center">Screenshot is ready.</div>
              <div className={'help-block'}>Tag us in Instagram with <a href={"https://www.instagram.com/27cragsofficial/"} target={'_blank'}>@27cragsofficial</a></div>
              <div className="text-center">
                <button onClick={async () => { await this.openShareBox(); }}  className="btn btn-share" type="button" title="Share image">
                  <span>Share screenshot</span>
                </button>
              </div>
            </div>}

          {this.state.shareLink &&
            <div className="share-dialog">
              <div className="dialog-title">Get link</div>
              <form>
                <div className={'form-group'}>
                  <div className="input-group">
                    <input type="text" className="form-control"
                           value={this.props.share_url} disabled/>
                    {this.hasClipboard() &&
                      <span className="input-group-btn">
                        <button ref={this.copyButton} onClick={this.copyToClipboard} className="btn btn-default" type="button">
                          {this.state.linkCopied ? '✔️ Copied' : 'Copy link'}
                        </button>
                      </span>}
                  </div>
                </div>
              </form>
              <div className="row col text-center">
                <button ref={this.closeLinkButton} onClick={ (event) => this.closeModal(event)} className="btn btn-close">Cancel</button>
              </div>
            </div>}
        </div>

        <button onClick={this.openShareDialog} className="btn btn-share" type="button" title="Share">
          <svg className={'btn__share-icon'}>
            <use href="#share-icon"></use>
          </svg>
          <span>Share</span>
        </button>
        <svg className="hidden">
          <defs>
            <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                    strokeLinecap="round" strokeLinejoin="round" className="feather feather-share">
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
              <polyline points="16 6 12 2 8 6"></polyline>
              <line x1="12" y1="2" x2="12" y2="15"></line>
            </symbol>
          </defs>
        </svg>
      </div>
    );
  }
}
export default ShareButton;
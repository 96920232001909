import React from 'react';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import configureStore from '../common/store';
import feedReducer from '../feed/reducer';
import GenericFeedContainer from './Container';

const GenericFeedApp = (railsProps) => {
  const currentClimber = JSON.parse(railsProps.currentClimber);

  const setupFeedItems = () => {
    const feedItems = JSON.parse(railsProps.feedItemsJsonStr);
    if (currentClimber) {
      for (let i = 0; i < feedItems.length; i += 1) {
        for (let j = 0; j < feedItems[i].likes.likes.length; j += 1) {
          if (feedItems[i].likes.likes[j].climber_id === currentClimber.id) {
            feedItems[i].likeStatus = 'liked';
            break;
          }
        }
      }
    }
    return feedItems;
  };

  const reducer = combineReducers({
    feed: feedReducer(
      currentClimber,
      railsProps.photoUrl,
      railsProps.apiUrl,
      setupFeedItems(),
    ),
    form: formReducer,
  });

  return (
    <Provider store={configureStore(reducer)}>
      <GenericFeedContainer />
    </Provider>
  );
};

export default GenericFeedApp;

;(function ($, window, document, undefined) {

  var pluginName = "fullScreenPopup",
    dataKey = "plugin_" + pluginName;

  var Plugin = function (element, options) {

    this.element = element;

    this.options = {};

    this.init(options);

    var plugin = this;

    this.element.find('.btn-back').click(function () {
      plugin.hide();
    });
  };

  Plugin.prototype = {
    init: function (options) {
      $.extend(this.options, options);
      this.element.addClass('full-screen-popup--hidden');
    },

    show: function () {
      var $element =  this.element;
      $element.css({'display': 'block', 'opacity': 0});
      $element.animate(
        {
          opacity: 1
        },
        200,
        function(){
          $element.css({'display': 'block'});
          $element.removeClass('full-screen-popup--hidden');
        }
      );
    },

    hide: function () {
      var $element =  this.element;
      $element.css({'display': 'block', 'opacity': 1});
      $element.animate(
        {
          opacity: 0
        },
        200,
        function(){
          $element.css({'display': '', 'opacity': ''});
          $element.addClass('full-screen-popup--hidden');
        }
      );
    }
  };

  /*
   * Plugin wrapper, preventing against multiple instantiations and
   * return plugin instance.
   */
  $.fn[pluginName] = function (options) {

    var plugin = this.data(dataKey);

    // has plugin instantiated ?
    if (plugin instanceof Plugin) {
      // if have options arguments, call plugin.init() again
      if (typeof options !== 'undefined') {
        plugin.init(options);
      }
    } else {
      plugin = new Plugin(this, options);
      this.data(dataKey, plugin);
    }

    return plugin;
  };

}(jQuery, window, document));
/* This is a file for various small jQuery plugins */
(function($){
  
  $.ltIE9 = function() {
    return $.browser.msie && parseInt($.browser.version) < 9;
  }
  
  // http://malsup.com/jquery/gfeed/jquery.gfeed.js version 1.0.2 (5/11/2007)
  $.fn.gFeed = function(options) {
      var opts = jQuery.extend({
          target: this,
          max:   5   // max number of items per feed
      }, options || {});

      var g = new google.feeds.FeedControl();

      this.each(function() {
          var url = this.href || opts.url;
          var title = opts.title || this.title || $(this).text();
          g.addFeed(url, title);
          g.setNumEntries(opts.max);
          if ( $('#news-index').is(':visible') ) g.setLinkTarget(google.feeds.LINK_TARGET_BLANK);
      });

      $(opts.target).each(function() {
          g.draw(this, opts.tabs ? { drawMode: google.feeds.FeedControl.DRAW_MODE_TABBED } : null );
      });

      return this;
  };
  
  // insert text into a text area at the position of the caret
  $.fn.insertAtCaret = function (myValue) {
          return this.each(function(){
                  //IE support
                  if (document.selection) {
                          this.focus();
                          sel = document.selection.createRange();
                          sel.text = myValue;
                          this.focus();
                  }
                  //MOZILLA/NETSCAPE support
                  else if (this.selectionStart || this.selectionStart == '0') {
                          var startPos = this.selectionStart;
                          var endPos = this.selectionEnd;
                          var scrollTop = this.scrollTop;
                          this.value = this.value.substring(0, startPos) + myValue + this.value.substring(endPos, this.value.length);
                          this.focus();
                          this.selectionStart = startPos + myValue.length;
                          this.selectionEnd = startPos + myValue.length;
                          this.scrollTop = scrollTop;
                  } else {
                          this.value += myValue;
                          this.focus();
                  }
          });

  };
  
  // set maxlength for textareas
  jQuery.fn.maxlength = function(options) {
    
    this.closest('div').append('<div class="tainfo"></div>');
    
    function update(textarea) {
      if ( !$.isFunction(textarea.attr) ) textarea = $(this);
      var maxlength = parseInt(textarea.attr('class').match(/max:([0-9]*)/)[1]);
      if ( textarea.val().length > maxlength ) {
        textarea.val(textarea.val().substr(0, maxlength));
      }
      textarea.closest('div').find('.tainfo').html('(' + textarea.val().length + '/' + maxlength + ')');
    }
    
    this.each(function(i, element) {
      var $textarea = $(element);
      update($textarea);
      $textarea.bind('paste', function(){ setTimeout(function(){update($textarea);}, 200); });
      $textarea.keyup(update);
    });
    
    return this;
  }

})(jQuery);

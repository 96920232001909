window.LocalStorage = (function() {
  var instances = {};

  function __Storage(__name) {
    var name = __name;
    var value = null;

    if (typeof Storage !== "undefined" && !isSafari()) {
      var d = new Date();
      if (
        !window.localStorage.getItem(name) ||
        getCurrentExpireDate() < d.getTime()
      ) {
        window.localStorage.setItem(name + "Expire", getNewExpireDate());
        updateToLocalStorage();
      } else {
        value = JSON.parse(window.localStorage.getItem(name));
      }
    }

    var self = this;

    function getNewExpireDate() {
      var d = new Date();
      d.setHours(d.getHours() + 1);
      return d.getTime();
    }

    function getCurrentExpireDate() {
      if (!window.localStorage.getItem(name + "Expire")) {
        window.localStorage.setItem(name + "Expire", getNewExpireDate());
      }
      return window.localStorage.getItem(name + "Expire");
    }

    function updateToLocalStorage() {
      window.localStorage.removeItem(name);
      window.localStorage.setItem(name, JSON.stringify(value));
    }

    function isSafari() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }

    this.get = function() {
      return value;
    };

    this.set = function(__value) {
      value = __value;
      if (!isSafari()) {
        updateToLocalStorage();
      }
    };

    this.setExpireDate = function(__expire) {
      window.localStorage.setItem(name + "Expire", __expire);
    };
  }

  function createInstance(name) {
    var object = new __Storage(name);
    return object;
  }

  return {
    getInstance: function(name) {
      if (typeof instances[name] === "undefined") {
        instances[name] = createInstance(name);
      }
      return instances[name];
    }
  };
})();

export default LocalStorage;

import React from "react";
import RegularSearch from "../regularSearch/App";
import PremiumSearch from "../premiumSearch/App";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regularActive: true,
      popupVisible: false
    };
  }

  openRegularSearch() {
    this.setState({ regularActive: true });
  }

  openPremiumSearch() {
    this.setState({ regularActive: false });
  }

  openSearch() {
    this.setState({ popupVisible: true });
  }

  closeSearch() {
    this.setState({ popupVisible: false });
  }
  render() {
    return (
      <div className="navbar-search">
        <div
          className="navbar-search-button"
          onClick={this.openSearch.bind(this)}
        >
          {this.props.children}
        </div>
        <div
          className={`search-popup ${
            this.state.popupVisible ? "search-popup--visible" : ""
          }`}
        >
          <div
            className="search-popup-close-btn"
            onClick={this.closeSearch.bind(this)}
          >
            &nbsp;
          </div>
          <div className="container search-popup-container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 col-xs-12">
                <ul className="nav nav-tabs nav-tabs--premium" role="tablist">
                  <li
                    role="presentation"
                    className={this.state.regularActive ? "active" : ""}
                  >
                    <a
                      href="#"
                      role="tab"
                      onClick={this.openRegularSearch.bind(this)}
                    >
                      Regular Search
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={!this.state.regularActive ? "active" : ""}
                  >
                    <a
                      href="#"
                      role="tab"
                      onClick={this.openPremiumSearch.bind(this)}
                    >
                      Premium search
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className={`tab-pane ${
                      this.state.regularActive ? "active" : ""
                    }`}
                  >
                    <RegularSearch
                      active={
                        this.state.regularActive && this.state.popupVisible
                      }
                    />
                  </div>
                  <div
                    role="tabpanel"
                    className={`tab-pane ${
                      !this.state.regularActive ? "active" : ""
                    }`}
                  >
                    <PremiumSearch
                      areas={this.props.areas}
                      gradingSystem={this.props.gradingSystem}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Search;

import React, { Component } from 'react';
import CragsSelect from "../../components/CragsSelect";
import {Bar} from 'react-chartjs-2';
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

class TopoValueChart extends Component {
  constructor(props) {
    super(props);
    var cragFilter = [];
    this.props.crags.forEach(crag => {
      cragFilter.push(crag['id'].toString());
    });
    this.state = {
      cragFilter: [...cragFilter],
      startMonth: this.props.startMonth,
      endMonth: this.props.endMonth,
      paramId: this.props.paramId,
      topoValue: this.props.topoValue
    };
    this.changeCragFilters = this.changeCragFilters.bind(this);
    this.getChartTopoIncomeData = this.getChartTopoIncomeData.bind(this);
    this.onDateApply = this.onDateApply.bind(this);

    this.props.getTopoValueData(
      this.state.cragFilter,
      this.state.startMonth,
      this.state.endMonth,
      this.state.paramId,
    );
  }

  changeCragFilters(selectedCragIds) {
    this.setState({
      cragFilter: selectedCragIds,
    }, () => {
      this.pullChartData();
    });
  }

  onDateApply(event, picker){
    this.setState({
      startMonth: picker.startDate.format("YYYY-MM"),
      endMonth: picker.endDate.format("YYYY-MM"),
    }, () => {
      this.pullChartData();
    });
  }

  pullChartData(){
    this.props.getTopoValueData(
        this.state.cragFilter,
        this.state.startMonth,
        this.state.endMonth,
        this.state.paramId,
    );
  }

  getChartTopoIncomeData(sales){
    const income = [];
    const labels = [];
    const downloads = [];

    if (typeof sales['monthly_sales'] != 'undefined') {
      sales['monthly_sales'].forEach(sale => {
        income.push(sale['income']);
        downloads.push(sale['downloads']);
        labels.push(sale['month']);
      });
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Downloads',
          type: 'line',
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(255, 99, 132)',
          data: downloads,
        },
        {
          label: 'Income',
          backgroundColor: 'rgb(99,151,255)',
          borderColor: 'rgb(99,151,255)',
          data: income,
        },
      ]
    };

    return data;
  }

  render() {
    return (
      <div className="routelist-container">
        <div className="panel panel-default">
          <div className="panel-body">
            <div className={"row"}>
              <div className="col-sm-4">
                  <CragsSelect
                    crags={this.props.crags}
                    onFilterChange={this.changeCragFilters}
                  />
              </div>
              <div className="col-sm-4">
                <DateRangePicker
                    initialSettings={{
                      startDate: this.state.startMonth,
                      endDate: this.state.endMonth,
                      locale: {
                        format: 'YYYY-MM',
                      },
                      ranges: {
                        'Last 12 Months': [moment().subtract(11, 'months'), moment()],
                        'This Year': [moment().startOf('year'), moment(), moment()],
                        'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
                      },
                      showCustomRangeLabel: false
                    }}
                    onApply={this.onDateApply}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className={"row"}>
              <div className="col-xs-4">
                <div className="number-liftup number-liftup__white-bg">
                  <div className="name">Income</div>
                  <div className="number">€{this.props.topoValue['total_income']}</div>
                </div>
              </div>
            </div>
            <hr />
            <div className={"chart-wrapper"}>
              <div className={`chart-loader-animation ${(this.props.loading ? "" : "hidden")}`}>
                <div className="lds">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <Bar
                data={this.getChartTopoIncomeData(this.props.topoValue)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TopoValueChart;

import React from 'react';

const Comment = (props) => {

  const {
    id,
    content,
    created_at_ms,
    climber,
    isOwn,
    editable,
    onEdit,
    onDelete,
    timeago,
  } = props;

  const climberPhoto = climber.photo_url ? climber.photo_url
    : 'https://www.gravatar.com/avatar/8c52fa805ce342ac3a0eadcc575aee7a?s=80&d=mm';

  const editButtonStyle = {
    cursor: 'pointer',
  };

  const renderEditButtons = () => {
    if (isOwn || editable) {
      return (
        <div className="edit-buttons">
          <a style={editButtonStyle} onClick={() => onEdit()}>Edit</a> &bull; <a style={editButtonStyle}
                                                                                 onClick={() => onDelete()}>Delete</a>
        </div>
      );
    }
    return null;
  };

  return (
    <div key={id} className="item-content chat-item">
      <div className="user-image">
        <a href={`/climbers/${climber.param_id}`}>
          <img className="climber-thumb" src={climberPhoto} alt=""/>
        </a>
      </div>
      <div className="item-details">
        <div>
          <a className="climber-name"
             href={`/climbers/${climber.param_id}`}>{`${climber.first_name} ${climber.last_name}`}</a>
          <span className="date"> &bull; <span className="ago">{timeago(created_at_ms)}</span></span>
          <div className="pull-right">
            {renderEditButtons()}
          </div>
        </div>
        <div>
          {content.split('\n').map((item, i) => <span key={i}>{item}<br/></span>)}
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default Comment;

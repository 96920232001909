import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import configureStore, { configurePersistStore } from "../common/store";
import userReducer from "../user/reducer";
import Container from "./Container";

class NavApp extends React.Component {
  constructor(props) {
    super(props);
    const reducer = {
      user: userReducer()
    };
    const { store, persistor } = configurePersistStore("user", reducer, null, [
      "user"
    ]);
    this.state = {
      store: store,
      persistor: persistor
    };
  }
  render() {
    return (
      <Provider store={this.state.store}>
        <PersistGate persistor={this.state.persistor}>
          <Container railsProps={this.props} />
        </PersistGate>
      </Provider>
    );
  }
}

export default NavApp;

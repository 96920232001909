import axios from 'axios'

export const authenticityToken = () => {
  try {
    return document.querySelector(`meta[name="csrf-token"]`).content
  }
  catch(e) {
    return null;
  }
}
export const authenticityParam = () => {
  try {
    return document.querySelector('meta[name="csrf-param"]').content
  }
  finally {
    return null
  }
}

export const checkEmbed = (text, _result) => {
  const regex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
  //for (let i = 0; i < feedItems.length; i += 1) {
  const matchArray = text.match(regex)
  if (matchArray) {
    //for (let j = 0; j < matchArray.length; j += 1) {
    axios
      .get(`https://api.embedly.com/1/oembed?key=dfdb7a330d7447e5a9560f53bf7a2c37&url=${matchArray[0]}`)
      .then((result) => {
        _result({
          providerName: result.data.provider_name,
          description: result.data.description,
          thumbnailUrl: result.data.thumbnail_url,
          isLandscape: result.data.thumbnail_width > result.data.thumbnail_height,
          url: matchArray[0],
        })
      })
      .catch((err) => _result(null))
    //}
  } else {
    _result(null)
  }
  //}
}

export const getGradingSystem = (genre = null) => {
  if (["T", "P"].includes(genre)) {
    return $.cookie("gsys_trad") == null ? "French" : $.cookie("gsys_trad");
  } else if (genre == "B") {
    return $.cookie("gsys_boulder") == null ? "Font" : $.cookie("gsys_boulder");
  } else
    return $.cookie("gsys_sport") == null ? "French" : $.cookie("gsys_sport");
}

export const getLocalizedGrade = (system, grade, genre = null) => {
  if (system == null) {
    system = getGradingSystem(genre);
  }
  var grade_int = 0;
  if (typeof grade === "string") {
    for (var g in GRADES_HASH) {
      if (g === grade || GRADES_HASH[g].includes(grade)) {
        grade_int = parseInt(g);
        break;
      }
    }
  } else {
    grade_int = grade;
  }
  var grade_row = GRADES_HASH[grade_int];
  if (!grade_row) {
    return "?";
  }
  var index = 4;
  if (system && GRADE_SYSTEMS.indexOf(system) > -1) {
    index = GRADE_SYSTEMS.indexOf(system);
  }
  return grade_row[index];
}

export const convertGrade = (grade, system = 'French') => {
  return getLocalizedGrade(system, grade)
}

export const holdHash = {
  crimpers: 'Crimpers',
  slopers: 'Slopers',
  jugs: 'Jugs',
  pockets: 'Pockets',
  tufas: 'Tufas',
}
export const steepnessHash = {
  roof: 'Roof',
  overhang: 'Overhang',
  vertical: 'Vertical',
  slab: 'Slab',
}
export const styleHash = {
  fingery: 'Fingery',
  powerful: 'Powerful',
  dyno: 'Dyno',
  endurance: 'Endurance',
  technical: 'Technical',
  mental: 'Mental',
  traverse: 'Traverse',
  sitstart: 'Sitstart',
  topslasthold: 'Tops last hold',
  tradgear_required: 'Trad gear required',
  dangerous: 'Dangerous',
  crack: 'Crack',
}
export const typesArr = ['Boulder', 'Sport', 'Traditional']
export const filterHash = {
  Boulder: 'Boulder',
  Sport: 'Sport',
  Traditional: 'Traditional',
  crimpers: 'Crimpers',
  slopers: 'Slopers',
  jugs: 'Jugs',
  fingery: 'Fingery',
  powerful: 'Powerful',
  dyno: 'Dyno',
  endurance: 'Endurance',
  technical: 'Technical',
  mental: 'Mental',
  roof: 'Roof',
  overhang: 'Overhang',
  vertical: 'Vertical',
  slab: 'Slab',
  traverse: 'Traverse',
  sitstart: 'Sitstart',
  topslasthold: 'Tops last hold',
  tradgear_required: 'Trad gear required',
  dangerous: 'Dangerous',
  crack: 'Crack',
  pockets: 'Pockets',
  tufas: 'Tufas',
}
export const routeListFieldHash = {
  name: 'Name',
  grade: 'Grade',
  genre: 'Genre',
  ascents_done_count: 'Ascents',
  property: 'Features',
  rating: 'Rating',
  crag_name: 'Crag',
}
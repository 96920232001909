import React , { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ShareButton from "./ShareButton";
import SwiperCore, {
    Pagination,Navigation
} from 'swiper';

import Start from './Start';
import Summary from './Summary';
import GenreSummary from './GenreSummary';
import AscentHighlight from "./AscentHighlight";
import HardestAscents from "./HardestAscents";
import Thanks from "./Thanks";

SwiperCore.use([Pagination,Navigation]);

class AnnualSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            swiper: null,
            hideFooter: true,
            bg: 'dark'
        };
    }

    onSwiperInit(swiper) {
        this.setState({swiper: swiper});
        this.navigateToHashSlide(swiper);
        this.updateFooterVisibility(swiper);
        this.updateHeaderClass(swiper);
    }

    onSlideChange(swiper) {
        location.hash = this.getHashFromSlide(swiper, swiper.activeIndex);
        this.updateFooterVisibility(swiper);
        this.updateHeaderClass(swiper);
    }

    getHashFromSlide(swiper, index){
        return swiper.slides[index].getAttribute('data-hash');
    }

    navigateToHashSlide(swiper){
        const currentHash = location.hash.replace('#', '');
        const len = swiper.slides.length;
        for(let i=0; i < len; i++) {
            if(currentHash == this.getHashFromSlide(swiper, i)) {
                swiper.slideTo(i, 0, () => {});
                break;
            }
        }
    }

    updateFooterVisibility(swiper){
        const first = 0;
        this.setState({
            hideFooter: (swiper.activeIndex == first || !this.props.share_url)
        });
    }

    updateHeaderClass(swiper) {
        const currentSlide = swiper.slides[swiper.activeIndex];
        this.setState({
          bg: (currentSlide.classList.contains('bg-dark') ? 'dark' : 'light')
        });
    }

    onNextClick() {
        this.state.swiper.slideNext();
    }

    render() {
        return(
            <div>
                <div className={`annual-summary-container bg-${this.state.bg} ${this.props.hide_controls ? 'hide-controls' : ''}`}>
                    <div className={`header header--bg-${this.state.bg}`}>
                        <a href={'/'} className={'logo'}><img src={this.state.bg == 'dark' ? this.props.images.logo_dark_bg : this.props.images.logo_light_bg} /></a>
                        <div className={'title'}>
                            <div className={'climber_name'}>{this.props.climber_name}</div>
                            <div className={'app_name'}>Climbing Statistics 2022</div>
                        </div>
                    </div>
                    <Swiper
                        navigation={true}
                        onSlideChange={(swiper) => this.onSlideChange(swiper)}
                        onSwiper={(swiper) => this.onSwiperInit(swiper)}
                    >
                        <SwiperSlide data-hash="start" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                            <div className={'slide-container'}>
                                <Start {...this.props} onNextClick={() => this.onNextClick()}/>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide data-hash="summary" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                            <div className={'slide-container'}>
                                <Summary {...this.props} onNextClick={() => this.onNextClick()}/>
                            </div>
                        </SwiperSlide>
                        {(this.props.genre_counts['Sport'] > 0) && (
                            <SwiperSlide data-hash="ascents-sport" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <GenreSummary {...this.props} genre={'Sport'} gradingSystem={this.props.grading_system_sport} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        )}
                        {(this.props.genre_counts['Traditional'] > 0) && (
                            <SwiperSlide data-hash="ascents-trad" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <GenreSummary {...this.props} genre={'Traditional'} gradingSystem={this.props.grading_system_trad} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        )}
                        {(this.props.genre_counts['Boulder'] > 0) > 0 && (
                            <SwiperSlide data-hash="ascents-boulder" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <GenreSummary {...this.props} genre={'Boulder'} gradingSystem={this.props.grading_system_boulder} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        )}
                        {(this.props.genre_counts['DWS']) > 0 && (
                            <SwiperSlide data-hash="ascents-dws" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <GenreSummary {...this.props} genre={'DWS'} gradingSystem={this.props.grading_system_sport} onNextClick={() => this.onNextClick()}/>
                            </SwiperSlide>
                        )}
                        {this.props.trad_highlight != null &&
                            <SwiperSlide data-hash="trad-highlight" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <AscentHighlight ascent={this.props.trad_highlight} year={this.props.year} gradingSystem={this.props.grading_system_trad} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        }
                        {this.props.sport_highlight != null &&
                            <SwiperSlide data-hash="sport-highlight" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <AscentHighlight ascent={this.props.sport_highlight} year={this.props.year} gradingSystem={this.props.grading_system_sport} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        }
                        {this.props.boulder_highlight != null &&
                            <SwiperSlide data-hash="boulder-highlight" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <AscentHighlight ascent={this.props.boulder_highlight} year={this.props.year} gradingSystem={this.props.grading_system_boulder} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        }
                        {(this.props.dws_highlight != null &&
                            <SwiperSlide data-hash="dws-highlight" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <AscentHighlight ascent={this.props.dws_highlight} year={this.props.year} gradingSystem={this.props.grading_system_dws} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        )}
                        {this.props.ascents.length > 0 &&
                            <SwiperSlide data-hash="hardest-ascents" className={'bg-dark'} style={{backgroundColor: '#222222'}}>
                                <HardestAscents {...this.props} onNextClick={() => this.onNextClick()} />
                            </SwiperSlide>
                        }
                        <SwiperSlide data-hash="thanks" className={'bg-light'} style={{backgroundColor: '#F0DB0F'}}>
                            <div className={'slide-container'}>
                                <Thanks {...this.props} />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className={'footer'} style={{visibility: (this.state.hideFooter ? 'hidden' : 'visible')}}>
                        {this.props.public_tick_list ?
                            <ShareButton {...this.props}/>
                            : <p className={'help-block'}>This page is private. To share your summary, set your tick list to public.</p>
                        }

                    </div>
                </div>
            </div>
        );
    }
};

export default AnnualSummary;

import React from 'react';
import timeago from 'timeago.js';
import Comment from './Comment';
import CommentForm from './CommentForm';

const Comments = (props) => {
  const {
    commentableId,
    feedItemId,
    currentClimber,
    comments,
    isEditing,
    editable,
    editableCommentId,
    addComment,
    fetchComments,
    startCommentEditing,
    editComment,
    deleteComment,
    type,
    refreshable,
    fetching,
    displayCommentsLoading,
    commentSum,
    addCommentPending,
  } = props;

  const url = '/api/web01/comments/';

  const time = new timeago();

  const renderComments = comments.map(comment => (
    <Comment
      key={comment.id}
      {...comment}
      timeago={time.format}
      editable={editable}
      onEdit={() => startCommentEditing(comment.id, comment.content, feedItemId)}
      onDelete={() => deleteComment(url, comment.id, feedItemId)}
    />
  ));

  const setRequestBody = (values) => {
    let body;
    switch (type) {
      case 'Comment':
        body = `comment_id=${commentableId}`;
        break;
      case 'Route':
        body = `route_id=${commentableId}`;
        break;
      case 'Post':
      case 'Video':
      case 'Photo':
        body = `id=${commentableId}&type=${type}`;
        break;
      default:
        body = `id=${commentableId}&type=FeedItem`;
        break;
    }
    return `${body}&comment=${encodeURIComponent(values.comment)}`;
  };

  const handleFormSubmit = (values) => {
    if (isEditing) {
      editComment(url, {commentId: editableCommentId, content: values.comment}, feedItemId);
    } else {
      addComment(url, setRequestBody(values), feedItemId);
    }
  };

  const refreshButtonStyle = {
    display: 'block',
    margin: '10px 50px',
    cursor: 'pointer',
  };

  const refreshButton = () => {
    if (refreshable) {
      return (
        <span style={refreshButtonStyle}>
          <a onClick={() => fetchComments()}>
            <span className="glyphicon glyphicon-refresh"/>
            {' Refresh'}
          </a>
        </span>
      );
    }
    return null;
  };

  return (
    <div className="chat">
      <div className="chat-container">
        {
          fetching && displayCommentsLoading ? (
            <p>Loading comments...</p>
          ) : (
            renderComments
          )
        }
      </div>
      <div className="chat-container refreshable">
        {refreshButton()}
        {currentClimber.paramId ? (
          <CommentForm
            climber={currentClimber}
            onSubmit={handleFormSubmit}
            isEditing={isEditing}
            form={`commentForm-${feedItemId}`}
            disabled={addCommentPending}
          />
        ) : (
          <p><a href="/login">Sign up</a> to comment</p>
        )}
      </div>
    </div>
  );
};

export default Comments;

import React, { Component } from "react";

class ToggleFilterButton extends Component {
  render() {
    const { active, value, onChange, content } = this.props;
    const inputClass = active
      ? "btn btn-primary checkbox-label"
      : "btn btn-default checkbox-label";
    return (
      <li className="premium-search__checkbox-item">
        <label>
          <input
            type="checkbox"
            value={value}
            checked={active}
            onChange={e => onChange(e)}
          />
          <div className={inputClass}>{this.props.children}</div>
        </label>
      </li>
    );
  }
}

export default ToggleFilterButton;

/* The first argument can be a json hash:
 * map:     {latitude, longitude, zoom},
 * crags:   [{name, latitude, longitude, url, photo_url}...],
 * sw:      sw bounds for the markers
 * ne:      ne bounds for the markers
 */
window.AreaMap = function() {
  
  var json = arguments[0] || {};
  var map = WorldMap(json);
  var g_map = map.g_map;
  var center = g_map.getCenter();
  
  if ( json.sw && json.ne ) {
    var sw = new google.maps.LatLng(json.sw.latitude, json.sw.longitude);
    var ne = new google.maps.LatLng(json.ne.latitude, json.ne.longitude);
    var marker_bounds = new google.maps.LatLngBounds(sw, ne);
    if (json.map.circle_radius) {
      draw_circle();
    }
    app.initialize_area_focus(g_map, sw, ne, 7);
  }

  function draw_circle() {
    var circle = new google.maps.Circle({
      strokeOpacity: 0,
      fillColor: '#FF0000',
      fillOpacity: 0.2,
      map: g_map,
      center: {
        lat: parseFloat(json.crag.latitude),
        lng: parseFloat(json.crag.longitude),
      },
      radius: json.map.circle_radius * 1000,
    });
  }
  
  function out_of_area() {
    return false;
    var viewport = g_map.getBounds();
    if ( !viewport ) return false;
    return !(marker_bounds.contains(viewport.getNorthEast()) && marker_bounds.contains(viewport.getSouthWest()));
  }
  
  function new_area(latlng) {
    latlng = latlng || g_map.getCenter();
    // return (g.computeDistanceBetween(center, latlng) > 50000); // 50 km
    var g = google.maps.geometry.spherical;
    var distanceLimit = g.computeDistanceBetween(marker_bounds.getCenter(), latlng) / 2.0;
    return !(marker_bounds.contains(g.computeOffset(latlng, distanceLimit, 225)) && marker_bounds.contains(g.computeOffset(latlng, distanceLimit, 45)));
  }
  
  // number of levels to zoom out before more markers are fetched
  function levels_to_go() {
    return (g_map.getZoom() - map.to_go_limit);
  }
  
  g_map.set('scaleControl', true);
  
  return $.extend(map, {
    center        : center, // the original map center (as it was before possible dragging)
    marker_bounds : marker_bounds,
    levels_to_go  : levels_to_go,
    out_of_area   : out_of_area,
    new_area      : new_area
  });
  
}

import React from 'react'
import GradeChart from './GradeChart'

const Crag = props => {
  const {
    id,
    name,
    location,
    param_id,
    photo,
    premium_topo_active,
    dws_count,
    sport_count,
    trad_n_p_count,
    boulder_count,
    likes_count,
    grade4_count,
    grade5_count,
    grade6_count,
    grade7_count,
    grade8_count,
    grade9_count,
    onMouseEnter,
    onMouseLeave,
    newTab,
  } = props

  const photoUrl = photo ? photo.styles.size_s.url : ''

  const gradeCounts = [grade4_count, grade5_count, grade6_count, grade7_count, grade8_count, grade9_count]

  return (
    <div
      className="crag-card"
      onMouseEnter={() => {
        if (onMouseEnter) {
          onMouseEnter(id)
        }
      }}
      onMouseLeave={() => {
        if (onMouseLeave) {
          onMouseLeave(id)
        }
      }}
    >
      <a href={`${newTab ? 'https://27crags.com' : ''}/crags/${param_id}`} target={newTab ? '_blank' : undefined} className={`crag-card__content-container`}>
        <span className="thumbnail" style={{ color: 'red' }}>
          <span className="img" style={{ backgroundImage: photoUrl ? `url(${photoUrl})` : '' }}>
            {premium_topo_active ? <span className="tag-premium-topo">Premium</span> : ''}
          </span>
        </span>
        <span className="details-container">
          <span className="title">
            <span className="name">{name}</span>
            <span className="area">{location ? `, ${location.name}` : ''}</span>
          </span>
          <span className="route-count-container">
            {sport_count > 0 && (
              <span className="route-count">
                <i className="icon27 icon-sport" />
                {` ${sport_count}`}
              </span>
            )}
            {trad_n_p_count > 0 && (
              <span className="route-count">
                <i className="icon27 icon-trad" />
                {` ${trad_n_p_count}`}
              </span>
            )}
            {boulder_count > 0 && (
              <span className="route-count">
                <i className="icon27 icon-boulder" />
                {` ${boulder_count}`}
              </span>
            )}
            {dws_count > 0 && (
              <span className="route-count">
                <i className="icon27 icon-dws" />
                {` ${dws_count}`}
              </span>
            )}
            {likes_count > 0 && (
              <span className="route-count">
                <i className="glyphicon glyphicon-heart-empty" />
                {` ${likes_count}`}
              </span>
            )}
          </span>
          <GradeChart gradeCounts={gradeCounts} options={{ height: 65 }} />
        </span>
      </a>
    </div>
  )
}

export default Crag

/* MapPointMarker uses this interface to notify the symbol about user interaction */
window.MapPointObserver = function(symbol) {

  var info_window_is_open = false;

  function info_window_closed() {
    info_window_is_open = false;
  }

  // if the info window was closed directly by google (or the user)
  function info_window_closed_unexpectedly() {
    info_window_closed();
    symbol.reset_or_remove();
  }

  function info_window_opened() {
    info_window_is_open = true;
    symbol.update_form(symbol.markers.first);
    if ( symbol.overlay() ) symbol.overlay().update_form();
    symbol.ajaxify_form();
  }

  function dragging_started(caller) {
    MapTooltip.off(caller);
    if ( symbol.overlay() ) symbol.overlay().follow_dragging();
    if ( info_window_is_open ) symbol.markers.close_info_window();
    else app.map.close_info_window();
    symbol.to_dom();
  }

  function dragging_stopped(caller) {
    if ( symbol.overlay() ) symbol.overlay().stop_following();
    symbol.reveal(caller); // updates the form (if it has loaded already)
    MapTooltip.on();
  }

  return {
    dragging_started: dragging_started,
    dragging_stopped: dragging_stopped,
    info_window_closed: info_window_closed,
    info_window_closed_unexpectedly: info_window_closed_unexpectedly,
    info_window_opened: info_window_opened,
    info_window_is_open: function(){ return info_window_is_open; }
  };

};

import React from "react";

class SearchField extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate() {
    if (this.props.active) {
      document.getElementById("regular-search-input").focus();
    }
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      const query = e.target.value;
      window.location.href = "/site/search?qs=" + query;
    }
  }

  render() {
    return (
      <div className="reqular-search">
        <div id="searchbox" className="input-group reqular-searchbox">
          <input
            id="regular-search-input"
            type="search"
            aria-label="Search climbing places"
            className="reqular-search-text"
            placeholder="Search crags, routes, climbers etc."
            value={this.props.query}
            onChange={this.props.updateQuery}
            onKeyDown={e => this.handleKeyDown(e)}
            ref={input => input && input.focus()}
          />
        </div>
      </div>
    );
  }
}

export default SearchField;

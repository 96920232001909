import * as types from "../../common/actionTypes";
import axios from "axios";
import * as apiEndpoints from "../../common/apiEndpoints";

export const getVisitsData = (cragIds, startDate, endDate, paramId) => (dispatch, getState) => {

  if (cragIds.length > 0) {
    var ids = cragIds[0];
    for (let i = 1; i < cragIds.length; i++) {
      ids += ',' + cragIds[i];
    }
    dispatch({
      type: types.GET_VISITS_DATA,
      payload: axios.get(apiEndpoints.crag_visit_analytics_path(paramId, cragIds, startDate, endDate))
    });
  }
};
import axios from 'axios'
import * as types from '../common/actionTypes'
import {authenticityToken} from "../common/util";
axios.defaults.headers.common['X-CSRF-Token'] = authenticityToken();

export const continueOnboarding = (state) => (dispatch, getState) => {
  let params = ''
  let stateIndex = '0'
  switch (state) {
    default:
      stateIndex = '1'
      break
    case 'grade_genre':
      stateIndex = '2'
      params = `&climber[boulders]=${getState().climber.boulders}&climber[trad]=${getState().climber.trad}`
      params += `&climber[sport]=${getState().climber.sport}&climber[mountains]=${
        getState().climber.mountains
      }&climber[ice]=${getState().climber.ice}`
      params += `&climber[grading_system_sport]=${getState().climber.grading_system_sport}`
      params += `&climber[grading_system_boulder]=${getState().climber.grading_system_boulder}`
      params += `&climber[grading_system_trad]=${getState().climber.grading_system_trad}`
      params += `&climber[grade_int]=${encodeURIComponent(
        getState().climber.grade_int,
      )}`
      break
    case 'information':
      stateIndex = '3'
      params = `&climber[home_town]=${encodeURIComponent(getState().climber.home_town)}&climber[sex]=${
        getState().climber.sex
      }`
      if (getState().climber.location_id) {
        params += `&climber[location_id]=${getState().climber.location_id}`
      }
      break
    case 'follow':
      stateIndex = '4'
      params = `&climber[follow_enabled]=${getState().climber.follow_enabled}`
      break
  }
  params += `&climber[onboarding_state]=${stateIndex}`
  dispatch({
    type: `${types.CONTINUE_ONBOARDING}_PENDING`,
    payload: stateIndex,
  })
  axios
    .post(`${getState().apiUrl}onboarding`, `${params}`)
    .then((result) => {
      dispatch({
        type: `${types.CONTINUE_ONBOARDING}_FULFILLED`,
        payload: result,
      })
    })
    .catch((err) => {
      dispatch({
        type: `${types.CONTINUE_ONBOARDING}_REJECTED`,
        payload: err,
      })
    })
}

export const changeGenreSelected = (genre) => (dispatch) => {
  dispatch({
    type: types.CHANGE_GENRE_SELECTED,
    payload: genre,
  })
}

export const changeGrade = (grade) => (dispatch) => {
  dispatch({
    type: types.CHANGE_GRADE,
    payload: grade,
  })
}

export const changeGradingSystem = (system, genre) => (dispatch) => {
  switch(genre) {
    case 'Sport':
      dispatch({
        type: types.CHANGE_GRADING_SYSTEM_SPORT,
        payload: system,
      })
      break;
    case 'Boulder':
      dispatch({
        type: types.CHANGE_GRADING_SYSTEM_BOULDER,
        payload: system,
      })
      break;
    case 'Trad':
      dispatch({
        type: types.CHANGE_GRADING_SYSTEM_TRAD,
        payload: system,
      })
      break;
    default:
      dispatch({
        type: types.CHANGE_GRADING_SYSTEM_SPORT,
        payload: system,
      })
  }
}

export const changeLocation = (location, locationId) => (dispatch) => {
  dispatch({
    type: types.CHANGE_LOCATION,
    payload: { location, locationId },
  })
}

export const changeSex = (sex) => (dispatch) => {
  dispatch({
    type: types.CHANGE_SEX,
    payload: sex,
  })
}

export const toggleFollowEnabled = () => (dispatch) => {
  dispatch({
    type: types.TOGGLE_FOLLOW_ENABLED,
  })
}

export const searchLocations = (location) => (dispatch, getState) => {
  dispatch({
    type: types.SEARCH_LOCATIONS,
    payload: axios.get(`${getState().apiUrl}search/locations?query=${location}`),
  })
}

export const searchClimbers = (query) => (dispatch, getState) => {
  dispatch({
    type: types.SEARCH_CLIMBERS,
    payload: axios.get(`${getState().apiUrl}search/climbers?query=${query}`),
  })
}

export const toggleFollow = (followeeId, follow) => (dispatch, getState) => {
  dispatch({
    type: types.TOGGLE_FOLLOW,
    payload: axios.post(`${getState().apiUrl}${follow}`, `followee_id=${followeeId}`),
  })
}

export const fetchClimbersByLocation = (locationId) => (dispatch, getState) => {
  dispatch({
    type: types.FETCH_CLIMBERS_BY_LOCATION,
    payload: axios.get(`${getState().apiUrl}climbers/by_location/${locationId}`),
  })
}

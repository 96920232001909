export const FETCH_FEED_ITEMS = "FETCH_FEED_ITEMS";
export const TOGGLE_FEED_ITEM_LIKE = "TOGGLE_FEED_ITEM_LIKE";
export const DELETE_FEED_ITEM = "DELETE_FEED_ITEM";
export const ADD_FEED_ITEM = "ADD_FEED_ITEM";
export const TOGGLE_DELETE_DIALOG_VISIBILITY =
  "TOGGLE_DELETE_DIALOG_VISIBILITY";

export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const TOGGLE_COMMENTS_VISIBILITY = "TOGGLE_COMMENTS_VISIBILITY";
export const ADD_COMMENT = "ADD_COMMENT";
export const START_COMMENT_EDITING = "START_COMMENT_EDITING";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";

export const CONTINUE_ONBOARDING = "CONTINUE_ONBOARDING";
export const CHANGE_GENRE_SELECTED = "CHANGE_GENRE_SELECTED";
export const CHANGE_GRADE = "CHANGE_GRADE";
export const CHANGE_GRADING_SYSTEM = "CHANGE_GRADING_SYSTEM";
export const CHANGE_GRADING_SYSTEM_SPORT = "CHANGE_GRADING_SYSTEM_SPORT";
export const CHANGE_GRADING_SYSTEM_BOULDER = "CHANGE_GRADING_SYSTEM_BOULDER";
export const CHANGE_GRADING_SYSTEM_TRAD = "CHANGE_GRADING_SYSTEM_TRAD";
export const CHANGE_LOCATION = "CHANGE_LOCATION";
export const CHANGE_SEX = "CHANGE_SEX";
export const TOGGLE_FOLLOW_ENABLED = "TOGGLE_FOLLOW_ENABLED";
export const SEARCH_CLIMBERS = "SEARCH_CLIMBERS";
export const SEARCH_LOCATIONS = "SEARCH_LOCATIONS";
export const TOGGLE_FOLLOW = "TOGGLE_FOLLOW";
export const FETCH_CLIMBERS_BY_LOCATION = "FETCH_CLIMBERS_BY_LOCATION";

export const FETCH_CLIMBER = "FETCH_CLIMBER";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";

export const SET_MAPBOX = "SET_MAPBOX";
export const SET_GOOGLE_MAPS = "SET_GOOGLE_MAPS";
export const UPDATE_MAP_BOUNDS = "UPDATE_MAP_BOUNDS";
export const TOGGLE_MAP_VIEW = "TOGGLE_MAP_VIEW";
export const TOGGLE_FILTERS_VISIBILITY = "TOGGLE_FILTERS_VISIBILITY";
export const SET_VISIBLE_CRAGS_STRING = "SET_VISIBLE_CRAGS_STRING";
export const SET_VISIBLE_MARKERS_STRING = "SET_VISIBLE_MARKERS_STRING";
export const STORE_CRAG_DETAILS = "STORE_CRAG_DETAILS";
export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const SET_CRAG_SORT = "SET_CRAG_SORT";
export const HIGHLIGHT_CRAG = "HIGHLIGHT_CRAG";
export const SET_MARKER_EDIT_POPUP = "SET_MARKER_EDIT_POPUP";
export const SET_COMMERCIAL_MARKER_POPUP = "SET_COMMERCIAL_MARKER_POPUP";

export const FILTER_ROUTES = "FILTER_ROUTES";
export const ORDER_BY = "ORDER_BY";
export const SEARCH_PHOTOS = "SEARCH_PHOTOS";

export const SET_RESULTS = "SET_RESULTS";

export const SET_USER = "SET_USER";

export const UPDATE_GRADE_MIN = "UPDATE_GRADE_MIN";
export const UPDATE_GRADE_MAX = "UPDATE_GRADE_MAX";
export const UPDATE_AREA = "UPDATE_AREA";
export const UPDATE_ORDER_BY = "UPDATE_ORDER_BY";

export const GET_VISITS_DATA = "GET_VISITS_DATA";
export const GET_TOPO_VALUE_DATA = "GET_TOPO_VALUE_DATA";

window.WorldMapFilters = function (markers, resultlist) {
  var $container = $('#mapControlsContainer');

  var $filters = $('.map-filter');
  var $filter_types = $('.filter-crag-type');
  var $filter_premium = $('.filter-premium');

  var default_filters = [];
  $filters.each(function () {
    default_filters.push($(this).val());
  });


  var $sortby = $('.sort-crags-by');
  var $grade_counts = $('.grade-counts');
  var $grade_sort_container = $('.sort-crags-by-routes');
  $grade_sort_container.hide();

  var sortby_cookie_name = 'this-is-new-world-map-order-dude';

  var markers = markers;
  var results = resultlist;
  var self = this;
  var current_sorting = 'distance';

  function setSorting(sorting) {
    // marker list default sorting is by distance to map center
    // likes and other info needs to be sorted after getting craginfo || possible problem when visible area has more than 100 crags
    if (sorting == 'distance') {
      results.setSorting(null);
      $grade_sort_container.hide();
      current_sorting = sorting;
    }
    else if (sorting == 'likes') {
      results.setSorting(sortByLikes);
      $grade_sort_container.hide();
      current_sorting = sorting;
    }
    else if (sorting == 'routes') {
      $grade_sort_container.show();
      results.setSorting(sortByRouteCount);
      current_sorting = sorting;
    }
  }

  function onSortByChange(sorting) {
    $.cookie(sortby_cookie_name, sorting);
    setSorting(sorting);
    results.renderFromMarkers(markers.getVisibleMarkers());
  }

  function onFilterChange() {
    var arr = self.getFilters();
    markers.setFilters(arr);
    setFiltersToHash(getActiveFilters());
    setFiltersToCookie(getActiveFilters());
    markers.refresh();
    results.renderFromMarkers(markers.getVisibleMarkers());
  }

  function sortCompare(a, b) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }

  function sortByLikes(crags) {
    crags.sort(function (cragA, cragB) {
      return sortCompare(cragA.likes_count, cragB.likes_count)
    });
  }

  function sortByRouteCount(crags) {
    var $checked = $grade_counts.filter('.pressed');
    if ($checked.length < 1) {
      $checked = $grade_counts;
    }

    var getRouteCount = function (crag) {
      var count = 0;
      $.each($checked, function () {
        count += parseInt(crag[$(this).val()]);
      });
      return count;
    };

    crags.sort(function (cragA, cragB) {
      return sortCompare(getRouteCount(cragA), getRouteCount(cragB));
    });
  }

  function initSorting() {

    var cookie_val = current_sorting;
    if ($.cookie(sortby_cookie_name)) {
      cookie_val = $.cookie(sortby_cookie_name);
      setSorting($.cookie(sortby_cookie_name));
    }
    $sortby.each(function () {
      btnPressedOff($(this));
      if (cookie_val == $(this).val()) {
        btnPressedOn($(this));
      }
    });

    $sortby.click(function () {
      var $pressed = $(this);
      $sortby.each(function () {
        if ($(this).val() != $pressed.val()) {
          btnPressedOff($(this));
        }
      });
      btnPressedOn($pressed);
      onSortByChange($pressed.val());
    });

    var count_of_checked = 0;
    $grade_counts.each(function () {
      var cookie_val = app.preferences.get('route_' + $(this).val());
      if (cookie_val != null) {
        btnPressedOn($(this));
      } else {
        btnPressedOff($(this));
      }
    });

    $grade_counts.click(function () {
      if ($(this).hasClass('pressed')) {
        btnPressedOff($(this));
        app.preferences.delete('route_' + $(this).val());
      } else {
        btnPressedOn($(this));
        app.preferences.set('route_' + $(this).val());
      }
      onSortByChange('routes');
    });
  }

  function setFilters(filters_arr){
    $filters.each(function(){
      if(filters_arr.indexOf($(this).val()) < 0){
        btnPressedOff($(this));
        app.preferences.delete('filter' + $(this).val());
      } else {
        btnPressedOn($(this));
        app.preferences.set('filter' + $(this).val());
      }
    });
    setFiltersToHash(filters_arr);
    setFiltersToCookie(filters_arr);
  }


  function initFilters() {
    var initFromHash = function(){
      var arr = getFiltersFromHash();
      if (arr != null) {
        setFilters(arr);
        return true;
      }
      return false;
    };

    var initFromCookie = function(){
      var arr = getFiltersFromCookie();
      if (arr != null) {
        setFilters(arr);
        return true;
      }
      return false;
    };

    var setDefaults = function(){
      setFilters(default_filters);
    };


    if(!initFromHash()) {
      if(!initFromCookie()){
        setDefaults();
      }
    }

    $filters.click(function () {
      $(this).toggleClass('pressed');
      onFilterChange();
    });
  }

  function initLayout() {
    var contheight = $container.height();
    var maxHeight = $('#mapcontainer').outerHeight();

    $('a.show-filters').on('click', function () {
      contheight = $container.height();
      var height = Math.max(maxHeight, contheight);
      $container.find('.controls-page.filters').show(100, function () {
      });
    });

    $('a.hide-filters').on('click', function () {
      $container.find('.controls-page.filters').hide(100, function () {
      });
    });
  }

  function btnPressedOn($btn) {
    $btn.addClass('pressed');
  }

  function btnPressedOff($btn) {
    $btn.removeClass('pressed');
  }

  function getTypeFilters() {
    var arr = [];
    $filter_types.filter('.pressed').each(function(){
      arr.push($(this).val());
    });
    return arr;
  }

  function getActiveFilters() {
    var arr = [];
    $filters.filter('.pressed').each(function(){
      arr.push($(this).val());
    });
    return arr;
  }

  this.getFilters = function () {
    var arr = getActiveFilters();
    return {
      type: getTypeFilters(),
      premium: $filter_premium.hasClass('pressed')
    };
  };

  this.getFiltersAsString = function(){
    return encodeURIComponent(getActiveFilters());
  };


  function setFiltersToCookie(filters_arr){
    $filters.each(function(){
      if(filters_arr.indexOf($(this).val()) < 0){
        app.preferences.delete('filter' + $(this).val());
      } else {
        app.preferences.set('filter' + $(this).val());
      }
    });
  }

  function getFiltersFromCookie(){
    var arr = [];
    $filters.each(function(){
      var val = app.preferences.get('filter' + $(this).val());
      if(val != null){
        arr.push($(this).val());
      }
    });
    return arr;
  }

  function setFiltersToHash(filters_arr) {
    if (location.hash.length < 1) {
      return null;
    }
    var position_as_string = location.hash.substring(1);
    var args = position_as_string.split(',');
    var hash = args[0] + ',' + args[1] + ',' + args[2] + (filters_arr != null ? ',' + encodeURIComponent(filters_arr) : '');

    var loc = window.location.pathname.split('@');
    var path = loc[0];
    window.history.pushState({}, document.title, path + '#' + hash);
  }

  function getFiltersFromHash() {
    if (location.hash.length < 1) {
      return null;
    }

    var position_as_string = location.hash.substring(1);
    var args = position_as_string.split(',');
    if (args.length < 4) {
      return null;
    }
    var str = decodeURIComponent(args[3]);
    try {
      return str.split(',');
    } catch (e) {
      return null;
    }
  }

  initLayout();
  initFilters();
  initSorting();

}
import * as types from '../../common/actionTypes'

const reducer = props => {
  const {
    newTab = false,
    locationId,
    markerIcons,
    lat,
    lng,
    zoom,
    editable,
    showList,
    showFilters,
    useHashLocation,
    sort,
    filters,
  } = props

  const initialState = {
    newTab,
    showList,
    showFilters,
    useHashLocation,
    locationId,
    location: {lat, lng},
    zoom,
    icons: JSON.parse(markerIcons),
    filters: {
      visible: false,
      boulder: true,
      sport: true,
      dws: true,
      trad: true,
      freeCrags: false,
      accommodation: true,
      climbingShop: true,
      climbingGuide: true,
      gym: true,
      ...filters,
    },
    sort: sort || 'distance',
    sortGrades: {
      4: true,
      5: true,
      6: true,
      7: true,
      8: true,
    },
    visibleCrags: '',
    visibleMarkers: '',
    view: 'map',
    editable,
    selectedCrag: null,
    cragPopupVisible: false,
    markerEditPopup: {
      visible: false,
      mapboxMarker: null,
    },
    commercialMarkerPopup: {
      visible: false,
      mapboxMarker: null,
    },
  }

  return (state = initialState, {type, payload}) => {
    switch (type) {
      case types.SET_VISIBLE_CRAGS_STRING:
        return {
          ...state,
          visibleCrags: payload,
        }
      case types.SET_VISIBLE_MARKERS_STRING:
        return {
          ...state,
          visibleMarkers: payload,
        }
      case types.TOGGLE_FILTERS_VISIBILITY:
        return {
          ...state,
          filters: {
            ...state.filters,
            visible: !state.filters.visible,
          },
        }
      case types.TOGGLE_MAP_VIEW:
        return {
          ...state,
          view: state.view === 'map' ? 'list' : 'map',
        }
      case types.TOGGLE_FILTER: {
        const {trad, sport, boulder, dws, freeCrags, accommodation, climbingGuide, climbingShop, gym} = state.filters
        return {
          ...state,
          filters: {
            ...state.filters,
            trad: payload === 'trad' ? !trad : trad,
            sport: payload === 'sport' ? !sport : sport,
            dws: payload === 'dws' ? !dws : dws,
            boulder: payload === 'boulder' ? !boulder : boulder,
            freeCrags: payload === 'freeCrags' ? !freeCrags : freeCrags,
            accommodation: payload === 'accommodation' ? !accommodation : accommodation,
            climbingGuide: payload === 'climbingGuide' ? !climbingGuide : climbingGuide,
            climbingShop: payload === 'climbingShop' ? !climbingShop : climbingShop,
            gym: payload === 'gym' ? !gym : gym,
          },
        }
      }
      case types.SET_CRAG_SORT:
        if (payload.isGradeSort) {
          return {
            ...state,
            sortGrades: {
              ...state.sortGrades,
              [payload.sort]: !state.sortGrades[payload.sort],
            },
          }
        }
        return {
          ...state,
          sort: payload.sort,
        }
      case types.HIGHLIGHT_CRAG:
        if (payload) {
          return {
            ...state,
            selectedCrag: payload,
            cragPopupVisible: true,
          }
        }
        return {
          ...state,
          cragPopupVisible: false,
        }
      case types.SET_MARKER_EDIT_POPUP:
        return {
          ...state,
          markerEditPopup: payload,
        }
      case types.SET_COMMERCIAL_MARKER_POPUP:
        return {
          ...state,
          commercialMarkerPopup: payload,
        }
      default:
        return state
    }
  }
}

export default reducer

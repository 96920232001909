import React from "react";

class DropDownFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onDiv: false,
      open: false
    };
    this.bodyClickEvent = this.bodyClickEvent.bind(this);
    this.onDropDownOpenClick = this.onDropDownOpenClick.bind(this);
    this.onDropDownCloseClick = this.onDropDownCloseClick.bind(this);
    this.onApplyClick = this.onApplyClick.bind(this);
  }

  onDropDownOpenClick() {
    document.addEventListener("click", this.bodyClickEvent, true);
    this.showOverlay();
    this.setState({open: true});
  }

  onDropDownCloseClick() {
    document.removeEventListener("click", this.bodyClickEvent, true);
    this.hideOverlay();
    this.setState({open: false});
  }

  onApplyClick(e) {
    this.onDropDownCloseClick();
    this.props.onApplyFilters ? this.props.onApplyFilters(e) : null;
  }

  bodyClickEvent() {
    if (!this.state.onDiv) {
      this.hideOverlay();
      document.removeEventListener("click", this.bodyClickEvent, true);
      this.setState({open: false});
    }
  }

  showOverlay() {
    let overlay = document.getElementById("overlay-div");
    if (!overlay) return;
    overlay.classList.add("visible");
  }

  hideOverlay() {
    const overlay = document.getElementById("overlay-div");
    if (!overlay) return;
    overlay.classList.remove("visible");
  }

  mouseEnter() {
    this.setState({
      onDiv: true
    });
  }

  mouseLeave() {
    this.setState({
      onDiv: false
    });
  }

  render() {
    return (
      <div
        className={
          "dropdown dropdown--filter" + (this.state.open ? " open" : "")
        }
      >
        <button
          type={"button"}
          className={this.props.btnClass}
          onClick={this.onDropDownOpenClick}
        >
          {this.props.titleButton}
          <span className="caret"></span>
        </button>
        <div className="dropdown-menu dropdown-menu--filter">
          <div className="dropdown-custom-container">
            <div
              onMouseEnter={() => {
                this.mouseEnter();
              }}
              onMouseLeave={() => this.mouseLeave()}
            >
              <div className="miniheader">{this.props.titleHeader}</div>
              <div className="dropdown-menu-body" style={{maxHeight: "50vh", overflow: "auto"}}>
                {this.props.children}
              </div>
              <div className="close-footer">
                <a
                  className="btn btn-link btn-block close-footer-btn"
                  onClick={this.onApplyClick}
                >
                  {this.props.titleClose}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DropDownFilter;

import PerfectScrollbar from 'perfect-scrollbar'

jQuery(document).ready(function($) {

  function toggleActivateRoute(topo_route_id) {
    var $route = $('#topo-route-' + topo_route_id);
    var $line = $('#line-tag-' + topo_route_id);

    var $topo = $route.closest('.topo');

    $topo.find('.nbr').removeClass('active');
    $topo.find('li.route').removeClass('active');

    $route.addClass('active');
    $line.addClass('active');

    var $topobox = $topo.find('.topobox');
    var topobox_index = $('.topobox').index($topobox);
    var line_index = $topobox.find('.nbr').index($line);
    if($.linebox[$topobox.attr('id')]) {
      $.linebox[$topobox.attr('id')].highlightLine(line_index);
    }
  }

  function deactivateRoute(topo_route_id){
    var $route = $('#topo-route-' + topo_route_id);
    var $line = $('#line-tag-' + topo_route_id);
    $route.removeClass('active');
    $line.removeClass('active');

    var $topo = $route.closest('.topo');
    var $topobox = $topo.find('.topobox');

    if($.linebox[$topobox.attr('id')]) {
      $.linebox[$topobox.attr('id')].highlightLine(-1);
    }
  }

  $('.topo-routes .route').hover(
    function(){
      var $topo_image = $(this).closest('.topo').find('.topo-image');
      $topo_image.find('.nbr').removeClass('active');
      toggleActivateRoute($(this).data('image') + '-' + $(this).data('route'));
    },
    function(){
      deactivateRoute($(this).data('image') + '-' + $(this).data('route'));
    }
  );
  $('.nbr').click(function(){
    var id = $(this).attr('id');

    var $topo_route = $('#topo-route-' + id.split('line-tag-')[1]);
    $topo_route.addClass('active');
    var top_pos = $topo_route[0].offsetTop;
    $topo_route.closest('.topo-routes').animate({scrollTop: top_pos}, '200', 'swing');
    $(this).closest('.topobox').find('.nbr').removeClass('active');

    toggleActivateRoute(id.split('line-tag-')[1]);
  });

  function initTopoRoutesScroll($topo){
    var $topo_routes = $topo.find('.topo-routes');
    var $img = $topo.find('.topobox img');
    if($topo_routes.height() < $img.height()-60) {
      return;
    }
    if(getWindowDeviceMode() == 'xs') {
      $topo_routes.height(200);
    } else {
      $topo_routes.height($img.height()-60);
    }
    let ps = new PerfectScrollbar($topo_routes[0], {
      suppressScrollX: true,
      wheelPropagation: false
    });
  }

  $('.topobox img').each(function(){
    var $img = $(this);
    $img.imagesLoaded().done(function(){
      var $topo = $img.closest('.topo');
      initTopoRoutesScroll($topo);
    });
  });


  $( window ).resize(function(){
    $('.topo-routes').each(function(){
      initTopoRoutesScroll($(this).closest('.topo'));
    });
  });


  if($('#cragmap-show')[0] || $('#cragmap-index')[0]){
    $('.sectors-menu-container .sector-item').click(function(e) {
      if(!$(this).hasClass('all-routes')) {
        e.preventDefault();
      }
      var $self = $(this);
      var url = $self.attr('href');

      function highlightMapItem() {
        url = url.replace('cragmap', 'sectors');
        app.map.highlightSector(url);
      }

      function highlightMenuItem(){
        var selected_item_text = $(
          $self.find('.name')[0]
        ).text();
        $('#sectors-dropdown .name').text(selected_item_text);
        $('.sector-item').removeClass('active');
        $self.addClass('active');
        $('#collapse-sectors').collapse('hide');
      }

      function updateTopoMenu(){
        function getSector(str){
          var parts = str.split('/');
          if(parts.length > 4)
            return parts[parts.length - 1];
          return '';
        }
        var sector = getSector(url);

        $('#topo-menu li a').each(function(i, elem){
          var link = $(elem).attr('href');
          var sec = getSector(link);
          if(!sec && sector.length > 0) {
            link = link + '/' + sector;
          }
          else {
            link = link.replace(getSector(link), sector);
          }
          $(elem).attr('href', link);
        });

      }
      highlightMapItem();
      highlightMenuItem();
      updateTopoMenu();
    });
  }

  /*$('body').panelSnap({
    panelSelector: '.panelSnap',
    offset: '70'
  });*/
});
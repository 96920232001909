// temporary messages that should not be fed to search engines
app.messages = (function() {
  var messages = [
    //{name: 'new', status: 'new', once: true, html: '<b>Welcome</b> to 27crags.com!'}
  ];

  function cookie_messages() {
    var str = $.cookie("messages");
    return str && str.length > 0
      ? $.map(str.split("``"), function(a) {
          return decodeURIComponent(a);
        })
      : [];
  }

  function delete_cookie() {
    $.cookie("messages", null, { path: "/" });
  }

  function getNewMessagesCount() {
    var c = 0;
    if (messages.length > 0) {
      for (var i = 0; i < messages.length; i++) {
        if (messages[i].status == "new") {
          c++;
        }
      }
    }
    return c;
  }

  function markAsRead() {
    for (var i = 0; i < messages.length; i++) {
      messages[i].status = "read";
    }
  }

  function updateBadge() {
    var count = getNewMessagesCount();
    if (count < 1) {
      //$('#user-notifications .badge').css('visibility', 'hidden');
    } else {
      //$('#user-notifications .badge').css('visibility', 'visible');
    }
    $("#user-notifications .badge").text(count);
  }

  function getMessagesAsHtml(number) {
    var str = "";
    if (messages.length > 0) {
      if (typeof number == "undefined") {
        number = 10;
      }
      var i = Math.min(number, messages.length) - 1;

      for (i; i > -1; i--) {
        str += messages[i].html;
      }
    }
    return str;
  }

  function showPopover(content) {
    /*$('#user-notifications').popover({
      trigger: 'manual',
      content: content,
      html: true,
      placement: 'bottom'
    });

    $('#user-notifications').popover('show');
    */

    $("#user-notifications-popover").click(function() {
      $(this).fadeOut();
    });
    $("#user-notifications-popover").html(content);
    $("#user-notifications-popover").fadeIn();
  }

  function hidePopover() {
    //$('#user-notifications').popover('hide');
    $("#user-notifications-popover").fadeOut();
  }

  function showLatest() {
    var timeoutShow = setTimeout(function() {
      var content = getMessagesAsHtml(getNewMessagesCount());
      showPopover(content);
      clearTimeout(timeoutShow);
    }, 1000);
    var timeoutHide = setTimeout(function() {
      hidePopover();
      clearTimeout(timeoutHide);
    }, 5000);
  }

  return {
    // public properties of app.messages
    refresh: function() {
      updateBadge();
    },
    clear: function() {
      if ($("#overthetop").length > 0) this.replace("");
      updateBadge();
    },
    /* DEPRECATED: use append instead */
    replace: function(html) {
      this.append(html);
    },
    append: function(html) {
      $("#user-notifications-popover").append(
        '<div class="notice">' + html + "</div>"
      );
    },
    show_js_messages: function() {
      if (cookie_messages().length > 0) {
        app.messages.append(
          $.map(cookie_messages(), function(a) {
            return a;
          }).join("")
        );
        delete_cookie();
      } /*else if ( $.trim($('#overthetop').html()).length == 0 ) {
        $.each(messages, function(i, msg) {
          if ( !msg.once || !$.cookie(msg.name) ) {
            app.messages.append(msg.html);
            if ( msg.once ) $.cookie(msg.name, 'shown', {path:'/'});
          }
        });
      }*/
    },
    toggle: function() {
      var content = getMessagesAsHtml(20);

      if (!$("#user-notifications").data("popover")) {
        $("#user-notifications").data("popover", true);
        showPopover(content);
        markAsRead();
        updateBadge();
      } else {
        $("#user-notifications").data("popover", false);
        hidePopover();
      }
    }
  };
})();

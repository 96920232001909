import * as types from '../common/actionTypes';
import commentReducer from '../common/reducers/commentReducer';

const feed = (_currentClimber, photoUrl, apiUrl, feedItems = []) => {
  const initialState = {
    apiUrl,
    currentClimber: { ..._currentClimber, photoUrl },
    feedItems,
    fetching: false,
    fetched: false,
    error: null,
    page: 0,
    feedItemsReceived: true,
    addFeedItemPending: false,
    addCommentPending: false,
  };

  return (state = initialState, { type, payload }) => {
    let newFeedItem;
    switch (type) {
      case `${types.FETCH_FEED_ITEMS}_PENDING`:
        return { ...state, fetching: true, error: null };
      case `${types.FETCH_FEED_ITEMS}_FULFILLED`:
        return {
          ...state,
          fetching: false,
          fetched: true,
          error: null,
          page: state.page + 1,
          feedItems: state.feedItems.concat(payload.data.feed_items.map((feedItem) => {
            for (let i = 0; i < state.feedItems.length; i += 1) {
              if (feedItem.id === state.feedItems[i]) {
                return null;
              }
            }
            for (let i = 0; i < feedItem.likes.likes.length; i += 1) {
              if (feedItem.likes.likes[i].climber_id === state.currentClimber.id) {
                return { ...feedItem, likeStatus: 'liked' };
              }
            }
            return feedItem;
          })),
          feedItemsReceived: payload.data.feed_items.length > 0,
        };
      case `${types.TOGGLE_FEED_ITEM_LIKE}_PENDING`:
        return state;
      case `${types.TOGGLE_FEED_ITEM_LIKE}_FULFILLED`:
        return {
          ...state,
          error: null,
          feedItems: state.feedItems.map((feedItem) => {
            if (feedItem.id === payload.data.feed_item.id) {
              return {
                ...feedItem,
                likes: payload.data.feed_item.likes,
                vote_sum: payload.data.feed_item.vote_sum,
                likeStatus: !feedItem.likeStatus || feedItem.likeStatus === 'unlike' ? 'like' : 'unlike',
              };
            }
            return feedItem;
          }),
        };
      case `${types.DELETE_FEED_ITEM}_FULFILLED`:
        return {
          ...state,
          error: null,
          feedItems: state.feedItems.filter(feedItem => feedItem.id !== payload.data),
        };
      case `${types.ADD_FEED_ITEM}_PENDING`:
        return {
          ...state,
          addFeedItemPending: true,
        };
      case `${types.ADD_FEED_ITEM}_FULFILLED`:
        newFeedItem = [{ ...payload.data.feed_item, comments: [] }];
        return {
          ...state,
          addFeedItemPending: false,
          error: null,
          feedItems: newFeedItem.concat(state.feedItems),
        };
      case `${types.FETCH_COMMENTS}_PENDING`:
        return {
          ...state,
          feedItems: state.feedItems.map((feedItem) => {
            if (feedItem.id === payload) {
              return {
                ...feedItem,
                fetchingComments: true,
                commentsVisible: true,
                comments: [],
              };
            }
            return feedItem;
          }),
        };
      case `${types.FETCH_COMMENTS}_FULFILLED`:
        return {
          ...state,
          feedItems: state.feedItems.map((feedItem) => {
            if (feedItem.id == payload.feedItemId) {
              return {
                ...feedItem,
                comments: commentReducer(feedItem.comments, type, payload.result, state.currentClimber),
                fetchingComments: false,
              };
            }
            return feedItem;
          }),
        };
      case `${types.TOGGLE_COMMENTS_VISIBILITY}`:
        return {
          ...state,
          feedItems: state.feedItems.map((feedItem) => {
            if (feedItem.id === payload) {
              return {
                ...feedItem,
                commentsVisible: !feedItem.commentsVisible,
              };
            }
            return feedItem;
          }),
        };
      case `${types.ADD_COMMENT}_PENDING`:
        return {
          ...state,
          addCommentPending: true,
        };
      case `${types.ADD_COMMENT}_FULFILLED`:
        return {
          ...state,
          addCommentPending: false,
          feedItems: state.feedItems.map((feedItem) => {
            if (feedItem.id === payload.feedItemId) {
              return {
                ...feedItem,
                comment_sum: feedItem.comment_sum + 1,
                comments: commentReducer(feedItem.comments, type, payload.result, state.currentClimber),
              };
            }
            return feedItem;
          }),
        };
      case `${types.DELETE_COMMENT}_FULFILLED`:
        return {
          ...state,
          feedItems: state.feedItems
            .filter(feedItem => (
              !(feedItem.source_id === payload.result.data.comment.id && feedItem.source_type === 'Comment')
            ))
            .map((feedItem) => {
              if (feedItem.id === payload.feedItemId) {
                return {
                  ...feedItem,
                  comment_sum: feedItem.comment_sum === 0 ? 0 : feedItem.comment_sum - 1,
                  comments: commentReducer(feedItem.comments, type, payload.result, state.currentClimber),
                };
              }
              return feedItem;
            }),
        };
      case `${types.EDIT_COMMENT}_PENDING`:
        return {
          ...state,
          addCommentPending: true,
        };
      case `${types.EDIT_COMMENT}_FULFILLED`:
        return {
          ...state,
          addCommentPending: false,
          feedItems: state.feedItems.map((feedItem) => {
            if (feedItem.id === payload.feedItemId) {
              return {
                ...feedItem,
                comments: commentReducer(feedItem.comments, type, payload.result, state.currentClimber),
                isEditing: false,
              };
            }
            return feedItem;
          }),
        };
      case types.START_COMMENT_EDITING:
        return {
          ...state,
          feedItems: state.feedItems.map((feedItem) => {
            if (feedItem.id === payload.feedItemId) {
              return { ...feedItem, isEditing: true, editableCommentId: payload.commentId };
            }
            return feedItem;
          }),
        };
      case `${types.ADD_COMMENT}_REJECTED`:
      case `${types.EDIT_COMMENT}_REJECTED`:
        return { ...state, error: payload, addCommentPending: false };
      case `${types.ADD_FEED_ITEM}_REJECTED`:
      case `${types.DELETE_FEED_ITEM}'_REJECTED`:
      case `${types.FETCH_FEED_ITEMS}_REJECTED`:
      case `${types.TOGGLE_FEED_ITEM_LIKE}_REJECTED`:
        return { ...state, error: payload };
      default:
        return state;
    }
  };
};

export default feed;

(function($) {
  $.extend({
    tablesorterPager: new function() {

      function updatePageDisplay(c) {
        var s = $(c.cssPageDisplay,c.container).val((c.page+1) + c.seperator + c.totalPages);
      }

      function setPageSize(table,size) {
        var c = table.config;
        c.size = size;
        c.totalPages = Math.ceil(c.totalRows / c.size);
        c.pagerPositionSet = false;
        moveToPage(table);
        //fixPosition(table);
      }

      function fixPosition(table) {
        var c = table.config;
        if(!c.pagerPositionSet && c.positionFixed) {
          var c = table.config, o = $(table);
          if(o.offset) {
            c.container.css({
              top: o.offset().top + o.height() + 'px',
              position: 'absolute'
            });
          }
          c.pagerPositionSet = true;
        }
      }

      function moveToFirstPage(table) {
        var c = table.config;
        c.page = 0;
        moveToPage(table);
      }

      function moveToLastPage(table) {
        var c = table.config;
        c.page = (c.totalPages-1);
        moveToPage(table);
      }

      function moveToNextPage(table) {
        var c = table.config;
        c.page++;
        if(c.page >= (c.totalPages-1)) {
          c.page = (c.totalPages-1);
        }
        moveToPage(table);
      }

      function moveToPrevPage(table) {
        var c = table.config;
        c.page--;
        if(c.page <= 0) {
          c.page = 0;
        }
        moveToPage(table);
      }


      function moveToPage(table) {
        var c = table.config;
        if(c.page < 0 || c.page > (c.totalPages-1)) {
          c.page = 0;
        }

        renderTable(table,c.rowsCopy);
      }

      function renderTable(table,rows) {

        var c = table.config;
        var l = rows.length;
        var s = (c.page * c.size);
        var e = (s + c.size);
        if(e > rows.length ) {
          e = rows.length;
        }


        var tableBody = $(table.tBodies[0]);

        // clear the table body

        $.tablesorter.clearTableBody(table);

        for(var i = s; i < e; i++) {

          //tableBody.append(rows[i]);

          var o = rows[i];
          var l = o.length;
          for(var j=0; j < l; j++) {

            tableBody[0].appendChild(o[j]);

          }
        }

        //fixPosition(table,tableBody);

        $(table).trigger("applyWidgets");

        if( c.page >= c.totalPages ) {
          moveToLastPage(table);
        }

        updatePageDisplay(c);
      }

      this.appender = function(table,rows) {

        var c = table.config;

        c.rowsCopy = rows;
        c.totalRows = rows.length;
        c.totalPages = Math.ceil(c.totalRows / c.size);

        renderTable(table,rows);
      };

      this.defaults = {
        size: 10,
        offset: 0,
        page: 0,
        totalRows: 0,
        totalPages: 0,
        container: null,
        cssNext: '.next',
        cssPrev: '.prev',
        cssFirst: '.first',
        cssLast: '.last',
        cssPageDisplay: '.pagedisplay',
        cssPageSize: '.pagesize',
        seperator: "/",
        positionFixed: true,
        appender: this.appender
      };

      this.construct = function(settings) {

        return this.each(function() {

          config = $.extend(this.config, $.tablesorterPager.defaults, settings);

          var table = this, pager = config.container;

          $(this).trigger("appendCache");


          // bind elements to
          var moveTo = function(i) {
            var c = table.config;
            c.page = i;
            if(i <= 0) {
              c.page = 0;
            }
            else if(i >= (c.totalPages-1)) {
              c.page = (c.totalPages-1);
            }
            else {
              c.page = i;
            }
            moveToPage(table);
          };

          var buildPages = function (){
            var $list = $('<ul class="pagination"></ul>');
            var pages = table.config.totalPages;

            var $link = $('<a href="javascript:;" data-page="'+i+'">&laquo;</a>');
            $link.click(function(e){
              e.preventDefault();
              moveToPrevPage(table);
              var i = table.config.page;
              $list.find('li').removeClass('active');
              $list.find('a[data-page="'+i+'"]').parent().addClass('active');
            });
            var $listitem = $('<li></li>');
            $listitem.append($link);
            $list.append($listitem);

            for(var i = 0; i < pages; i++) {
              var $link = $('<a href="javascript:;" data-page="'+i+'">' + (i+1) + '</a>');
              $link.click(function(e){
                e.preventDefault();
                $list.find('li').removeClass('active');
                $(this).parent().addClass('active');
                moveTo($(this).data('page'));
              });
              var $listitem = $('<li></li>');
              if(i == 0)
                $listitem.addClass('active');

              $listitem.append($link);
              $list.append($listitem);
            }

            var $link = $('<a href="javascript:;" data-page="'+i+'">&raquo;</a>');
            $link.click(function(e){
              e.preventDefault();
              moveToNextPage(table);
              var i = table.config.page;
              $list.find('li').removeClass('active');
              $list.find('a[data-page="'+i+'"]').parent().addClass('active');
            });
            var $listitem = $('<li></li>');
            $listitem.append($link);
            $list.append($listitem);

            return $list;
          };

          if(table.config.totalPages > 1) {
            pager.append(buildPages());
          }
          moveTo(0);

          //config.size = parseInt($(".pagesize",pager).val());
/*
          $(config.cssFirst,pager).click(function() {
            moveToFirstPage(table);
            return false;
          });
          $(config.cssNext,pager).click(function() {
            moveToNextPage(table);
            return false;
          });
          $(config.cssPrev,pager).click(function() {
            moveToPrevPage(table);
            return false;
          });
          $(config.cssLast,pager).click(function() {
            moveToLastPage(table);
            return false;
          });
          $(config.cssPageSize,pager).change(function() {
            setPageSize(table,parseInt($(this).val()));
            return false;
          });*/
        });
      };

    }
  });
  // extend plugin scope
  $.fn.extend({
    tablesorterPager: $.tablesorterPager.construct
  });

})(jQuery);
import * as types from '../../common/actionTypes';
import moment from "moment";

const topoValueReducer = (crags, paramId) => {
  const initialState = {
    crags: crags,
    paramId: paramId,
    topoValue: [],
    loading: true,
    startMonth: moment().subtract(12, 'months').format('YYYY-MM'),
    endMonth: moment().subtract(1, 'months').format('YYYY-MM')
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `${types.GET_TOPO_VALUE_DATA}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${types.GET_TOPO_VALUE_DATA}_ERROR`:
        return {
          ...state,
          loading: false,
        };
      case `${types.GET_TOPO_VALUE_DATA}_FULFILLED`:
        return {
          ...state,
          loading: false,
          topoValue: payload.data.sales,
        };

      default:
        return {
          ...state,
          loading: false
        };
    }
  };
};
export default topoValueReducer;

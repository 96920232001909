import * as types from "../common/actionTypes";

const routeListReducer = (routes, noImageUrl) => {
  const initialState = {
    noImageUrl,
    routes: routes,
    filteredRoutes: [],
    searchedPhotos: [],
    defaultSystem: true
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case types.FILTER_ROUTES:
        return {
          ...state,
          filteredRoutes: payload
        };
      case types.ORDER_BY:
        return {
          ...state,
          filteredRoutes: payload
        };
      case `${types.SEARCH_PHOTOS}_FULFILLED`:
        return {
          ...state,
          searchedPhotos: [...state.searchedPhotos, payload.data.routes]
        };
      case types.CHANGE_GRADING_SYSTEM:
        return {
          ...state,
          defaultSystem: false
        };
      default:
        return state;
    }
  };
};
export default routeListReducer;

import * as types from '../actionTypes';

const commentReducer = (state, type, payload, currentClimber) => {
  switch (type) {
    case `${types.FETCH_COMMENTS}_FULFILLED`:
      return payload.data.comments.map(comment => {
        if (currentClimber && comment.climber.id === currentClimber.id) {
          return { ...comment, isOwn: true };
        }
        return comment;
      });
    case `${types.ADD_COMMENT}_FULFILLED`:
      return state.concat({ ...payload.data.comment, isOwn: true });
    case `${types.EDIT_COMMENT}_FULFILLED`:
      return state.map(comment => {
        if (comment.id === payload.data.comment.id) {
          return { ...comment, content: payload.data.comment.content };
        }
        return comment;
      });
    case `${types.DELETE_COMMENT}_FULFILLED`:
      return state.filter(comment => (comment.id !== payload.data.comment.id));
    default:
      return state;
  }
};

export default commentReducer;

import React from 'react'

class OnboardingProgress extends React.Component {
  render() {
    const onboarding_state = parseInt(this.props.onboarding_state ? this.props.onboarding_state : 0, 10)
    const progressCompletedStyle = {
      width: `${((onboarding_state + 1) / 5) * 100}%`,
    }

    return (
      <a className="onboarding-progress" href="/feed">
        Only {4 - onboarding_state} step{4 - onboarding_state > 1 ? 's' : ''} to complete your profile and follow your
        friends
        <div className="onboarding-progress__progressbar">
          <div className="onboarding-progress__completed" style={progressCompletedStyle} />
        </div>
      </a>
    )
  }
}

export default OnboardingProgress

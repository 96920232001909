import React, { Component } from "react";
import RouteList from "./RouteList";
import qs from "query-string";
import { filterHash, routeListFieldHash } from "../../common/util.js";

class RouteListContainer extends Component {
  componentWillMount() {
    const query = qs.parse(this.props.query);
    this.props.syncUser();
    const filterKeys = Object.keys(filterHash);
    const queryKeys = Object.keys(query);
    const filters = [];
    for (let i = 0; i < queryKeys.length; i++) {
      if (filterKeys.includes(queryKeys[i])) {
        filters.push(queryKeys[i]);
      }
    }
    let orderer, orderDir;
    if (query.order_by) {
      const orderQueries = query.order_by.split("_");
      orderer =
        orderQueries.length > 1
          ? orderQueries.splice(0, orderQueries.length - 1).join("_")
          : orderQueries[0];
      if (!Object.keys(routeListFieldHash).includes(orderer)) {
        orderer = "name";
      }
      orderDir = orderQueries[orderQueries.length - 1];
    }
    const grades = Object.keys(GRADES_HASH);
    let gradeMin, gradeMax;
    if (query.grade_min) {
      gradeMin = grades.includes(query.grade_min)
        ? query.grade_min
        : 100;
    } else gradeMin = 100;
    if (query.grade_max) {
      gradeMax =
        query.grade_max && grades.includes(query.grade_max)
          ? query.grade_max
          : 1500;
    } else gradeMax = 1500;
    const rating = query.rating ? query.rating : [];
    this.props.filterRoutes(
      filters,
      rating,
      gradeMin,
      gradeMax,
      orderer,
      orderDir
    );
  }

  render() {
    const {
      searchedPhotos,
      searchPhotos,
      filteredRoutes,
      filterRoutes,
      orderBy,
      query,
      history,
      noImageUrl,
      gradingSystemSport,
      gradingSystemBoulder,
      gradingSystemTrad
    } = this.props;
    return (
      <RouteList
        gradingSystemSport={gradingSystemSport}
        gradingSystemBoulder={gradingSystemBoulder}
        gradingSystemTrad={gradingSystemTrad}
        noImageUrl={noImageUrl}
        searchedPhotos={searchedPhotos}
        searchPhotos={searchPhotos}
        orderBy={orderBy}
        filteredRoutes={filteredRoutes}
        query={query}
        filterRoutes={filterRoutes}
        history={history}
      />
    );
  }
}

export default RouteListContainer;

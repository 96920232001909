import React, {Component} from "react";
import DropDownFilter from "../../common/components/DropDownFilter";
import ToggleCheckbox from "../../common/components/ToggleCheckbox";

class CragsSelect extends Component {

  constructor(props) {
    super(props);
    this.props.crags.sort((crag_a, crag_b) => {
      return (crag_a['name'] < crag_b['name']) ? -1 : 1
    });
    this.state = {
      selectedCrags: this.props.crags.map(crag => crag['id'].toString()),
    }
    this.toggleCrag = this.toggleCrag.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.onApply = this.onApply.bind(this);
  }

  cragType(crag) {
    var cragType = {
      'sport': crag['sport_count'],
      'trad': crag['trad_n_p_count'],
      'boulder': crag['boulder_count'],
      'dws': crag['dws_count']
    };
    return Object.keys(cragType).reduce((a, b) => cragType[a] > cragType[b] ? a : b);
  }

  getCragIds() {
    return this.props.crags.map(crag => crag['id'].toString());
  }

  onApply() {
    this.props.onFilterChange(this.state.selectedCrags);
  }

  toggleAll() {
    if (this.state.selectedCrags.length == this.props.crags.length) {
      this.setState({
        selectedCrags: []
      });
    } else {
      this.setState({
        selectedCrags: this.getCragIds()
      });
    }
  }

  toggleCrag(e) {
    e.target.checked ? this.addCrag(e.target.value) : this.removeCrag(e.target.value);
  }

  addCrag(crag_id) {
    const selectedCrags = this.state.selectedCrags;
    if (!selectedCrags.includes(crag_id)) {
      this.setState({
        selectedCrags: [...selectedCrags, crag_id],
      });
    }
  }

  removeCrag(crag_id) {
    const selectedCrags = this.state.selectedCrags;
    if (selectedCrags.includes(crag_id)) {
      this.setState({
        selectedCrags: selectedCrags.filter((item, i) => crag_id !== item),
      });
    }
  }

  render() {
    const selectedCrags = this.state.selectedCrags;
    const crags = this.props.crags;
    const btnClass = "btn btn-default btn-block dropdown-toggle";
    return (
      <DropDownFilter
        titleButton={`Select crags (${selectedCrags.length}) `}
        titleHeader={"Select crags"}
        titleClose={"Apply"}
        showDropdown={this.props.showDropdown}
        hideDropdown={this.props.hideDropdown}
        btnClass={btnClass}
        onApplyFilters={this.onApply}
      >
        <ul className="dropdown-filter__checkbox-container list-unstyled">
          <ToggleCheckbox
            active={selectedCrags.length == crags.length}
            key="toggle-all"
            value="all"
            onChange={this.toggleAll}
          >
            Select all
          </ToggleCheckbox>
          {this.props.crags.map((crag) => {
            return (
              <ToggleCheckbox
                active={selectedCrags.includes(crag['id'].toString())}
                key={crag['name']}
                value={crag['id']}
                onChange={this.toggleCrag}
              >
                {crag['name'] + (crag['deleted_at'] ? ' (deleted)' : '')}
              </ToggleCheckbox>
            );
          })}
        </ul>
      </DropDownFilter>
    );
  }
}


export default CragsSelect;
// tooltips using the facebox layout
(function($) {

  $.facebox.prepare_tooltip = function(content, options, that) {
    content = content || tooltipContent(that);
    options = options || {}
    $('#facetip .content').css(options).html(content)
    var $facetip = $('#facetip');
    $facetip.css({left: 0, top: $.dimensions.max('height')}).show();
    $.facebox.tt_width = $facetip.width() + 2;
    $facetip.hide();
  }
  $.facebox.move_tooltip = function(event) {
    var left = event.pageX - 20;
    if ( $.facebox.tt_width > $(window).width() - left ) {
      left = $(window).width() - $.facebox.tt_width;
    }
    if ( left < 0 ) left = 0;
    $('#facetip').css({left: left, top: event.pageY + 19});
  }
  $.facebox.show_tooltip = function(event, content, options) {
    if ( content != false ) $.facebox.prepare_tooltip(content, options, this)
    $.facebox.move_tooltip(event)
    $('#facetip').show()
  }
  $.facebox.hide_tooltip = function() {
    $('#facetip').hide();
    $('#facetip .content').removeAttr('style');
  }

  tooltipContent = function(element) {
    var window_url = window.location.href.split('#')[0];
    var target_id  = element.href.replace(window_url,'');
    return $(target_id).html();
  }

})(jQuery);

import * as types from "../common/actionTypes";

const reducer = climberId => {
  const initialState = {
    notifications: [],
    climberId,
    fetching: false,
    error: null
  };

  return (state = initialState, { type, payload }) => {
    switch (type) {
      case `${types.FETCH_NOTIFICATIONS}_PENDING`:
        return { ...state, fetching: true, error: null };
      case `${types.FETCH_NOTIFICATIONS}_REJECTED`:
        return { ...state, fetching: false, error: payload };
      case `${types.FETCH_NOTIFICATIONS}_FULFILLED`:
        return {
          ...state,
          notifications: payload.data.notifications,
          fetching: false,
          error: null
        };
      default:
        return state;
    }
  };
};

export default reducer;

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const Summary = (props) => {
    const { year, ascents, onNextClick, genre_counts } = props;
    const colors = {
        "DWS": '#026eea',
        "Boulder": '#d53b00',
        "Sport": '#F2DF00',
        "Traditional": '#b215b1',
    }
    const orderedGenreCounts = Object.entries(genre_counts).sort(
        (a, b) => { return b[1]-a[1]; }
    );
    const labels = orderedGenreCounts.map((item) => { return item[0]; });
    const data_values = orderedGenreCounts.map((item) => { return item[1]; });
    const chart_data = {
        labels: labels,
        datasets: [
            {
                label: '# of Ascents',
                data: data_values,
                backgroundColor: labels.map((i) => { return colors[i]; })
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    }

    const positiveChange = 'That\'s xx more than last year.'; // That’s XXX more than last year.

    return (
        <div className={'slide-content'}>
            <h2>
                Your ascents
                <br />
                in {year}
            </h2>
            <p>
                You made {ascents.length} ascents in total.
                {ascents.length < 1 &&
                <span> Maybe next year... 😎</span>}
            </p>
            {ascents.length > 0 &&
            <div className={'doughnut-container'}>
                <Doughnut data={chart_data} options={options} />
            </div>
            }
            <div className={"genre-chart-legends"}>
                {labels.map((object, i) =>
                    <div className={`legend-${object.toLowerCase()}`} key={`legend${object}`}>
                        <span style={{backgroundColor: colors[object]}}></span>
                        {object}
                    </div>
                )}
            </div>
            <a className={'btn-continue'} onClick={onNextClick}>
                Continue
            </a>
        </div>
    );
}

export default Summary;
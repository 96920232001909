import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore from "../common/store";
import regularSearchReducer from "./reducer";
import Container from "./Container";

class RegularSearchApp extends Component {
  constructor(props) {
    super(props);
    const reducer = regularSearchReducer();
    const store = configureStore(reducer);
    this.state = {
      store: store
    };
  }
  render() {
    return (
      <Provider store={this.state.store}>
        <Container active={this.props.active} />
      </Provider>
    );
  }
}

export default RegularSearchApp;

import React, { Component } from "react";
import Container from "./Container";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import topoValueReducer from "./reducer.jsx";
import { configurePersistStore } from "../../common/store";

class TopoValue extends Component {
  constructor(props) {
    super(props);
    const reducer = {
      topoValueReducerParams: topoValueReducer(this.props.crags, this.props.param_id)
    };
    const { store, persistor } = configurePersistStore(
      "topoValue",
      reducer,
      null,
      ["TopoValue"]
    );
    this.state = {
      persistor: persistor,
      store: store
    };
  }

  render() {
    return (
      <Provider store={this.state.store}>
        <PersistGate persistor={this.state.persistor}>
          <Container
          />
        </PersistGate>
      </Provider>
    );
  }
}
export default TopoValue;

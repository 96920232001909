/* the old dimensions plugin has been merged into jQuery core */
(function($){

$.dimensions = {
	max: function(dimension) {
	  var win = $(window)[dimension]();
	  var doc = $(document)[dimension]();
	  return Math.max(win, doc);
	}
};

})(jQuery);

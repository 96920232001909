import React from 'react'

const Second = (props) => {
  const { climber, grades, gradingSystemsSport, gradingSystemsBoulder, gradingSystemsTrad, changeGenreSelected, changeGrade, changeGradingSystem, moveForward } = props

  var selectedGrade = 100
  var filteredGrades = []
  grades.forEach((grade) => {
    if (
      grade['points'] != 0 &&
      grade[climber.grading_system_sport] != null &&
      !filteredGrades.map((g) => g[climber.grading_system_sport]).includes(grade[climber.grading_system_sport])
    ) {
      filteredGrades.push(grade)
      if (Math.abs(selectedGrade - climber.grade_int) >= Math.abs(grade['points'] - climber.grade_int)) {
        selectedGrade = grade['points']
      }
    }
  })

  return (
    <div>
      <div className="row">
        <div className="col-md-7 col-sm-8">
          <div className="onboarding__header-description">STEP 2 OF 4</div>
          <h3>Which climbing genres do you enjoy?</h3>
          <div className="onboarding__genres">
            <button
              className={climber.boulders ? 'selected btn-rounded' : 'btn-rounded'}
              onClick={() => changeGenreSelected('boulders')}
            >
              Boulder
            </button>
            <button
              className={climber.trad ? 'selected btn-rounded' : 'btn-rounded'}
              onClick={() => changeGenreSelected('trad')}
            >
              Trad
            </button>
            <button
              className={climber.sport ? 'selected btn-rounded' : 'btn-rounded'}
              onClick={() => changeGenreSelected('sport')}
            >
              Sport
            </button>
            <button
              className={climber.mountains ? 'selected btn-rounded' : 'btn-rounded'}
              onClick={() => changeGenreSelected('mountains')}
            >
              Mountains
            </button>
            <button
              className={climber.ice ? 'selected btn-rounded' : 'btn-rounded'}
              onClick={() => changeGenreSelected('ice')}
            >
              Ice
            </button>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="onboarding__grade-genre-label">
                  Which grading system do you prefer for sport climbing?{' '}
                  <span
                    className="help show-tooltip"
                    data-placement="bottom"
                    title="Different genres and nationalities have different grading systems. The most used system in 27 Crags is French, but you can choose what ever you like."
                  >
                    What's this?
                  </span>
                </label>
                <select
                  className="form-control"
                  value={climber.grading_system_sport}
                  onChange={(e) => changeGradingSystem(e.target.value, 'Sport')}
                >
                  {gradingSystemsSport.map((system) => (
                    <option key={system} value={system}>
                      {system}
                    </option>
                  ))}
                </select>
                <label className="onboarding__grade-genre-label">
                  Which grading system do you prefer for boulders?{' '}
                </label>
                <select
                  className="form-control"
                  value={climber.grading_system_boulder}
                  onChange={(e) => changeGradingSystem(e.target.value, 'Boulder')}
                >
                  {gradingSystemsBoulder.map((system) => (
                    <option key={system} value={system}>
                      {system}
                    </option>
                  ))}
                </select>
                <label className="onboarding__grade-genre-label">
                  Which grading systems do you prefer for trad climbing?
                </label>
                <select
                  className="form-control"
                  value={climber.grading_system_trad}
                  onChange={(e) => changeGradingSystem(e.target.value, 'Trad')}
                >
                  {gradingSystemsTrad.map((system) => (
                    <option key={system} value={system}>
                      {system}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="onboarding__grade-genre-label">
                  Which grade do you prefer?
                </label>
                <select className="form-control" value={selectedGrade} onChange={(e) => changeGrade(e.target.value)}>
                  {filteredGrades.map((grade) => (
                    <option key={grade['points']} value={grade['points']}>
                      {grade[climber.grading_system_sport]}{' '}
                      {grade['points'] == 200
                        ? '(Very easy)'
                        : grade['points'] == 300
                        ? '(Easy)'
                        : grade['points'] == 400
                        ? '(Demanding)'
                        : grade['points'] == 700
                        ? '(Difficult)'
                        : grade['points'] == 1000
                        ? '(Very difficult)'
                        : grade['points'] == 1200
                        ? '(Extreme)'
                        : ''}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-sm-4">
          <div className="onboarding__forward-container--top">
            <button className="btn btn-primary btn-primary--onboarding" onClick={() => moveForward()}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Second

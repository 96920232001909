/* The SectorOverlay can follow_dragging and redraw the sector_line.
 * All sector specific methods are in this file. */
window.SectorOverlay = function(symbol) {
  
  var g_map = app.g_map;
  
  var markers = symbol.markers;
  var sector_line;
  var drag_listener;
  
  function remove_sector_line() {
    sector_line.setMap(null);
  }
  
  function redraw_sector_line() {
    sector_line.setPath([markers.first.latlng(), markers.end_marker.latlng()]);
  }
  
  function follow_dragging() {
    drag_listener = google.maps.event.addListener(g_map, 'mousemove', function(event) {
      redraw_sector_line();
    });
  }
  
  function stop_following() {
    if ( drag_listener ) google.maps.event.removeListener(drag_listener);
    redraw_sector_line();
  }
  
  // Sector specific update_form
  function update_form() {
    symbol.update_form(markers.end_marker, 'end_');
  }
  
  // Sector specific reset
  function reset(attributes) {
    markers.end_marker.set_latlng(attributes.end_latitude, attributes.end_longitude);
    redraw_sector_line();
  }
  
  // Constructor
  var opts = MapMarkerIcons.sector_line();
  opts.map = g_map;
  opts.path = [markers.first.latlng(), markers.end_marker.latlng()];
  sector_line = new google.maps.Polyline(opts);
  
  return {
    g_polyline      : sector_line,
    show            : function(){ sector_line.setVisible(true); },
    hide            : function(){ sector_line.setVisible(false); },
    redraw          : redraw_sector_line,
    remove          : remove_sector_line,
    follow_dragging : follow_dragging,
    stop_following  : stop_following,
    update_form     : update_form,
    reset           : reset,
    make_unobtrusive: function(){ sector_line.set('clickable', false); },
    make_responsive : function(){ sector_line.set('clickable', true);  }
  };
  
}

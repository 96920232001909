// markers are always SinglePointMarkers
window.MarkerCollection = function(markers) {

  var g_map = app.g_map;

  function each_marker(message) {
    $.each(markers, function(i,m){ m[message](); });
  }

  function each_marker_except(excluded_marker, message) {
    $.each(markers, function(i, marker) {
      if ( marker !== excluded_marker ) marker[message]();
    });
  }

  // public marker getters
  
  this.first = markers[0];
  this.end_marker = markers[1];
  
  this.last = function () {
    return markers[markers.length - 1];
  }
  
  // public methods
  
  this.set_observer = function(obs) {
    $.each(markers, function(i, marker) {
      marker.set_observer(obs);
    });
  }
  
  this.highlight = function() {
    each_marker('highlight');
  }

  this.resetHighlight = function() {
    each_marker('resetHighlight');
  }

  this.enable_dragging = function() {
    each_marker('enable_dragging');
  }
  
  this.close_info_window = function() {
    each_marker('close_info_window');
  }
  
  this.remove = function() {
    $.each(markers, function(i, marker) {
      marker.g_marker.setMap(null);
    });
  }
  
  this.hide = function() { each_marker('hide'); }
  this.show = function() { each_marker('show'); }

  this.make_unobtrusive = function() { each_marker('make_unobtrusive'); }
  this.make_responsive  = function() { each_marker('make_responsive');  }
  this.each_marker_except = each_marker_except

}

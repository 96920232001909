import React from 'react';
import TextArea from './TextArea';
import {Field, reduxForm} from 'redux-form';

const CommentForm = ({climber, handleSubmit, isEditing, disabled, pristine}) => {

  const buttonText = isEditing ? 'Save' : 'Comment';

  return (
    <div className="item-content chat-item comment-form">
      <div className="user-image">
        <a href={`/climbers/${climber.paramId}`}>
          <img className="climber-thumb" src={climber.photoUrl} alt="climber_thumbnail"/>
        </a>
      </div>
      <div className="item-details">
        <div>
          <form onSubmit={handleSubmit} className={`no-jquery`}>
            <div className="chat-input-table">
              <div className="text-cell">
                <Field
                  className="comment"
                  name="comment"
                  cols="40"
                  maxLength="500"
                  placeholder="Your comment..."
                  submit={handleSubmit}
                  component={TextArea}
                />
              </div>
              <div className="send-cell">
                <button disabled={disabled || pristine} className="btn btn-default btn-sm send-button"
                        type="submit">{buttonText}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};

export default reduxForm()(CommentForm);
